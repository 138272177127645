import { utils } from "./_utils";

export const filters = {
  buildFilterObject(data, defaultFilters) {
    if (data && data.length > 0) {
      let filters = [];

      defaultFilters &&
        defaultFilters.length > 0 &&
        defaultFilters.forEach((filter) => {
          var filterObj = {};
          let filterId = filter.id;
          let filterName = filter.name;
          let filterOptions = [];
          filterObj.id = filterId;
          filterObj.name = filterName;
          data.forEach((item) => {
            if (item[filterId]) {
              if (Array.isArray(item[filterId])) {
                //check if filterOptions has an object with the same value
                item[filterId].forEach((option) => {
                  if (!filterOptions.find((o) => o.value == option)) {
                    filterOptions.push({ value: option, label: option });
                  }
                });
              } else {
                if (!filterOptions.find((o) => o.value == item[filterId])) {
                  filterOptions.push({
                    value: item[filterId],
                    label: item[filterId],
                  });
                }
              }
            }
          });
          filterObj.options = filterOptions;
          filters.push(filterObj);
        });
      console.log("Filter Options", filters);
      return filters;
    }
  },
  buildTabs(data, defaultFilters) {
    let tabsContainer = [];

    let filters = this.buildFilterObject(data, defaultFilters);
    console.log("FILTERS", filters);

    filters &&
      filters.length > 0 &&
      filters.forEach((filter) => {
        let tabFilters = {};
        let tabField = filter.id;
        let tabName = filter.name;
        tabFilters.field = tabField;
        let tabs = [];
        let orderedTabs = [];

        orderedTabs.push({
          name: "View All",
          id: "all_tabs",
          category: "All",
          service: "All",
          active: true,
        });
        filter.options.forEach((option) => {
          let category = filter.id;
          let categoryName = filter.name;
          let service = option.value;
          let tab = {
            name: service,
            id: service,
            category: category,
            service: service,
            active: false,
          };

          tabs.push(tab);
        });
        //sort tabs alphabetically
        tabs.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        // combine tabs and orderedTabs
        orderedTabs = [...orderedTabs, ...tabs];
        tabFilters.tabs = orderedTabs;
        tabsContainer.push(tabFilters);
      });
    console.log("Tabs", tabsContainer);
    return tabsContainer;
  },

  buildTabsV1(data, defaultFilters) {
    let tabs = [];
    tabs.push({
      name: "All",
      id: "all_tabs",
      category: "All",
      service: "All",
      active: true,
    });
    let filters = this.buildFilterObject(data, defaultFilters);
    console.log("FILTERS", filters);
    filters &&
      filters.length > 0 &&
      filters.forEach((filter) => {
        let category = filter.id;
        let categoryName = filter.name;
        filter.options.forEach((option) => {
          let service = option.value;
          let tab = {
            name: service,
            id: service,
            category: category,
            service: service,
            active: false,
          };
          tabs.push(tab);
        });
      });
    console.log("Tabs", tabs);
    return tabs;
  },
  getFilterOptions() {},

  filter(data, filters) {
    console.log("FILTERS", filters);
    console.log("DATA", data);
    //for each filter, loop through data and add matches to filteredData
    let filteredData = [];
    filters.forEach((filter) => {
      console.log("THIS -> FILTER", filter);
      let filterId = filter.filter;
      let filterValue = filter.value;
      let filterType = filter.type || "array";

      if (filterType == "array") {
        console.log("filtering array");
        filteredData = this.filterArray(data, filterId, filterValue);
      }
      if (filterType == "object") {
        console.log("filtering object");
        filteredData = this.filterObject(data, filterId, filterValue);
      }
    });

    console.log("FILTERED DATA", filteredData);
    return filteredData;
  },
  filterArray(data, filter, value) {
    let filteredData = data.filter((item) => {
      //check item for each value in value array
      let found = false;
      value.forEach((v) => {
        if (item[filter].includes(v)) {
          found = true;
        }
      });
      return found;
    });
    return filteredData;
  },

  filterObject(data, filter, value) {
    let filteredData = data.filter((item) => {
      return item[filter] == value;
    });
    return filteredData;
  },
  filterObjectByArray(data, filter, value) {
    let filteredData = data.filter((item) => {
      console.log("filterObjectByArray", item[filter], value);
      return item[filter].includes(value);
    });
    return filteredData;
  },
  filterObjectByArrayAndObject(data, filter, value, filter2, value2) {
    let filteredData = data.filter((item) => {
      return item[filter].includes(value) && item[filter2] == value2;
    });
    return filteredData;
  },

  removeFilter(data, filter, value) {
    let filteredData = data.filter((item) => {
      return item[filter] != value;
    });
    return filteredData;
  },
};

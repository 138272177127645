import React from "react";
import { useEffect, useState } from "react";
import BoardCard from "./card";
import Button from "../../../elements/Button";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { Icon } from "@iconify/react";
export default function CardColumn({
  column,
  empty,
  showAddColumn,
  sortColumn,
  children,
}) {
  console.log("Column", column);

  const [sortedColumn, setSortedColumn] = useState([]);
  const [sortType, setSortType] = useState("asc");
  const [sortField, setSortField] = useState("createdAt");

  useEffect(() => {
    console.log("Column sortSettings changed", sortType, sortField);
    if (column && column.title) {
      sortColumn(column.title, { sortType, sortField });
    }
  }, [sortType]);

  const handleSort = () => {
    setSortType(sortType === "asc" ? "desc" : "asc");
    setSortField("createdAt");
  };

  return (
    <div
      className={`flex flex-col  text-sm mt-2 mx-2 ${
        !empty && "bg-gray-100 shadow-sm  "
      } rounded-sm w-[280px] min-w-[280px] `}
    >
      <div
        className={` flex items-center justify-between rounded-t-sm items-center py-4 px-4 uppercase font-medium ${
          !empty && "bg-gray-300"
        }`}
      >
        {empty && showAddColumn ? (
          <Button
            color="indigo"
            className="flex w-[280px] min-w-[280px]  text-sm  p-3"
          >
            Add Column +
          </Button>
        ) : (
          column && column.title + " " + column?.cards?.length + " "
        )}
        {!empty && (
          <div>
            <Button iconButton size="lg" handleClick={() => handleSort()}>
              {sortType == "asc" ? (
                <Icon className="h-5 w-5" icon="mdi:sort-calendar-ascending" />
              ) : (
                <Icon className="h-5 w-5" icon="mdi:sort-calendar-descending" />
              )}
            </Button>
          </div>
        )}
      </div>

      <Droppable droppableId={column?.title ? column.title : "column"}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="flex flex-col h-full w-full space-y-3 pb-6 px-3 pt-4 overflow-y-auto
            overflow-x-hidden scrollbar-default list-none
   shadow-sm"
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

import { useState, useEffect } from "react";
import Button from "../../../elements/Button";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
export default function NewMessage(props) {
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const {
    handleSubmit,
    handleBackClick,
    errMessage,
    successMessage,
    loading,
    setMessage,
    message,

    welcomeMessage,
  } = props;
  useEffect(() => {
    console.log("success changed", props.success);
    setSuccess(props.success);
  }, [props.success]);
  useEffect(() => {
    setErr(props.err);
  }, [props.err]);

  return (
    <div className=" items-center justify-center space-y-10">
      {success ? (
        <div className="flex items-center justify-center">
          <div className="pt-10 flex items-center justify-center m-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 48 48 "
            >
              <path
                fill="#43A047"
                d="M40.6 12.1L17 35.7l-9.6-9.6L4.6 29L17 41.3l26.4-26.4z"
              />
            </svg>
            <span className=" block text-sm font-semibold text-gray-900">
              Message sent!
            </span>
          </div>
        </div>
      ) : (
        <div className=" items-center justify-center space-y-10">
          <div className="mx-auto max-w-4xl ">
            <p className="mt-2 text-md  tracking-tight text-gray-900 sm:text-md">
              {welcomeMessage}
            </p>
          </div>
          <div className="mt-10  sm:mt-0 w-full">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Enter your message here"
              className={`h-72 block w-full rounded-md ${
                errMessage && errMessage !== ""
                  ? "border-red-500"
                  : "border-gray-300"
              } shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
            ></textarea>
          </div>
          <div className="w-full  flex items-center justify-between">
            <div className="flex items-center justify-between">
              {handleBackClick && (
                <Button
                  color={"text"}
                  handleClick={
                    () => handleBackClick()
                    // {
                    // setLoading(false);
                    // setErrMessage("");
                    // setSelectingBid(false);
                    // }
                  }
                >
                  <ArrowLeftIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                  Back to Bids
                </Button>
              )}
            </div>
            <div className="flex items-center justify-between">
              <Button
                loader={loading}
                color="secondary"
                handleClick={(e) => handleSubmit(e)}
                // audit={true}
                // action="Confirm Bid Winner"
                // details={{}}
              >
                Send Message
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

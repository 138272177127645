// Note: Pricing Explanations Module

export const pricing_explanations = {
  //type is standard, volume, or graduated
  //recurrence is recurring, one time, or null
  //billing_interval is month, year, etc
  //unit is document, GB, etc
  //price is the price
  //tier is the number of units (e.g. 1-2, 3-4, 5-∞)
  //example: This is a one-time fee of $100 per document
  //example: This is a recurring fee of $100 per document per month
  //example: This is a one-time fee of $100 per GB
  //example: This is a recurring fee of $100 per GB per month for the first 2 GB, $200 per GB per month for the next 2 GB, and $300 per GB per month for all GB after that
  //example: This is a one-time fee of $100 per GB for the first 2 GB, $200 per GB for the next 2 GB, and $300 per GB for all GB after that
  //   {
  //     "pricing": {
  //         "Base": {
  //             "type": "Standard",
  //             "price": "100",
  //             "unit": "Page",
  //             "recurrence": "Recurring",
  //             "billing_interval": "Week"
  //         },
  //         "Additional Language": {
  //             "type": "Volume",
  //             "unit": "Template",
  //             "tiers": [
  //                 {
  //                     "min": 1,
  //                     "max": 2,
  //                     "price": "1",
  //                     "currency": "USD",
  //                     "price_type": "unit_pricing"
  //                 },
  //                 {
  //                     "min": 3,
  //                     "max": "∞",
  //                     "price": "3",
  //                     "currency": "USD",
  //                     "price_type": "flat"
  //                 }
  //             ],
  //             "recurrence": "Recurring",
  //             "billing_interval": "Year"
  //         },
  //         "Address Append": {
  //             "type": "Volume",
  //             "unit": "Page",
  //             "tiers": [
  //                 {
  //                     "min": 1,
  //                     "max": 200,
  //                     "price": "122",
  //                     "currency": "USD",
  //                     "price_type": "flat"
  //                 },
  //                 {
  //                     "min": 201,
  //                     "max": "∞",
  //                     "price": "3",
  //                     "currency": "USD",
  //                     "price_type": "unit_pricing"
  //                 }
  //             ]
  //         }
  //     },
  //     "additional_comments": "qwer"
  // }
  StandardPricing(obj) {
    let price = obj.price;
    let unit = obj.unit;
    let type = obj.type;
    let recurrence = obj.recurrence;
    let billing_interval = obj.billing_interval;

    if (recurrence && recurrence === "Recurring") {
      return `This is a ${recurrence} fee of $${price} per ${unit} per ${billing_interval}`;
    } else {
      return `This is a one-time fee of $${price} per ${unit}`;
    }
  },
  GetVolumeAndGraduatedPricing(obj) {
    if (obj.type === "Volume") {
      return this.VolumePricing(obj);
    } else if (obj.type === "Graduated") {
      return this.GraduatedPricing(obj);
    }
  },
  VolumePricing(obj) {
    let unit = obj.unit;
    let type = obj.type;
    let recurrence = obj.recurrence;
    let billing_interval = obj.billing_interval;
    let tiers = obj.tiers;

    if (recurrence && recurrence === "Recurring") {
      return `This is a recurring volume price.  Volume pricing is the price per unit of the highest tier that the full quantity falls into.  For example, if the tiers are 1-2, 3-4, 5-∞, and the quantity is 3, then the price is the price for 3-4.  If the quantity is 6, then the price is the price for 5-∞.`;
    } else {
      return `This is a one-time volume price.  Volume pricing is the price per unit of the highest tier that the full quantity falls into.  For example, if the tiers are 1-2, 3-4, 5-∞, and the quantity is 3, then the price is the price for 3-4.  If the quantity is 6, then the price is the price for 5-∞.`;
    }
  },
  GraduatedPricing(obj) {
    let unit = obj.unit;
    let type = obj.type;
    let recurrence = obj.recurrence;
    let billing_interval = obj.billing_interval;
    let tiers = obj.tiers;
    return `This is a graduated price.  Graduated pricing is the price per unit of the highest tier that the full quantity falls into.  For example, if the tiers are 1-2, 3-4, 5-∞, and the quantity is 3, then the price is the price for 3-4.  If the quantity is 6, then the price is the price for 5-∞.`;
  },

  explainPrice(price, unit, type, recurrence, billing_interval, tier) {
    let explanation = "";

    if (type === "Standard") {
      if (recurrence === "Recurring") {
        explanation = `This is a recurring fee of ${price} per ${unit} per ${billing_interval}`;
      } else {
        explanation = `This is a one-time fee of ${price} per ${unit}`;
      }
    } else if (type === "Volume") {
      //volume pricing is the price per unit of the highest tier that the full quantity falls into
      //e.g. if the tiers are 1-2, 3-4, 5-∞, and the quantity is 3, then the price is the price for 3-4
      //e.g. if the tiers are 1-2, 3-4, 5-∞, and the quantity is 6, then the price is the price for 5-∞

      if (recurrence == "Recurring") {
        explanation = `This is a recurring ${billing_interval} fee of the price of the highest tier that the full quantity falls into.  For example, 
        if the tiers are ${tier.min}-${tier.max}, ${tier.min}-${tier.max}, ${
          tier.min
        }-${tier.max}, and the quantity is ${
          tier.max
        }, then the price is the price for ${tier.min}-${
          tier.max
        }.  If the quantity is ${
          tier.max + 1
        }, then the price is the price for ${tier.min}-${tier.max}.`;
      }
    } else if (type === "Graduated") {
      if (recurrence === "Recurring") {
        explanation = `This is a recurring fee of ${price} per ${unit} per ${billing_interval} for the first ${tier.min} ${unit}, ${price} per ${unit} per ${billing_interval} for the next ${tier.max} ${unit}, and ${price} per ${unit} per ${billing_interval} for all ${unit} after that`;
      } else {
        explanation = `This is a one-time fee of ${price} per ${unit} for the first ${tier.min} ${unit}, ${price} per ${unit} for the next ${tier.max} ${unit}, and ${price} per ${unit} for all ${unit} after that`;
      }
    }
    return explanation;
  },
};

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  ArrowLongLeftIcon,
  CheckIcon,
  HandThumbUpIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
// import { _assessment } from "../../modules/_assessment";
import { Solicitations } from "../../../modules/_solicitations";
import { Responses } from "../../../modules/_responses";
import Button from "../../../elements/Button";
import ContentSplit from "../../../components/ContentSplit";
import ActionPlanExplanation from "../../../components/ActionPlanExplanation";
import RightActionArea from "../../../components/ActionAreaRight";
import { Label } from "../../../components/Typography/FieldLabel";
import { utils } from "../../../modules/_utils";
import Tip from "../../../components/Tip";
import JurisdictionInput from "./JurisdictionInput";
import WYSIWYGViewer from "../../../components/WYSIWYGViewer";
// import { _assessment } from "../modules/_assessment";
// import { _assessment } from "../modules/_assessment";
export default function Welcome(props) {
  const {
    name,
    company,
    accept,
    decline,
    solicitation,
    handleSetPdfModalOpen,
    dueDate,
    ndaRequired,
    newProject,
    selectedJurisdictions,
  } = props;
  const [fullQuote, setFullQuote] = useState();

  useEffect(() => {
    setFullQuote(props.fullQuote);
  }, [props.fullQuote]);

  // useEffect(() => {
  //   (async () => {
  //     if (location.state.inviteId) {
  //       let invite = await Responses.getFullInvite({ inviteId: inviteId });
  //       if (invite) {
  //         setGuestId(invite._id);
  //         console.log("Got guest id from invite id", invite);
  //       }
  //       //get soliticationId by inviteId
  //       let solicitationId = await Solicitations.getByInviteId({
  //         inviteId: location.state.inviteId,
  //       });
  //       if (solicitationId) {
  //         console.log(
  //           "Got solicitationId from InviteId, settings state",
  //           solicitationId
  //         );
  //         setId(solicitationId);
  //         const solicitation = await Solicitations.getById({
  //           id: solicitationId,
  //         });
  //         if (solicitation && solicitation._id) {
  //           console.log("Got solicitation", solicitation);
  //           setSolicitation(solicitation);
  //           let item = solicitation;

  //           //get response by solicitationId
  //         } else {
  //           setSolicitation({});
  //           setExpiredOrInvalid(true);
  //         }
  //       }
  //     }
  //   })();
  // }, []);
  const parseFirstName = (name) => {
    if (name) {
      let nameArray = name.split(" ");
      return nameArray[0];
    }
  };

  return (
    // <div className="w-screen h-screen overflow-none bg-slate-100">
    // <div className=" ">
    <div className=" flex flex-col h-full justify-between overflow-none ">
      <div
        className="  
        col-span-2 
        overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
        scrollbar-track-rounded-full scrollbar-thumb-rounded-full
        "
      >
        <h2
          id="applicant-information-title"
          className="text-2xl font-medium text-slate-900"
        >
          Welcome to Breachlink, {parseFirstName(name)}!
        </h2>
        <Tip className="mt-5  text-slate-500">
          You've been invited to bid on a breach response project
          <span className="font-medium ml-1">
            {solicitation &&
              solicitation._id &&
              "by " + solicitation.tenantId?.tenantCompany}
          </span>
          . If you're not interested, click the Decline button to let the vendor
          know. If you would like to respond, start by filling in the Supplier
          Information tab. If the client requires you to review the NDA prior to
          bidding, you can view it below and indicate your acceptance or
          decline.
        </Tip>
        {solicitation && solicitation._id ? (
          <div>
            {solicitation && solicitation.rtf_description ? (
              <WYSIWYGViewer
                editorState={
                  solicitation.rtf_description
                    ? solicitation.rtf_description[0]
                      ? solicitation.rtf_description[0]
                      : solicitation.rtf_description
                    : {}
                }
              />
            ) : (
              <div className="font-medium text-secondary-600">
                {solicitation && solicitation.description && (
                  <div>{solicitation.description}</div>
                )}
              </div>
            )}
            <p className="mt-5  text-slate-500">
              <span className="font-medium">Requestor:</span>{" "}
              {solicitation && solicitation.tenantId?.tenantCompany}
            </p>
            <p className="mt-5  text-slate-500">
              <span className="font-medium">Bid Request ID:</span>{" "}
              {solicitation && //if solicitation is not empty
                solicitation._id &&
                "BR-" +
                  solicitation.projectId?.projectId +
                  "-" +
                  solicitation.incrementId}
            </p>
            <p className="mt-5  text-slate-500">
              <span className="font-medium"> Bid Request Name:</span>{" "}
              {solicitation && solicitation.name}
            </p>
            <p className="mt-5  text-slate-500">
              <span className="font-medium"> Bid Request Due Date:</span>{" "}
              {solicitation && utils.formatDate(solicitation.deadline, "short")}
            </p>

            <p className="mt-5  text-slate-500">
              <span className="font-medium"> Insurer:</span>{" "}
              {solicitation && solicitation.projectId?.insurerName}
            </p>
          </div>
        ) : (
          <div className="font-medium text-secondary-600">
            This bid request is no longer available.
          </div>
        )}
        {/* <p className="mt-5  text-slate-500">
            <span className="font-medium"> Affected Individuals:</span>{" "}
            <JurisdictionInput
              create={false}
              newProject={newProject}
              field={"jurisdictions"}
              onFieldChange={null}
              selectedJurisdictions={
                solicitation && solicitation.projectId?.jurisdictions
              }
              setSelectedJurisdictions={null}
            />
          </p> */}
      </div>

      <div id="cardFooter" className=" bg-white w-full pb-6 ">
        <ul role="list" className="mb-6">
          {solicitation &&
            solicitation.attachments &&
            solicitation.attachments.length > 0 && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-slate-500">
                  Attachments
                </dt>

                <dd className="mt-1 text-sm text-slate-900">
                  <ul
                    role="list"
                    className="divide-y divide-gray-200 rounded-md border border-gray-200"
                  >
                    {solicitation &&
                      solicitation.attachments &&
                      solicitation.attachments.map((attachment) => (
                        <li
                          key={attachment.name}
                          onClick={() => {
                            handleSetPdfModalOpen(attachment, "solicitation");
                          }}
                          className="flex cursor-pointer items-center justify-between py-3 pl-3 pr-4 text-sm"
                        >
                          <div className="flex w-0 flex-1 items-center">
                            <PaperClipIcon
                              className="h-5 w-5 flex-shrink-0 text-slate-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 w-0 flex-1 truncate">
                              {attachment.name}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <a
                              // href={attachment.href}
                              onClick={() => {
                                handleSetPdfModalOpen(
                                  attachment,
                                  "solicitation"
                                );
                              }}
                              className="font-medium text-secondary-600 hover:text-secondary-500"
                            >
                              View
                            </a>
                          </div>
                        </li>
                      ))}
                  </ul>
                </dd>
              </div>
            )}
        </ul>
        <div className="flex items-center justify-between">
          <div className="relative flex items-start">
            {ndaRequired && (
              <div className="flex flex-row">
                <div className=" h-5 items-center">
                  {fullQuote &&
                  fullQuote.accept_nda &&
                  fullQuote.accept_nda == true ? (
                    <CheckIcon className="h-5 w-5  text-green-500" />
                  ) : (
                    <input
                      id="accept_nda"
                      name="accept_nda"
                      type="checkbox"
                      className="flex h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      defaultChecked={fullQuote && fullQuote.accept_nda}
                      value={fullQuote && fullQuote.accept_nda}
                      onChange={(e) => {
                        props.handleCheckNDABox();
                        //update solicitation with true value
                      }}
                    />
                  )}
                </div>
                <div className=" ml-3 text-sm">
                  <p className="text-slate-600">
                    Check this box to confirm that you have read and agree to
                    the NDA.
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* <Button
              handleClick={() => {
                return null;
              }} //use this button to unlock the tabs
              color={"secondary"}
              fullwidth
            >
              Lets do this!
            </Button> */}
        </div>
      </div>
    </div>
    // </div>
  );
}

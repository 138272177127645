export function Paragraph({ children, variant, xs, sm, md, lg, className }) {
  return (
    <p
      className={`${
        xs
          ? "text-xs"
          : sm
          ? "text-sm"
          : md
          ? "text-md"
          : lg
          ? "text-lg"
          : "text-md"
      }
      
      ${variant == "dark" ? "text-slate-300" : "text-slate-700"} ${className}
      
      `}
    >
      {children}
    </p>
  );
}

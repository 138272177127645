import { Fragment, useEffect, useState } from "react";
import {
  Bars3Icon,
  CalendarIcon,
  CogIcon,
  HomeIcon,
  MagnifyingGlassCircleIcon,
  MapIcon,
  MegaphoneIcon,
  SquaresPlusIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { ChevronLeftIcon, EnvelopeIcon } from "@heroicons/react/20/solid";
import { StarIcon } from "@heroicons/react/20/solid";
import { _marketplace } from "../../modules/_marketplace";
import Badge from "../../components/Badge";
import DynamicImage from "../../components/DynamicImage";
import MenuDropdown from "../../components/Menu";
import { FeatureFlag } from "../../components/FeatureFlag";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function MarketplaceListingGrid({
  profile,
  show,
  setShow,
  setSelectedItem,
  selectedItem,
  data,
  updateMarketplace,
}) {
  const [mktItems, setMktItems] = useState();

  useEffect(() => {
    console.log("mktItems changed", data);
    setMktItems(data);
  }, [data]);

  return (
    <div className="relative z-0 flex flex-1   ">
      <main
        className={`relative 
   
        transition-all duration-500 ease-in-out h-[calc(100vh-175px)] z-0 flex-1 focus:outline-none xl:order-last
        overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full
        `}
      >
        <div className="pb-6 pr-6 gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5 sm:mx-0 md:grid-cols-3 lg:grid-cols-4">
          {mktItems &&
            mktItems.length > 0 &&
            mktItems.map((product) => (
              <div
                key={product._id}
                className="hover:bg-gray-50 p-4 sm:p-6 shadow-xl bg-white hover-bg-gray-50 hover:cursor-pointer group relative rounded border border-gray-200 "
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedItem(product);
                  setShow(true);
                }}
              >
                <div className="flex justify-between ">
                  <div className="flex items-center">
                    <div className="flex items-center  max-w-12 h-12 w-12 aspect-h-1 aspect-w-1 rounded-full">
                      {product.image &&
                        product.image !== "" &&
                        product.image !== null &&
                        product.image !== undefined && (
                          <img
                            //base64 image
                            src={product.image}
                            // alt={product.imageAlt}
                            className="inline-block w-12 object-scale-down rounded-full ring-2 ring-white"
                          />
                        )}
                    </div>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedItem(product);
                        setShow(true);
                      }}
                      className="ml-3"
                    >
                      <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                        {product.title}
                      </p>
                      <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                        View profile
                      </p>
                    </div>
                  </div>
                  <div className="flex z-25">
                    <FeatureFlag type="auth" featureName="Marketplace Menu">
                      <MenuDropdown
                        data={product}
                        handleChangeMarketplaceVisibility={updateMarketplace}
                        handleChangeFeaturedVendor={updateMarketplace}
                        setSelectedItem={setSelectedItem}
                      />
                    </FeatureFlag>
                  </div>
                </div>
                <div>
                  <div className=" py-4  ">
                    {/* <h3 className="text-md font-medium text-gray-900">
                    <a href={product.href && product.href}>
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.title}
                    </a>
                  </h3> */}

                    {/* <div className="mt-3 text-xs flex flex-col items-center">
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          product && product.previewText && product.previewText,
                      }}
                    ></p>
                  </div> */}
                    {/* <div className="mt-3 flex  items-center">
                    <div className="flex items-center flex-row-reverse">
                      {[0, 1, 2, 3, 4].map((rating) => (
                        <StarIcon
                          key={rating && rating}
                          className={classNames(
                            rating && product.rating && product.rating > rating
                              ? "text-yellow-400"
                              : "text-gray-200",
                            "h-5 w-5 flex-shrink-0 "
                          )}
                          aria-hidden="true"
                        />
                      ))}
                    </div>
                    {product.reviewCount && (
                      <p className="mt-1 text-sm text-gray-500">
                        {product.reviewCount && product.reviewCount} reviews
                      </p>
                    )}
                  </div> */}
                    {profile && profile.role == "Super Admin" && (
                      <div className="mt-3 flex space-x-4 items-center ">
                        {product.published && product.published == true ? (
                          <span className="uppercase font-medium text-xs text-emerald-700 bg-emerald-50 rounded px-2">
                            Published
                          </span>
                        ) : (
                          <span className="uppercase font-medium text-xs text-red-700 bg-red-50 rounded px-2">
                            Not Published
                          </span>
                        )}
                        {product.featured && product.featured == true ? (
                          <span className="uppercase font-medium text-xs text-emerald-700 bg-emerald-50 rounded px-2">
                            Featured
                          </span>
                        ) : (
                          <span className="uppercase font-medium text-xs text-red-700 bg-red-50 rounded px-2">
                            Not Featured
                          </span>
                        )}
                      </div>
                    )}
                    <p className="mt-4 text-base font-medium text-gray-900"></p>
                  </div>
                  <div className="absolute bottom-4">
                    {product.services && product.services.length > 0 && (
                      <div className="flex flex-row space-x-2">
                        {product.services &&
                          product.services.slice(0, 2).map((service) => (
                            <div className="flex flex-row space-x-2">
                              <div className="flex items-center justify-center">
                                <Badge color={"primary"}>{service}</Badge>
                              </div>
                            </div>
                          ))}
                        {product.services && product.services.length > 2 && (
                          <a
                            class="flex items-center justify-center p-2  text-xs 
          font-medium  rounded-full 
          transition-all ease-in-out hover:scale-110 duration-150"
                            href="#"
                          >
                            + {product.services && product.services.length - 2}
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </main>
    </div>
  );
}

import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const Files = {
  profile() {
    if (
      _auth.getUserProfile() === null ||
      _auth.getUserProfile() === undefined
    ) {
      return JSON.parse(localStorage.getItem("guestProfile"));
    } else {
      return _auth.getUserProfile();
    }
  },

  async uploadNewSolicitationDocs(file, type) {
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    // try {
    console.log("uploadNewSolicitationDocs before api", file);
    const data = new FormData();

    data.append("file", file);
    data.append("companyId", profile.companyId);
    //   data.append("company", profile.company);
    data.append("tenantId", profile.tenantId);
    data.append("type", type);
    let response = await postRequest("upload/bid-request-docs", data);
    if (response) {
      console.log("uploadNewSolicitationDocs", response.data.data);
      return response.data.data;
    } else {
      console.log("uploadNewSolicitationDocs failed", response);
      return false;
    }
    // } catch (e) {
    //   console.log(e);
    // }
  },
  async getFileById(id, documentType, solicitationId) {
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    let data = {
      id: id,
      companyId: profile.companyId,
      tenantId: profile.tenantId,
      solicitationId: solicitationId,
      // path: path,
      documentType: documentType,
    };
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("upload/get-file-by-id", data);
    if (response) {
      console.log("getFileById", response.data.data);
      return response.data.data;
    } else {
      console.log("getFileById failed", response);
      return false;
    }
  },
  async getFileDownloadUrl(id) {
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    let data = {
      docId: id,
      companyId: profile.companyId,
      tenantId: profile.tenantId,
    };
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("upload/get-file-by-id", data);
    if (response) {
      console.log("getFileById", response.data.data.url);
      return response.data.data.url;
    } else {
      console.log("getFileById failed", response);
      return false;
    }
  },
  async convertToExcel(tableData) {
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    let data = {
      tableData,
    };
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("files/convertToExcel", data);
    if (response) {
      // console.log("convertToExcel", response.data.data);
      const base64 = response.data.data;
      const binary = atob(base64); // Decode the base64 data
      const arrayBuffer = new Uint8Array(binary.length).map((_, i) =>
        binary.charCodeAt(i)
      ).buffer; // Convert the binary data to an ArrayBuffer
      const blob = new Blob([arrayBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "responses.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      console.error("Error:", response.statusText);
      return false;
    }
  },
};

import React from "react";
import BidList from "../pages/projects/BidList";

function BidRequestListWithBids({ solicitations, handleClick }) {
  return (
    <div className="space-y-12 w-full">
      {solicitations &&
        solicitations.map((solicitation) => {
          let allBids = solicitation?.responses;
          return (
            allBids &&
            allBids.length > 0 && (
              <div key={solicitation?._id} className="">
                <div className="mb-2 border-t border-b border-gray-200 bg-gray-50 py-2 px-4">
                  <h2 className="uppercase text-gray-500 font-semibold">
                    {solicitation?.name}
                  </h2>
                </div>
                <BidList
                  onItemClick={handleClick}
                  project={allBids}
                  solicitation={solicitation}
                  items={
                    allBids.length > 0
                      ? allBids.length > 3
                        ? allBids
                            //sort by date newest to oldest
                            .sort((a, b) => {
                              return (
                                new Date(b.createdAt) - new Date(a.createdAt)
                              );
                            })
                            .slice(0, 10)
                        : allBids
                      : []
                  }
                />
              </div>
            )
          );
        })}
    </div>
  );
}

export default BidRequestListWithBids;

import React, { useEffect } from "react";
import Uppy from "@uppy/core";
import { _auth } from "../modules/_auth";
import { baseUrl } from "../config/urlRoutes";
import XHRUpload from "@uppy/xhr-upload";
import DropTarget from "@uppy/drop-target";

export const UppyDropTarget = ({ children }) => {
  const DROPZONE_ID = "myDropzone";
  const uppy = new Uppy({ id: "myUppy" }).use(XHRUpload, {
    endpoint: baseUrl + "/assessments/templates/questions/upload",
    // "http://localhost:2000/api/assessments/templates/questions/upload",
    formData: true,
    fieldName: "assessment_template_import",
    method: "post",
    headers: {
      "x-access-token": _auth.getToken(),
    },
  });

  useEffect(() => {
    uppy.use(DropTarget, {
      target: "#dropzone",
      id: DROPZONE_ID,
    });
  }, []);

  return (
    <div
      id="dropzone"
      data-id="dropzone-inner"
      className="w-full col-span-full flex items-center"
    >
      {children}
    </div>
  );
};

import React, { useEffect } from "react";
import Board from "./parts/board";
import { useState } from "react";
import { Solicitations } from "../../modules/_solicitations";

const MyPlan = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await Solicitations.getByTenantId();
      setData(response);
    })();
  }, []);
  //create columns from solicitation status
  const createColumns = (solicitations, type) => {
    let existing = [];
    let columns = [];

    if (solicitations && solicitations.length > 0) {
      //if type == "workflow", create columns from solicitation.workflow, else create columns from solicitation.status
      //make sure to only have 1 column per status/workflow
      //the colum title should be the status/workflow
      solicitations.forEach((solicitation) => {
        if (type === "workflow") {
          if (!existing.includes(solicitation.workflow)) {
            columns.push({ title: solicitation.workflow });
            existing.push(solicitation.workflow);
          }
        } else {
          if (!existing.includes(solicitation.status)) {
            columns.push({ title: solicitation.status });
            existing.push(solicitation.status);
          }
        }
      });
    }
    return columns;
  };
  return (
    <div className="h-full">
      <div className="flex flex-row px-2 overflow-x-auto w-full h-full p-6">
        <div
          id="content-card"
          className="h-full w-full rounded bg-white shadow-xl border border-gray-200 p-6"
        >
          <Board
            columns={createColumns(data, "workflow")}
            cards={data}
            type={"workflow"}
          />
        </div>
      </div>
    </div>
  );
};

export default MyPlan;

import { LightBulbIcon } from "@heroicons/react/20/solid";
import React from "react";

export default function Tip(props) {
  const { children, color, icon, noIcon, className, ...rest } = props;
  const colSpan = props.colSpan ? props.colSpan : false;
  const noMargin = props.noMargin ? props.noMargin : false;
  //   const classes = useStyles();
  const getColor = () => {
    if (color === undefined) {
      return (
        " bg-indigo-50 border-2 border-dashed border-indigo-500 " +
        props.className
      );
    }
    switch (color) {
      case "primary":
        return " bg-primary-50 border-2 border-dashed border-primary-500";
      case "secondary":
        return " bg-indigo-50 border-2 border-dashed border-indigo-500";
      case "emerald":
        return " bg-emerald-50 border-2 border-dashed border-emerald-500";
      case "gray":
        return " bg-gray-50 border-2 border-dashed border-gray-200";
      case "tertiary":
        return " bg-tertiary-200  text-primary-600";
      case "accent":
        return " bg-accent-500  text-white-600";
      case "green":
        return " bg-green-50 border-2 border-dashed border-green-500";
      case "purple":
        return " bg-primary-200 text-primary-600";
      case "orange":
        return " bg-orange-200  text-primary-600";
      case "red":
        return " bg-red-50 border-2 border-dashed border-red-500  text-primary-600";
      case "light-purple":
        return " bg-primary-200  text-purple-500";
      case "lime":
        return " bg-lime-500  text-primary-600 ";
      case "white":
        return " bg-white border-gray-200  text-slate-200 ";
      case "text":
        return "text-slate-500  mx-3 p-1";
      case "link":
        return "text-blue-500 ";
      default:
        return " bg-primary-500 text-primary-600" + props.className;
    }
  };
  return (
    <div
      className={`${noMargin == true ? "my-0" : "my-6"}
                      ${colSpan ? "col-span-" + colSpan : ""} `}
    >
      <p className={`p-2 ${getColor()} text-sm text-slate-600`}>
        {icon ? (
          icon
        ) : noIcon ? (
          ""
        ) : (
          <LightBulbIcon className="my-auto inline-flex w-5 h-5 mr-1 fill-yellow-400" />
        )}
        {children}
      </p>
    </div>
  );
}

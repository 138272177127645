import React, { useEffect, useMemo, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import CenterCard from "../../../components/CenterCard";
import Button from "../../../elements/Button";

//test change

function VendorRedirect() {
  const location = useLocation();
  const history = useHistory();
  const [answer, setAnswer] = useState(null);

  return (
    <div>
      <div className=" overflow-x-hidden overflow-y-hidden">
        <CenterCard
          size={"lg"}
          headingText={"Your bid has been submitted!"}
          headingSubtext={""}
        >
          {!answer ? (
            <div className="space-y-4">
              <div>
                Looks like your company has Breachlink account. If you have not
                been invited to join, we suggest reaching out to your admin and
                getting an free account so you can track your bids, make edits,
                and more.
              </div>
              <div>You can safely close your browser now.</div>
            </div>
          ) : (
            // <div>
            //   <div>
            //     Looks like your company has an account. Would you like us to
            //     email your admin on your behalf to get you signed up for an
            //     account?
            //   </div>
            //   <div className="flex flex-row items-center py-6 justify-around">
            //     <Button color="secondary">Yes, please</Button>
            //     <Button color="outline">No, thanks</Button>
            //   </div>
            // </div>
            <div>Thanks!</div>
          )}
        </CenterCard>
      </div>
    </div>
  );
}

export default VendorRedirect;

export default function Chip({ value, index }) {
  return (
    <span
      key={index}
      className="mr-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-slate-100 text-slate-800"
    >
      {value}
    </span>
  );
}

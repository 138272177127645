import { CheckIcon } from "@heroicons/react/20/solid";
import React from "react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Steps(props) {
  const [steps, setSteps] = React.useState(props.steps);

  React.useEffect(() => {
    console.log("Steps", props.steps);
    setSteps(props.steps);
  }, [props.steps]);

  const calculateNegativeMargin = (step, index, stepsLength) => {
    let first = index === 0;
    let last = index === stepsLength - 1;
    if (first) {
      return 0;
    }
    if (last) {
      return 4;
    }
    //calculate negative margin by character length
    let margin = 0;
    //get character count of step
    let characterCount = step.length;
    //calculate margin
    if (characterCount < 8) {
      margin = 1;
    } else if (characterCount < 8) {
      margin = 2;
    } else if (characterCount > 8) {
      margin = 6;
    } else {
      margin = 4;
    }
    return margin;
  };

  return (
    <nav aria-label="Progress">
      <ol role="list" className="flex items-center justify-around ">
        {steps.map((step, stepIdx) => (
          <>
            <li
              key={step.name}
              className={classNames(
                stepIdx !== steps.length - 1
                  ? "w-full pr-8 sm:pr-4 md:pr-6 lg:pr-6 2xl:pr-20"
                  : "",
                "relative"
              )}
            >
              {step.status === "complete" ? (
                <>
                  <div
                    className={`absolute inset-${
                      stepIdx !== steps.length - 1 ? "0" : "4"
                    } flex items-center`}
                    aria-hidden="true"
                  >
                    <div className="h-0.5 w-full bg-secondary-600" />
                  </div>
                  <div className="relative flex mt-5  h-8 w-8 items-center justify-center rounded-full bg-secondary-600 hover:bg-secondary-900">
                    <CheckIcon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{step.name}</span>
                  </div>
                  <div
                    className={`inline -ml-${calculateNegativeMargin(
                      step.name,
                      stepIdx,
                      steps.length
                    )} text-xs text-center whitespace-nowrap`}
                  >
                    {step.name}
                  </div>
                </>
              ) : step.status === "current" ? (
                <>
                  <div
                    className={`absolute inset-${
                      stepIdx !== steps.length - 1 ? "0" : "4"
                    } flex items-center`}
                    aria-hidden="true"
                  >
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <div
                    href="#"
                    className="relative flex mt-5 h-8 w-8 items-center justify-center rounded-full border-2 border-secondary-600 bg-white"
                    aria-current="step"
                  >
                    <span
                      className="h-2.5 w-2.5 rounded-full bg-secondary-600"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{step.name}</span>
                  </div>
                  <div
                    className={`nowrap inline -ml-${calculateNegativeMargin(
                      step.name,
                      stepIdx,
                      steps.length
                    )} text-xs text-center whitespace-nowrap`}
                  >
                    {step.name}
                  </div>
                </>
              ) : step.status === "skipped" ? (
                <>
                  <div
                    className={`absolute inset-${
                      stepIdx !== steps.length - 1 ? "0" : "4"
                    } flex items-center`}
                    aria-hidden="true"
                  >
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <div className="group relative flex mt-5 h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
                    <span
                      className="h-2.5 w-2.5 rounded-full  bg-gray-300"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{step.name}</span>
                  </div>
                  <div
                    className={`inline whitespace-nowrap -ml-${calculateNegativeMargin(
                      step.name,
                      stepIdx,
                      steps.length
                    )} text-xs text-center`}
                  >
                    {step.name}
                    {/* {step.status} */}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`absolute inset-${
                      stepIdx !== steps.length - 1 ? "0" : "4"
                    } flex items-center`}
                    aria-hidden="true"
                  >
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <div
                    href="#"
                    className="group relative flex mt-5 h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                  >
                    <span
                      className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{step.name}</span>
                  </div>
                  <div
                    className={`inline whitespace-nowrap -ml-${calculateNegativeMargin(
                      step.name,
                      stepIdx,
                      steps.length
                    )} text-xs text-center`}
                  >
                    {step.name}
                  </div>
                </>
              )}
            </li>
          </>
        ))}
      </ol>
    </nav>
  );
}

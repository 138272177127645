import Button from "../../elements/Button";
import {
  XMarkIcon,
  ChevronLeftIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function VendorProfile({
  setSidebarPanel,
  selectedItem,
  team,
  tabs,
  setShow,
}) {
  return (
    <>
      <article>
        <div>
          <div className="flex border-b border-gray-200 justify-between items-center">
            <div className="flex py-2 items-center">
              {" "}
              <div className="p-2 h-14 items-center aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-white group-hover:opacity-75">
                {selectedItem.image &&
                  selectedItem.image !== "" &&
                  selectedItem.image !== null &&
                  selectedItem.image !== undefined && (
                    <img
                      src={selectedItem.image}
                      // alt={product.imageAlt}
                      className="h-full w-full object-cover object-center"
                    />
                  )}
              </div>
              <div className="ml-4 in-w-0 items-center  flex-1 sm:hidden 2xl:block">
                <h1 className="truncate text-2xl font-bold text-gray-900">
                  {selectedItem && selectedItem.title}
                </h1>
              </div>
            </div>
            <div className="py-2 px-2  flex items-right">
              <XMarkIcon
                className="h-6 w-6 hover:cursor-pointer"
                onClick={() => setShow(false)}
              />
            </div>
          </div>

          <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
            <div className="mt-0 sm:mt-0 sm:flex sm:items-end sm:space-x-5">
              <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                {/* <div className="in-w-0 flex-1 sm:hidden 2xl:block">
                  <h1 className="truncate text-2xl font-bold text-gray-900">
                    {selectedItem && selectedItem.title}
                  </h1>
                </div> */}
                <div className="justify-stretch flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                    onClick={() => setSidebarPanel("message")}
                  >
                    <EnvelopeIcon
                      className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span>Message</span>
                  </button>
                  {/* <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                >
                  <PhoneIcon
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Call</span>
                </button> */}
                </div>
              </div>
            </div>
            <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
              <h1 className="truncate text-2xl font-bold text-gray-900">
                {selectedItem && selectedItem.title && selectedItem.title}
              </h1>
            </div>
          </div>
        </div>
        {/* tabs */}
        {/* <div className="mt-6 sm:mt-2 2xl:mt-5">
          <div className="border-b border-gray-200">
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      tab.current
                        ? "border-pink-500 text-gray-900"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div> */}

        <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:pr-12 ">
          <dl className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div className="col-span-2">
              <dt className="text-sm font-medium text-gray-500">About</dt>
              <dd
                className=" mt-1 max-w-prose space-y-5 text-sm text-gray-900"
                dangerouslySetInnerHTML={{
                  __html:
                    selectedItem &&
                    selectedItem.content &&
                    selectedItem.content,
                }}
              />
            </div>

            <div className="col-span-1 row-span-2 space-y-5">
              {selectedItem &&
                selectedItem.contactFields &&
                Object.keys(selectedItem && selectedItem.contactFields).map(
                  (field) => (
                    <div key={field}>
                      <dt className="capitalize text-sm font-medium text-gray-500">
                        {field}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {selectedItem &&
                          selectedItem.contactFields &&
                          selectedItem.contactFields[field]}
                      </dd>
                    </div>
                  )
                )}
            </div>
            <div className="col-span-2">
              <dt className="text-sm font-medium text-gray-500">Services</dt>
              <dd
                className=" mt-1 max-w-prose space-y-5 text-sm text-gray-900"
                // dangerouslySetInnerHTML={{ __html: profile.about }}
              >
                {selectedItem && selectedItem.services && (
                  <ul className="list-disc pl-6">
                    {selectedItem.services.map((service) => (
                      <li key={service}>{service}</li>
                    ))}
                  </ul>
                )}
              </dd>
            </div>
          </dl>
        </div>

        {/* <div className="mx-auto mt-8 max-w-5xl px-4 pb-12 sm:px-6 lg:px-8">
          <h2 className="text-sm font-medium text-gray-500">Team members</h2>
          <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {team.map((person) => (
              <div
                key={person.handle}
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400"
              >
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={person.imageUrl}
                    alt=""
                  />
                </div>
                <div className="min-w-0 flex-1">
                  <a href="#" className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900">
                      {person.name}
                    </p>
                    <p className="truncate text-sm text-gray-500">
                      {person.role}
                    </p>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </article>
    </>
  );
}

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Responses } from "../../modules/_responses";
import ListTable from "../projects/components/ListTable";
import ListTableItem from "../projects/components/ListTableItem";
import { utils } from "../../modules/_utils";
import { TrashIcon } from "@heroicons/react/24/outline";
import PageHeader from "../../components/PageHeader";
import Steps from "../../components/Steps";
import Tabs from "../component/Tabs";
import ModalCookies from "../../components/ModalCookies";
import NotificationBanner from "../../components/NotificationBanner";
import { _auth } from "../../modules/_auth";

export default function VendorProjects() {
  const fromModal = false;
  const history = useHistory();
  const profile = _auth.getUserProfile();

  console.log("DEBUG --> VendorProjects profile", profile);
  const [data, setData] = useState([]);
  const [pendingBids, setPendingBids] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Invited");
  useEffect(() => {
    (async () => {
      console.log("Running VendorProjects useEffect");
      const response = await Responses.getVendorProjects();
      if (response) {
        console.log("ALL DATA", response);
        setData(response);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await Responses.getPendingBids();
      if (response) {
        console.log("PENDING BIDS", response);
        setPendingBids(response);
      }
    })();
  }, []);

  const navigateToProject = (item) => {
    history.push({
      pathname: "/projects/project-details/vendor-bid-info",
      state: {
        inviteId: item.inviteId,
        name: profile.name,
        email: item.email,
        companyId: profile.tenantCompanyId,
        vendorCompanyId: profile.tenantCompanyId,
        solicitationId: item.solicitationId,
        profile: profile,
        fromVendorProjectList: true,
      },
    });
  };

  const buildSteps = (solicitation) => {
    console.log("BuildSteps solicitation", solicitation);
    const workflow = solicitation.solicitationWorkflow;
    const wfStatus = solicitation.soclicitationStatus;
    const allBidsReceived = solicitation.allBidsReceived;
    let deadline = new Date(solicitation.deadline);
    let now = new Date();
    let expired = false;
    expired = deadline < now;

    console.log("BuildSteps", workflow, wfStatus, allBidsReceived, expired);

    // const workflowOptions = [
    //   "accepting_bids",
    //   "responses_closed",
    //   "winner_selected",
    //   "winner_notified",
    //   "closed",
    //   "archived",
    // ];

    const stepOptions = [
      "Created",
      "Accepting Bids",
      "Bidding Closed",
      "Reviewing Bids",
      "Winner Selected",
      "Winner Notified",
      "Closed",
      "Archived",
    ];

    const statusOptions = ["complete", "current", "incomplete", "skipped"];

    const steps = stepOptions.map((stepOption) => {
      let status = "incomplete";
      //When closing, the wfStatus is Closed, the current workflow should be complete
      if (stepOption === "Created") {
        status = "complete";
      } else if (stepOption === "Accepting Bids") {
        // change the backend so that when the last response is submitted, the workflow is changed to "Bidding Closed"
        if (workflow === "Accepting Bids" || workflow === "Open") {
          if (expired == true || allBidsReceived == true) {
            status = "complete";
          } else if (["Open", "Active"].includes(wfStatus)) {
            status = "current";
          } else if (["Closed", "Archived"].includes(wfStatus)) {
            status = "skipped";
          } else {
            status = "incomplete";
          }
        } else {
          if (
            ["Bidding Closed", "Winner Selected", "Winner Notified"].includes(
              workflow
            )
          ) {
            status = "complete";
          } else {
            // status = "skipped";
          }
        }
      } else if (stepOption === "Bidding Closed") {
        if (workflow === "Bidding Closed") {
          if (["Open", "Active"].includes(wfStatus)) {
            status = "current";
          } else if (["Closed", "Archived"].includes(wfStatus)) {
            status = "skipped";
          } else {
            status = "incomplete";
          }
        } else {
          if (workflow === "Accepting Bids" && expired == true) {
            status = "current";
          } else if (
            [
              "Winner Selected",
              "Winner Notified",
              "Closed",
              "Archived",
            ].includes(workflow)
          ) {
            status = "complete";
          } else {
            status = "incomplete";
          }
        }
      } else if (stepOption === "Winner Selected") {
        if (workflow === "Winner Selected") {
          if (["Open", "Active"].includes(wfStatus)) {
            status = "complete";
          } else if (["Closed", "Archived"].includes(wfStatus)) {
            status = "complete"; //change this to market complete instead of skipped when Status is Closed
          } else {
          }
        } else {
          if (["Winner Notified", "Closed", "Archived"].includes(workflow)) {
            status = "complete";
          } else if (["Closed", "Archived"].includes(wfStatus)) {
            status = "skipped";
          } else {
            status = "incomplete";
          }
        }
      } else if (stepOption === "Winner Notified") {
        if (workflow === "Winner Notified" || workflow === "winner notified") {
          if (["Open", "Active"].includes(wfStatus)) {
            status = "complete";
          } else if (["Closed", "Archived"].includes(wfStatus)) {
            status = "complete"; //change this to mark it complete instead of skipped when Status is Closed
          } else {
            if (["Closed", "Archived"].includes(workflow)) {
              status = "complete";
            } else {
              status = "skipped";
            }
          }
        } else {
          if (["Closed", "Archived"].includes(workflow)) {
            status = "complete";
          } else if (["Closed", "Archived"].includes(wfStatus)) {
            status = "skipped";
          } else if (["Winner Selected"].includes(workflow)) {
            status = "current";
          } else {
            status = "incomplete";
          }
        }
      } else if (stepOption === "Closed") {
        if (["Closed"].includes(wfStatus)) {
          status = "current";
        } else {
          if (["Archived"].includes(wfStatus)) {
            status = "complete";
          }
        }
      } else if (stepOption === "Archived") {
        if (["Archived"].includes(wfStatus)) {
          status = "complete";
        } else {
          status = "incomplete";
        }
      } else {
      }

      return { name: stepOption, status };
    });

    return steps;
  };

  return (
    <>
      {" "}
      <div className="absolute left-0 right-0 top-[48px] h-12 text-center">
        <NotificationBanner
          showClose={false}
          text={
            "This page may contain broken links or incomplete features.  If you discover any issues, please let us know. We appreciate your help as we begin building our vendor portal."
          }
        />
      </div>
      <div
        id="page_container"
        className=" w-full mb-12 max-w-14xl mx-auto h-full overflow-hidden"
      >
        <div
          id="main_card"
          className={`${"h-[calc(100vh-120px)] shadow-xl"} mt-8 bg-white rounded-sm border border-gray-200 mb-4 `}
        >
          {!fromModal && (
            <div id="card_header" className="px-6  ">
              <div className=" w-full flex items-center justify-between  ">
                <div>
                  <header className=" py-4">
                    <h2 className="text-xl font-semibold text-gray-800">
                      Bid Requests{" "}
                      <span className="text-md text-gray-400 font-medium">
                        {/* {data && data.length} */}
                      </span>
                    </h2>
                  </header>
                </div>
                <div></div>
              </div>
              <div className="border-b border-gray-200 flex items-center justify-between pb-4">
                <Tabs
                  tabs={[
                    {
                      name: "Invited",
                      href: "#",
                      current: true,
                      status: "beta",
                    },
                    {
                      name: "Draft",
                      href: "#",
                      current: true,
                      status: "beta",
                    },
                    {
                      name: "Submitted",
                      href: "#",
                      current: true,
                      status: "beta",
                    },
                    {
                      name: "Declined",
                      href: "#",
                      current: true,
                      status: "beta",
                    },
                  ]}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
                <div> </div>
              </div>
            </div>
          )}
          {selectedTab == "Submitted" && (
            <ListTable
              vendor={true}
              fromModal={false}
              selectedRow={null}
              showDelete={!fromModal}
              setSelectedRow={null}
              dedupe={false}
              columns={[]}
              profile={null}
              competencies={[]}
              updatedId={null}
              updateMyData={null}
              setUpdatedId={null}
              skipReset={null}
              updateData={null}
              initialData={data} //this could also be unclaimedImpediments
              data={data} //this could also be unclaimedImpediments
              page_size={10}
              size_limit={10}
              fetch_limit={10}
              loading={false}
              fromDashboard={false}
              showFooter={false}
              toggleValue={false}
              hasSubComponent={false}
              onToggleChange={(value) => {
                console.log("Toggle", value);
              }}
              navigateToProject={(item) => {
                navigateToProject(item);
                // history.push({
                //   pathname: "/projects/project-details/vendor-bid-info",
                //   state: {
                //     inviteId: item.inviteId,
                //     profile: profile,
                //   },
                // });
              }}
              handleDeleteProjectClick={(e, item) => null}
            >
              {data &&
              data.length > 0 &&
              data.filter((item) => !item.status || item.status === "submitted")
                .length > 0 ? (
                data
                  .filter((item) => item.status === "submitted")
                  .map((item) => {
                    return (
                      <ListTableItem
                        clickHandler={() => {
                          console.log("DEBUG --> Item clicked", item);
                          navigateToProject(item);
                          // history.push({
                          //   pathname:
                          //     "/projects/project-details/vendor-bid-info",
                          //   state: {
                          //     inviteId: item.inviteId,
                          //     solicitationId: item.solicitationId,
                          //     name: profile.name,
                          //     profile: profile,
                          //   },
                          // });
                          // let state = item;
                          // history.push({
                          //   pathname: "/vendor/project-details",
                          //   state: state,
                          // });
                        }}
                        item={{
                          key: {
                            label: "Project ID",
                            value: item.key,
                          },
                          name: {
                            label: "Project Name",
                            value: item.bidRequestName || "Bid request name",
                          },
                          id: {
                            label: "Solicitation ID",
                            value: item?.solicitationId && item.solicitationId,
                          },

                          badge: {
                            label: "Status",
                            value: () => {
                              return (
                                <div className="inline-flex items-center px-2.5 py-0.5 rounded-md uppercase text-xs font-medium bg-green-100 text-green-800 border border-green-300">
                                  {item.requestorDecision === "winner"
                                    ? "Won"
                                    : item.requestorDecision === "lost"
                                    ? "Lost"
                                    : item.requestorDecision === "canceled"
                                    ? "Canceled by Requestor"
                                    : item.requestorDecision == "pending"
                                    ? "Awaiting Decision"
                                    : item.status}
                                </div>
                              );
                            },
                          },
                          subTitle: {
                            label: "Submitted on",
                            value: utils.formatDate(item.submittedOn, "short"),
                          },
                          topMiddle: [
                            {
                              label: "Requestor",
                              value: item.clientName,
                            },
                            {
                              label: "Deadline",
                              value: utils.formatDate(
                                item.soclicitationDeadline,
                                "short"
                              ),
                            },
                          ],

                          middleRow: [],
                          lastRow: {
                            label: "Status Tracker",
                            value: () => {
                              return (
                                <div
                                  id="stepper_row"
                                  className="hidden md:flex pb-2   items-center  w-full"
                                >
                                  <div className="px-6 block w-full">
                                    <Steps
                                      steps={
                                        item.workflow_status
                                          ? item.workflow_status
                                          : buildSteps(item)
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            },
                          },

                          actionButtons: [
                            {
                              label: "View",
                              handleClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push({
                                  pathname:
                                    "/projects/project-details/vendor-bid-info",
                                  state: {
                                    inviteId: item.inviteId,
                                    profile: profile,
                                  },
                                });
                                console.log("View clicked", item._id);
                              },
                              icon: () => {
                                return "View";
                              },
                            },
                            // {
                            //   label: "Delete",
                            //   handleClick: (e) => {
                            //     e.preventDefault();
                            //     e.stopPropagation();
                            //     console.log("Delete clicked", item._id);
                            //   },
                            //   icon: () => {
                            //     return <TrashIcon className="w-4 h-4" />;
                            //   },
                            // },
                          ],
                        }}
                      />
                    );
                  })
              ) : (
                <div className="flex flex-col items-center justify-center h-96">
                  <div className="text-2xl text-gray-500 font-medium">
                    No submitted bids found
                  </div>
                </div>
              )}
            </ListTable>
          )}
          {selectedTab == "Invited" && (
            <ListTable
              vendor={true}
              fromModal={false}
              selectedRow={null}
              showDelete={!fromModal}
              setSelectedRow={null}
              dedupe={false}
              columns={[]}
              profile={null}
              competencies={[]}
              updatedId={null}
              updateMyData={null}
              setUpdatedId={null}
              skipReset={null}
              updateData={null}
              initialData={data} //this could also be unclaimedImpediments
              data={data} //this could also be unclaimedImpediments
              page_size={10}
              size_limit={10}
              fetch_limit={10}
              loading={false}
              fromDashboard={false}
              showFooter={false}
              toggleValue={false}
              hasSubComponent={false}
              onToggleChange={(value) => {
                console.log("Toggle", value);
              }}
              navigateToProject={(item) => {
                history.push({
                  pathname: "/projects/project-details/vendor-bid-info",
                  state: {
                    inviteId: item.inviteId,
                    solicitationId: item.solicitationId,
                    name: profile.name,
                    profile: profile,
                  },
                });
              }}
              handleDeleteProjectClick={(e, item) => null}
            >
              {pendingBids && pendingBids.length > 0 ? (
                pendingBids.map((item) => {
                  return (
                    <ListTableItem
                      clickHandler={() => {
                        console.log("Item clicked", item);
                        navigateToProject(item);
                      }}
                      item={{
                        key: {
                          label: "Project ID",
                          value: item.key,
                        },
                        name: {
                          label: "Project Name",
                          value: item.bidRequestName || "Bid request name",
                        },
                        id: {
                          label: "Solicitation ID",
                          value: item?.solicitationId && item.solicitationId,
                        },

                        badge: {
                          label: "Status",
                          value: () => {
                            return (
                              <div className="inline-flex items-center px-2.5 py-0.5 rounded-md uppercase text-xs font-medium bg-green-100 text-green-800 border border-green-300">
                                {item.requestorDecision === "winner"
                                  ? "Won"
                                  : item.requestorDecision === "lost"
                                  ? "Lost"
                                  : item.requestorDecision === "canceled"
                                  ? "Canceled by Requestor"
                                  : item.requestorDecision == "pending"
                                  ? "Awaiting Decision"
                                  : "Invited"}
                              </div>
                            );
                          },
                        },
                        subTitle: {
                          label: "Submitted on",
                          value: utils.formatDate(item.submittedOn, "short"),
                        },
                        topMiddle: [
                          {
                            label: "Requestor",
                            value: item.clientName,
                          },
                          {
                            label: "Deadline",
                            value: utils.formatDate(
                              item.soclicitationDeadline,
                              "short"
                            ),
                          },
                        ],

                        middleRow: [],
                        lastRow: {
                          label: "Status Tracker",
                          value: () => {
                            return (
                              <div
                                id="stepper_row"
                                className="hidden md:flex pb-2   items-center  w-full"
                              >
                                <div className="px-6 block w-full">
                                  <Steps
                                    steps={
                                      item.workflow_status
                                        ? item.workflow_status
                                        : buildSteps(item)
                                    }
                                  />
                                </div>
                              </div>
                            );
                          },
                        },

                        actionButtons: [
                          {
                            label: "View",
                            handleClick: (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              history.push({
                                pathname:
                                  "/projects/project-details/vendor-bid-info",
                                state: {
                                  inviteId: item.inviteId,
                                },
                              });
                              console.log("View clicked", item._id);
                            },
                            icon: () => {
                              return "View";
                            },
                          },
                          // {
                          //   label: "Delete",
                          //   handleClick: (e) => {
                          //     e.preventDefault();
                          //     e.stopPropagation();
                          //     console.log("Delete clicked", item._id);
                          //   },
                          //   icon: () => {
                          //     return <TrashIcon className="w-4 h-4" />;
                          //   },
                          // },
                        ],
                      }}
                    />
                  );
                })
              ) : (
                <div className="flex flex-col items-center justify-center h-96">
                  <div className="text-2xl text-gray-500 font-medium">
                    No pending invites found
                  </div>
                </div>
              )}
            </ListTable>
          )}
          {selectedTab == "Draft" && (
            <ListTable
              vendor={true}
              fromModal={false}
              selectedRow={null}
              showDelete={!fromModal}
              setSelectedRow={null}
              dedupe={false}
              columns={[]}
              profile={null}
              competencies={[]}
              updatedId={null}
              updateMyData={null}
              setUpdatedId={null}
              skipReset={null}
              updateData={null}
              initialData={data} //this could also be unclaimedImpediments
              data={data} //this could also be unclaimedImpediments
              page_size={10}
              size_limit={10}
              fetch_limit={10}
              loading={false}
              fromDashboard={false}
              showFooter={false}
              toggleValue={false}
              hasSubComponent={false}
              onToggleChange={(value) => {
                console.log("Toggle", value);
              }}
              navigateToProject={(item) => {
                navigateToProject(item);
                // history.push({
                //   pathname: "/projects/project-details/vendor-bid-info",
                //   state: {
                //     inviteId: item.inviteId,
                //     profile: profile,
                //   },
                // });
              }}
              handleDeleteProjectClick={(e, item) => null}
            >
              {data &&
              data.length > 0 &&
              data.filter((item) => !item.status || item.status === "draft")
                .length > 0 ? (
                data
                  .filter((item) => !item.status || item.status === "draft")
                  .map((item) => {
                    return (
                      <ListTableItem
                        clickHandler={() => {
                          console.log("DEBUG --> Item clicked", item);
                          history.push({
                            pathname:
                              "/projects/project-details/vendor-bid-info",
                            state: {
                              inviteId: item.inviteId,
                              solicitationId: item.solicitationId,
                              name: profile.name,
                              profile: profile,
                            },
                          });
                          // let state = item;
                          // history.push({
                          //   pathname: "/vendor/project-details",
                          //   state: state,
                          // });
                        }}
                        item={{
                          key: {
                            label: "Project ID",
                            value: item.key,
                          },
                          name: {
                            label: "Project Name",
                            value: item.bidRequestName || "Bid request name",
                          },
                          id: {
                            label: "Solicitation ID",
                            value: item?.solicitationId && item.solicitationId,
                          },

                          badge: {
                            label: "Status",
                            value: () => {
                              return (
                                <div className="inline-flex items-center px-2.5 py-0.5 rounded-md uppercase text-xs font-medium bg-green-100 text-green-800 border border-green-300">
                                  {item.status}
                                </div>
                              );
                            },
                          },
                          subTitle: {
                            label: "Submitted on",
                            value: utils.formatDate(item.submittedOn, "short"),
                          },
                          topMiddle: [
                            {
                              label: "Requestor",
                              value: item.clientName,
                            },
                            {
                              label: "Deadline",
                              value: utils.formatDate(
                                item.soclicitationDeadline,
                                "short"
                              ),
                            },
                          ],

                          middleRow: [],
                          lastRow: {
                            label: "Status Tracker",
                            value: () => {
                              return (
                                <div
                                  id="stepper_row"
                                  className="hidden md:flex pb-2   items-center  w-full"
                                >
                                  <div className="px-6 block w-full">
                                    <Steps
                                      steps={
                                        item.workflow_status
                                          ? item.workflow_status
                                          : buildSteps(item)
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            },
                          },

                          actionButtons: [
                            {
                              label: "View",
                              handleClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push({
                                  pathname:
                                    "/projects/project-details/vendor-bid-info",
                                  state: {
                                    inviteId: item.inviteId,
                                    profile: profile,
                                  },
                                });
                                console.log("View clicked", item._id);
                              },
                              icon: () => {
                                return "View";
                              },
                            },
                            // {
                            //   label: "Delete",
                            //   handleClick: (e) => {
                            //     e.preventDefault();
                            //     e.stopPropagation();
                            //     console.log("Delete clicked", item._id);
                            //   },
                            //   icon: () => {
                            //     return <TrashIcon className="w-4 h-4" />;
                            //   },
                            // },
                          ],
                        }}
                      />
                    );
                  })
              ) : (
                <div className="flex flex-col items-center justify-center h-96">
                  <div className="text-2xl text-gray-500 font-medium">
                    No drafts found
                  </div>
                </div>
              )}
            </ListTable>
          )}
          {selectedTab == "Declined" && (
            <ListTable
              vendor={true}
              fromModal={false}
              selectedRow={null}
              showDelete={!fromModal}
              setSelectedRow={null}
              dedupe={false}
              columns={[]}
              profile={null}
              competencies={[]}
              updatedId={null}
              updateMyData={null}
              setUpdatedId={null}
              skipReset={null}
              updateData={null}
              initialData={data} //this could also be unclaimedImpediments
              data={data} //this could also be unclaimedImpediments
              page_size={10}
              size_limit={10}
              fetch_limit={10}
              loading={false}
              fromDashboard={false}
              showFooter={false}
              toggleValue={false}
              hasSubComponent={false}
              onToggleChange={(value) => {
                console.log("Toggle", value);
              }}
              navigateToProject={(item) => {
                navigateToProject(item);
                // history.push({
                //   pathname: "/projects/project-details/vendor-bid-info",
                //   state: {
                //     inviteId: item.inviteId,
                //     profile: profile,
                //   },
                // });
              }}
              handleDeleteProjectClick={(e, item) => null}
            >
              {data &&
              data.length > 0 &&
              data.filter((item) => !item.status || item.status === "declined")
                .length > 0 ? (
                data
                  .filter((item) => item.status === "declined")
                  .map((item) => {
                    return (
                      <ListTableItem
                        clickHandler={() => {
                          console.log("DEBUG --> Item clicked", item);
                          history.push({
                            pathname:
                              "/projects/project-details/vendor-bid-info",
                            state: {
                              inviteId: item.inviteId,
                              solicitationId: item.solicitationId,
                              name: profile.name,
                              profile: profile,
                            },
                          });
                          // let state = item;
                          // history.push({
                          //   pathname: "/vendor/project-details",
                          //   state: state,
                          // });
                        }}
                        item={{
                          key: {
                            label: "Project ID",
                            value: item.key,
                          },
                          name: {
                            label: "Project Name",
                            value: item.bidRequestName || "Bid request name",
                          },
                          id: {
                            label: "Solicitation ID",
                            value: item?.solicitationId && item.solicitationId,
                          },

                          badge: {
                            label: "Status",
                            value: () => {
                              return (
                                <div className="inline-flex items-center px-2.5 py-0.5 rounded-md uppercase text-xs font-medium bg-green-100 text-green-800 border border-green-300">
                                  {item.status == "winner"
                                    ? "Won"
                                    : [
                                        "Winner Selected",
                                        "Winner Notified",
                                        "Closed",
                                        "Archived",
                                      ].includes(item.solicitationStatus)
                                    ? "Lost"
                                    : item.status}
                                </div>
                              );
                            },
                          },
                          subTitle: {
                            label: "Submitted on",
                            value: utils.formatDate(item.submittedOn, "short"),
                          },
                          topMiddle: [
                            {
                              label: "Requestor",
                              value: item.clientName,
                            },
                            {
                              label: "Deadline",
                              value: utils.formatDate(
                                item.soclicitationDeadline,
                                "short"
                              ),
                            },
                          ],

                          middleRow: [],
                          lastRow: {
                            label: "Status Tracker",
                            value: () => {
                              return (
                                <div
                                  id="stepper_row"
                                  className="hidden md:flex pb-2   items-center  w-full"
                                >
                                  <div className="px-6 block w-full">
                                    <Steps
                                      steps={
                                        item.workflow_status
                                          ? item.workflow_status
                                          : buildSteps(item)
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            },
                          },

                          actionButtons: [
                            {
                              label: "View",
                              handleClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push({
                                  pathname:
                                    "/projects/project-details/vendor-bid-info",
                                  state: {
                                    inviteId: item.inviteId,
                                    profile: profile,
                                  },
                                });
                                console.log("View clicked", item._id);
                              },
                              icon: () => {
                                return "View";
                              },
                            },
                            // {
                            //   label: "Delete",
                            //   handleClick: (e) => {
                            //     e.preventDefault();
                            //     e.stopPropagation();
                            //     console.log("Delete clicked", item._id);
                            //   },
                            //   icon: () => {
                            //     return <TrashIcon className="w-4 h-4" />;
                            //   },
                            // },
                          ],
                        }}
                      />
                    );
                  })
              ) : (
                <div className="flex flex-col items-center justify-center h-96">
                  <div className="text-2xl text-gray-500 font-medium">
                    No drafts found
                  </div>
                </div>
              )}
            </ListTable>
          )}
        </div>
      </div>
    </>
  );
}

import { Fragment, useEffect, useRef, useState } from "react";
import { RadioGroup, Transition } from "@headlessui/react";
import { ArrowDownLeftIcon, CheckIcon } from "@heroicons/react/20/solid";
import {
  ArrowDownTrayIcon,
  ArrowLeftIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { utils } from "../../../modules/_utils";
import Button from "../../../elements/Button";
import Slide from "react-reveal/Slide";
import Badge from "../../../components/Badge";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import html2canvas from "html2canvas";
import BidCompareTable from "./BidCompareTable";
import { Solicitations } from "../../../modules/_solicitations";
import Tip from "../../../components/Tip";
import PdfViewer from "../../../components/PdfViewer";
import PDFViewerModal from "../../../components/PDFViewerModal";
import { Files } from "../../../modules/_files";
import { openAI } from "../../../modules/openAI";
import { set } from "date-fns";
import { Icon } from "@iconify/react";
import LoadingRocket from "../../../images/rocket_loading.gif";
import { FeatureFlag } from "../../../components/FeatureFlag";
import AssumptionsCalc from "../../../components/AssumptionsCalc";
const XLSX = require("xlsx");
const ExcelJS = require("exceljs");
const frequencies = [
  { value: "monthly", label: "Flat Rate", priceSuffix: "" },
  { value: "annually", label: "Variable Rate", priceSuffix: "" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const messages = [
  "Hold on while we compare your bids...",
  "We're still working on it...",
  "We'll get there soon...",
  "We're almost there, we promise...",
  "It's gonna be worth the wait...",
  "AI can be slow, but it's game changing...",
  "Hold on while we check with our bots to see what's going on...",
  "If it's any consolation, it would take longer to do it yourself...",
  "Ok, seriously, we're still thinking...",
  "Stick around, it'll be just a little longer...",
  "The good news is, you aren't paying for this....",
  "If you're a little annoyed, our AI Assistant's name is Blair...",
  "Why Blair, you ask?",
  "Breach(l)ink AI Robot...",
  "Creative, right?",
];

export default function BidCompare(props) {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const [tiers, setTiers] = useState(props.data);
  const [allResponses, setAllResponses] = useState(
    props.solicitation?.responses
  );
  const [selectingBid, setSelectingBid] = useState(false);
  const [viewAttachments, setViewAttachments] = useState(false);
  const [viewSummary, setViewSummary] = useState(false);
  const [viewCalculator, setViewCalculator] = useState(false);
  const [hideSelectDiv, setHideSelectDiv] = useState(false);
  const [hideEmailDiv, setHideEmailDiv] = useState(true);
  const [summary, setSummary] = useState(null);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [summaryLoadingText, setSummaryLoadingText] = useState(messages[0]);
  const [summaryDiv, setSummaryDiv] = useState(false);
  const [selectedTier, setSelectedTier] = useState(null);

  const [attachmentFile, setAttachmentFile] = useState({});
  const [hideAttachmentDiv, setAttachmentDiv] = useState(false);

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [solicitation, setSolicitation] = useState(null);
  const printRef = useRef();
  const tableRef = useRef();
  useEffect(() => {
    console.log("props.loading changed", props.loading);
    setLoading(props.loading);
  }, [props.loading]);

  // useEffect(() => {
  //   console.log("props.data changed", props.data);
  //   setAllResponses(props.data);
  //   setTiers(props.data);
  // }, [props.data]);

  // useEffect(() => {
  //   setAllResponses(solicitation?.responses);
  //   setTiers(solicitation?.responses);
  // }, [solicitation]);
  const handleNavigateBack = () => {
    // debugger
    props.handleNavigate(); // call the function passed as a prop
  };

  useEffect(() => {
    console.log(
      "DEBUG props.solicitation changed",
      props.solicitation,
      summaryLoading
    );
    setSolicitation(props.solicitation);
    setAllResponses(props.solicitation?.responses);
    setTiers(props.solicitation?.responses);
  }, [props.solicitation, summaryLoading]);

  // useEffect(() => {
  //   let timeouts = [];

  //   if (summaryLoading && props.modalOpen && props.modalOpen == true) {
  //     messages.forEach((message, index) => {
  //       let timeout = setTimeout(() => {
  //         setSummaryLoadingText(message);
  //       }, index * 4000);
  //       timeouts.push(timeout);
  //     });
  //   } else {
  //     timeouts.forEach((timeout) => clearTimeout(timeout));
  //     setSummaryLoadingText("");
  //   }
  //   return () => timeouts.forEach((timeout) => clearTimeout(timeout)); // cleanup function
  // }, [summaryLoading]);

  useEffect(() => {
    let messageIndex = 1; // Start from the second message
    let intervalId;

    if (summaryLoading && props.modalOpen && props.modalOpen === true) {
      intervalId = setInterval(() => {
        setSummaryLoadingText(messages[messageIndex]);
        messageIndex++;
        if (messageIndex >= messages.length) {
          messageIndex = 0; // Reset the index if it exceeds the length of messages
        }
      }, 4000);
    } else {
      setSummaryLoadingText("");
    }

    return () => clearInterval(intervalId); // cleanup function
  }, [summaryLoading]);

  useEffect(() => {
    console.log("props.errMessage changed", props.errMessage);
    setErrMessage(props.errorMessage);
  }, [props.errorMessage]);

  //CAN I REMOVE THIS???? I THINK SO
  useEffect(() => {
    console.log("props.data changed", props.data);
    setAllResponses(props.data);
    setTiers(props.data);
  }, [props.data]);

  useEffect(() => {
    setAllResponses(solicitation?.responses);
    setTiers(solicitation?.responses);
  }, [solicitation]);
  // END CAN I REMOVE THIS???? I THINK SO

  const handleDownloadHTMLPdf = async () => {
    // manageLoading("pdf", true);
    const elementHTML = printRef.current;
    var doc = new jsPDF();

    // Source HTMLElement or a string containing HTML.
    // var elementHTML = document.querySelector("#contentToPrint");

    doc.html(elementHTML, {
      callback: function (doc) {
        // Save the PDF
        doc.save("document-html.pdf");
      },
      margin: [10, 10, 10, 10],
      autoPaging: "text",
      x: 0,
      y: 0,
      width: 190, //target width in the PDF document
      windowWidth: 675, //window width in CSS pixels
    });

    // manageLoading("pdf", false);
    // doc.save("Bid Response.pdf");
  };
  const handleDownloadPdf = async () => {
    // manageLoading("pdf", true);
    //ORAN
    // const root = parse(printRef.current);
    // console.log("ROOT HTML PARSED", root);
    // manageLoading("pdf", false);
    // doc.save("Bid Response.pdf");
  };
  const handleDownloadPdfImage = async () => {
    // let name = `Bid Comparison for ${solicitation?.projectId?.projectId}-${solicitation.incrementId}  ${solicitation?.name}`;
    // const element = document.getElementById("response_table");
    // const canvas = await html2canvas(element, {
    //   ignoreElements: (element) =>
    //     element.getAttribute("data-html2canvas-ignore") === "true",
    // });

    // const doc = new jsPDF("l", "in");
    // doc.setFont("Helvetica");
    // doc.setFontSize("12");
    // doc.text(0.5, 0.33, name);
    // doc.text(10.5, 0.33, utils.formatDate(new Date(), "short"));

    // // Calculate the width and height of the image to fit the page with 0.5 inch margin
    // const pageWidth = doc.internal.pageSize.getWidth() - 1; // Subtracting margins
    // const pageHeight = doc.internal.pageSize.getHeight() - 1; // Subtracting margins
    // let imgWidth = pageWidth;
    // let imgHeight = (canvas.height * imgWidth) / canvas.width;

    // // Check if the height exceeds the page height
    // if (imgHeight > pageHeight) {
    //   imgHeight = pageHeight;
    //   imgWidth = (canvas.width * imgHeight) / canvas.height;
    // }

    // const xPosition = (doc.internal.pageSize.getWidth() - imgWidth) / 2;
    // const yPosition = (doc.internal.pageSize.getHeight() - imgHeight) / 2;

    // doc.addImage(
    //   canvas,
    //   "PNG",
    //   xPosition,
    //   yPosition,
    //   imgWidth,
    //   imgHeight,
    //   "",
    //   "FAST"
    // );
    // doc.save("Bid Responses.pdf");

    const table = document.getElementById("response_table");
    const rows = Array.from(table.querySelectorAll("tr"));
    const data = rows.map((row) => {
      return Array.from(row.querySelectorAll("td, th")).map(
        (cell) => cell.innerText
      );
    });
    const numColumns = data[0].length;
    const cellWidth = 110;
    const margin = 20;
    // Set document header with the current date on the right side
    const columnStyles = {};
    for (let i = 0; i < numColumns; i++) {
      columnStyles[i] = { cellWidth: cellWidth }; // Set the width for each column
    }

    const pageWidth = numColumns * cellWidth + 2 * margin;
    //if the pageWidth is less than A4, set it to A4
    if (pageWidth < 210) {
      pageWidth = 210;
    }

    const pdf = new jsPDF({
      format: [pageWidth, 400], // "a4", // You can choose a different format
      unit: "mm",
      orientation: "landscape",
    });
    let name = `Bid Comparison for ${solicitation?.projectId?.projectId}-${solicitation.incrementId}  ${solicitation?.name}`;
    pdf.setFont("Helvetica");
    pdf.setFontSize("12");

    // Set document header
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });

    // Get the width of the date text
    const dateWidth = pdf.getStringUnitWidth(formattedDate);

    pdf.text(250 - dateWidth - 1, 10, formattedDate);
    pdf.text(20, 10, name);
    // Define table headers and data
    const tableConfig = {
      startY: 20,
      //head: [headers],
      body: data,
      columnStyles: columnStyles,
      cellStyles: {
        rowEven: { fillColor: [255, 0, 0] }, // Red background color for even-numbered rows
        // Add more custom styling as needed
      },
    };

    // Generate the table using autoTable
    pdf.autoTable(tableConfig);

    // Save the PDF
    pdf.save("Bid Responses.pdf");
  };
  const handleCalculateBids = async () => {
    setViewCalculator(true);
  };
  const handleSummarizeBids = async () => {
    setSummaryLoadingText(messages[0]);
    setViewSummary(true);
    setSummaryLoading(true);

    let responses = JSON.parse(JSON.stringify(allResponses));
    console.log("handleSummarizeBids", responses);
    responses.map((response) => {
      console.log("handleSummarizeBids removing response", response.services);
      delete response.accept_nda;
      delete response.answers;
      delete response.answers;
      delete response.comments;
      delete response.companyId;
      delete response.createdAt;
      delete response.inviteId;
      delete response.projectId;
      delete response.solicitationId;
      delete response.status;

      delete response.submittedByEmail;

      delete response.submittedOn;
      delete response.tenantId;
      delete response.updatedAt;
      delete response.vendorCompanyId;
      delete response._id;
      delete response.vendorId;
      delete response.vendorNotified;
      Object.keys(response.services).map((service) => {
        // console.log(
        //   "handleSummarizeBids service",
        //   response.services[service].additional_comments_object
        // );
        delete response.services[service].additional_comments_object;
      });
    });
    console.log("Modified handleSummarizeBids", responses);
    const result = await openAI.summarize({
      responses: responses,
    });

    // const result = ""; //testing only
    // console.log("Getting ready to summarize bids", result);
    if (result) {
      setSummary(result);
      setSummaryLoading(false);

      //testing only
      //   setTimeout(() => {
      //     setSummary(result);
      //     setSummaryLoading(false);
      //   }, 30000);

      // console.log("handleSummarizeBids results", result);
    } else {
      console.log("Error summarizing bids");
      setErr(true);
      setErrMessage(
        "Actually, it looks like we hit a snag. Go back and try again."
      );
    }

    //call the summarize api
    // when results are returned, create a pdf and download it
  };

  const createSpreadsheetFromTableData = async () => {
    const table = document.getElementById("response_table");
    const rows = Array.from(table.querySelectorAll("tr"));
    const data = rows.map((row) => {
      return Array.from(row.querySelectorAll("td, th")).map(
        (cell) => cell.innerText
      );
    });

    const result = await Files.convertToExcel(data);
    if (result) {
      console.log("createSpreadsheetFromTableData", result);
      window.open(result);
    }
  };

  // const createSpreadsheetFromTableData = () => {
  //   const worksheet = XLSX.utils.table_to_sheet(
  //     document.getElementById("response_table"),
  //     { cellStyles: true, raw: true }
  //   );
  //   worksheet["!cols"] = [
  //     { width: 20 },
  //     { width: 20 },
  //     { width: 20 },
  //     { width: 20 },
  //     { width: 20 },
  //   ];
  //   worksheet["!rows"] = [{ height: 80 }];

  //   const ws_name = "Responses";
  //   const ws = XLSX.utils.json_to_sheet(XLSX.utils.sheet_to_json(worksheet));
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, ws_name);
  //   const cellStyle = {
  //     alignment: {
  //       wrapText: true,
  //     },
  //     font: {
  //       name: "Calibri",
  //       size: 12,
  //     },
  //     border: {
  //       top: { style: "thin", color: { rgb: "000000" } },
  //       bottom: { style: "thin", color: { rgb: "000000" } },
  //       left: { style: "thin", color: { rgb: "000000" } },
  //       right: { style: "thin", color: { rgb: "000000" } },
  //     },
  //   };

  //   const range = XLSX.utils.decode_range(ws["!ref"]);
  //   for (let R = range.s.r; R <= range.e.r; ++R) {
  //     for (let C = range.s.c; C <= range.e.c; ++C) {
  //       const cell_address = { c: C, r: R };
  //       const cell_ref = XLSX.utils.encode_cell(cell_address);
  //       if (!ws[cell_ref]) continue;

  //       XLSX.utils.format_cell(ws[cell_ref], cellStyle);
  //       // book_set_style(wb, cellStyle, ws[cell_ref]);
  //     }
  //   }
  //   XLSX.writeFile(wb, "responses.xlsx");
  // };

  const handleDownloadPdfImage1 = async () => {
    const html = document.getElementById("response_table").outerHTML;
    const doc = new jsPDF("l", "in");
    doc.setFont("Helvetica");
    // doc.setTextColor(60, 142, 201);
    doc.setFontSize("20");
    doc.text(4.25, 0.33, "Bid Comparison");
    doc.autoTable({ html: html });

    doc.save("response_table.pdf");
  };

  const handleSelectWinnerClick = async (e, bid) => {
    e.preventDefault();
    // alert("handleSelectWinnerClick");
    console.log("handleSelectWinnerClick", bid);
    setSelectedTier(bid);
    // setSelectingBid(true);

    // e.preventDefault();
    // console.log("Selecting winner", e, bid);
    // setSelectWinnerLoading(true);
    // setErrMessage(null);

    let result = await Solicitations.selectWinner({
      solicitationId: solicitation._id,
      bid: bid,
      bidId: bid._id,
      solicitationId: bid.solicitationId,
    });

    if (result) {
      console.log("selectWinner result", result);
      // props.selectWinner(e, result);
      setSolicitation(result);
      setAllResponses(result.responses);
      setErrMessage(null);
      // setCompareModalOpen(true);
      // setSelectWinnerLoading(false);
      props.selectWinner(e, bid) && props.selectWinner(e, result);
    } else {
      console.log("Error selecting winner");
      // setSelectWinnerLoading(false);
      setErrMessage("Error selecting winner");
    }
  };
  const handleNotifyVendorClick = (e, tier) => {
    e.preventDefault();
    setSelectedTier(tier);
    setSelectingBid(true);
  };

  const handleViewPDF = async (e, attachment, fileType) => {
    e.preventDefault();
    console.log("handleViewPDF", attachment, fileType);
    const file = await Files.getFileById(
      attachment._id,
      fileType ? fileType : "response",
      solicitation._id
    );
    attachment.url = file.url;
    console.log("Got file download link from server", file);
    // let name = file.url.split("%5c");
    // name = name[name.length - 1];
    setViewAttachments(true);
    setAttachmentFile(attachment);
  };
  const handleSelectWinnerConfirmation = async (e) => {
    e.preventDefault();
    let bid = selectedTier;
    // setLoading(true);
    console.log("handleSelectWinnerConfirmation", selectedTier, message);
    // props.notifyWinner(e, selectedTier, message);
    // Move notifyWinner to here
    console.log("Notifying winner", bid, message);
    // setSelectWinnerLoading(true);
    setErrMessage(null);
    if (message == "" || message == null) {
      setTimeout(() => {
        setErrMessage("Please enter a message to the vendor");

        // setSelectWinnerLoading(false);
      }, 500);
      return false;
    } else {
      let result = await Solicitations.notifyWinner({
        solicitationId: solicitation._id,
        bid: bid,
        bidId: bid._id,
        solicitationId: bid.solicitationId,
        vendorEmail: bid.submittedByEmail,
        message: message,
      });

      if (result) {
        console.log("selectWinner result", result);
        // console.log("Winner selected and confirmed", result);
        setSolicitation(result);
        setAllResponses(result.responses);

        // setSelectWinnerLoading(false);
        setMessage("");
        setSelectingBid(false);
        setHideSelectDiv(false);
        setErrMessage(null);
        props.notifyWinner(e, result) && props.notifyWinner(e, result);
        props.setCompareModalOpen(false);
      } else {
        console.log("Error selecting winner");
        // setSelectWinnerLoading(false);
        setErrMessage("Error selecting winner");
      }
    }
    // End Move notifyWinner to here
  };

  return (
    <div
      id="bid-compare-card"
      className="h-full flex flex-col justify-between shadow-sm  overflow-x-hidden overflow-y-hidden bg-white "
    >
      <div id="content" className="h-full overflow-hidden pb-8">
        {" "}
        <Transition
          id="bid-compare-table-transition-container"
          enter="transform transition ease-in-out duration-500 sm:duration-500 "
          enterFrom="translate-x-full"
          enterTo=" translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-500"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className={"h-full"}
          show={
            !selectingBid && !viewAttachments && !viewSummary && !viewCalculator
          }
          as={"div"}
          afterLeave={() => {
            setHideSelectDiv(true);
            setHideEmailDiv(false);
          }}
        >
          {!hideSelectDiv && (
            <div
              id="bid-compare-content"
              className=" w-full h-full mx-auto px-6 lg:px-8"
            >
              <div
                id="bid-compare-header-row"
                className="flex items-center justify-between py-1 border-b border-gray-200"
              >
                <div>
                  <span className="flex items-center">
                    <Button
                      iconButton
                      handleClick={(e) => handleNavigateBack(e)}
                      color="text"
                    >
                      <Icon
                        className="h-6 w-6"
                        //back button

                        icon="ic:baseline-arrow-back"
                      />
                    </Button>{" "}
                    <h2 className="text-lg font-medium leading-6 text-gray-900">
                      {solicitation && solicitation?.name}
                    </h2>
                  </span>
                </div>
                <div className="space-x-6">
                  <FeatureFlag
                    id="bid-calculator-button"
                    featureName="Bid Calculator"
                    type="flag"
                  >
                    <Button
                      // loader={props.isLoading("pdf")}
                      color="text"
                      handleClick={handleCalculateBids}
                    >
                      <Icon
                        icon="uil:calculator-alt"
                        className="h-5 w-5 mr-2"
                        aria-hidden="true"
                      />
                      Calculate bids
                    </Button>
                  </FeatureFlag>
                  <FeatureFlag
                    id="summarize-bids-button"
                    featureName="Summarize"
                    type="flag"
                  >
                    <Button
                      // loader={props.isLoading("pdf")}
                      color="text"
                      handleClick={handleSummarizeBids}
                    >
                      <Icon
                        icon="uil:newspaper"
                        className="h-5 w-5 mr-2"
                        aria-hidden="true"
                      />
                      Summarize bids
                    </Button>
                  </FeatureFlag>
                  <Button
                    // loader={props.isLoading("pdf")}
                    color="text"
                    handleClick={handleDownloadPdfImage}
                  >
                    <Icon icon="fa:file-pdf-o" className="h-5 w-5 mr-2" />
                    Download PDF
                  </Button>
                  <Button
                    // loader={props.isLoading("pdf")}
                    color="text"
                    handleClick={createSpreadsheetFromTableData}
                  >
                    <Icon icon="mdi:microsoft-excel" className="h-5 w-5 mr-2" />
                    Download Spreadsheet
                  </Button>
                </div>
              </div>

              <div
                id="bid-compare-table-container"
                ref={printRef}
                className="w-full isolate mx-auto pb-4 h-full grid max-w-md grid-cols-1 gap-0 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4"
              >
                {allResponses &&
                allResponses.filter(
                  (response) => response.status !== "declined"
                ).length > 0 ? (
                  <BidCompareTable
                    responses={
                      allResponses &&
                      allResponses.filter(
                        (response) => response.status !== "declined"
                      )
                    }
                    solicitation={solicitation}
                    handleSelectWinnerClick={handleSelectWinnerClick}
                    handleNotifyVendorClick={handleNotifyVendorClick}
                    handleViewPDF={handleViewPDF}
                  />
                ) : (
                  <div>There are currently no bids to compare</div>
                )}
              </div>
            </div>
          )}
        </Transition>
        <Transition
          enter="transform transition ease-in-out  duration-500 sm:duration-500"
          enterFrom="translate-x-full"
          enterTo=" translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-500 "
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className={"h-full"}
          show={selectingBid}
          as={"div"}
          afterLeave={() => {
            setHideSelectDiv(false);
            setHideEmailDiv(true);
          }}
        >
          {!hideEmailDiv && (
            //this should be a component that can be used for any vendor contacts
            <div className="flex flex-col jusify-between items-center h-full space-y-10">
              <div className="px-6 w-full">
                <div className=" max-w-4xl">
                  <p className="mt-2 text-xl font-bold tracking-tight text-gray-900 ">
                    Congratualtions on selecting your winner!
                  </p>
                  Use the box below to send them a message with next steps.
                </div>
                <Tip color="emerald">
                  Please be sure to include the Bid Request Name, your company
                  name, and contact details such as email addresses where
                  contracts should be sent.
                </Tip>
              </div>

              <div className="mt-10 h-full px-10 sm:mt-0 w-full">
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Enter your message here. "
                  className={`h-full block w-full rounded-md ${
                    errMessage && errMessage !== ""
                      ? "border-red-500"
                      : "border-gray-300"
                  } shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                ></textarea>
              </div>
              <div className="w-full px-10 flex items-center justify-between">
                <div className="flex items-center justify-between">
                  <Button
                    color={"text"}
                    handleClick={() => {
                      setLoading(false);
                      setErrMessage("");
                      setSelectingBid(false);
                    }}
                  >
                    <ArrowLeftIcon
                      className="h-5 w-5 mr-2"
                      aria-hidden="true"
                    />
                    Back to Bids
                  </Button>
                </div>
                <div className="flex items-center justify-between">
                  <Button
                    loader={loading}
                    color="secondary"
                    handleClick={(e) => handleSelectWinnerConfirmation(e)}
                    // audit={true}
                    // action="Confirm Bid Winner"
                    // details={{}}
                  >
                    Send Message
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Transition>
        <Transition
          className={"h-full"}
          enter="transform transition ease-in-out  duration-500 sm:duration-500"
          enterFrom="translate-x-full"
          enterTo=" translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-500 "
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          show={viewAttachments}
          as={"div"}
          afterLeave={() => {
            setHideSelectDiv(false);
            setHideEmailDiv(true);
          }}
        >
          <div id="pdf-viewer-container" className="h-full">
            <PdfViewer
              file={attachmentFile ? attachmentFile : {}}
              filename={attachmentFile ? attachmentFile.name : "Test"}
              url={attachmentFile ? attachmentFile.url : ""}
              // selectedFileName={selectedFileName ? selectedFileName : "Test"} />
            />
          </div>

          <div className="w-full px-10 flex items-center justify-between">
            <div className="flex items-center justify-between">
              <Button
                color={"text"}
                handleClick={() => {
                  // setLoading(false);
                  // setErrMessage("");
                  setViewAttachments(false);
                  setAttachmentDiv(false);
                  // setHideSelectDiv(true)
                }}
              >
                <ArrowLeftIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                Back to Bids
              </Button>
            </div>
          </div>
        </Transition>
        <Transition
          className={"h-full "}
          enter="transform transition ease-in-out  duration-500 sm:duration-500"
          enterFrom="translate-x-full"
          enterTo=" translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-500 "
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          show={viewSummary}
          as={"div"}
          afterLeave={() => {
            setHideSelectDiv(false);
            setHideEmailDiv(true);
          }}
        >
          {summaryLoading ? (
            <div className="flex pt-12 flex-col h-full items-center text-center justify-center ">
              <div className="text-xl  font-medium flex">
                {/* Give us a few minutes to summarize your bids...
                 */}
                {summaryLoadingText}
              </div>
              <img
                className="flex"
                src="/static/media/rocket_loading.15e68c95fb64b90e1803.gif"
                alt="Loading Rocket"
              />
            </div>
          ) : err ? (
            <div className="flex pt-12 flex-col h-full items-center text-center justify-center ">
              <div className="text-xl font-medium flex text-red-500">
                {errMessage}
              </div>
            </div>
          ) : (
            <div
              id="summary-container"
              className="p-6 h-full overflow-y-scroll scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
                scrollbar-thumb-rounded-full scrollbar-track-rounded-full "
            >
              <div
                id="summary-container-html"
                className="h-full whitespace-pre-wrap  text-md"
                dangerouslySetInnerHTML={{ __html: summary }}
              ></div>
            </div>
          )}

          <div className="w-full px-10  flex items-center justify-between">
            <div className="flex items-center justify-between">
              <Button
                color={"text"}
                handleClick={() => {
                  // setLoading(false);
                  // setErrMessage("");
                  setViewSummary(false);
                  setSummary("");
                  setErr(false);
                  setErrMessage("");
                  setSummaryLoadingText("");
                  setSummaryLoading(false);
                  setSummaryDiv(false);
                  // setHideSelectDiv(true)
                }}
              >
                <ArrowLeftIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                Back to Bids
              </Button>
            </div>
          </div>
        </Transition>
        <Transition
          className={"h-full "}
          enter="transform transition ease-in-out  duration-500 sm:duration-500"
          enterFrom="translate-x-full"
          enterTo=" translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-500 "
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          show={viewCalculator}
          as={"div"}
          afterLeave={() => {
            setHideSelectDiv(false);
            setHideEmailDiv(true);
          }}
        >
          <div
            id="calculator-container"
            className="p-6 h-full overflow-y-scroll scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
                scrollbar-thumb-rounded-full scrollbar-track-rounded-full "
          >
            <div
              id="calculator-container-html"
              className="h-full flex justify-between w-full"
              // style={{
              //   whiteSpace: "pre-wrap",
              //   wordWrap: "break-word",
              //   overflowWrap: "break-word",
              // }}
            >
              <AssumptionsCalc bids={allResponses} />
            </div>
          </div>

          <div className="w-full px-10 flex items-center justify-between">
            <div className="flex items-center justify-between">
              <Button
                color={"text"}
                handleClick={() => {
                  // setLoading(false);
                  // setErrMessage("");
                  setViewCalculator(false);
                }}
              >
                <ArrowLeftIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                Back to Bids
              </Button>
            </div>
          </div>
        </Transition>
      </div>
      <div id="footer" className="h-6"></div>
    </div>
  );
}

//import statements here if needed
import Image01 from "../images/user-40-01.jpg";
import Image02 from "../images/user-40-02.jpg";
import Image03 from "../images/user-40-03.jpg";
import Image04 from "../images/user-40-04.jpg";
import Image05 from "../images/user-40-05.jpg";
import Image06 from "../images/user-40-06.jpg";
import Image07 from "../images/user-40-07.jpg";
import Image08 from "../images/user-40-08.jpg";
import Image09 from "../images/user-40-09.jpg";
import Image10 from "../images/user-40-10.jpg";
import Image11 from "../images/user-28-11.jpg";
import Image12 from "../images/user-28-12.jpg";

export const _regulations = {
  getRegulations() {
    const regulations = [
      {
        id: 0,
        initials: "PCI-DSS",
        title: "Payment Card Industry Data Security Standard",
        content:
          "The PCI Security Standards Council’s mission is to enhance global payment account data security by developing standards and supporting services that drive education, awareness, and effective implementation by stakeholders.",
        videoLink: "https://player.vimeo.com/video/342258218",
        resources: [],
        details: [],
        type: "At Risk", //last assessment score
        linkedAssessments: [],
        status: "Active",
        country: "United States",
        to: "/team/profile",
      },
      {
        id: 0,
        initials: "NIST-CSF",
        title: "National Institute of Standards and Technology",
        content:
          "The National Institute of Standards and Technology has developed a cybersecurity risk frameworks for voluntary use by critical infrastructure owners and operators.",

        videoLink: "https://player.vimeo.com/video/342258347",
        resources: [],
        details: [],
        type: "At Risk", //last assessment score
        linkedAssessments: [],
        status: "Active",
        country: "United States",
      },
      {
        id: 0,
        initials: "GDPR",
        title: "The General Data Protection Regulation",
        content:
          "The primary objective of the GDPR is to provide EU individuals with protections in relation to the collection and use of their personal data.",
        videoLink: "https://player.vimeo.com/video/351388045?h=f443f70b98",
        resources: [],
        details: [],
        type: "At Risk", //last assessment score
        linkedAssessments: [],
        status: "coming soon",
        country: "European Union",
      },
      // {
      //   id: 0,
      //   initials: "CPRA",
      //   title: "The California Privacy Rights Act of 2020",
      //   content: "",
      //   videoLink: "",
      //   resources: [],
      //   details: [],
      //   type: "", //last assessment score
      //   linkedAssessments: [],
      //   status: "coming soon",
      //   country: "United States",
      // },
      // {
      //   id: 0,
      //   initials: "CPPA",
      //   title: "Sample Law",
      //   content: "",
      //   videoLink: "",
      //   resources: [],
      //   details: [],
      //   type: "", //last assessment score
      //   linkedAssessments: [],
      //   status: "coming soon",
      //   country: "United States",
      // },
      // {
      //   id: 0,
      //   initials: "CPRA",
      //   title: "Sample Law",
      //   content: "",
      //   videoLink: "",
      //   resources: [],
      //   details: [],
      //   type: "", //last assessment score
      //   linkedAssessments: [],
      //   status: "coming soon",
      //   country: "United States",
      // },
    ];
    return regulations;
  },
};

import React, { useState, useRef, useEffect } from "react";
import Transition from "../utils/Transition";
import Button from "../elements/Button";
// import DropdownImage from "../images/logo.jpg";
// import DropdownImage01 from "../images/logo.jpg";
// import DropdownImage02 from "../images/channel-02.png";
// import DropdownImage03 from "../images/channel-03.png";
import {
  ArrowDownIcon,
  ArrowSmDownIcon,
  ArrowsRightLeftIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import Tabs from "../pages/component/Tabs";

function DropdownSwitch(
  { align, profile, handleTenantSwitch, setCreateTeamOpen, tenantsToShow },
  props
) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tenant, setTenant] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const [selected, setSelected] = useState({ id: "", name: "" });
  const [selectedTab, setSelectedTab] = useState("Consumer");

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const tabsRef = useRef(null);
  const tabs = [
    {
      name: "Consumer",
      href: "#",
      current: true,
    },
    {
      name: "Vendor",
      href: "#",
      current: true,
    },
    {
      name: "Admin",
      href: "#",
      current: true,
    },
  ];
  useEffect(() => {
    console.log("TenantList profile", profile);
    setSelected({ id: profile.tenantId, name: profile.tenantName });
  }, []);

  useEffect(() => {
    tenantsToShow = tenantsToShow.filter((item) => {
      return item.tenantId !== null;
    });
    tenantsToShow.sort((a, b) =>
      a.tenantId.tenantTitle.toLowerCase() >
      b.tenantId.tenantTitle.toLowerCase()
        ? 1
        : -1
    );
    setTenantList(tenantsToShow);
  }, [tenantsToShow]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current || !tabsRef.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target) ||
        tabsRef.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [dropdownOpen]);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const handleCreateTeamClick = async () => {
    console.log("handleCreateTeam");

    setCreateTeamOpen(true);
  };

  // handle tenant switch
  const handleSwitch = (id, name, type) => {
    // e.preventDefault();
    console.log("handleTenantSwitch", id, name);
    const newTenant = { id: id, name: name, type: type };
    setSelected(newTenant);
    handleTenantSwitch(newTenant);
  };

  return (
    <div className="flex relative items-center whitespace-nowrap sm:whitespace-auto">
      {/* Switch Teams Button vv */}
      <button
        ref={trigger}
        className="flex rounded items-center justify-center hover:bg-slate-50 px-3 py-2"
        // className="w-24 justify-center items-center px-4 py-4 border border-gray-800 leading-5 font-medium "
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <div className=" text-base font-medium text-secondary-600">
          {selected.name}
        </div>
        <span className="ml-4 text-xs text-secondary-600">
          {/* <ArrowsRightLeftIcon className="inline-flex h-3 w-3 mr-1" />{" "} */}

          {/* <span className="hidden xxs:inline-flex">Switch Teams</span> */}
        </span>
        <ChevronDownIcon className="inline-flex h-4 w-4 text-secondary-600" />
      </button>
      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
          align === "right" ? "right-0" : "left-0"
        }`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        {" "}
        <div
          id="tabs-container"
          className="flex items-center justify-between px-4 py-2 bg-slate-50 border-b border-gray-200"
        >
          <Tabs
            ref={tabsRef}
            tabs={tabs}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
        </div>
        <ul
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
          className="overflow-y-auto max-h-96 pb-10  scrollbar-thin scrollbar-thumb-slate-300 
          scrollbar-track-slate-100  scrollbar-track-rounded-full 
          scrollbar-thumb-rounded-full"
        >
          {tenantList
            .filter((item) => item.tenantId.type === selectedTab.toLowerCase())
            .map((tenant) => {
              // console.log("Tenant List", tenant);
              let id = tenant.tenantId ? tenant.tenantId._id : null;
              let tenantName = tenant.tenantId
                ? tenant.tenantId.tenantTitle
                : null;
              let type = tenant.tenantId ? tenant.tenantId.type : null;
              let company = tenant.tenantId.tenantCompany
                ? tenant.tenantId.tenantCompany
                : null;

              return (
                <li className="py-1">
                  <a
                    className="font-medium text-sm text-slate-600  block py-1.5 px-3"
                    href="#0"
                    onClick={() => handleSwitch(id, tenantName, type)}
                  >
                    {" "}
                    {/* <div className="flex items-center">
                    <div className="grow">
                      <div className="flex flex-wrap items-center justify-between mb-0.5">
                        <span className="flex font-semibold text-slate-800">
                          {tenantTitle
                            ? tenantTitle
                            : profile.company
                            ? profile.company
                            : profile.email}
                          {status != "Active" && (
                            <span className="normal-case text-xs italic text-accent-800 align-top ml-2">
                              {a.status == "Invited"
                                ? "    Accept your invitation to continue"
                                : null}
                            </span>
                          )}
                        </span>
                        <span>
                          <span className="font-medium text-accent-600">
                            {role}
                          </span>
                        </span>
                      </div>
                      <div className="text-xs">
                        {company && (
                          <span className="text-slate-500">{company}</span>
                        )}
                      </div>
                    </div>
                  </div> */}
                    <div className="grow">
                      <div className="flex flex-wrap items-center justify-between mb-0.5">
                        <span className="flex items-center font-semibold text-slate-800">
                          {tenantName}{" "}
                          <span>
                            <span className="ml-2 uppercase text-xxs text-opacity-80 font-medium text-secondary-600">
                              {type}
                            </span>
                          </span>
                        </span>
                        {/* <div className="flex items-center justify-between">
                    <div className="grow flex items-center truncate"> */}
                        {/* <img
                        className="w-6 h-6  mr-3"
                        src={DropdownImage01}
                        width="28"
                        height="28"
                        alt="Channel 01"
                      /> */}
                        {/* <div className="truncate">{tenantName}</div> */}

                        {selected.id === id && (
                          <svg
                            className="w-3 h-3 shrink-0 fill-primary-900 text-primary-800 ml-2"
                            viewBox="0 0 12 12"
                          >
                            <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                          </svg>
                        )}
                      </div>

                      <div className="text-xs">
                        {company && (
                          <span className="text-slate-500">{company}</span>
                        )}
                      </div>
                    </div>
                  </a>
                </li>
              );
            })}
          {/* Create Team link */}
          {/* <li className="border-t bg-white z-1 w-full absolute fixed mt-10 bottom-0">
            <a
              className="font-medium text-sm text-accent-600 hover:text-accent-700 block py-3 px-3 "
              href="#0"
              onClick={() => handleCreateTeamClick()}
            >
              <div className="flex items-center justify-between">
                <div className="grow flex items-center truncate">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 ml-2 mr-3 "
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>

                  <div className="truncate text-sm">Create Team</div>
                </div>
              </div>
            </a>
          </li> */}
        </ul>
      </Transition>
      {/* Create Teams button */}
      {/* <hr className="flex  w-px h-6 bg-gray-200 mx-3" />
      <Button
        color="secondary"
        size="sm"
        className="flex items-center justify-center"
        handleClick={() => handleCreateTeamClick()}
      >
        Create Team
      </Button> */}
    </div>
  );
}

export default DropdownSwitch;

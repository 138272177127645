import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { _auth } from "../modules/_auth";
import { useHistory } from "react-router-dom";
// import { NavLink, useNavigate } from "react-router-dom";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import Button from "../elements/Button";
import Input from "../components/Input";

function SignupConfirm() {
  // const navigate = useNavigate();
  // const { register } = useAuthStore();
  const history = useHistory();
  const [isValidForSignup, setIsValidForSignup] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [accessCodeInvalid, SetAccessCodeInvalid] = useState(false);
  const [accessCodeErrorMessage, setAccessCodeErrorMessage] = useState("");
  const [signUpErrorMessage, setSignUpErrorMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessageName, setErrorMessageName] = useState("");
  const [errorMessageCompanyName, setErrorMessageCompanyName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    // event.preventDefault();
    history.push("/signin");
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-full">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8"></div>
            </div>

            <div className="max-w-md mx-auto px-4 py-8">
              <h1 className="text-3xl text-slate-800 font-bold mb-6">
                Your account has been created!
              </h1>
              <div className="text-sm">
                Check your email to verify your account and get started!
              </div>

              {/* Form */}

              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                {/* <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"> */}

                {/* </div> */}
                <div className="flex items-center justify-between mt-5">
                  {signUpErrorMessage !== "" && (
                    <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                      {signUpErrorMessage}
                    </div>
                  )}
                </div>
                <div className="mt-5">
                  <Button
                    // enabled={!loader}
                    loader={loading}
                    type="submit"
                    className="btn btn-primary"
                    // onClick={onRegister}
                    handleClick={(e) => handleClick(e.target.value)}
                  >
                    Back to Sign In
                  </Button>
                </div>
              </div>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-gray-200"></div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default SignupConfirm;

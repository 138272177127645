/* This example requires Tailwind CSS v2.0+ */

import ContactForm from "../components/ContactForm";
import Empty from "../components/Empty";
import HomeHeader from "../components/HomeHeader";
import OptionButtons from "../components/OptionButtons";
import React, { useState, useEffect } from "react";
import { _auth } from "../modules/_auth";
import { useHistory } from "react-router-dom";
import { CalculatorIcon } from "@heroicons/react/20/solid";

const headerInfo = {
  title: "Get in touch",
  text: "Sometimes you just need a little advice. We’re here to help. ",
  to: "/home",
  linkText: "",
  action_icon: "",
  status: "",
  showLinkLine: false,
};

export default function ContactPage() {
  const history = useHistory();
  const profile = _auth.getUserProfile(); // { email: "joe@mycy.com", phone: null };
  const [confirmed, setConfirmed] = useState(false);
  useEffect(() => {
    console.log(">resppp");
    _auth.getAuth(history);
  }, []);
  return (
    // <div
    //   id="contact_container"
    //   style={{
    //     height: confirmed ? "30vh" : "70vh",
    //     top: "25%",
    //     left: "10%",
    //     position: "absolute",
    //     width: "80%",
    //     bottom: "25%",
    //     height: "45%",
    //     right: "10%",
    //   }}
    //   className="px-4 sm:px-6 lg:px-8 py-8 w-full h-full fixed top-25/100 left-15/100 mx-auto justify-center  content-center"
    // >
    <div
      id="contact_container"
      // style={{
      //   height: confirmed ? "30vh" : "70vh",
      //   top: "25%",
      //   left: "10%",
      //   position: "absolute",
      //   width: "80%",
      //   bottom: "25%",
      //   height: "45%",
      //   right: "10%",
      // }}
      style={{ height: "calc(100vh - 64px)" }}
      className="flex flex-cols-4 "
    >
      {/* make conditions here  */}
      {confirmed ? (
        <div className=" m-auto h-96 w-1/2 bg-white shadow-lg rounded-sm border border-gray-200  max-w-5xl mx-auto justify-center  content-center ">
          <Empty
            buttonColor={"green"}
            title={"You're all set!"}
            content={
              "One of our team members will be in touch with you within 48 hours."
            }
            scheduling={true}
            buttonText={"Done"}
          />
        </div>
      ) : (
        <div
          id="content_container"
          className="m-auto h-96 w-1/2 bg-white shadow-lg rounded-sm border border-gray-200  max-w-5xl mx-auto justify-center  content-center  "
        >
          <div className="relative bg-white p-10 h-full">
            <div className="col-span-2 py-5">
              <span id="email-and-name" className="">
                Select an option below and we will reach out to you.
              </span>
            </div>
            {/* {profile.email != null && profile.phone != null ? ( */}
            <OptionButtons setConfirmed={setConfirmed} opts={profile} />
            {/* ) : (
      <ContactForm />
    )} */}
          </div>
        </div>
      )}

      {/* <div className=" h-full bg-white shadow-lg rounded-sm border border-gray-200 relative max-w-5xl mx-auto justify-center  content-center  ">
        {/* <HomeHeader data={headerInfo} /> */}
      {/* <Grid data={cards} /> */}
      {/* </div> */}
      {""}
    </div>
  );
}

import { useEffect } from "react";
import Uppy from "@uppy/core";

import XHRUpload from "@uppy/xhr-upload";
import { Dashboard } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { _auth } from "../modules/_auth";
import { baseUrl } from "../config/urlRoutes";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Uploader(props) {
  const uppy = new Uppy({
    id: "uppy",
    autoProceed: true,
    allowMultipleUploadBatches: true,
    debug: true,
    restrictions: {
      maxFileSize: null,
      minFileSize: null,
      maxTotalFileSize: null,
      maxNumberOfFiles: null,
      minNumberOfFiles: null,
      allowedFileTypes: null,
      requiredMetaFields: [],
    },
    meta: {
      tenantId: _auth.getUserProfile() ? _auth.getUserProfile().tenantId : 123,
      question_type: props.assessment_type,
    },
    onBeforeFileAdded: (currentFile, files) => currentFile,
    onBeforeUpload: (files) => {},
    locale: {},
    // store: new DefaultStore(),
    // logger: justErrorsLogger,
    infoTimeout: 5000,
  }).use(XHRUpload, {
    endpoint: baseUrl + "/assessments/templates/questions/upload",
    // "http://localhost:2000/api/assessments/templates/questions/upload",
    formData: true,
    fieldName: "assessment_template_import",
    method: "post",
    headers: {
      "x-access-token": _auth.getToken(),
    },
  });
  useEffect(() => {
    (async () => {
      uppy.on("complete", (result) => {
        props.refreshQuestions();
      });
    })();
    // props.setAddQuestionOption();
  }, [uppy]);

  const item = props.modalItem ? props.modalItem : {};

  const create =
    props.formAction && props.formAction == "create" ? true : false;
  console.log("ModalItem", item);

  return (
    <div className="flex flex-col shrink-1 mt-1/4 mb-1/4 justify-center items-center overflow-hidden">
      <div className="p-4 shadow-lg rounded-md bg-white">
        {/* <img src={currentAvatar} alt="Current Avatar" /> */}
        <Dashboard
          autoProceed={true}
          id="uppy"
          // width="100%"
          // height="100%"
          // note="Images up to 200×200px"
          // assuming `props.uppy` contains an Uppy instance:
          uppy={uppy}
          locale={{
            strings: {
              // Text to show on the droppable area.
              // `%{browse}` is replaced with a link that opens the system file selection dialog.
              dropHereOr: "Drop here or %{browse}",
              // Used as the label for the link that opens the system file selection dialog.
              browse: "browse",
            },
          }}
        />
      </div>
    </div>
  );
}

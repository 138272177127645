/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/24/outline";

const features = [];

export default function LandingIntro(props) {
  const tenantType = props.tenantType ? props.tenantType : "consumer";
  return (
    <div>
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            Welcome to Breachlink!
          </h1>
        </div>
      </div>
      <div className="">
        <div className="max-w-3xl mx-auto text-left">
          {tenantType == "vendor" ? (
            <p className="mt-4  ">
              Thank you for choosing Breachlink! We are excited to have you join
              our vendor community. This area is currently in{" "}
              <span className="uppercase bg-secondary-50 text-secondary-800 rounded text-xs px-2 font-medium">
                Beta
              </span>
              . Please bear with us as we continue to develop and improve our
              platform.{" "}
            </p>
          ) : (
            <p className="mt-4  ">
              Thank you for choosing Breachlink! We are excited to have you join
              our community. We are working hard to build a platform that will
              give you back significant time, help you make better decisions,
              and ultimately help you grow your business. Please let us know if
              you have any feedback or suggestions.{" "}
            </p>
          )}
          {/* <p className="mt-4 text-md ">Follow this wizard to get started.</p> */}

          {/* <p className="mt-4  ">
            {" "}
            Let's start by completing the onboarding wizard so we can get to
            know you better.{" "}
          </p> */}
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon
                  className="absolute h-6 w-6 text-green-500"
                  aria-hidden="true"
                />
                <p className="ml-9 text-md leading-6 font-medium text-slate-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 ml-9 text-base ">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}

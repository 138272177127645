import React from "react";
import ReactDOM from "react-dom/client";
import { ReactFormBuilder } from "react-form-builder2";

import TemplateBuilderToolbar from "./TemplateBuilderToolbar";

import DemoBar from "react-form-builder2";
// import * as variables from "./variables";
import "../css/font-awesome.min.css";
import "react-form-builder2/dist/app.css";

import store from "../store/formBuilderStore";

export default function FormBuilder() {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    console.log("FormBuilder data", data);
  }, [data]);

  store.subscribe((state) => setData(state.data));

  const handleSubmit = (data) => {
    alert("TemplateBuilder Post", data);
    console.log("TemplateBuilder Submit", data);
  };
  const handleUpdate = (data) => {
    console.log("TemplateBuilder Update", data);
  };
  var items = [
    {
      key: "Header",
      name: "Header Text",
      icon: "fa fa-header",
      static: true,
      content: "Placeholder Text...",
    },
    {
      key: "Paragraph",
      name: "Paragraph",
      static: true,
      icon: "fa fa-paragraph",
      content: "Placeholder Text...",
    },
  ];

  return (
    <div
      id="creator-content"
      // style={{
      //   maxHeight: "calc(100vh - 100px)",
      //   minHeight: "calc(100vh - 100px)",
      // }}
      className="pt-3 absolute bottom-0 top-10  h-ful w-full bg-gray-50 
        col-span-10 2xl:col-span-10 lg:col-span-9  
        overflow-y-hidden
      "
    >
      <div className="py-2">
        <TemplateBuilderToolbar />
      </div>

      <div
        id="form-builder-main-container"
        style={{
          minHeight: "calc(100vh - 300px)",
          maxHeight: "calc(100vh - 300px)",
        }}
      >
        <ReactFormBuilder
          // variables={variables}
          className="h-90 overflow-y-scroll"
          //   className="h-100 overflow-y-scroll scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100
          // scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
          data={data}
          onChange={handleUpdate}
          onPost={handleSubmit}
          onSubmit={handleSubmit}
          actionName={"Set this to change the default submit button text"}
          url="/api/formdata"
          locale="en"
          saveAlways={false}
        />
      </div>
    </div>
  );
}

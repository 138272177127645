import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function VerticalAlignContainer(props) {
  const { children } = props;
  const noShadow = props.noShadow;
  const transparent = props.transparent;
  return (
    <div className="flex flex-col m-auto justify-center items-center h-full w-full overflow-hidden">
      {/* <div className="p-4 shadow-lg rounded-md bg-white">{children}</div> */}
      {children}
    </div>
  );
}

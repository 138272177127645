import HelpItem from "./HelpItem";

const helpItems = [
  {
    id: 0,
    title: "Getting Started",
    content:
      "Welcome to the new Quote Builder!  The goal of this builder is to make it easier for consumers to read and compare your quotes.  Please take a moment to review the following help items to get started.  If you run into any problems, please report them to us and use the Additional Info text field to finish building your quote.  We will be adding more features in the future, so please check back often and keep the feedback coming!  Thank you for your patience and support!",
  },
  {
    id: 1,
    title: "Line Items",
    content:
      "Line items are how you build your quote.  You can add as many line items as you need.  When adding a line item, you can choose from a variety of pricing structures.",
  },
  {
    id: 2,
    title: "Standard Pricing",
    content:
      "Standard line items are basic pricing structures.  They can be recurring or one time.",
    example: "Example: $100 per document per month for 12 months",
  },
  {
    id: 3,
    title: "Graduated Pricing",
    content:
      "Graduated pricing is a tier-based pricing structure.  Each teir is priced independently.  For example, if you have a tier of 1-10, the first 10 items will be priced at the first tier price.  If you have a tier of 11-20, the next 10 items will be priced at the second tier price, and so on.  You can add as many tiers as you need. ",
    example:
      "Example: $100 per document for the first 10 documents, $90 per document for the next 10 documents, $80 per document for the next 10 documents",
  },
  {
    id: 4,
    title: "Volume Pricing",
    content:
      "Volume pricing is a teir-based pricing structure.  Unlike Graduated Pricing, the price is based on the tier price for the total number of items. You can add as many tiers as you need. ",
    example:
      "Example: If you have a tier of 1-10 at a price of $10 each, and a tier of 11-20 at a price of $9 each, and you have 15 items, all items will be priced at $9 each. ",
  },
  {
    id: 7,
    title: "Tiers",
    content:
      "There are two ways to charge for a tier.  The first way is per unit. The second, is a flat rate. If you choose flat rate, you will charge a single price for all items in that tier.  If you choose per unit, the price will be multiplied by the number of items in the tier.",
    warning:
      "Use caution when pricing your tiers.  For example, billing a graduated price of $100 for the first 10 items, and $5 each for the next 5 items will result in a total price of $125.  However, if you bill a volume price and enter $100 for the first 10 items, and $5 for the next 5 items, the total price will be $75.",
  },
  {
    id: 5,
    title: "Service",
    content: "A service is what you'll be doing for your client. ",
  },
  {
    id: 6,
    title: "Unit",
    content: "A unit is how you charge for your service.",
  },
];

const HelpItems = () => {
  return (
    <div className="h-full overflow-y-auto pr-2 pb-10 scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
      {helpItems.map((item) => (
        <HelpItem
          key={item.id}
          title={item.title}
          explanation={item.content}
          example={item.example}
          caution={item.warning}
        />
      ))}
    </div>
  );
};

export default HelpItems;

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SettingsSidebar from "../../partials/settings/SettingsSidebar";
import AccountPanel from "../../partials/settings/AccountPanel";
import FormPage from "../component/FormPage";
import UsersTable from "../../components/UsersTable";
import UsersPanel from "../../components/UsersPanel";
import UserAddPanel from "../../components/UserAddPanel";
import Billing from "./Billing";
import AssessmentTemplatesPanel from "../../components/AssessmentTemplatesPanel";
import Button from "../../elements/Button";
import { _auth } from "../../modules/_auth";
import NotificationsPanel from "../../partials/settings/NotificationsPanel";
import Preferences from "./Preferences";
import { features } from "../../modules/_features";
import PreferencesPage from "./Preferences";

function Account() {
  const location = useLocation();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [panel, setPanel] = useState();
  const [tabName, setTabName] = useState(); //default tab
  const [tabGroup, setTabGroup] = useState();
  const history = useHistory();
  const profile = _auth.getUserProfile();
  // const [preferenceTabs, setPreferenceTabs] = useState([]);
  // const settingsTabs = [
  //   // { id: "account", name: "Account" },
  //   { id: "users", name: "Users" },
  //   // { id: "billing", name: "Billing" },
  //   // { id: "account", name: "Account" },
  //   // { id: "solicitation_templates", name: "Templates" },
  //   // { id: "notifications", name: "Notifications" },
  // ];
  const accountTabs = [
    {
      id: "profile",
      name: "Profile",
      status: "Coming Soon!",
      visible: false,
      disabled: true,
      iconify: "pajamas:profile",
    },
    {
      id: "notifications",
      name: "Notifications",
      status: "Coming Soon!",
      visible: false,
      disabled: true,
      iconify: "pajamas:notifications",
    },
    {
      id: "preferences",
      name: "Preferences",
      status: "Coming Soon!",
      visible: true,
      disabled: true,
      iconify: "pajamas:preferences",
    },
  ];

  const teamTabs = [
    // { id: "users", name: "Users" },
    {
      id: "collaborators",
      name: "Collaborators",
      visible: true,
      iconify: "pajamas:users",
    },
    {
      id: "invite-collaborators",
      name: "Invite Collaborators",
      visible: true,
      // iconify: "pajamas:assignee",
      iconify: "pajamas:assignee",
    },
    {
      id: "team-plan",
      name: "Team Plan",
      status: "Coming Soon!",
      visible: false,
      disabled: true,
      iconify: "pajamas:list-bulleted",
    },
    {
      id: "team-connections",
      name: "Team Connections",
      status: "Coming Soon!",
      visible: false,
      disabled: true,
      iconify: "pajamas:diagram",
      description:
        "Business Partners let you choose who you work with. To start, you will send an email to a contact at another company. Once they accept your invite, you will be able to share access to projects and bid requests.  Note that you have full control over which projects and bid reqeusts you invite your partners to see.",
    },
  ];
  const companyTabs = [
    {
      id: "company-users",
      name: "Company Users",
      visible: true,
      disabled: false,
      // iconify: "grommet-icons:group",
      iconify: "pajamas:users",
    },
    {
      id: "create-team",
      name: "Create Team",
      visible: true,
      disabled: false,
      iconify: "pajamas:assignee",
    },
    {
      id: "billing",
      name: "Billing",
      status: "Coming Soon!",
      visible: false,
      disabled: true,
      iconify: "streamline:subscription-cashflow-solid",
    },
    {
      id: "all-plans",
      name: "All Plans",
      status: "Coming Soon!",
      visible: false,
    },
    {
      id: "marketplace-profile",
      name: "Marketplace Profile",
      status: "Coming Soon!",
      visible: false,
      disabled: true,
      iconify: "mingcute:profile-line",
    },
    {
      id: "business-partners",
      name: "Business Partners",
      status: "Coming Soon!",
      visible: false,
      disabled: true,
      iconify: "carbon:collaborate",
      description:
        "Business Partners let you choose who you work with. To start, you will send an email to a contact at another company. Once they accept your invite, you will be able to share access to projects and bid requests.  Note that you have full control over which projects and bid reqeusts you invite your partners to see.",
    },
  ];
  const systemTabs = [
    { id: "system-users", name: "All Users" },
    { id: "create-company", name: "Create Company" },
  ];
  const settingsTabs = [
    { id: "account", name: "Account", tabs: accountTabs },
    // { id: "preferences", name: "Preferences", tabs: preferenceTabs },
    { id: "team", name: "Team", tabs: teamTabs },
    {
      id: "company",
      name: "Company",
      tabs: companyTabs,
    },
    {
      id: "system",
      name: "System",
      tabs: systemTabs,
    },
  ];

  useEffect(() => {
    if (location && location.state) {
      setPanel(location.state.panel);
      setTabGroup(location.state.group);
      setTabName(location.state.tabName);
    } else {
      setPanel("collaborators");
      setTabGroup("team");
      setTabName("Collaborators");
    }
  }, []);

  const handleCancel = () => {
    history.push("/home");
  };

  const handleSetPanel = (panel, group, tabName) => {
    console.log("handleSetPanel", panel, group, tabName);
    setPanel(panel);
    setTabGroup(group);
    setTabName(tabName);
  };

  return (
    <div
      id="settings"
      className="h-screen px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto"
    >
      <div
        id="settings_card"
        className="h-full bg-white shadow-lg rounded-sm border border-gray-200 relative"
      >
        <div
          // style={{
          //   maxHeight: "calc(100vh - 150px)",
          //   minHeight: "calc(100vh - 150px)",
          // }}
          className="flex flex-col h-full md:flex-row md:-mr-px"
        >
          <SettingsSidebar
            profile={profile}
            tabname={panel}
            setPanel={(tab, group, tabName) =>
              handleSetPanel(tab, group, tabName)
            }
            defaultPanel={
              location && location.state
                ? location.state.panel
                : "collaborators"
            }
            defaultGroup={
              location && location.state ? location.state.group : "team"
            }
            defaultTabName={
              location && location.state
                ? location.state.tabName
                : "Collaborators"
            }
            settingsTabs={settingsTabs}
          />
          <div
            id="settings-panel-container"
            className="flex flex-col flex-grow overflow-hidden p-4 h-full"
          >
            <div id="settings-header" className="flex flex-col ">
              <div
                id="settings-title-row"
                className="flex items-center justify-between  border-b border-gray-200 mb-4 "
              >
                <div id="settings-title-left-action" className="">
                  <h2 className="text-2xl text-slate-800 font-bold mb-5">
                    {tabName}
                  </h2>
                </div>
                <div id="settings-title-left-action" className=""></div>
              </div>
              <div
                id="settings-header-bottom-row"
                className="flex items-center justify-between"
              >
                <div id="settings-header-bottom-left-action" className=""></div>
                <div
                  id="settings-header-bottom-right-action"
                  className=""
                ></div>
              </div>
            </div>
            <div
              id="settings-panel-body"
              className="flex-grow overflow-y-auto h-full scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full"
            >
              {panel == "account" && <AccountPanel />}
              {/* {panel == "users" && <UsersPanel />} */}
              {(panel == "collaborators" ||
                panel == "users" ||
                panel == "invite-collaborators" ||
                panel == "create-team" ||
                panel == "company-users" ||
                panel == "system-users" ||
                panel == "create-company") && (
                <UsersPanel
                  profile={profile}
                  group={tabGroup}
                  selectedTab={panel}
                />
              )}
              {/* {panel == "billing" && <Billing />} */}
              {panel == "solicitation_templates" && (
                <AssessmentTemplatesPanel />
              )}
              {panel == "preferences" && (
                <PreferencesPage group="Preferences" />
              )}
            </div>
            {/* <footer> */}
            <div className="flex flex-col pt-4 px-6 items-center border-t border-gray-200">
              <div className="flex self-end items-center">
                <Button color="secondary" handleClick={handleCancel}>
                  Done
                </Button>
              </div>
            </div>
            {/* </footer> */}
          </div>
        </div>
        {/* Panel footer */}
      </div>
    </div>
  );
}

export default Account;

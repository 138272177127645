import { useState, useEffect } from "react";
import Button from "../../elements/Button";
import {
  XMarkIcon,
  ChevronLeftIcon,
  EnvelopeIcon,
  ArrowLeftIcon,
} from "@heroicons/react/20/solid";
import NewMessage from "../projects/components/NewMessage";
import { messaging } from "../../modules/_messaging";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function MessageVendor({ handleClickBack, selectedItem }) {
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const successMessage = "Your message has been sent.";

  const sendMessage = async () => {
    let messageData = {
      vendorCompany: selectedItem.title,
      vendorEmail: selectedItem.email,
      message: message,
    };
    setLoading(true);
    const sent = await messaging.marketplace_email(messageData);
    if (sent) {
      console.log("Marketplace Email Sent", sent);
      setSuccess(true);
      setLoading(false);
    } else {
      console.log("Marketplace Email failed to send");
      setSuccess(false);
      setLoading(false);
    }
  };
  return (
    <>
      <div className="">
        <div className="flex border-b border-gray-200 justify-between items-center">
          <div className="flex py-2">
            {" "}
            <div className="p-2 h-10 items-center aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-white group-hover:opacity-75">
              <div className="flex items-center">
                <ArrowLeftIcon
                  onClick={() => handleClickBack("profile")}
                  className="flex items-center h-6 w-6 mr-2 cursor-pointer "
                />
                <div className="flex items-center">New message</div>
              </div>
            </div>
          </div>
          <div className="py-2 px-2  flex ">
            {/* <XMarkIcon className="h-6 w-6" /> */}
          </div>
        </div>
        {/* <div className=" mt-6 max-w-5xl px-4 sm:px-6 lg:px-8"> */}
        {/* <dl className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3"> */}
        <div className="col-span-2 px-8">
          <dt className="text-sm font-medium text-gray-500">Send a message</dt>
          <dd className=" mt-1  space-y-5 text-sm text-gray-900">
            <NewMessage
              welcomeMessage={
                "Enter your question below to send a message to this vendor."
              }
              handleSubmit={sendMessage}
              errMessage={""}
              loading={loading}
              setMessage={(msg) => setMessage(msg)}
              message={message || ""}
              handleBackClick={null}
              success={success}
            />
          </dd>
        </div>
        {/* </dl> */}
        {/* </div> */}
      </div>
    </>
  );
}

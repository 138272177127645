import React, { useEffect, useState } from "react";
import ReadOnlyAnswer from "./ReadOnlyAnswer";
import { Label } from "../../../components/Typography/FieldLabel";
import ComboboxSelector from "../../../components/ComboBoxSelector";
import RadioGroupCards from "../../../components/RadioGroupCards";
import { Paragraph } from "../../../components/Typography/Paragraph";
import QuestionToolTip from "../../../components/QuestionToolTip";
import { utils } from "../../../modules/_utils";

export const TextInput = (props) => {
  const {
    label,
    service,
    field,
    create,
    opts2,
    placeholder,
    tooltip,
    onFieldChange,
    type,
    indicator,
  } = props;
  const subLabel = props.subLabel ? props.subLabel : null;
  const defaultValue = props.defaultValue ? props.defaultValue : null;
  const required = props.required ? props.required : false;
  // const initialValue = opts2[field]
  //   ? opts2[field].toString()
  //   : defaultValue
  //   ? defaultValue.toString()
  //   : "";
  // let initialFormattedValue, initialActualValue;

  // if (initialValue && !isNaN(Number(initialValue.replace(/,/g, "")))) {
  //   initialActualValue = initialValue.replace(/,/g, "");
  //   initialFormattedValue = Number(initialActualValue).toLocaleString();
  // } else {
  //   initialActualValue = initialValue;
  //   initialFormattedValue = initialValue;
  // }

  // const stacked = props.stacked ? props.stacked : false;
  // const fieldType = props.fieldType ? props.fieldType : "text";
  // const [displayValue, setDisplayValue] = useState(initialFormattedValue);
  // const [actualValue, setActualValue] = useState(initialActualValue);
  const [err, setErr] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [displayValue, setDisplayValue] = useState();
  // value={opts2[field] ? opts2[field] : ""}
  // useEffect(() => {
  //   //if defaultValue is set, call onFieldChange to set the default value
  //   if (defaultValue && defaultValue !== null && create) {
  //     onFieldChange(
  //       "options",
  //       {
  //         target: { name: field, value: defaultValue },
  //       },
  //       field,
  //       service
  //     );
  //     setDisplayValue(defaultValue);
  //   } else {
  //     let defaultValue = opts2[field] ? opts2[field] : "";
  //     setDisplayValue(defaultValue);
  //   }
  // }, [defaultValue]);

  useEffect(() => {
    let value = "";
    if (opts2[field]) {
      value = opts2[field];
    } else if (defaultValue && defaultValue !== null && create) {
      value = defaultValue;
      onFieldChange(
        "options",
        {
          target: { name: field, value: defaultValue },
        },
        field,
        service
      );
    }
    setDisplayValue(value);
  }, [defaultValue, opts2, field, create, onFieldChange, service]);

  useEffect(() => {
    if (
      required &&
      (displayValue == "" || displayValue == null || !displayValue)
    ) {
      setErr(true);
    } else {
      setErr(false);
    }
  }, [defaultValue, displayValue]);

  const handleInputChange = (e) => {
    setIsChanged(true);
    const value = e.target.value;
    setDisplayValue(value);
    onFieldChange("options", e, field, service);
  };
  // const handleInputChange = (e) => {
  //   const value = e.target.value;
  //   let actualValue, formattedValue;

  //   if (value !== "" && !isNaN(Number(value.replace(/,/g, "")))) {
  //     actualValue = value.replace(/,/g, "");
  //     formattedValue = Number(actualValue).toLocaleString();
  //   } else {
  //     actualValue = value;
  //     formattedValue = value;
  //   }

  //   setDisplayValue(formattedValue);
  //   setActualValue(actualValue);
  //   onFieldChange(
  //     "options",
  //     { ...e, target: { ...e.target, value: actualValue } },
  //     field,
  //     service
  //   );
  // };

  return (
    <div className="sm:col-span-6 flex flex-col ">
      <div className="flex flex-col col-span-full lg:col-span-3">
        <Label
          bold
          sm
          htmlFor={field}
          className="col-span-4 block text-slate-700 items-center"
        >
          {label}{" "}
          {create && tooltip && tooltip.text && (
            <QuestionToolTip text={tooltip.text} field={tooltip.title} />
          )}
        </Label>
        {subLabel && (
          <p htmlFor={field} sm className="text-sm text-gray-600">
            {subLabel}
          </p>
        )}
        {!create && (
          <div className="col-span-6 mb-6">
            <ReadOnlyAnswer
              answer={opts2[field] ? opts2[field] : ""}
              type="text"
            />
          </div>
        )}{" "}
      </div>
      <div className="grid grid-cols-6">
        {create && (
          <div className=" mt-1 col-span-full lg:col-span-2 flex rounded-md shadow-sm relative">
            {type && type == "size" && (
              <div className=" items-end text-xl pointer-events-none absolute inset-y-1 right-0 flex pr-3">
                GB
              </div>
            )}
            {type && type == "hours_per_week" && (
              <div className=" items-end text-xl pointer-events-none absolute inset-y-1 right-0 flex pr-3">
                hrs/wk
              </div>
            )}
            {indicator && (
              <div className=" items-end text-xl pointer-events-none absolute inset-y-1 right-0 flex pr-3">
                {indicator}
              </div>
            )}

            <input
              name={field}
              type="text"
              id={field}
              autoComplete="none"
              placeholder={placeholder} //field, e, key, service
              // value={opts2[field] ? opts2[field] : defaultValue}
              onChange={(e) => handleInputChange(e)}
              value={displayValue}
              // onChange={handleInputChange}
              className={`block w-full min-w-0 flex-1  rounded-md border-gray-300 ${
                required && err ? "border-l-4 border-l-red-500" : ""
              } focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export const Dropdown = (props) => {
  const {
    label,
    service,
    field,
    create,
    options,
    fieldType,
    handleClearAllSelections,
    handleSelect,
    handleDeselect,
    handleDeselectedLanguage,
    opts2,
    placeholder,
    tooltip,
    onFieldChange,
    newProject,
  } = props;
  const defaultValue = props.defaultValue ? props.defaultValue : null;
  const [data, setData] = React.useState(null);
  useEffect(() => {
    console.log("newProject in DataEntry", newProject);
    setData(newProject);
  }, [newProject]);

  return (
    <div className="sm:col-span-6 grid grid-cols-6">
      <div className="flex flex-col col-span-full lg:col-span-2">
        <Label sm bold htmlFor={field} className="my-auto  block">
          {label}{" "}
          {create && tooltip && tooltip.text && (
            <QuestionToolTip text={tooltip.text} field={tooltip.title} />
          )}
        </Label>
        {!create && (
          <div className="col-span-full mb-6">
            <ReadOnlyAnswer
              type={"chips"}
              answer={
                newProject &&
                newProject.services[
                  newProject.services.findIndex((el) => el.service === service)
                ].opts2[field]
              }
            />
          </div>
        )}

        {create && (
          <div className="mt-1 col-span-2 flex rounded-md shadow-sm">
            <ComboboxSelector
              name={field}
              options={options}
              fieldType={fieldType}
              open={true}
              handleSelect={(v) => handleSelect(v, service, field)}
              handleDeselect={handleDeselect}
              handleClearAllSelections={(value) =>
                handleClearAllSelections(value, service, field)
              } //{handleClearAllSelections}
              // value={opts2[field] ? opts2[field] : null}
              value={() => {
                return fieldType === "multiSelect"
                  ? newProject &&
                    newProject.services &&
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2[field]
                    ? newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2[field]
                    : []
                  : newProject &&
                      newProject.services[
                        newProject.services.findIndex(
                          (el) => el.service === service
                        )
                      ].opts2[field];
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const RadioGroupSingle = (props) => {
  const {
    label,
    subLabel,
    description,
    service,
    field,
    create,
    options,
    cols,
    type,
    handleClearAllSelections,
    handleSelect,
    handleDeselectedLanguage,
    placeholder,
    tooltip,
    onFieldChange,
    newProject,
  } = props;
  const stacked = props.stacked ? props.stacked : false;
  const defaultValue = props.defaultValue ? props.defaultValue : null;
  useEffect(() => {
    //if defaultValue is set, call onFieldChange to set the default value
    if (defaultValue && create) {
      handleSelect(defaultValue, service, field);
    }
  }, [defaultValue]);
  return (
    <div className="col-span-full ">
      <div className="col-span-full">
        <Label sm bold htmlFor={field} className="block  ">
          {label}
          {create && tooltip && tooltip.text && (
            <QuestionToolTip text={tooltip.text} field={tooltip.title} />
          )}
        </Label>
        {subLabel && (
          <p htmlFor={field} sm className="text-sm text-gray-600">
            {subLabel}
          </p>
        )}
        {create && description && (
          <p className="mt-2 text-sm text-slate-500">{description}</p>
        )}
        <RadioGroupCards
          name="services"
          type={type}
          stacked={stacked}
          defaultValue={() => {
            return newProject &&
              newProject.services[
                newProject.services.findIndex((el) => el.service === service)
              ].opts2[field]
              ? newProject.services[
                  newProject.services.findIndex((el) => el.service === service)
                ].opts2[field]
              : defaultValue
              ? defaultValue
              : "";
          }}
          value={() => {
            return newProject &&
              newProject.services[
                newProject.services.findIndex((el) => el.service === service)
              ].opts2[field]
              ? newProject.services[
                  newProject.services.findIndex((el) => el.service === service)
                ].opts2[field]
              : defaultValue
              ? defaultValue
              : "";
          }}
          disabled={!create}
          className={`mt-6 ${stacked && "flex flex-col w-96"} ${
            !stacked && "grid"
          } gap-y-6 grid-cols-1  lg:grid-cols-2   xl:grid-cols-${cols}  sm:gap-x-12`}
          // onChange={(e) => handleSelect("options", e, field, service)}
          onChange={(e) => handleSelect(e, service, field)}
          options={options}
        />
      </div>
      {newProject &&
        newProject.services[
          newProject.services.findIndex((el) => el.service === service)
        ].opts2.weekday_hours == "custom" && (
          <div className="sm:col-span-full mt-4">
            <label
              htmlFor={field}
              className="block text-sm font-medium text-slate-700"
            >
              Custom Weekday Hours
            </label>

            {!create && (
              <div className="mt-1">
                <ReadOnlyAnswer
                  type="text"
                  answer={
                    newProject.services[
                      newProject.services.findIndex(
                        (el) => el.service === service
                      )
                    ].opts2[field]
                  }
                />
              </div>
            )}
          </div>
        )}
    </div>
  );
};
export const Checkboxes = (props) => {
  const {
    newProject,
    onFieldChange,
    opts2,
    label,
    description,
    service,
    field,
    create,
    options,
    tooltip,
    fieldType,
  } = props;
  const defaultValue = props.defaultValue ? props.defaultValue : false;
  const defaultChecked = props.defaultChecked ? props.defaultChecked : false;
  let optionsField = field + "_options";
  useEffect(() => {
    //if defaultValue is set, call onFieldChange to set the default value
    let e = {
      target: { name: field, value: true },
    };

    if (
      defaultChecked &&
      create &&
      newProject?.services[
        newProject?.services?.findIndex((el) => el.service === service)
      ].opts2[field] === undefined
    ) {
      onFieldChange(
        "options",

        e,

        field,
        service,
        null,
        true,
        optionsField
      );
    }
  }, [defaultChecked]);
  return (
    <div className="col-span-6">
      <div className="w-full relative flex items-start">
        <div className="flex h-5 items-center">
          <input
            id={field}
            name={field}
            type="checkbox"
            disabled={!create}
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            // value={opts2.project_reporting && opts2.project_reporting}\
            checked={
              newProject &&
              newProject.services[
                newProject.services.findIndex((el) => el.service === service)
              ].opts2[field]
            }
            onChange={(e) => {
              onFieldChange(
                "options",

                e,

                field,
                service,
                null,
                true,
                optionsField
              );
            }}
          />
        </div>
        <div className="ml-3 text-sm">
          <Label sm bold htmlFor={field} className="">
            {label}{" "}
            {create && tooltip && tooltip.text && (
              <QuestionToolTip text={tooltip.text} field={tooltip.title} />
            )}
          </Label>
          <p className="text-slate-500">{description}</p>
          {options &&
            options.length > 0 &&
            opts2 &&
            opts2[field] &&
            opts2[field] == true && (
              <div className="mt-2 space-y-2">
                {/* Subcheckboxes */}
                {/* map options */}
                {options &&
                  options.length > 0 &&
                  options.map((option) => {
                    return (
                      <div className="relative flex items-start">
                        <div className="relative flex ">
                          <div className="flex h-5 items-center">
                            <input
                              id={option.value}
                              name={option.value}
                              type="checkbox"
                              disabled={!create}
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              value={
                                opts2 && opts2[optionsField]
                                  ? opts2[optionsField][option.value]
                                  : false
                              }
                              defaultChecked={
                                opts2 && opts2[optionsField]
                                  ? opts2[optionsField][option.value]
                                  : false
                              }
                              onChange={(e) => {
                                onFieldChange(
                                  "options",
                                  //if checked, true, else false
                                  e,
                                  option.value,
                                  service,
                                  optionsField
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ml-3 text-sm">
                          <Label sm normal htmlFor={option.value} className="">
                            {option.label}
                          </Label>
                        </div>
                      </div>
                    );
                  })}

                {/* Other Confirmation */}
                <div className="relative flex items-start">
                  <div className="relative flex ">
                    <div className="flex h-5 items-center">
                      <input
                        id="other_confirmation"
                        name="other_confirmation"
                        type="checkbox"
                        disabled={!create}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        value={
                          opts2[optionsField] &&
                          opts2[optionsField].other_confirmation
                            ? opts2[optionsField].other_confirmation
                            : false
                        }
                        defaultChecked={
                          opts2[optionsField] &&
                          opts2[optionsField].other_confirmation
                            ? opts2[optionsField].other_confirmation
                            : false
                        }
                        onChange={(e) => {
                          onFieldChange(
                            "options",
                            //if checked, true, else false
                            e,

                            "other_confirmation",
                            service,

                            optionsField
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor={"other_confirmation"}
                      className="text-slate-700"
                    >
                      Other Reporting
                    </label>
                  </div>
                </div>
                {opts2 &&
                  opts2[optionsField] &&
                  opts2[optionsField].other_confirmation == true && (
                    <div className="col-span-6">
                      <label
                        htmlFor={field}
                        className="block text-sm font-medium text-slate-700"
                      >
                        Other reporting
                      </label>
                      {!create && (
                        <ReadOnlyAnswer
                          type="text"
                          answer={opts2[optionsField].other_details}
                        />
                      )}
                      {create && (
                        <p className="mt-2 text-sm text-slate-500">
                          Write a few sentences to describe your required
                          reporting.
                        </p>
                      )}
                      {create && (
                        <div className="w-full mt-1">
                          <textarea
                            id="description"
                            name="description"
                            rows={3}
                            value={opts2[optionsField].other_details}
                            onChange={(e) => {
                              onFieldChange(
                                "options",
                                //if checked, true, else false
                                e,

                                "other_details",
                                service,

                                optionsField
                              );
                            }}
                            className="block  w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            defaultValue={""}
                          />
                        </div>
                      )}
                    </div>
                  )}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export const PricingInput = (props) => {
  const { label, service, field, fullQuote, response, onFieldChange } = props;
  return (
    <div className="relative w-full col-span-full ">
      <label htmlFor={field} className="text-sm font-medium mb-3 capitalize">
        Price for {utils.cleanUpKeys(field)}
      </label>
      <div className="pb-3 items-end text-xl pointer-events-none absolute inset-y-0 left-0 flex pl-3">
        $
      </div>
      <input
        id="price"
        name="price"
        type="text"
        autoComplete="price"
        disabled={fullQuote && fullQuote.status == "submitted"}
        required
        className="block w-full min-w-full max-w-full px-10 py-3 placeholder-gray-500  focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
        placeholder={`Enter your ${utils.cleanUpKeys(field)} pricing.`}
        value={
          response && response[service] && response[service]["price"]
            ? response[service][field]
            : ""
        }
        onChange={(e) => {
          onFieldChange(e, service);
        }}
      />
    </div>
  );
};

export const TextArea = (props) => {
  const {
    label,
    description,
    service,
    field,
    create,
    opts2,
    placeholder,
    tooltip,
    onFieldChange,
    newProject,
  } = props;
  return (
    <div className="sm:col-span-full mt-4">
      <label
        htmlFor={field}
        className="block text-sm font-medium text-slate-700"
      >
        {label}
        {create && tooltip && tooltip.text && (
          <QuestionToolTip text={tooltip.text} field={tooltip.title} />
        )}
      </label>

      {create && (
        <div>
          <p className="mt-2 text-sm text-slate-500">{description}</p>
          <div className="mt-1">
            <textarea
              id={field}
              name={field}
              rows={3}
              value={
                newProject.services[
                  newProject.services.findIndex((el) => el.service === service)
                ].opts2[field]
              }
              placeholder={placeholder}
              onChange={(e) => {
                onFieldChange(
                  "options",
                  //if checked, true, else false
                  e,

                  field,
                  service
                );
              }}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              defaultValue={""}
            />
          </div>
        </div>
      )}
      {!create && (
        <div className="mt-1">
          <ReadOnlyAnswer
            type="text"
            answer={
              newProject.services[
                newProject.services.findIndex((el) => el.service === service)
              ].opts2[field]
            }
          />
        </div>
      )}
    </div>
  );
};

export const RadioGroupMulti = (props) => {};

export const Section = (props) => {
  const { label, description, create, tooltip } = props;
  return (
    <div className="sm:col-span-6">
      <h3 className="text-large font-semibold leading-6 text-slate-900">
        {label}
        {create && tooltip && tooltip.text && (
          <QuestionToolTip text={tooltip.text} field={tooltip.title} />
        )}
      </h3>
      {create && (
        <Paragraph sm className="">
          {description}
        </Paragraph>
      )}
    </div>
  );
};

export const SectionDivider = () => {
  return <div className="my-4 sm:col-span-6 border-b border-gray-200" />;
};

import React from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import Button from "../../../elements/Button";

import { utils } from "../../../modules/_utils";
import RightActionArea from "../../../components/ActionAreaRight";

export default function ListTableItem(props) {
  const { item, selectedRow, vendor } = props;
  return (
    <li
      className={`px-6 py-4 ${
        selectedRow && selectedRow._id == item._id ? "bg-gray-100" : "bg-white"
      } hover:bg-gray-50 cursor-pointer
            transition-all duration-500 ease-in-out
           
            
            `}
      key={item._id}
      onClick={(e) => props.clickHandler(item)}
    >
      <div className="space-y-3 transition-all duration-500 ease-in-out">
        <div
          id="top-row"
          className="flex flex-row grid grid-cols-12 items-top col-span-full space-x-6  space-y-3 sm:space-y-1 md:flex-row  justify-between  "
        >
          <div id="top-left" className="col-span-6">
            <div className="col-span-4 items-center justify-between">
              <h2 className="text-lg  font-semibold text-slate-800">
                {item.key.value} {item.name && item.name.value}{" "}
                <span className="ml-2">{item.badge && item.badge.value()}</span>
              </h2>

              <div className=" ">
                {item && item.subTitle && (
                  <p className="truncate text-xs text-slate-600">
                    <span className="text-slate-500">
                      {" "}
                      {item.subTitle && item.subTitle.label}{" "}
                    </span>
                    <span className="text-indigo-500 font-semibold">
                      {item.subTitle && item.subTitle.value}
                    </span>{" "}
                    {item && item.createdBy && (
                      <>
                        <span className="text-slate-500">by </span>
                        <span className="text-indigo-500 font-semibold">
                          Some text here
                        </span>
                      </>
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            id="top-middle"
            className="col-span-4 flex grid grid-cols-8 gap-x-6  sm:space-y-1 md:flex-row justify-start"
          >
            {item.topMiddle && (
              <div className=" col-span-1 items-center ">
                {item.topMiddle.map((item, index) => {
                  return (
                    <div className="text-md">
                      <span className="font-semibold whitespace-nowrap">
                        {item.label}: {item.value}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div id="top-right" className="col-span-2">
            <RightActionArea>
              {item.actionButtons &&
                item.actionButtons.map((button, index) => {
                  return (
                    <Button
                      key={index}
                      iconButton
                      size="sm"
                      color="secondary"
                      handleClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        button.handleClick(e, item);
                      }}
                    >
                      {button.icon()}
                    </Button>
                  );
                })}
            </RightActionArea>
          </div>
        </div>

        <div
          id="second-row"
          className={`flex flex-col md:flex-row  data-center justify-between
               
                `}
        >
          <p className="line-clamp-3 text-sm  text-slate-800">
            {item.description}
          </p>
        </div>
        {/* <div id="last_row">{item.lastRow && item.lastRow.value()}</div> */}
      </div>
    </li>
  );
}

let pdfKey = "";
if (process.env.REACT_APP_NODE_ENV === "development") {
  pdfKey = "5f5aa7c940444940aa54c9b8e9882b69";
} else if (process.env.REACT_APP_NODE_ENV === "production") {
  pdfKey = "74ae679d4dd74c12806f5289876d35cf";
} else if (process.env.REACT_APP_NODE_ENV === "beta") {
  pdfKey = "2e29a91eea9e4eb79ba664caa3e057ed";
} else {
  //local
  pdfKey = "5f5aa7c940444940aa54c9b8e9882b69";
}
// console.info("CURRENT ENVIRONMENT: ", process.env.REACT_APP_NODE_ENV);
console.info("CURRENT PDF KEY: ", pdfKey);

export default { pdfKey };

import { Fragment, useEffect, useMemo, useState } from "react";

import Tip from "../../../components/Tip";
import { Section } from "./DataEntry";
import all_assumptions from "../../../config/assumptions";
import { utils } from "../../../modules/_utils";
export default function Assumptions({ service, solicitation }) {
  const [data, setData] = useState({});
  const [assumptions, setAssumptions] = useState([]);
  const colSpan = 2;
  const rowspan = 1;

  useEffect(() => {
    console.log(
      "DataMiningInfo useEffect",
      solicitation.services[
        solicitation.services.findIndex((el) => el.service === service)
      ].opts2
    );
    let sol =
      solicitation.services[
        solicitation.services.findIndex((el) => el.service === service)
      ].opts2;
    console.log("Assumptions useEffect solicitation", sol);
    setData(sol);

    console.log("all_assumptions", all_assumptions.services);
    setAssumptions(all_assumptions.services[service].fields);
  }, [solicitation]);

  return (
    <div className="p-4 rounded bg-gray-50 border border-gray-200 ">
      <div className="col-span-6 text-xl font-medium text-slate-900">
        {utils.cleanUpKeys(service)} Assumptions
      </div>
      <div className="col-span-6 text-md">
        These are the assumptions that must be used to generate your quote.
        Please read them carefully.
      </div>
      {assumptions.map((assumption, index) => {
        if (
          assumption.type === "warning" &&
          data[assumption.name] &&
          data[assumption.name] !== false
        ) {
          return (
            // <Tip color="red" colSpan={"full"}>
            <div className="col-span-full border-l-4 rounded border-l-red-500 bg-white shadow p-1.5  mb-6 mt-6">
              <div>
                <span className="ml-1 font-normal text-sm">
                  {assumption.message(data[assumption.name], service)}
                </span>
              </div>
            </div>
          );
        }
      })}
      {/* <Tip color="red" colSpan={"full"}>
          <div className="mb-6 inline">
            <span className="ml-1 font-medium">
              The requestor has asked that you not mix quotes from Data Mining
              and Review. Please use the appropiate service for your quote.
            </span>
          </div>
        </Tip> */}
      <div id="assumptions-list" className="w-full  mt-4">
        <div className="col-span-full grid grid-cols-6 gap-4 ">
          {assumptions.map((assumption, index) => {
            if (
              (data[assumption.name] || assumption.type === "system") &&
              assumption.type !== "warning"
            ) {
              return (
                <div
                  className={`col-span-2 bg-white shadow row-span-${rowspan}  rounded `}
                >
                  <div className="px-5 pt-5 pb-5">
                    {/* <header className="flex grid grid-flow-col align-middle ">
                    <div className="inline-flex w-fit align-middle  text-lg font-semibold text-slate-800 mb-2 ml-0">
                      {assumption.title}
                    </div>
                  </header> */}

                    <div className="flex items-start">
                      <div className="  text-2xl font-semibold text-slate-800 mr-3 mb-2">
                        {!isNaN(Number(data[assumption.name]))
                          ? Number(data[assumption.name]).toLocaleString()
                          : assumption.type == "system"
                          ? assumption.value &&
                            utils.cleanUpKeys(assumption.value.toLocaleString())
                          : data[assumption.name] &&
                            utils.cleanUpKeys(data[assumption.name])}{" "}
                        {assumption.label &&
                          assumption.label != "Value" &&
                          assumption.label != "Documents" &&
                          assumption.label}
                      </div>
                    </div>
                    <div className="inline-flex w-fit align-middle  text-md font-semibold text-slate-600 mb-2 ml-0">
                      {assumption.title}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/20/solid";
import OnboardingImage from "../images/onboarding-image.jpg";
import OnboardingDecoration from "../images/auth-decoration.png";
import { _assessment } from "../modules/_assessment";
const steps = _assessment.getAssessmentSteps();

const mailingLists = [
  {
    id: 1,
    title: "Food & Beverage",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 2,
    title: "Hospitality",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 3,
    title: "Retail",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 4,
    title: "Education",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 5,
    title: "Healthcare",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 6,
    title: "Financial",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 7,
    title: "Consumer Services",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 8,
    title: "Manufacturing",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 9,
    title: "Government",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 10,
    title: "Transportation",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 11,
    title: "Media",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 12,
    title: "Communications",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  // {
  //   id: 13,
  //   title: "Energy",
  //   description: "Last message sent an hour ago",
  //   users: "621 users",
  // },
  // {
  //   id: 14,
  //   title: "Technology",
  //   description: "Last message sent 2 weeks ago",
  //   users: "1200 users",
  // },
  // {
  //   id: 15,
  //   title: "Other",
  //   description: "Last message sent 4 days ago",
  //   users: "2740 users",
  // },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Onboarding01() {
  const [selectedMailingLists, setSelectedMailingLists] = useState(
    mailingLists[0]
  );
  return (
    <main className="bg-white">
      <div className="relative flex">
        {/* Content */}
        <div className="w-full">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            <div className="flex-1">
              {/*New Progress Bar*/}
              <nav aria-label="Progress">
                <ol
                  role="list"
                  className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0"
                >
                  {steps.map((step, stepIdx) => (
                    <li key={step.name} className="relative md:flex-1 md:flex">
                      {step.status === "complete" ? (
                        <a
                          href={step.href}
                          className="group flex items-center w-full"
                        >
                          <span className="px-6 py-4 flex items-center text-sm font-medium">
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-primary-600 rounded-full group-hover:bg-primary-800">
                              <CheckIcon
                                className="w-6 h-6 text-white"
                                aria-hidden="true"
                              />
                            </span>
                            <span className="ml-4 text-sm font-medium text-slate-900">
                              {step.name}
                            </span>
                          </span>
                        </a>
                      ) : step.status === "current" ? (
                        <a
                          href={step.href}
                          className="px-6 py-4 flex items-center text-sm font-medium"
                          aria-current="step"
                        >
                          <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-primary-600 rounded-full">
                            <span className="text-primary-600">{step.id}</span>
                          </span>
                          <span className="ml-4 text-sm font-medium text-primary-600">
                            {step.name}
                          </span>
                        </a>
                      ) : (
                        <a href={step.href} className="group flex items-center">
                          <span className="px-6 py-4 flex items-center text-sm font-medium">
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                              <span className="text-slate-500 group-hover:text-slate-900">
                                {step.id}
                              </span>
                            </span>
                            <span className="ml-4 text-sm font-medium text-slate-500 group-hover:text-slate-900">
                              {step.name}
                            </span>
                          </span>
                        </a>
                      )}

                      {stepIdx !== steps.length - 1 ? (
                        <>
                          {/* Arrow separator for lg screens and up */}
                          <div
                            className="hidden md:block absolute top-0 right-0 h-full w-5"
                            aria-hidden="true"
                          >
                            <svg
                              className="h-full w-full text-slate-300"
                              viewBox="0 0 22 80"
                              fill="none"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M0 -2L20 40L0 82"
                                vectorEffect="non-scaling-stroke"
                                stroke="currentcolor"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </>
                      ) : null}
                    </li>
                  ))}
                </ol>
              </nav>

              {/* OLD Progress bar */}
              {/* <div className="px-4 pt-12 pb-8">
                <div className="max-w-md mx-auto w-full">
                  <div className="relative">
                    <div
                      className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-gray-200"
                      aria-hidden="true"
                    ></div>
                    <ul className="relative flex justify-between w-full">
                      <li>
                        <Link
                          className="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-primary-500 text-white"
                          to="/onboarding-01"
                        >
                          1
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-gray-100 text-slate-500"
                          to="/onboarding-02"
                        >
                          2
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-gray-100 text-slate-500"
                          to="/onboarding-03"
                        >
                          3
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-gray-100 text-slate-500"
                          to="/onboarding-04"
                        >
                          4
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="px-4 py-8">
              <div className="max-w-md mx-auto">
                <h1 className="text-3xl text-slate-800 font-bold mb-6">
                  Tell us about your industry
                </h1>
                <RadioGroup
                  value={selectedMailingLists}
                  onChange={setSelectedMailingLists}
                >
                  <RadioGroup.Label className="text-base font-medium text-slate-900">
                    Select your industry
                  </RadioGroup.Label>

                  <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                    {mailingLists.map((mailingList) => (
                      <RadioGroup.Option
                        key={mailingList.id}
                        value={mailingList}
                        className={({ checked, active }) =>
                          classNames(
                            checked ? "border-transparent" : "border-gray-300",
                            active ? "ring-2 ring-primary-500" : "",
                            "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                          )
                        }
                      >
                        {({ checked, active }) => (
                          <>
                            <div className="flex-1 flex  items-center">
                              <div className="flex flex-col">
                                <RadioGroup.Label
                                  as="span"
                                  className="block text-sm font-medium text-slate-900 "
                                >
                                  {mailingList.title}
                                </RadioGroup.Label>
                                {/* <RadioGroup.Description
                                  as="span"
                                  className="mt-1 flex items-center text-sm text-slate-500"
                                >
                                  {mailingList.description}
                                </RadioGroup.Description> */}
                                {/* <RadioGroup.Description
                                  as="span"
                                  className="mt-6 text-sm font-medium text-slate-900"
                                >
                                  {mailingList.users}
                                </RadioGroup.Description> */}
                              </div>
                            </div>
                            <CheckCircleIcon
                              className={classNames(
                                !checked ? "invisible" : "",
                                "h-5 w-5 text-primary-600"
                              )}
                              aria-hidden="true"
                            />
                            <div
                              className={classNames(
                                active ? "border" : "border-2",
                                checked
                                  ? "border-primary-500"
                                  : "border-transparent",
                                "absolute -inset-px rounded-lg pointer-events-none"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
                {/* Form */}
                {/* <form>
                  <div className="space-y-3 mb-8">
                    <label className="relative block cursor-pointer">
                      <input
                        type="radio"
                        name="radio-buttons"
                        className="peer sr-only"
                        defaultChecked
                      />
                      <div className="flex items-center bg-white text-sm font-medium text-slate-800 p-4 rounded border border-gray-200 hover:border-gray-300 shadow-sm duration-150 ease-in-out">
                        <svg
                          className="w-6 h-6 shrink-0 fill-current mr-4"
                          viewBox="0 0 24 24"
                        >
                          <path
                            className="text-primary-500"
                            d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z"
                          />
                          <path
                            className="text-primary-300"
                            d="m11 12.588-9-5V18a1 1 0 0 0 .514.874L11 23.588v-11Z"
                          />
                          <path
                            className="text-primary-200"
                            d="M13 12.588v11l8.486-4.714A1 1 0 0 0 22 18V7.589l-9 4.999Z"
                          />
                        </svg>
                        <span>Financial</span>
                      </div>
                      <div
                        className="absolute inset-0 border-2 border-transparent peer-checked:border-primary-400 rounded pointer-events-none"
                        aria-hidden="true"
                      ></div>
                    </label>
                    <label className="relative block cursor-pointer">
                      <input
                        type="radio"
                        name="radio-buttons"
                        className="peer sr-only"
                      />
                      <div className="flex items-center bg-white text-sm font-medium text-slate-800 p-4 rounded border border-gray-200 hover:border-gray-300 shadow-sm duration-150 ease-in-out">
                        <svg
                          className="w-6 h-6 shrink-0 fill-current mr-4"
                          viewBox="0 0 24 24"
                        >
                          <path
                            className="text-primary-500"
                            d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z"
                          />
                          <path
                            className="text-primary-300"
                            d="m11 12.588-9-5V18a1 1 0 0 0 .514.874L11 23.588v-11Z"
                          />
                        </svg>
                        <span>Transportation</span>
                      </div>
                      <div
                        className="absolute inset-0 border-2 border-transparent peer-checked:border-primary-400 rounded pointer-events-none"
                        aria-hidden="true"
                      ></div>
                    </label>
                    <label className="relative block cursor-pointer">
                      <input
                        type="radio"
                        name="radio-buttons"
                        className="peer sr-only"
                      />
                      <div className="flex items-center bg-white text-sm font-medium text-slate-800 p-4 rounded border border-gray-200 hover:border-gray-300 shadow-sm duration-150 ease-in-out">
                        <svg
                          className="w-6 h-6 shrink-0 fill-current mr-4"
                          viewBox="0 0 24 24"
                        >
                          <path
                            className="text-primary-500"
                            d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z"
                          />
                        </svg>
                        <span>Medical</span>
                      </div>
                      <div
                        className="absolute inset-0 border-2 border-transparent peer-checked:border-primary-400 rounded pointer-events-none"
                        aria-hidden="true"
                      ></div>
                    </label>
                  </div></form> */}
                <div className="mt-10 flex items-center justify-between">
                  <Link
                    className="btn bg-primary-500 hover:bg-primary-600 text-white ml-auto"
                    to="/onboarding-02"
                  >
                    Next Step -&gt;
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        {/* <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={OnboardingImage}
            width="760"
            height="1024"
            alt="Onboarding"
          />
          <img
            className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8 hidden lg:block"
            src={OnboardingDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div> */}
      </div>
    </main>
  );
}

export default Onboarding01;

import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const Preferences = {
  profile() {
    if (
      _auth.getUserProfile() === null ||
      _auth.getUserProfile() === undefined
    ) {
      return JSON.parse(localStorage.getItem("guestProfile"));
    } else {
      return _auth.getUserProfile();
    }
  },
  getPreference(preferences, preferenceIdToFind) {
    const foundPreference = preferences.find(
      (preference) => preference.id === preferenceIdToFind
    );

    if (foundPreference) {
      console.log(`Found preference: ${foundPreference}`);
    } else {
      console.log(`Preference ${preferenceIdToFind} not found`);
    }
    return foundPreference;
  },
  getPreferenceValue(preferences, preferenceIdToFind) {
    const foundPreference = preferences.find(
      (preference) => preference.id === preferenceIdToFind
    );

    if (foundPreference) {
      console.log(
        `Found preference value for ${preferenceIdToFind}: ${foundPreference.value}`
      );
    } else {
      console.log(`Preference ${preferenceIdToFind} not found`);
    }
    return foundPreference?.value || false;
  },
  async getPreferences() {
    let data = {};
    console.log("Call API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.user_id = profile.user_id;
    data.tenantId = profile.tenantId;
    data.companyId = profile?.tenantCompanyId && profile.tenantCompanyId;

    console.log("API body - preferences/get-preferences", data);
    let response = await postRequest("preferences/get-preferences", data);
    if (response) {
      console.log(
        "Got preferences/get-preferences response",
        response.data.data
      );
      return response.data.data;
    } else {
      return [];
    }
  },
  async setPreferences(updates) {
    let data = {};

    console.log("Call API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.user_id = profile.user_id;
    data.tenantId = profile.tenantId;
    data.companyId = profile?.tenantCompanyId && profile.tenantCompanyId;
    data.updates = updates;
    // data.updates.userId = profile.user_id;
    console.log("API body - preferences/set-preferences", data);
    let response = await postRequest("preferences/set-preferences", data);
    if (response) {
      console.log("Got preferences/set-preferences response", response);
      return response.data.data;
    } else {
      return response;
    }
  },
};

export const calculator = {
  calculate(bids, assumptions) {
    console.log("Calculating based on assumptions", assumptions);
    // bids = [
    //   {
    //     submittedByName: "Betty Blueberry",
    //     submittedByCompany: "Blueberry's Blueberries",
    //     services: {
    //       data_mining: {
    //         pricing: {
    //           Processing: {
    //             type: "Standard",
    //             unit: "GB",
    //             price: "10",
    //           },
    //           Review: {
    //             type: "Volume",
    //             tiers: [
    //               {
    //                 min: 1,
    //                 max: 50,
    //                 price: "65",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 51,
    //                 max: 250,
    //                 price: "48",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 251,
    //                 max: 500,
    //                 price: "35",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 501,
    //                 max: 1000,
    //                 price: "25",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 1001,
    //                 max: "∞",
    //                 price: "12",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //             ],

    //             unit: "Document",
    //           },
    //           Hosting: {
    //             type: "Graduated",
    //             tiers: [
    //               {
    //                 min: 1,
    //                 max: 50,
    //                 price: "65",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 51,
    //                 max: 250,
    //                 price: "48",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 251,
    //                 max: 500,
    //                 price: "35",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 501,
    //                 max: 1000,
    //                 price: "25",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 1001,
    //                 max: "∞",
    //                 price: "12",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //             ],

    //             unit: "GB",
    //           },
    //           "PDF Review": {
    //             type: "Volume",
    //             unit: "Document",
    //             tiers: [
    //               {
    //                 min: "1",
    //                 max: "∞",
    //                 price: "0.78",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //             ],
    //           },
    //           "Excel Review": {
    //             type: "Volume",
    //             unit: "Document",
    //             tiers: [
    //               {
    //                 min: "1",
    //                 max: "∞",
    //                 price: "21",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //             ],
    //           },
    //         },
    //       },
    //     },
    //   },
    //   {
    //     submittedByName: "Bennie Beanie",
    //     submittedByCompany: "Bennie Beanie's Beanie Business",
    //     services: {
    //       data_mining: {
    //         pricing: {
    //           Processing: {
    //             type: "Standard",
    //             unit: "GB",
    //             price: "5",
    //           },
    //           Review: {
    //             type: "Volume",
    //             tiers: [
    //               {
    //                 min: 1,
    //                 max: 50,
    //                 price: "650",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 51,
    //                 max: 250,
    //                 price: "3",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 251,
    //                 max: 500,
    //                 price: "35",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 501,
    //                 max: 1000,
    //                 price: "2",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 1001,
    //                 max: "∞",
    //                 price: "1",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //             ],

    //             unit: "Document",
    //           },
    //           Hosting: {
    //             type: "Volume",
    //             tiers: [
    //               {
    //                 min: 1,
    //                 max: 50,
    //                 price: "65",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 51,
    //                 max: 250,
    //                 price: "48",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 251,
    //                 max: 500,
    //                 price: "35",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 501,
    //                 max: 1000,
    //                 price: "25",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //               {
    //                 min: 1001,
    //                 max: "∞",
    //                 price: "12",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //             ],

    //             unit: "GB",
    //           },
    //           "PDF Review": {
    //             type: "Volume",
    //             unit: "Document",
    //             tiers: [
    //               {
    //                 min: "1",
    //                 max: "∞",
    //                 price: "0.83",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //             ],
    //           },
    //           "Excel Review": {
    //             type: "Volume",
    //             unit: "Hour",
    //             tiers: [
    //               {
    //                 min: "1",
    //                 max: "∞",
    //                 price: "2",
    //                 currency: "USD",
    //                 price_type: "unit_pricing",
    //               },
    //             ],
    //           },
    //         },
    //       },
    //     },
    //   },
    // ];
    let total = 0;
    let allBids = [];

    bids.forEach((bid) => {
      let allServices = [];
      let bidObject = {
        [bid.submittedByCompany]: {},
      };
      Object.keys(bid.services).forEach((service) => {
        let serviceTotalObject = {
          [service]: {},
        };
        let serviceTotal = 0;
        if (
          bid.services[service]?.pricing &&
          typeof bid.services[service]?.pricing === "object"
        ) {
          Object.keys(bid.services[service]?.pricing).forEach((lineItem) => {
            let lineItemTotal = 0;

            let item = bid.services[service].pricing[lineItem];
            console.log("calc lineItem", item);

            let calType = this.getCalType(item);
            let unit = this.getUnit(item);
            let recurring = this.getRecurring(item);
            let billingInterval = this.getBillingInterval(item);
            //   lineItemTotal["name"] = lineItem;
            console.log(
              "calc items",
              calType,
              unit,
              recurring,
              billingInterval
            );
            switch (calType) {
              case "standard":
                console.log("calc standard");

                lineItemTotal = this.standardCalculator(
                  item,
                  assumptions,
                  lineItem
                );

                break;
              case "graduated":
                console.log("calc graduated");
                lineItemTotal = 0;
                lineItemTotal = this.graduatedCalculator(
                  item,
                  assumptions,
                  lineItem
                );
                break;
              case "volume":
                lineItemTotal = 0;
                console.log("calc volume");
                //   lineItemTotal = 0;
                lineItemTotal = this.volumeCalculator(
                  item,
                  assumptions,
                  lineItem
                );
                break;
              default:
                break;
            }
            //push the line item total object to the service total object
            serviceTotalObject[service][lineItem] = lineItemTotal;
          });
        }
        allServices.push(serviceTotalObject);
      });
      //push the service total object to the bid object
      bidObject[bid.submittedByCompany] = allServices;
      allBids.push(bidObject);
      console.log("calc bidObject", bidObject);
    });

    return allBids;
  },
  getCalType(lineItem) {
    let calType = "standard";
    if (lineItem.type == "Volume") {
      calType = "volume";
    } else if (lineItem.type == "Graduated") {
      calType = "graduated";
    }
    return calType;
  },
  getUnit(lineItem) {
    console.log("calc getUnit", lineItem);
    return lineItem.unit;
  },
  getRecurring(lineItem) {
    return lineItem.recurrence;
  },
  getBillingInterval(lineItem) {
    return lineItem.billing_interval;
  },
  getAssumptionByUnit(unit, assumptions, itemName, callingFunction) {
    console.log("calc getAssumptionByUnit", itemName, callingFunction);
    if (unit == "GB") {
      //if the unit is GB we need to consider whether the line item is Hosting or Processing in case there is an expansion rate applied.
      return assumptions.data_volume || 0;
    } else if (unit == "Document") {
      let returnAssumption = 0;
      if (itemName === undefined || itemName === null) {
        return assumptions.number_of_documents || 0;
      }
      if (itemName == "PDF Review") {
        console.log("calc getAssumptionByUnit PDF Review", itemName);
        returnAssumption = assumptions.special_pdf_total;
      } else if (itemName == "Excel Review") {
        console.log("calc getAssumptionByUnit Excel Review", itemName);
        returnAssumption = assumptions.special_spreadsheets_total;
      } else {
        returnAssumption = assumptions.number_of_documents;
      }
      return returnAssumption || 0;
    } else if (unit == "Hour") {
      let returnAssumption = 0;
      if (itemName == "PDF Review") {
        console.log("calc getAssumptionByUnit PDF Review", itemName);
        returnAssumption = assumptions.special_pdf_total;
      } else if (itemName == "Excel Review") {
        console.log("calc getAssumptionByUnit Excel Review", itemName);
        returnAssumption = assumptions.special_spreadsheets_total;
      } else {
        returnAssumption = assumptions.project_management;
      }
      return returnAssumption || 0;
    } else if (unit == "Month") {
      console.log("calc getAssumptionByUnit Month", itemName, assumptions);
      return assumptions?.project_duration || 0;
    } else {
      return 0;
    }
  },
  standardCalculator(lineItem, assumptions, itemName) {
    // we need to set a price variable to zero
    // if unit is anything other than fixed price, then we need to multiply lineItem.price by the assumption value and add it to the price variable
    // if unit is fixed price, then we need to use the lineItem.price as the price variable
    // if recurrence exists and the value is "Recurring" we need to get the billing interval. if billing interval is month, then we need to multiply the price by the number of months in the project duration
    // if recurrence exists and the value is "One Time" OR if recurrence does not exist, we need to use the price as the price variable
    console.log("calc standard lineItem", lineItem);
    let price = lineItem.price;
    console.log("calc lineItem price before parse", price);
    //convert to number
    price = parseFloat(price);

    let unit = this.getUnit(lineItem);
    let recurring = this.getRecurring(lineItem);
    let billingInterval = this.getBillingInterval(lineItem);

    if (unit != "Fixed Price") {
      price =
        lineItem.price *
        parseFloat(
          this.getAssumptionByUnit(
            unit,
            assumptions,
            itemName,
            "standardCalculator"
          )
        );
    } else {
      price = price;
    }
    console.log("calc standard price before recurring", price);

    if (recurring == "Recurring") {
      if (billingInterval == "Month") {
        price =
          price *
          this.getAssumptionByUnit(
            billingInterval,
            assumptions,
            itemName,
            "standardCalculator-billingInterval"
          );
      }
    } else {
      price = price;
    }
    console.log("calc standard price", price);
    return price;
  },
  graduatedCalculator(lineItem, assumptions, itemName) {
    console.log("graduatedCalculator itemName, lineItem", itemName, lineItem);
    let price = 0;
    let unit = this.getUnit(lineItem);
    let recurring = this.getRecurring(lineItem);
    let billingInterval = this.getBillingInterval(lineItem);
    let assumptionValue = parseFloat(
      this.getAssumptionByUnit(unit, assumptions, itemName)
    );
    console.log("graduatedCalculator volume assumptionValue", assumptionValue);
    let tiers = lineItem.tiers;
    console.log("graduatedCalculator volume tiers", tiers, itemName);
    // try {
    let total = 0;
    for (let i = 0; i < tiers.length; i++) {
      const tier = tiers[i];
      if (assumptionValue > tier.min) {
        const max =
          tier.max === "∞"
            ? assumptionValue
            : Math.min(assumptionValue, tier.max);
        const unitsInTier = max - tier.min;
        total +=
          tier.price_type === "unit_pricing"
            ? unitsInTier * tier.price
            : tier.price;
      }
    }
    console.log("Graduated pricing total", total);

    console.log("graduatedCalculator volume price before recurring", price);
    if (recurring == "Recurring") {
      if (billingInterval == "Month") {
        total = total * this.getAssumptionByUnit(billingInterval);
      }
    } else {
      total = total;
    }
    console.log("graduatedCalculator returning volume price", total);
    return total;
  },
  volumeCalculator(lineItem, assumptions, itemName) {
    console.log("volumeCalculator itemName, lineItem", itemName, lineItem);
    let price = 0;
    let unit = this.getUnit(lineItem);
    let recurring = this.getRecurring(lineItem);
    let billingInterval = this.getBillingInterval(lineItem);
    let assumptionValue = parseFloat(
      this.getAssumptionByUnit(unit, assumptions, itemName)
    );
    console.log("volumeCalculator volume assumptionValue", assumptionValue);
    let tiers = lineItem.tiers;
    console.log("volumeCalculator volume tiers", tiers, itemName);
    // try {
    tiers.forEach((tier) => {
      if (tier.max == "∞") {
        tier.max = Infinity;
      }
      if (assumptionValue >= tier.min && assumptionValue <= tier.max) {
        console.log("volumeCalculator volume tier", tier);
        price = tier.price;
        // if the tier.price_type is unit_pricing, then we need to multiply the price by the assumption value
        if (tier.price_type == "unit_pricing") {
          price = price * assumptionValue;
        }
      }
    });
    // } catch (e) {
    //   console.log("volumeCalculator Error in", itemName, lineItem, e);
    // }

    console.log("volumeCalculator volume price before recurring", price);
    if (recurring == "Recurring") {
      if (billingInterval == "Month") {
        price = price * this.getAssumptionByUnit(billingInterval);
      }
    } else {
      price = price;
    }
    console.log("volumeCalculator returning volume price", price);
    return price;
  },
};

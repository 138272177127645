import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FormPage from "../pages/component/FormPage";
import { InviteUser } from "./FormPageInviteUser";

function UserAddPanel(props) {
  const history = useHistory();
  const profile = props.profile;

  return (
    <div
      id="settings"
      className="px-4  sm:px-6 lg:px-8 py-8 h-full w-full max-w-9xl mx-auto"
    >
      <div id="user-add-panel" className="h-full">
        <div className="grid grid-cols-2 divide-x px-3 h-full">
          <InviteUser
            profile={profile}
            type={props.type}
            inviteType={props.inviteType}
          />

          {/* <FormPage type={props.type} inviteType={props.inviteType} /> */}
        </div>
      </div>
    </div>
  );
}

export default UserAddPanel;

import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const Solicitations = {
  profile() {
    if (
      _auth.getUserProfile() === null ||
      _auth.getUserProfile() === undefined
    ) {
      return JSON.parse(localStorage.getItem("guestProfile"));
    } else {
      return _auth.getUserProfile();
    }
  },
  BuildWorkflow(oldStatus, newStatus) {},
  getCurrentWorkflow(data) {
    if (!data) return;
    let current = data.find((item) => {
      return item.status === "current";
    });
    console.log("Current -->", current);
    return current;
  },
  async getByInviteId(data) {
    console.log("Get solicitationId get-by-invite-id API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("API body", data);
    try {
      let response = await postRequest("solicitations/get-by-invite-id", data);
      if (response) {
        console.log(
          "Get solicitationId by inviteid got response",
          response.data.data
        );
        return response.data.data;
      } else {
        return response;
      }
    } catch (error) {
      console.log("solicitations/get-by-invite-id error", error);
      return error;
    }
  },
  async getById(data) {
    console.log("Call solicitation get-by-id API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.tenantType = profile.tenantType;
    data.tenantId = profile.tenantId;
    data.companyId = profile.tenantCompanyId;
    console.log("API body", data);
    let response = await postRequest("solicitations/get-by-id", data);
    if (response) {
      console.log("solicitations/get-by-id got response", response.data.data);
      return response.data.data;
    } else {
      return response;
    }
  },
  async getByTenantId() {
    let data = {};
    console.log("Call solicitation get-by-tenantId API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("API body", data);
    let response = await postRequest("solicitations/get-by-tenant-id", data);
    if (response && response.data && response.data.data) {
      console.log(
        "solicitations/solicitations/get-by-tenant-id got response",
        response.data.data
      );
      return response.data.data;
    } else {
      return response;
    }
  },

  async getByProjectId(data) {
    console.log("Call solicitation get-by-id API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    if (!token) {
      token = profile.token;
    }
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.tenantType = profile.tenantType;

    data.tenantId = profile.tenantId || data.tenantId;
    data.companyId = profile.companyId;
    console.log("API body", data);
    let response = await postRequest("solicitations/get-by-project-id", data);
    if (response && response.data && response.data.data) {
      console.log(
        "solicitations/solicitations/get-by-project-id got response",
        response.data.data
      );
      return response.data.data;
    } else {
      return response;
    }
  },

  async create(data) {
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.createdBy = profile.user_id;
    data.tenantId = profile.tenantId;
    data.companyId = profile?.tenantCompanyId && profile.tenantCompanyId;
    data.workflow = "Accepting Bids";
    data.status = "Active";

    console.log("Solicitation create Call API and pass data", data);
    let response = await postRequest("solicitations/create", data);
    if (response) {
      console.log("Create solicitation got response", response);
      return response.data.data;
    } else {
      return response;
    }
  },
  async update(data) {
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.createdBy = profile.user_id;
    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("Call API and pass data update", data);
    let response = await postRequest("solicitations/update", data);
    if (response) {
      console.log("Update solicitation got response", response);
      return response.data.data;
    } else {
      return response;
    }
  },
  async updateWorkflowStatus(data) {
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.createdBy = profile.user_id;
    data.tenantId = profile.tenantId;
    data.companyId = profile.tenantCompanyId;
    console.log("Call API and pass data update", data);
    //   {
    //     "_id": "65bfe8511e6997e5f1a97c4b",
    //     "status": "Open",
    //     "newWorkflow": "Closed",
    //     "oldWorkflow": "Bidding Closed",
    //     "createdBy": "65bfe6e61e6997e5f1a97bb3",
    //     "tenantId": "65bfe6e61e6997e5f1a97bb5"
    // }
    let response = await postRequest(
      "solicitations/update-workflow-status",
      data
    );
    if (response) {
      console.log("Update solicitation got response", response);
      return response.data.data;
    } else {
      return response;
    }
  },
  async openAndCloseBidding(data) {
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.createdBy = profile.user_id;
    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("Call API and pass data update", data);
    let response = await postRequest(
      "solicitations/close-and-reopen-bidding",
      data
    );
    if (response) {
      console.log("solicitations/close-and-reopen-bidding", response);
      return response.data.data.data;
    } else {
      return response;
    }
  },
  async delete(solicitationId) {
    //solicitation/delete-by-id
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let data = {};
    data.solicitationId = solicitationId;
    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("Call API and pass data update", data);
    let response = await postRequest("solicitation/delete-by-id", data);
    if (response) {
      console.log("Update solicitation got response", response);
      return response.data.data;
    } else {
      return response;
    }
  },
  async createFromTemplate(data) {
    console.log("Call API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    //temprory
    data.solicitationId = "644ee9bf1fa815739596a6fb";
    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("API body", JSON.stringify(data));

    let response = await postRequest(
      "solicitations/create-from-template",
      data
    );
    if (response) {
      console.log("solicitations/create-from-template", response);
      return response.data.data;
    } else {
      return response;
    }
  },
  async createFromDuplicate(data) {
    console.log("Call API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    //temprory
    data.solicitationId = "644ee9bf1fa815739596a6fb";
    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    console.log("API body", JSON.stringify(data));

    let response = await postRequest(
      "solicitations/create-from-duplicate",
      data
    );
    if (response) {
      console.log("solicitations/create-from-duplicate", response);
      return response.data.data;
    } else {
      return response;
    }
  },
  async list(scope) {
    let data = {};
    console.log("Call API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.tenantCompanyId;
    data.scope = scope;
    console.log("API body - list projects", data);
    let response = await postRequest("solicitations/get-all", data);
    if (response) {
      console.log("Get solicitations got response", response);
      return response.data.data;
    } else {
      return response;
    }
  },
  async getBoardData(scope) {
    let data = {};
    console.log("Call getBoardData API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.tenantCompanyId;
    data.scope = scope;
    console.log("API body - getBoardData", data);
    let response = await postRequest("solicitations/get-board-data", data);
    if (response) {
      console.log("solicitations/get-board-data got response", response);
      return response.data.data;
    } else {
      return response;
    }
  },
  async saveAsTemplate(data) {
    console.log("saveAsTemplate Call API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;
    let body = { update: data };
    console.log("SaveAsTemplate API body", body);
    let response = await postRequest(
      "solicitations/templates/save-as-template",
      body
    );
    if (response) {
      console.log(
        "solicitations/templates/save-as-template response",
        response
      );
      return response.data;
    } else {
      return response;
    }
  },
  async selectWinner(data) {
    console.log("SendMessage Call API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;

    console.log("SelectWinner API body", data);
    let response = await postRequest("bids/select-winner", data);
    if (response) {
      console.log("/bids/select-winner response", response);
      return response.data.data.data;
    } else {
      return false;
    }
  },
  async notifyWinner(data) {
    console.log("SendMessage Call API and pass data", data);
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

    data.tenantId = profile.tenantId;
    data.companyId = profile.tenantCompanyId;

    console.log("SelectWinner API body", data);
    let response = await postRequest("bids/notify-winner", data);
    if (response) {
      console.log("/bids/notify-winner response", response);
      return response.data.data.data;
    } else {
      return false;
    }
  },
  async closeBidRequestById(data) {
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    data.tenantId = profile.tenantId;
    data.companyId = profile.companyId;

    let response = await postRequest("solicitation/close-bid-by-id", data);
    if (response) {
      return response;
    } else {
      return response;
    }
  },
};

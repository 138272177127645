import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Notification from "./Notification";
import { _auth } from "../modules/_auth";
import { contactus } from "../modules/_contact_us";
const opts = [
  // {
  //   name: "Call me",
  //   type: "phone",
  // },
  {
    name: "Email me",
    type: "email",
  },
  // {
  //   name: "No Thanks",
  //   description: "I'll figure it out",
  // },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function OptionButtons(props) {
  const data = _auth.getUserProfile();
  const [selected, setSelected] = useState(opts[0]);

  const history = useHistory();
  const confirm = (path) => {
    console.info("CONFIRMING", selected);
    if (selected === "no_thanks" || path === "no_thanks") {
      return history.push({
        pathname: props.to ? props.to : "/home",
      });
    } else {
      props.setConfirmed(true);
      // return history.push({
      //   pathname: props.to ? props.to : "/confirm",
      // });
    }
  };

  useEffect(() => {
    if (data.phone === "") {
      console.log(data.phone, "phone");
      // opts.shift()
    }
  }, [data]);

  const handleClick = async (path) => {
    console.log(path, "path");
    if (path !== "no_thanks") {
      if (
        String(path)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        const result = await contactus.contact_email(history);
      }
    } else {
      confirm(path);
    }

    // return history.push({
    //   pathname: props.to ? props.to : "/home",
    // });
  };

  return (
    <div>
      <RadioGroup value={selected} onChange={(e) => handleClick(e)}>
        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
        <div className="space-y-4">
          {data.phone.length > 0 && (
            <RadioGroup.Option
              key={"no_thanks"}
              value={"no_thanks"}
              className={({ checked, active }) =>
                classNames(
                  checked ? "border-transparent" : "border-gray-300",
                  active ? "border-primary-500 ring-2 ring-primary-500" : "",
                  "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label
                        as="p"
                        className="font-medium text-slate-900"
                      >
                        No thanks
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="div"
                        className="text-slate-500"
                      >
                        <p className="sm:inline">I'll figure it out.</p>{" "}
                      </RadioGroup.Description>
                    </div>
                  </div>

                  <div
                    className={classNames(
                      active ? "border" : "border-2",
                      checked ? "border-primary-500" : "border-transparent",
                      "absolute -inset-px rounded-lg pointer-events-none"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          )}
          {opts.map((opt) => (
            <RadioGroup.Option
              key={opt.type}
              value={opt.type === "phone" ? data.phone : data.email}
              className={({ checked, active }) =>
                classNames(
                  checked ? "border-transparent" : "border-gray-300",
                  active ? "border-primary-500 ring-2 ring-primary-500" : "",
                  "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label
                        as="p"
                        className="font-medium text-slate-900"
                      >
                        {opt.name}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="div"
                        className="text-slate-500"
                      >
                        <p className="sm:inline">
                          {opt.type == "phone" ? data.phone : data.email}
                        </p>{" "}
                      </RadioGroup.Description>
                    </div>
                  </div>

                  <div
                    className={classNames(
                      active ? "border" : "border-2",
                      checked ? "border-primary-500" : "border-transparent",
                      "absolute -inset-px rounded-lg pointer-events-none"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
          <RadioGroup.Option
            key={"no_thanks"}
            value={"no_thanks"}
            className={({ checked, active }) =>
              classNames(
                checked ? "border-transparent" : "border-gray-300",
                active ? "border-primary-500 ring-2 ring-primary-500" : "",
                "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <div className="flex items-center">
                  <div className="text-sm">
                    <RadioGroup.Label
                      as="p"
                      className="font-medium text-slate-900"
                    >
                      No thanks
                    </RadioGroup.Label>
                    <RadioGroup.Description as="div" className="text-slate-500">
                      <p className="sm:inline">I'll figure it out.</p>{" "}
                    </RadioGroup.Description>
                  </div>
                </div>

                <div
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-primary-500" : "border-transparent",
                    "absolute -inset-px rounded-lg pointer-events-none"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        </div>
      </RadioGroup>
      <div>
        <button
          onClick={confirm}
          className="mt-5 float-right btn w-35 bg-primary-500 hover:bg-primary-600 text-white"
        >
          Continue
        </button>
      </div>
    </div>
  );
  // return (
  //   <div className="px-5 pt-4 pb-1">
  //     <div className="text-sm">
  //       <div className="mb-4">Select a contact option:</div>
  //       {/* Options */}
  //       <ul className="space-y-2 mb-4">
  //         <li>
  //           <button className="w-full h-full text-left py-3 px-4 rounded bg-white border-2 border-primary-400 shadow-sm duration-150 ease-in-out">
  //             <div className="flex items-center">
  //               <div className="w-4 h-4 border-4 border-primary-500 rounded-full mr-3"></div>
  //               <div className="grow">
  //                 <div className="flex flex-wrap items-center justify-between mb-0.5">
  //                   <span className="font-medium text-slate-800">
  //                     Call Me{" "}
  //                     {/* <span className="text-xs italic text-slate-500 align-top">
  //                               Current opt
  //                             </span> */}
  //                   </span>
  //                   {/* <span>
  //                             <span className="font-medium text-green-600">
  //                               $39.00
  //                             </span>
  //                             /mo
  //                           </span> */}
  //                 </div>
  //                 <div className="text-sm">202-220-2020</div>
  //               </div>
  //             </div>
  //           </button>
  //         </li>
  //         <li>
  //           <button className="w-full h-full text-left py-3 px-4 rounded bg-white border border-gray-200 hover:border-gray-300 shadow-sm duration-150 ease-in-out">
  //             <div className="flex items-center">
  //               <div className="w-4 h-4 border-2 border-gray-300 rounded-full mr-3"></div>
  //               <div className="grow">
  //                 <div className="flex flex-wrap items-center justify-between mb-0.5">
  //                   <span className="font-semibold text-slate-800">
  //                     Email Me{" "}
  //                   </span>
  //                 </div>
  //                 <div className="text-sm">demo@datasecurityu.com</div>
  //               </div>
  //             </div>
  //           </button>
  //         </li>
  //         <li>
  //           <button
  //             onClick={handleClick}
  //             className="w-full h-full text-left py-3 px-4 rounded bg-white border border-gray-200 hover:border-gray-300 shadow-sm duration-150 ease-in-out"
  //           >
  //             <div className="flex items-center">
  //               <div className="w-4 h-4 border-2 border-gray-300 rounded-full mr-3"></div>
  //               <div className="grow">
  //                 <div className="flex flex-wrap items-center justify-between mb-0.5">
  //                   <span className="font-semibold text-slate-800">
  //                     No Thanks{" "}
  //                   </span>
  //                 </div>
  //                 <div className="text-sm">I'll figure it out on my own.</div>
  //               </div>
  //             </div>
  //           </button>
  //         </li>
  //       </ul>
  //     </div>
  //   </div>
  // );
}

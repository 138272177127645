export function Label({
  htmlFor,
  children,
  className,
  variant,
  xs,
  sm,
  md,
  lg,
  bold,
  normal,
}) {
  return (
    <label
      htmlFor={htmlFor}
      className={`${
        sm ? "text-sm " : md ? "text-md" : lg ? "text-lg" : "text-base"
      }
      ${bold ? "font-semibold" : normal ? "font-normal" : "font-semibold"} 
      ${variant == "dark" ? "text-slate-300" : "text-slate-700"}
      block ${className}
      `}
    >
      {children}
    </label>
  );
}

/*
Copyright 2020 Adobe
All Rights Reserved.
NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

import React, { Component } from "react";
import ViewSDKClient from "./pdfView";

class InLine extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      /* Invoke file preview */
      viewSDKClient.previewFile(
        "pdf-div",
        {
          /* Pass the embed mode option here */
          embedMode: "",

          showDownloadPDF: true,
          showPrintPDF: false,
          enableFormFilling: false,
          showAnnotationTools: false,

          showLeftHandPanel: true,

          showPageControls: true,
          dockPageControls: true,
          defaultViewMode:
            "FIT_PAGE" /* Allowed possible values are "FIT_PAGE", "FIT_WIDTH" or "". */,
        },
        this.props.file,
        this.props.filename,
        this.props.url
      );
    });
  }

  render() {
    return <div id="pdf-div" className="sized-container-div" />;
  }
}

export default InLine;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../images/BreachConnectLogoBlue.png";

import SearchModal from "../components/ModalSearch";
import Notifications from "../components/DropdownNotifications";
import Help from "../components/DropdownHelp";
import UserMenu from "../components/DropdownProfile";
import { _auth } from "../modules/_auth";
import { Lifebouy } from "@heroicons/react/24/outline";
import HeaderLinks from "../partials/header/HeaderLinks";
import { features } from "../modules/_features";
import { FeatureFlag } from "../components/FeatureFlag";
import Button from "../elements/Button";
import ModalBasic from "../components/ModalBasic";
import ContactConfirm from "../components/ContactConfirm";
import { _navigation } from "../modules/_navigation";
import { _stripe } from "../modules/_stripe";
import DropdownSwitch from "../components/DropdownSwitch";
import DataEntryDialog from "../components/DataEntryDialog";
import { _user } from "../modules/_user";
import { _teams } from "../modules/_teams";
import { Icon } from "@iconify/react";

function Header({ sidebarOpen, setSidebarOpen, navigation }, props) {
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const history = useHistory();

  const [profile, setProfile] = useState(_auth.getUserProfile());
  const productID = 323; //should get from Stripe
  const [showModal, setShowModal] = useState(false);
  // const [navigation, setNavigation] = useState(props.navigation);
  const [showHome, setShowHome] = useState(true);
  const [navLinks, setNavLinks] = useState();
  const [stripeLink, setStripeLink] = useState();
  const [createTeamOpen, setCreateTeamOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(true);
  const [companyTenants, setCompanyTenants] = useState([]);
  const [tenants1, setTenants] = useState([]);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [teams, setTeams] = useState([]);

  // useEffect(() => {
  //   (async () => {
  //     setLoader(true);
  //     let path = "all";
  //     let data = {
  //       companyId: profile.companyId,
  //     };
  //     if (profile.role == "Administrator") {
  //       let tenants = await _auth.getTentants(path, data);
  //       console.info("GET TENANTS", tenants);
  //       if (tenants) {
  //         tenants.sort((a, b) =>
  //           a.tenantId.tenantTitle > b.tenantId.tenantTitle ? 1 : -1
  //         );
  //         setCompanyTenants(tenants);
  //         console.log("setCompanyTenants", tenants);
  //         let showTenants =
  //           profile.role == "Administrator" ? tenants : profile.tenants;
  //         setTenants(showTenants);
  //         setLoader(false);
  //       } else {
  //         let showTenants = profile.tenants;
  //         setTenants(showTenants);
  //         setLoader(false);
  //       }
  //     } else {
  //       let showTenants = profile.tenants;
  //       setTenants(showTenants);
  //       setLoader(false);
  //     }
  //   })();
  // }, [profile]);

  // JDO Verson
  // useEffect(() => {
  //   (async () => {
  //     setLoader(true);
  //     let path = "all";
  //     let data = {
  //       companyId: profile.companyId,
  //     };
  //     if (profile.role == "Administrator" || profile.role == "Observer") {
  //       let tenants = await _auth.getTentants(path, data);
  //       console.info("GET TENANTS", profile.role, tenants);
  //       if (tenants) {
  //         tenants.sort((a, b) =>
  //           a.tenantId.tenantTitle > b.tenantId.tenantTitle ? 1 : -1
  //         );
  //         setCompanyTenants(tenants);
  //         console.log("GET TENANTS setCompanyTenants", tenants);
  //         let showTenants = tenants;

  //         setTenants(showTenants);
  //         setLoader(false);
  //       } else {
  //         console.info("GET TENANTS Profile 67", profile.role, profile.tenants);
  //         let showTenants = profile.tenants;
  //         setTenants(showTenants);
  //         setLoader(false);
  //       }
  //     } else {
  //       console.info("GET TENANTS Profile 73", profile.role, profile.tenants);
  //       let showTenants = profile.tenants;
  //       setTenants(showTenants);
  //       setLoader(false);
  //     }
  //   })();
  // }, [profile]);

  //New version
  useEffect(() => {
    (async () => {
      setLoader(true);
      let tenants = await _auth.getTentants2();
      if (tenants) {
        console.log("Got tenants", tenants);
        setTenants(tenants);
      }
      // setTenants(tenants)
    })();
  }, [profile]);

  // useEffect(() => {
  //   (async () => {
  //     console.log("CREATE teams BEFORE");
  //     const teams = await _teams.getTeams();
  //     console.log("CREATE teams", teams);
  //     if (teams) {
  //       setTeams(teams);
  //     }
  //   })();
  // }, [profile]);

  useEffect(() => {
    setNavLinks(navigation);
    setShowHome(props.showHome);
    console.log("navigation in Header", navigation);
  }, []);

  useEffect(() => {
    setProfile(_auth.getUserProfile());
  }, []);

  useEffect(() => {
    console.log("header profile", profile);
  }, [profile]);

  const handleOpenSidebarClick = (e, value) => {
    e.preventDefault();
    setSidebarOpen(e, value);
  };

  const handleSetCreateTeamOpen = (value) => {
    console.log("DataEntryDialog handleSetCreateTeamOpen");
    setCreateTeamOpen(value);
  };

  const handleOnSubmit = async (data) => {
    console.log("DataEntryDialog handleOnSubmit", data);

    let newTenant = await _user.createTenant(data);
    if (newTenant) {
      console.log("newTenant", newTenant);
      let tenantInfo = {
        _id: newTenant._id,
        tenantTitle: newTenant.tenantTitle,
        type: newTenant.type,
      };
      newTenant.tenantId = tenantInfo;

      let toUpdate = _auth.getUserProfile();
      toUpdate.tenants.push(newTenant);

      console.log("newTenant toUpdate", toUpdate);
      _auth.setUserProfile(toUpdate);
      setProfile(toUpdate);
      return true;
    }
  };

  const handleTenantSwitch = async (newTenant) => {
    //get tenant info from tenants list
    let tenant = tenants1.find((item) => {
      return item.tenantId._id === newTenant.id;
    });

    let tenantInfo = tenant.tenantId;
    console.log("tenantInfo", tenantInfo);
    // let newCompany = await _auth.getCompanyById(newTenant.id);
    let toUpdate = _auth.getUserProfile();
    toUpdate.tenantId = newTenant.id;
    toUpdate.tenantName = newTenant.name;
    toUpdate.tenantInfo = tenantInfo;

    toUpdate.company = tenantInfo.tenantCompany;
    toUpdate.companyId = tenantInfo.tenantCompanyID;
    toUpdate.companyName = tenantInfo.tenantCompany;
    toUpdate.tenantCompany = tenantInfo.tenantCompany;
    toUpdate.tenantCompanyId = tenantInfo.tenantCompanyID;
    toUpdate.tenantCompanyDomain = tenantInfo.domain;
    toUpdate.tenantType = newTenant.type;

    // toUpdate = _auth.buildProfile(toUpdate);
    _auth.setUserProfile(toUpdate);
    setProfile(toUpdate);
    console.log("toUpdate", toUpdate);
    debugger;
    history.push("/home");
    window.location.reload();

    console.log("switchedTenant", newTenant);
    console.log("newTenant toUpdate", toUpdate);
  };

  const handleSettingsClick = (e) => {
    e.preventDefault();
    history.push("/settings/account");
  };
  const handleSetShowModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(!showModal);
  };
  const getStripeLink = async (e) => {
    e.preventDefault();
    const stripeLink = await _stripe.stripePortalSession();
    return window.open(stripeLink, "_blank");
  };

  return (
    <header className="sticky top-0 z-20 bg-white border-b border-gray-200  ">
      <div className="px-2">
        <div className="flex justify-between items-center h-12 -mb-px">
          {/* Header: Left side */}
          <div className="flex">
            {/* Hamburger button */}
            <button
              className="text-slate-500 hover:text-slate-600  xl:hidden mr-3"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={(e) => handleOpenSidebarClick(e, !sidebarOpen)}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
            {/* <div className="flex items-center m-auto lg:inline xl:inline 2xl:inline 3xl:inline 4xl:inline "> */}
            <img
              src={Logo}
              alt="Logo"
              className="mr-2 my-auto  inline-flex h-6 hidden sm:hidden md:hidden lg:inline xl:inline 2xl:inline 3xl:inline 4xl:inline "
              // style={{ height: "60px" }}
            />
            <span className="hidden sm:inline my-auto  text-xl font-semibold mr-2">
              Breachlink
            </span>
            {/* </div> */}

            <div className="hidden sm:hidden md:hidden lg:hidden xl:inline 2xl:inline 3xl:inline 4xl:inline ">
              <HeaderLinks
                showHome={showHome}
                align="left"
                navigation={navigation}
              />
            </div>
          </div>

          {/* Header: Right side */}
          <div className="flex items-center space-x-1">
            {/* <button className="btn flex items-center justify-center  bg-lime-500 hover:bg-lime-600 text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
                />
              </svg>
              <span className="hidden xs:block ml-2">Marketplace</span>
            </button> */}
            {/* <button
              className={`w-8 h-8 flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition duration-150 rounded-full ml-3 ${
                searchModalOpen && "bg-gray-200"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setSearchModalOpen(true);
              }}
              aria-controls="search-modal"
            >
              <span className="sr-only">Search</span>
              <svg
                className="w-4 h-4"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current text-slate-500"
                  d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                />
                <path
                  className="fill-current text-slate-400"
                  d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                />
              </svg>
            </button> */}

            {/* <SearchModal id="search-modal" searchId="search" modalOpen={searchModalOpen} setModalOpen={setSearchModalOpen} /> */}
            {/* <Notifications align="right" /> */}
            {/* <Help align="right" /> */}

            {/*  Divider */}
            {/* <FeatureFlag featureName="Upgrade" type="flag">
              <form className="inline-flex" target="_blank" action={stripeLink}>
                <Button
                  handleClick={(e) => {
                    getStripeLink(e);
                  }}
                  size="xs"
                  color="orange"
                  rounded
               
                >
                  Upgrade
                </Button>
              </form>
            </FeatureFlag> */}
            <FeatureFlag type="auth" featureName="Switch Tenants">
              <div className=" flex items-center text-sm font-md align-middle m-auto text-sm font-bold ">
                <DropdownSwitch
                  profile={profile}
                  align="right"
                  tenantsToShow={
                    tenants1
                    // profile.role === "Administrator" ||
                    // profile.role === "Observer"
                    //   ? tenants1
                    //   : profile.tenants
                  }
                  handleTenantSwitch={(tenant) => handleTenantSwitch(tenant)}
                  setCreateTeamOpen={() => handleSetCreateTeamOpen(true)}
                />
              </div>
            </FeatureFlag>

            <hr className="w-px h-6 bg-gray-200 mx-3" />
            <Button
              iconButton
              audit={false}
              // action="Open Settings"
              handleClick={(e) => {
                e.preventDefault();
                handleSetShowModal(e);
              }}
            >
              <Icon
                icon="material-symbols:contact-support-rounded"
                className="w-5 h-5"
              />
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg> */}
            </Button>
            {/* {profile && profile.role === "Admin" && ( */}
            <FeatureFlag
              type="auth"
              featureName={"Settings"}
              role={profile && profile.role && profile.role}
            >
              <Button
                iconButton
                audit={true}
                action="Open Settings"
                handleClick={(e) => {
                  e.preventDefault();
                  handleSettingsClick(e);
                }}
              >
                {/* <span className="text-slate-400 hover:text-slate-500"> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
                    clip-rule="evenodd"
                  />
                </svg>
                {/* </span> */}
              </Button>
            </FeatureFlag>

            <UserMenu align="right" />
          </div>
        </div>
      </div>
      <ModalBasic
        id="support_modal"
        modalOpen={showModal}
        setModalOpen={setShowModal}
        title={"Contact Breachlink"}
        showClose={false}
      >
        <ContactConfirm
          setModalOpen={setShowModal}
          company={"Breachlink"}
          email={profile !== null && _auth.getUserProfile().email}
          vendor_email={"vendor_email"}
          type="support"
        />
      </ModalBasic>
      <DataEntryDialog
        handleOnSubmit={(data) => handleOnSubmit(data)}
        setOpen={(value) => handleSetCreateTeamOpen(value)}
        open={createTeamOpen}
        success={success}
        dialogLoading={dialogLoading}
        data={teams}
      />
    </header>
  );
}

export default Header;

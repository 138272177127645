import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ActionPlanExplanation from "../components/ActionPlanExplanation";
import ContentSplit from "../components/ContentSplit";
import { _assessment } from "../modules/_assessment";
import Button from "../elements/Button";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
// import { _assessment } from "../modules/_assessment";

function ActionPlan(props) {
  const printRef = useRef();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  // const counts = location.state.data;
  const thisAssessment = location.state.thisAssessment;
  const counts = thisAssessment.counts;
  console.info("My State", location.state.test);

  console.info("ASMT My Assessment", location.state.thisAssessment);

  const handleDownloadPdf = async () => {
    setLoading(true);
    // try {
    // debugger;
    const element = printRef.current;
    // debugger;
    const canvas = await html2canvas(element);
    // debugger;
    const imgWidth = 208;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;
    heightLeft -= pageHeight;
    const doc = new jsPDF("p", "mm");
    doc.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight, "", "FAST");
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight, "", "FAST");
      heightLeft -= pageHeight;
    }
    setLoading(false);
    doc.save("Action Plan Download.pdf");
  };
  //  catch (e) {
  //   throw e;
  // }
  // };
  // const data = _assessment.getAssessmentQuestions1("PCI");
  const data = thisAssessment;
  const answers = props.answers;
  const risk = thisAssessment.risk_score;

  const handleClickBack = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/assessments",
      state: { thisAssessment: thisAssessment },
    });
  };

  return (
    <div ref={printRef} className="px-4 sm:px-6 lg:px-8 py-8 w-full  ">
      <div className="max-w-5xl mx-auto  content-center  ">
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          {/* Welcome banner */}
          <div className="mb-0 sm:mb-0 inline-flex ">
            <Button color="light-purple" rounded handleClick={handleClickBack}>
              <svg
                className="h-6 w-6 text-white-500 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                ></path>
              </svg>
              Assessments
            </Button>

            {/* <Link to="assessments">
              <div className="mt-25 space-y-10">
                <span className=" text-md text-primary-500">
                  &larr; Back to Assessment History
                </span>
              </div>
            </Link> */}
          </div>
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            <Button
              loader={loading}
              audit={true}
              action="Download Action Plan"
              details={{ data: data }}
              color="lime"
              handleClick={handleDownloadPdf}
            >
              {!loading && (
                <ArrowDownTrayIcon
                  className="h-5 w-5 mr-2"
                  aria-hidden="true"
                />
              )}
              Download PDF
            </Button>
          </div>
        </div>

        <div className="mb-10 space-y-6 lg:col-start-1 col-span-3 bg-white shadow ">
          <ContentSplit counts={counts} thisAssessment={thisAssessment} />
          {/* Description list*/}
          {risk != "Normal" && (
            <section aria-labelledby="applicant-information-title">
              <div className="">
                <div className="px-4 py-5 sm:px-6 col-span-2">
                  <h2
                    id="applicant-information-title"
                    className="text-2xl leading-6 font-medium text-slate-900"
                  >
                    Your Customized Action Plan
                  </h2>
                  <p className="mt-5  text-sm text-slate-500">
                    Now that you know your score, it's time to find out what
                    steps can you take to improve it. Check out all of the areas
                    of risk below and be sure to reach out to us if you have any
                    questions.
                  </p>
                  {/* <p className="mt-1  text-sm text-slate-500">
                    Now that you know your score what steps can you take to
                    improve it? Click read more on an area of risk below to get
                    specific advice and assistance.
                  </p> */}
                </div>
                {/* <div className="px-4 py-5 sm:px-6 col-span-2"> */}
                {/* <h2
                    id="applicant-information-title"
                    className="text-lg leading-6 font-medium text-slate-900"
                  >
                    Get Help with All Areas of Risk Right Now
                  </h2> */}
                {/* <p className="mt-1  text-sm text-primary-500">
                    Need help understanding? View our Cheatsheet
                  </p> */}
                {/* </div> */}
                <div className=" px-4 py-5 sm:px-6">
                  {/*//border-t border-gray-200*/}
                  {/* <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative p-10"> */}
                  <ul role="list" className="divide-y divide-gray-200 to mb-10">
                    {data.questions.map(
                      (question, index) =>
                        question.isCorrect !== true && // "yes" &&
                        // question.best_answer.toLowerCase()
                        // &&
                        question.action_plan &&
                        question.action_plan !== "" && (
                          <ActionPlanExplanation
                            key={index + 1}
                            index={index + 1}
                            type={data.assessment_type}
                            action_plan={question.action_plan}
                            action_title={question.action_title}
                            action_icon={question.action_icon}
                            marketplace={question.marketplace}
                          />
                        )
                    )}
                  </ul>
                  {/* </div> */}
                </div>
              </div>
            </section>
          )}
          {/* Comments*/}
        </div>
      </div>
    </div>

    //   {/* <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
    //   {data.map((item, index) => (
    //     <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
    //       <div key={index}>{item.Question}</div>
    //       <div>{""}</div>
    //     </div>
    //   ))}
    // </div> */}
    //   {/* Pagination */}
    // </div>
  );
}

export default ActionPlan;

import React, { createContext, useContext } from "react";
import io from "socket.io-client";

const SocketContext = createContext();

export const useSocket = () => {
  debugger;
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  let socketLink;
  if (process.env.REACT_APP_NODE_ENV === "development") {
    socketLink = "http://localhost:7001";
  } else if (process.env.REACT_APP_NODE_ENV === "production") {
    socketLink = "https://prod-api.breachlink.com";
  } else if (process.env.REACT_APP_NODE_ENV === "beta") {
    socketLink = "https://demo-api.breachlink.com";
  }

  const socket = io(socketLink, { transports: ["websocket", "polling"] }); // Replace with your server URL

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

//export SocketContext;

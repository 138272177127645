import { useState, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "../../../components/Skeleton";
import { Icon } from "@iconify/react";
import QuestionToolTip from "../../../components/QuestionToolTip";
import { UserGroupIcon } from "@heroicons/react/24/solid";
import { utils } from "../../../modules/_utils";
import RightActionArea from "../../../components/ActionAreaRight";
import { TrashIcon } from "@heroicons/react/24/outline";
import Button from "../../../elements/Button";
import ListTableItem from "./ListTableItem";
import Badge from "../../../components/Badge";

export default function ListTable({
  showDelete,
  project,
  size_limit,
  fetch_limit,
  dedupe,
  // data,
  // setSelectedRow,
  setUpdatedId,
  columns,
  profile,
  updatedId,
  updateData,
  competencies,
  loading,
  fromDashboard,
  showFooter,
  toggleValue,
  hasSubComponent,
  onToggleChange,
  // selectedRow,
  handleTextColumnValueChange2,
  handleOnBlur,
  children,
  ...props
}) {
  // const { data } = props;
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(data && data.length); //data.length
  const [hasMore, setHasMore] = useState(true);
  const [dedupeMode, setDedupeMode] = useState(true);
  const [selectedRow, setSelectedRow] = useState({});
  const [loader, setLoader] = useState(loading);

  const infiniteRef = useRef(null);
  let scrollParentRef = useRef(null);
  console.log("ImedimentList:props", profile);
  useEffect(() => {
    console.log("ImpedimentList:useEffect:data-changed", data);
    console.log("ImpedimentList:useEffect:hasMore-changed", hasMore);
    setHasMore(true);
  }, [data]);

  useEffect(() => {
    console.log("ImpedimentList:useEffect:dedupe-changed", dedupe);
    setDedupeMode(dedupe);
  }, [dedupe]);

  // useEffect(() => {
  //   setOffset(data.length);
  //   console.log(
  //     "ImpedimentList:useEffect:offset",
  //     offset,
  //     "fetch_limit",
  //     fetch_limit,
  //     "data",
  //     data
  //   );
  // }, [offset]);

  useEffect(() => {
    setAllData(props.data); //all data
    setData(props.data); //sliced  is props.initialData

    // setData(props.data.slice(0, LIMIT));
    console.log("ImpedimentList:useEffect:data", props.data);
  }, [props.data]);

  // useEffect(() => {
  //   if (props.data.length > allData.length) {
  //     fetchMoreData();
  //   } else {
  //     setData(props.initialData);
  //     setAllData(props.data);
  //   }
  // }, [props.data]);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  const handleItemClick = (e, item) => {
    e.preventDefault();
    // props.onItemClick(project, item);
  };

  const handleLoadMore1 = () => {
    console.log("Getting more data");
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      setData(data.concat(Array.from({ length: 20 })));
      // this.setState({
      //   data: data.concat(Array.from({ length: 20 }))
      // });
    }, 1500);
  };

  const handleLoadMore = () => {
    console.log("Getting more data");
    const newData = allData.slice(0, data.length + 10);
    // const combine = [...data, ...allData.slice(data.length, data.length + 50)];
    setData([...data, ...allData.slice(data.length, data.length + 50)]);
    setHasMore(newData.length < allData.length);
    return newData;
  };

  // const handleLoadMore = () => {
  //   console.log("Getting more data");
  //   setLoader(true);
  //   //take the next 20 from the allData array and add it to the data array
  //   // setInitialLoad(true);
  //   const LIMIT = data.length + fetch_limit;
  //   setOffset(offset + LIMIT);
  //   const d = allData.slice(offset, LIMIT);
  //   const combined = [...data, ...d];

  //   setTimeout(() => {
  //     setData(combined);
  //     setLoader(false);
  //   }, 1500);
  // };

  const combineArrays = (arr1, arr2) => {
    let nextData = arr2.slice(arr1.length, arr1.length + 10);
    return [...arr1, ...nextData];
  };

  const fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setLoader(true);
    setTimeout(() => {
      setData(combineArrays(data, allData));

      setLoader(false);
    }, 1000);
  };

  const handleSelectProject = (project) => {
    setSelectedRow(project);
    props.navigateToProject(project);
  };

  console.log("ImpedimentList:competencies", competencies);

  return (
    <div
      id="list-table-container"
      // when changing dedupeMod, the width of the table changes to accomodate the dedupe column
      // this causes the table to shift left and right.  This transition helps to smooth out the shift
      className={`${
        dedupeMode && "pr-[33vw]"
      } transition-all duration-500 ease-in-out
      overflow-y-auto
      scrollbar-thin scrollbar-thumb-slate-300 h-full
      
    
      `}
    >
      {loader && (
        // toast notication indicates loading
        <div
          className="fixed inset-x-0 inset-y-12 flex data-end justify-center 
        pl-4 py-6  pointer-events-none sm:p-6 sm:data-start sm:justify-end"
        >
          <div
            className="max-w-sm w-full 
           pointer-events-auto overflow-hidden"
          >
            <div className="p-4">
              <div className="flex data-start">
                <div className="flex-shrink-0">
                  {/* <!-- Heroicon name: check-circle --> */}
                  <Skeleton type="spinner" color="indigo" forButton />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">
                    Loading...
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Please wait while we load the data for you.
                  </p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    type="button"
                    className="inline-flex text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">Close</span>
                    {/* <!-- Heroicon name: x --> */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        id="scrollableDiv"
        // style={{ height: "calc(100vh - 16rem)" }}
        className={`bg-white ${
          props.fromModal ? "h-[calc(100vh-29rem)]" : "h-[calc(100vh-16rem)]"
        }  scrollbar-thin scrollbar-thumb-slate-300 
        scrollbar-track-slate-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full overflow-visible`}
      >
        {/* <InfiniteScroll
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={true}
          loader={() => {
            return (
              <div>
                <Skeleton type="spinner" />
              </div>
            );
          }}
          scrollableTarget="scrollableDiv"
        > */}
        <ul role="list" className=" py-2 divide-y divide-gray-200">
          {props.vendor && props.vendor == true
            ? children
            : data &&
              data.length > 0 &&
              data.map((item) => {
                return (
                  <li
                    className={`px-6 py-4 ${
                      selectedRow && selectedRow._id == item._id
                        ? " bg-gray-50 border-l-4 border-gray-200 "
                        : "bg-white "
                    }  cursor-pointer hover:bg-gray-50
                    transition-all duration-500 ease-in-out
                   
                    
                    `}
                    key={item._id}
                    onClick={(e) => props.navigateToProject(item)}
                  >
                    <div className="space-y-3 transition-all duration-500 ease-in-out">
                      <div
                        id="top-row"
                        className="flex flex-col grid grid-cols-5 col-span-9 space-x-6 data-center space-y-3 sm:space-y-1 md:flex-row  data-start justify-between  "
                      >
                        <div className="col-span-2 data-center justify-between">
                          <h2 className="flex text-lg  font-semibold text-slate-800">
                            BL-{item.projectId} - {item.name}{" "}
                            <div className="flex ml-4">
                              <Badge
                                color={
                                  item.status == "Open" ? "green" : "secondary"
                                }
                                case="uppercase"
                                // shape="square"
                                className="ml-4 "
                              >
                                {" "}
                                {item.status}
                              </Badge>
                            </div>
                          </h2>{" "}
                          <div className=" ">
                            {item && (
                              <p className="truncate text-sm text-slate-600">
                                <span className="text-slate-500">
                                  Created on{" "}
                                </span>
                                <span className="text-indigo-500 font-semibold">
                                  {utils.formatDate(item.createdAt, "short")}
                                </span>{" "}
                                {item && item.createdBy && (
                                  <>
                                    <span className="text-slate-500">by </span>
                                    <span className="text-indigo-500 font-semibold">
                                      Some text here
                                    </span>
                                  </>
                                )}
                              </p>
                            )}

                            {/* <div className="ml-2 flex flex-shrink-0">
                    <p className="truncate text-xs  text-slate-600">
                      <span className="text-indigo-500">
                        {item.submittedByName}
                      </span>
                    </p>
                    <p className="inline-flex capitalize rounded-full bg-slate-100 px-2 text-xs font-semibold leading-5 text-slate-800">
                      {item.projectId && item.projectId.name}
                    </p>
                  </div> */}
                          </div>
                          <div className="flex items-center mt-2  sm:justify-between">
                            {item.solicitations && item.solicitations.length}{" "}
                            bid requests
                          </div>
                        </div>
                        {/* <div className="p-3 space-y-2"> */}
                        <div className="text-md">
                          <span className="font-semibold">Client:</span>{" "}
                          {item.client && item.client.name}
                        </div>
                        <div className="text-md">
                          <span className="font-semibold">Deadline:</span>{" "}
                          {utils.formatDate(item.deadline, "short")}
                        </div>

                        {/* </div> */}
                        <RightActionArea>
                          {/* <div className="inline-flex data-center text-xs font-semibold text-primary-600 mr-4">
                            Some action?
                          </div> */}

                          <Button
                            iconButton
                            size="sm"
                            color="secondary"
                            handleClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              console.log("Delete clicked", item._id);
                              props.handleDeleteProjectClick(e, item._id);
                            }}
                          >
                            <TrashIcon className="w-4 h-4" />
                          </Button>
                          {/* <MenuComponent align={"left"} /> */}
                        </RightActionArea>
                      </div>
                      {/* <div className=" flex data-center justify-between">
                        {item && (
                          <p className="truncate text-sm text-slate-600">
                            <span className="text-slate-500">Created on </span>
                            <span className="text-indigo-500 font-semibold">
                              {utils.formatDate(item.createdAt, "short")}
                            </span>{" "}
                            {item && item.createdBy && (
                              <>
                                <span className="text-slate-500">by </span>
                                <span className="text-indigo-500 font-semibold">
                                  Some text here
                                </span>
                              </>
                            )}
                          </p>
                        )}
                      </div> */}

                      <div
                        id="bottom-row"
                        className={`flex flex-col md:flex-row  data-center justify-between
                       
                        `}
                      >
                        <p className="line-clamp-3 text-sm  text-slate-800">
                          {item.description}
                        </p>
                        {/* <div className="p-3 space-y-2">
                          <div className="text-sm">
                            <span className="font-semibold">Client:</span>{" "}
                            {item.client && item.client.name}
                          </div>
                          <div className="text-sm">
                            <span className="font-semibold">Deadline:</span>{" "}
                            {utils.formatDate(item.deadline, "short")}
                          </div>
                          <p className="line-clamp-3 text-sm font-medium text-slate-600">
                            {item.description}
                          </p>
                        </div> */}
                      </div>
                      {/* 
                      <div
                        id="middle-row"
                        className="flex flex-col py-4 md:flex-row px-4 data-center justify-between  "
                      >
                        <div className="col-span-2 flex data-center justify-center">
                          <div>
                            <p className="flex text-sm font-medium text-slate-800 mb-2">
                              Related Competency{" "}
                              <QuestionToolTip
                                field={"Related Competency"}
                                text={
                                  "Which competency is blocked by this impediment?  Note that this is an impediment-wide category and not a team-specific category."
                                }
                              />
                            </p>
                            <div className=" text-sm font-medium text-slate-800"></div>
                          </div>
                        </div>
                        <div className="col-span-2 flex data-center justify-center">
                          <div>
                            <p className="flex text-sm font-medium text-slate-800  mb-2">
                              Impediment Type{" "}
                              <QuestionToolTip
                                field={"Impediment Type"}
                                text={
                                  "This is the type of impediment that is being reported.  Note that this field may be disabled if you are not an Administrator. "
                                }
                              />
                            </p>
                            <div className=" text-sm font-medium text-slate-800"></div>
                          </div>
                        </div>
                        <div className="col-span-2 flex data-center justify-center">
                          <div>
                            <p className="flex text-sm font-medium text-slate-800 mb-2">
                              Impediment Scope{" "}
                              <QuestionToolTip
                                field={"Impediment Scope"}
                                text={
                                  "The scope of the impediment relates to the impact it has and who is best able to resolve it. Note that this field may be disabled if you are not an Administrator."
                                }
                              />
                            </p>
                            <div className=" text-sm font-medium text-slate-800"></div>
                          </div>
                        </div>
                        <div className="col-span-1 flex data-center justify-center">
                          <div>
                            <p className="flex  text-sm font-medium text-slate-800 mb-2">
                              Burden{" "}
                              <QuestionToolTip
                                field={"Burden"}
                                text={
                                  "How many days per week are wasted on this impediment?"
                                }
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-span-1 flex data-center justify-center">
                          <div>
                            <p className="flex  text-sm font-medium text-slate-800 mb-2">
                              Effort{" "}
                              <QuestionToolTip
                                field={"Effort"}
                                text={
                                  "How many days of effort required to resolve this impediment?"
                                }
                              />
                            </p>
                          </div>
                        </div>
                      </div> */}

                      {/* <div id="bottom-row" className="grid grid-cols-12 px-4 py-0 sm:px-6 gap-x-6 ">
                        <div className="col-span-8">
                          <p className="inline-flex text-sm font-medium text-slate-800">
                            Description
                          </p>
                          <div className="flex w-full data-start text-sm text-gray-500 sm:mt-0"></div>
                        </div>

                        <div className="col-span-4  ">
                          <p className="inline-flex text-sm font-medium text-slate-800">
                            Legacy Fields
                          </p>
                          <div className="grid grid-cols-2"></div>
                        </div>
                      </div> */}
                    </div>
                  </li>
                );
              })}
        </ul>
        {/* </InfiniteScroll> */}
      </div>
      {/* </ul> */}
    </div>
  );
}

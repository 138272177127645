import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const ErrorLogging = {
  profile() {
    if (
      _auth.getUserProfile() === null ||
      _auth.getUserProfile() === undefined
    ) {
      return JSON.parse(localStorage.getItem("guestProfile"));
    } else {
      return _auth.getUserProfile();
    }
  },

  async CreateError(
    errorStatus,
    errorMessage,
    errorStack,
    errorLocation,
    details
  ) {
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    // try {

    let data = {
      errorStatus: errorStatus ? errorStatus : "",
      errorMessage: errorMessage ? errorMessage : "",
      errorStack: errorStack ? errorStack : "",
      errorLocation: errorLocation ? errorLocation : "",
      details: details ? details : {},
    };
    let response = await postRequest("error-logging/create-error", data);
    if (response) {
      console.log("/error-logging/create-error", response.data.data);
      return response.data.data;
    } else {
      console.log("/error-logging/create-error failed", response);
      return false;
    }
    // } catch (e) {
    //   console.log(e);
    // }
  },
  async LogFrontEnd(msg, logArray) {
    // let profile = this.profile();
    // let token = localStorage.getItem("jwtToken");
    // setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    // try {

    let data = {
      message: msg ? msg : "",
      logArray: logArray ? logArray : [],
    };
    console.log("Call API and pass data for LogFrontEnd", data);
    let response = await postRequest("error-logging/log-front-end", data);
    if (response) {
      console.log("/error-logging/log-front-end", response);
      return response;
    } else {
      console.log("/error-logging/log-front-end failed", response);
      return false;
    }
    // } catch (e) {
    //   console.log(e);
    // }
  },
};

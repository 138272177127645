/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";
import ReactPlayer from "react-player";
import { NavLink } from "react-router-dom";
import { _assessment } from "../modules/_assessment";
import { audit } from "../modules/_audit";

export default function AssessmentIntro(props) {
  console.info("AssessmentIntro props", props);
  const resume = props.resume ? props.resume : null;
  const { setStep, currentStep } = props;
  console.log("AssessmentIntro ", props.intro);
  const type = props.type;
  const intro = props.intro;

  // const listOfParagraphs = props.intro.split("\n\n");
  // console.log("listOfParagraphs", listOfParagraphs);
  const assessment_key = props.assessment_key;
  const name = props.name;
  const assessment = _assessment.getAssessmentTypeDetails(assessment_key);
  console.info("AssessmentIntro assessment", assessment);
  const handleStartAssessment = props.handleStartAssessment;
  const [listOfParagraphs, setListOfParagraphs] = useState([]);

  useEffect(() => {
    if (intro) {
      let listOfParagraphs = intro.split("\n\n");
      console.log("listOfParagraphs", listOfParagraphs);
      setListOfParagraphs(listOfParagraphs);
    }
  }, [intro]);

  const handleClick = () => {
    if (type == "assessment") {
      handleStartAssessment();
    }
    handleStartAssessment();
    setStep(currentStep + 1);
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto bg-white">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            {type === "assessment" ? name + " Assessment" : "Pre-Assessment"}
          </h1>
        </div>
      </div>
      <div className="">
        {type === "assessment" && (
          <div className="max-w-3xl mx-auto text-left">
            {/* {intro} */}
            {listOfParagraphs.map((paragraph, index) => {
              return (
                <p className="mt-4 text-md" key={index}>
                  {paragraph}
                </p>
              );
            })}
          </div>
        )}

        {type !== "assessment" && (
          <div className="max-w-3xl mx-auto text-left">
            <p className="mt-4 text-md">
              {intro}
              {/* {listOfParagraphs.map((paragraph, index) => {
                return (
                  <p className="mt-4 text-md" key={index}>
                    {paragraph}
                  </p>
                );
              })} */}
              {/* The pre-assessment will let you know not only your company's
              current level of cyber preparedness and compliance with data
              privacy laws at a high level, but also give you a feeling for who
              may be best suited to take the more in-depth assessments. Often,
              it takes the help of others to properly answer the questions for
              your company. Let's get started! */}
            </p>
          </div>
        )}
        {resume && (
          <div className="max-w-3xl mx-auto text-left">
            <p className="mt-4 text-md">
              It looks like you're coming back to finish an assessment. That's
              great! When you resume your assessment, we'll start at the
              beginning so you can review your previous answers.
            </p>
          </div>
        )}
        <div>
          {/* <ReactPlayer
            url={"https://player.vimeo.com/video/342258347"}
            width="100%"
            // height="360px"
            controls={true}
          /> */}
        </div>
      </div>
      <button
        onClick={handleClick}
        className="btn w-full mt-10 bg-secondary-600 text-white "
      >
        {resume ? "Resume Assessment" : "Begin Assessment"}
      </button>
      <NavLink
        className="btn w-full mt-10 bg-gray-200 text-slate-500"
        exact
        to="/home"
      >
        <span onClick={() => audit.logAction("Abandon Assessment")}>
          I'll come back later
        </span>
      </NavLink>
      {/* <button
          onClick={handleClick}
          className="btn w-full mt-10 bg-gray-400 text-white "
        >
          I changed my mind
        </button> */}
    </div>
  );
}

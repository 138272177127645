import React from "react";
import { Paragraph } from "../../components/Typography/Paragraph";
import Home from "../Home";
import GettingStarted from "../../components/GettingStarted";

export default function IntroPage(props) {
  return (
    <main className="h-[calc(100vh-64px)] ">
      <div className="relative md:flex justify-center">
        <div className=" justify-center content-center">
          <div className="min-h-[calc(100vh-64px)] h-full flex flex-col content-center justify-center">
            <Home
              solicitations={props.solicitations}
              updated={props.updated}
              handleOpenAssessmentModalFromHome={
                props.handleOpenAssessmentModalFromHome
              }
              setNewSolicitationShortcutModalOpen={(e) => {
                console.log("onclick called", e);
                props.setNewSolicitationShortcutModalOpen(true);
              }}
              handleOpenContactModal={props.handleOpenContactModal}
              options={props.options}
              handleBidRequestClick={props.handleBidRequestClick}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

import React, { useEffect, useRef, useState } from "react";
import PreferencesSection from "../../components/Preferences/PreferenceSection";
import Divider from "../../components/Divider";
import { Preferences } from "../../modules/_user_preferences";

function PreferencesPage({ group }) {
  const [comments, setComments] = useState(false);
  const [messages, setMessages] = useState(false);
  const [mentions, setMentions] = useState(false);
  const [preferences, setPreferences] = useState([]);
  const timerRef = useRef(null);
  const prevPreferencesRef = useRef(JSON.stringify({}));
  useEffect(() => {
    //preferences is an array of objects
    //each object has an id, value, and group
    //let preferences = [{ id: "comments", value: false, group: "notifications" }];

    (async () => {
      let response = await Preferences.getPreferences();
      console.log("getPreferences response", response);
      if (response) {
        setPreferences(response);
      } else {
        setPreferences([]);
      }
    })();
  }, []);

  useEffect(() => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(async () => {
      if (prevPreferencesRef.current !== JSON.stringify(preferences)) {
        console.log("Preferences changed, updating", preferences);
        let response = await Preferences.setPreferences({
          preferences: preferences,
        });
        console.log("setPreferences response", response);
        prevPreferencesRef.current = JSON.stringify(preferences);
      }
    }, 750);

    return () => clearTimeout(timerRef.current);
  }, [preferences]);

  const handleUpdatePreferences = (id, value, group) => {
    //sample object
    //let preferences = [{ id: "comments", value: false, group: "notifications" }];
    let newPreferences = [...preferences];
    let index = newPreferences.findIndex((obj) => obj.id === id);
    if (index === -1) {
      newPreferences.push({ id: id, value: value, group: group });
    } else {
      newPreferences[index].value = value;
      newPreferences[index].group = group;
    }
    setPreferences(newPreferences);
  };

  return (
    <>
      <PreferencesSection
        title="Security"
        bottomDivider={false}
        options={
          [
            // {
            //   id: "mfa",
            //   name: "Mutli-Factor Authentication",
            //   description: "Enable or disable mulitfactor authentication.",
            //   value: preferences.find((obj) => obj.id === "mfa")?.value,
            //   setValue: (value) => handleUpdatePreferences("mfa", value, group),
            // },
            // {
            //   id: "messages",
            //   name: "Messages",
            //   description:
            //     "Email me when new messages are left on bid requests.",
            //   value: messages,
            //   setValue: setMessages,
            // },
            // {
            //   id: "mentions",
            //   name: "Mentions",
            //   description: "Email me when I am mentioned in a comment.",
            //   value: mentions,
            //   setValue: setMentions,
            // },
          ]
        }
      />
      <PreferencesSection
        title="Notifications"
        bottomDivider={false}
        options={[
          {
            id: "comment-notifications",
            name: "Comments",
            description: "Email me when new comments are left on bid requests.",
            value: preferences.find((obj) => obj.id === "comment-notifications")
              ?.value,
            setValue: (value) =>
              handleUpdatePreferences("comment-notifications", value, group),
          },
        ]}
      />
    </>
  );
}

export default PreferencesPage;

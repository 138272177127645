//solicitation detail tabs
export const SOLICITATION_TAB_ORDER = [
  "mailings",
  "emailings",
  "call_center",
  "credit_monitoring",
  "data_mining",
  "document_review",
];

export const PRICING_TYPES = [
  // {
  //   name: "Flat",
  //   value: "flat",
  //   description:
  //     "Choose this option if the user will pay one price for the entire order.",
  // },
  {
    name: "Standard",
    value: "standard",
    description:
      "Choose this option if the user will pay the same price for every unit.",
  },
  // {
  //   name: "Package",
  //   value: "package",
  //   description:
  //     "Select package pricing if you charge by the package, or group of units. For example, say you charge $25.00 for every 5 units. Purchases are rounded up by default, so a customer buying 8 units would pay $50.00.",
  // },
  {
    name: "Graduated",
    value: "graduated",
    description:
      "Select graduated pricing if you use pricing tiers that may result in a different price for some units in an order. For example, you might charge $10.00 per unit for the first 100 units and then $5.00 per unit for the next 50.",
  },
  {
    name: "Volume",
    value: "volume",
    description:
      "Select volume pricing if you charge the same price for each unit based on the total number of units sold. For example, you might charge $10.00 per unit for 50 units, and $7.00 per unit for 100 units.",
  },
];

export const BILLING_RECURRENCE = [
  {
    name: "One Time",
    title: "One Time",
    // description: "Charge the customer once for the entire order.",
  },
  {
    name: "Recurring",
    title: "Recurring",
    // description:
    // "Charge the customer on a recurring basis, such as every month or year.",
  },
];

export const BILLING_INTERVAL = [
  {
    name: "Day",
    description: "Charge the customer once for the entire order.",
  },
  {
    name: "Week",
    description:
      "Charge the customer on a recurring basis, such as every month or year.",
  },
  {
    name: "Month",
    description:
      "Charge the customer on a recurring basis, such as every month or year.",
  },
  {
    name: "Year",
    description:
      "Charge the customer on a recurring basis, such as every month or year.",
  },
];

export const SOLICITATION_STATUS = ["Active", "Closed", "Archived", "Deleted"];

export const WORKFLOW_STATES = [
  "Accepting Bids",
  "Bidding Closed",
  "Winner Selected",
  "Winner Notified",
  "Closed",
  "Archived",
];
export const RESPONSE_STATUS = [
  "Draft",
  "Submitted",
  "Accepted",
  "Declined",
  "Withdrawn",
  "Archived",
];

export const RESPONSE_STATEES = ["Won", "Lost", "Declined", "Withdrawn"];

export const WORKFLOW_STATUS = [
  //response status
  "Draft",
  "Active",
  "Closed",
  "Archived",
  "Deleted",
];

import React, { useState, useEffect, useMemo, useContext } from "react";
import Sidebar from "../partials/Sidebar";
import NarrowSidebar from "../components/NarrowSidebar";
import Header from "../partials/Header";
import { useHistory } from "react-router-dom";
import { _auth } from "../modules/_auth";
import ToastNotification from "../components/ToastNotification";
import { _navigation } from "../modules/_navigation";
import { Store } from "./store";
import useGlobalState from "../hooks/useGlobalState";

const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [noChange, setNoChange] = useState(false);
  const { setProfile } = useGlobalState();
  let history = useHistory();
  const profile = _auth.getUserProfile();

  useEffect(() => {
    setProfile({ profile });
  }, []);
  // const role = useMemo(() => profile.role, [role]);
  useEffect(() => {
    _auth.getAuth(history);
  }, []);

  // const navigation = useMemo(
  //   () => _navigation.getNavLinksByRole(profile.role),
  //   [profile]
  // );

  const navigation = useMemo(
    () =>
      _navigation.getNavLinksByTenantType(
        profile && profile.tenantType ? profile.tenantType : "admin",
        profile.role
      ),
    [profile]
  );

  // const homelinks = useMemo(
  //   () =>
  //     _navigation.getHomeLinksByTenantType(
  //       profile && profile.tenantType ? profile.tenantType : "admin"
  //     ),
  //   [profile]
  // );

  const showHome = useMemo(() => profile.showHome, [profile.showHome]);

  const setSidebarExpanded = (e) => {
    e.preventDefault();
    setSidebarOpen(true);
  };
  const setSidebarClosed = (e) => {
    e.preventDefault();
    setSidebarOpen(false);
  };

  // useEffect(() => {
  //   _auth.getAuth(history);
  //   let profile = _auth.getUserProfile();
  //   setProfile(profile);
  //   // setShowHome(profile.showHome ? profile.showHome : true);
  //   setShowHome(profile.showHome);
  //   console.log("profile in layout showHome", profile);
  //   // const navigation = _navigation.getNavLinksByRole(profile.role);
  //   // setNavigation(navigation);
  //   console.info(" showHome", profile.showHome);
  // }, []);

  // useEffect(() => {
  //   const navigation = _navigation.getNavLinksByRole(profile.role);
  //   setNavigation(navigation);
  //   console.info("navigation in layout", JSON.stringify(navigation));
  // }, [profile]);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* <Store profile={profile}> */}
      <Sidebar
        showHome={showHome}
        profile={profile}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        setSidebarClosed={setSidebarClosed}
        navigation={navigation}
      />
      {/* <NarrowSidebar
          showHome={showHome}
          profile={profile}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          navigationItems={navigation}
        /> */}
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          showHome={showHome}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarExpanded}
          setSidebarClosed={setSidebarClosed}
          navigation={navigation}
        />

        {/* <main className={"px-4 sm:px-6 lg:px-8 py-8 "}> */}
        <main className={"p-4 min-h-[calc(100vh-56px)] overflow-y-hidden"}>
          {" "}
          <ToastNotification show={true} />
          {children}
        </main>
      </div>
      {/* </Store> */}
    </div>
  );
};

export default Layout;

import { ArrowUpTrayIcon } from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/outline";
import React from "react";

const items = [
  {
    id: "manual",
    name: "Manually add questions",
    description: "Description 1",
    price: 100,
    icon: <PlusIcon className="mx-auto h-12 w-12 text-slate-400" />,
  },
  {
    id: "import",
    name: "Import questions",
    description: "Description 2",
    price: 200,
    icon: <ArrowUpTrayIcon className="mx-auto h-12 w-12 text-slate-400" />,
  },
];

export default function EmptyButton(props) {
  //   const items = props.items ? props.items : [];
  return (
    //map items to a list of items
    <div className="grid grid-cols-2 space-x-6 m-auto justify-center items-center h-full w-1/2 overflow-hidden">
      {items.map((item) => {
        return (
          <button
            type="button"
            onClick={() => {
              props.handleClick(item.id);
            }}
            className="bg-white rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:border-primary-500"
          >
            {item.icon}

            <span className="mt-2 block text-sm font-medium text-slate-900">
              {item.name}
            </span>
          </button>
        );
      })}
    </div>
  );
}

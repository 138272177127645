import React from "react";
import SearchForm from "../../partials/actions/SearchForm";
import Tabs from "../../pages/component/Tabs";
import { _marketplace } from "../../modules/_marketplace";

function AppsPanel() {
  const tabs = _marketplace.getMarketplaceTabs();
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            Marketplace
          </h1>
        </div>
        {/* Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          {/* Search form */}

          <SearchForm />
          {/* Filter button */}
          {/* <FilterButton align="right" /> */}
          {/* Create campaign button */}
          {/* <button className="btn bg-primary-500 hover:bg-primary-600 text-white">
                  <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Create Campaign</span>
                </button> */}
        </div>
      </div>
      {/* General */}
      <div className="mb-6">
        {/* Filters */}
        <div className="mb-5">
          <Tabs tabs={tabs} />
        </div>
        {/* <div className="mb-4 border-b border-gray-200">
          <ul className="text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
            <li className="pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
              <a className="text-primary-500 whitespace-nowrap" href="#0">
                View All
              </a>
            </li>
            <li className="pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
              <a
                className="text-slate-500 hover:text-slate-600 whitespace-nowrap"
                href="#0"
              >
                Insurance
              </a>
            </li>
            <li className="pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
              <a
                className="text-slate-500 hover:text-slate-600 whitespace-nowrap"
                href="#0"
              >
                Monitoring
              </a>
            </li>
            <li className="pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
              <a
                className="text-slate-500 hover:text-slate-600 whitespace-nowrap"
                href="#0"
              >
                Incident Response
              </a>
            </li>
            <li className="pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
              <a
                className="text-slate-500 hover:text-slate-600 whitespace-nowrap"
                href="#0"
              >
                Security
              </a>
            </li>
          </ul>
        </div> */}
      </div>

      {/* Connect Apps cards */}
      <section className="pb-6 border-b border-gray-200">
        <div className="grid grid-cols-12 gap-6">
          {/* Card 1 */}
          <div className="col-span-full xl:col-span-6 2xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
            {/* Card content */}
            <div className="flex flex-col h-full p-5">
              <div className="grow">
                <header className="flex items-center mb-4">
                  <div className="w-10 h-10 rounded-full shrink-0 bg-gradient-to-tr from-primary-500 to-primary-300 mr-3">
                    <svg
                      className="w-10 h-10 fill-current text-white"
                      viewBox="0 0 40 40"
                    >
                      <path d="M26.946 18.005a.583.583 0 00-.53-.34h-6.252l.985-3.942a.583.583 0 00-1.008-.52l-7 8.167a.583.583 0 00.442.962h6.252l-.984 3.943a.583.583 0 001.008.52l7-8.167a.583.583 0 00.087-.623z" />
                    </svg>
                  </div>
                  <h3 className="text-lg text-slate-800 font-semibold">
                    CyberInsure Now
                  </h3>
                </header>
                <div className="text-sm">
                  Lorem ipsum dolor sit amet eiusmod sed do eiusmod tempor.
                </div>
              </div>
              {/* Card footer */}
              <footer className="mt-4">
                <div className="flex flex-wrap justify-between items-center">
                  {/* Left side */}
                  <div className="flex space-x-3">
                    <div className="flex items-center text-slate-400">
                      <svg
                        className="w-4 h-4 shrink-0 fill-current mr-1.5"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14.14 9.585a2.5 2.5 0 00-3.522 3.194c-.845.63-1.87.97-2.924.971a4.979 4.979 0 01-1.113-.135 4.436 4.436 0 01-1.343 1.682 6.91 6.91 0 006.9-1.165 2.5 2.5 0 002-4.547h.002zM10.125 2.188a2.5 2.5 0 10-.4 2.014 5.027 5.027 0 012.723 3.078c.148-.018.297-.028.446-.03a4.5 4.5 0 011.7.334 7.023 7.023 0 00-4.469-5.396zM4.663 10.5a2.49 2.49 0 00-1.932-1.234 4.624 4.624 0 01-.037-.516 4.97 4.97 0 011.348-3.391 4.456 4.456 0 01-.788-2.016A6.989 6.989 0 00.694 8.75c.004.391.04.781.11 1.166a2.5 2.5 0 103.86.584z" />
                      </svg>
                      <div className="text-sm text-slate-500">4K+</div>
                    </div>
                    <div className="flex items-center text-yellow-500">
                      <svg
                        className="w-4 h-4 shrink-0 fill-current mr-1.5"
                        viewBox="0 0 16 16"
                      >
                        <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                      </svg>
                      <div className="text-sm text-yellow-600">4.7</div>
                    </div>
                  </div>
                  {/* Right side */}
                  <button className="btn-sm border-gray-200 hover:border-gray-300 shadow-sm flex items-center">
                    <svg
                      className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2"
                      viewBox="0 0 12 12"
                    >
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span>Connect</span>
                  </button>
                </div>
              </footer>
            </div>
          </div>
          {/* Card 2 */}
          <div className="col-span-full xl:col-span-6 2xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
            {/* Card content */}
            <div className="flex flex-col h-full p-5">
              <div className="grow">
                <header className="flex items-center mb-4">
                  <div className="w-10 h-10 rounded-full shrink-0 bg-gradient-to-tr from-green-500 to-green-300 mr-3">
                    <svg
                      className="w-10 h-10 fill-current text-white"
                      viewBox="0 0 40 40"
                    >
                      <path d="M26.946 18.005a.583.583 0 00-.53-.34h-6.252l.985-3.942a.583.583 0 00-1.008-.52l-7 8.167a.583.583 0 00.442.962h6.252l-.984 3.943a.583.583 0 001.008.52l7-8.167a.583.583 0 00.087-.623z" />
                    </svg>
                  </div>
                  <h3 className="text-lg text-slate-800 font-semibold">
                    BreachResponse
                  </h3>
                </header>
                <div className="text-sm">
                  Lorem ipsum dolor sit amet eiusmod sed do eiusmod tempor.
                </div>
              </div>
              {/* Card footer */}
              <footer className="mt-4">
                <div className="flex flex-wrap justify-between items-center">
                  {/* Left side */}
                  <div className="flex space-x-3">
                    <div className="flex items-center text-slate-400">
                      <svg
                        className="w-4 h-4 shrink-0 fill-current mr-1.5"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14.14 9.585a2.5 2.5 0 00-3.522 3.194c-.845.63-1.87.97-2.924.971a4.979 4.979 0 01-1.113-.135 4.436 4.436 0 01-1.343 1.682 6.91 6.91 0 006.9-1.165 2.5 2.5 0 002-4.547h.002zM10.125 2.188a2.5 2.5 0 10-.4 2.014 5.027 5.027 0 012.723 3.078c.148-.018.297-.028.446-.03a4.5 4.5 0 011.7.334 7.023 7.023 0 00-4.469-5.396zM4.663 10.5a2.49 2.49 0 00-1.932-1.234 4.624 4.624 0 01-.037-.516 4.97 4.97 0 011.348-3.391 4.456 4.456 0 01-.788-2.016A6.989 6.989 0 00.694 8.75c.004.391.04.781.11 1.166a2.5 2.5 0 103.86.584z" />
                      </svg>
                      <div className="text-sm text-slate-500">4K+</div>
                    </div>
                    <div className="flex items-center text-yellow-500">
                      <svg
                        className="w-4 h-4 shrink-0 fill-current mr-1.5"
                        viewBox="0 0 16 16"
                      >
                        <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                      </svg>
                      <div className="text-sm text-yellow-600">4.7</div>
                    </div>
                  </div>
                  {/* Right side */}
                  <button className="btn-sm border-gray-200 hover:border-gray-300 shadow-sm flex items-center">
                    <svg
                      className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2"
                      viewBox="0 0 12 12"
                    >
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span>Connect</span>
                  </button>
                </div>
              </footer>
            </div>
          </div>
          {/* Card 3 */}
          <div className="col-span-full xl:col-span-6 2xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
            {/* Card content */}
            <div className="flex flex-col h-full p-5">
              <div className="grow">
                <header className="flex items-center mb-4">
                  <div className="w-10 h-10 rounded-full shrink-0 bg-gradient-to-tr from-light-blue-500 to-light-blue-300 mr-3">
                    <svg
                      className="w-10 h-10 fill-current text-white"
                      viewBox="0 0 40 40"
                    >
                      <path d="M26.946 18.005a.583.583 0 00-.53-.34h-6.252l.985-3.942a.583.583 0 00-1.008-.52l-7 8.167a.583.583 0 00.442.962h6.252l-.984 3.943a.583.583 0 001.008.52l7-8.167a.583.583 0 00.087-.623z" />
                    </svg>
                  </div>
                  <h3 className="text-lg text-slate-800 font-semibold">
                    Jones, Jones, and Jones LLP.
                  </h3>
                </header>
                <div className="text-sm">
                  Lorem ipsum dolor sit amet eiusmod sed do eiusmod tempor.
                </div>
              </div>
              {/* Card footer */}
              <footer className="mt-4">
                <div className="flex flex-wrap justify-between items-center">
                  {/* Left side */}
                  <div className="flex space-x-3">
                    <div className="flex items-center text-slate-400">
                      <svg
                        className="w-4 h-4 shrink-0 fill-current mr-1.5"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14.14 9.585a2.5 2.5 0 00-3.522 3.194c-.845.63-1.87.97-2.924.971a4.979 4.979 0 01-1.113-.135 4.436 4.436 0 01-1.343 1.682 6.91 6.91 0 006.9-1.165 2.5 2.5 0 002-4.547h.002zM10.125 2.188a2.5 2.5 0 10-.4 2.014 5.027 5.027 0 012.723 3.078c.148-.018.297-.028.446-.03a4.5 4.5 0 011.7.334 7.023 7.023 0 00-4.469-5.396zM4.663 10.5a2.49 2.49 0 00-1.932-1.234 4.624 4.624 0 01-.037-.516 4.97 4.97 0 011.348-3.391 4.456 4.456 0 01-.788-2.016A6.989 6.989 0 00.694 8.75c.004.391.04.781.11 1.166a2.5 2.5 0 103.86.584z" />
                      </svg>
                      <div className="text-sm text-slate-500">4K+</div>
                    </div>
                    <div className="flex items-center text-yellow-500">
                      <svg
                        className="w-4 h-4 shrink-0 fill-current mr-1.5"
                        viewBox="0 0 16 16"
                      >
                        <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                      </svg>
                      <div className="text-sm text-yellow-600">4.7</div>
                    </div>
                  </div>
                  {/* Right side */}
                  <button className="btn-sm border-gray-200 hover:border-gray-300 shadow-sm flex items-center">
                    <svg
                      className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2"
                      viewBox="0 0 12 12"
                    >
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span>Connect</span>
                  </button>
                </div>
              </footer>
            </div>
          </div>
          {/* Card 4 */}
          <div className="col-span-full xl:col-span-6 2xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
            {/* Card content */}
            <div className="flex flex-col h-full p-5">
              <div className="grow">
                <header className="flex items-center mb-4">
                  <div className="w-10 h-10 rounded-full shrink-0 bg-gradient-to-tr from-red-500 to-red-300 mr-3">
                    <svg
                      className="w-10 h-10 fill-current text-white"
                      viewBox="0 0 40 40"
                    >
                      <path d="M26.946 18.005a.583.583 0 00-.53-.34h-6.252l.985-3.942a.583.583 0 00-1.008-.52l-7 8.167a.583.583 0 00.442.962h6.252l-.984 3.943a.583.583 0 001.008.52l7-8.167a.583.583 0 00.087-.623z" />
                    </svg>
                  </div>
                  <h3 className="text-lg text-slate-800 font-semibold">
                    MaterialStack
                  </h3>
                </header>
                <div className="text-sm">
                  Lorem ipsum dolor sit amet eiusmod sed do eiusmod tempor.
                </div>
              </div>
              {/* Card footer */}
              <footer className="mt-4">
                <div className="flex flex-wrap justify-between items-center">
                  {/* Left side */}
                  <div className="flex space-x-3">
                    <div className="flex items-center text-slate-400">
                      <svg
                        className="w-4 h-4 shrink-0 fill-current mr-1.5"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14.14 9.585a2.5 2.5 0 00-3.522 3.194c-.845.63-1.87.97-2.924.971a4.979 4.979 0 01-1.113-.135 4.436 4.436 0 01-1.343 1.682 6.91 6.91 0 006.9-1.165 2.5 2.5 0 002-4.547h.002zM10.125 2.188a2.5 2.5 0 10-.4 2.014 5.027 5.027 0 012.723 3.078c.148-.018.297-.028.446-.03a4.5 4.5 0 011.7.334 7.023 7.023 0 00-4.469-5.396zM4.663 10.5a2.49 2.49 0 00-1.932-1.234 4.624 4.624 0 01-.037-.516 4.97 4.97 0 011.348-3.391 4.456 4.456 0 01-.788-2.016A6.989 6.989 0 00.694 8.75c.004.391.04.781.11 1.166a2.5 2.5 0 103.86.584z" />
                      </svg>
                      <div className="text-sm text-slate-500">4K+</div>
                    </div>
                    <div className="flex items-center text-yellow-500">
                      <svg
                        className="w-4 h-4 shrink-0 fill-current mr-1.5"
                        viewBox="0 0 16 16"
                      >
                        <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                      </svg>
                      <div className="text-sm text-yellow-600">4.7</div>
                    </div>
                  </div>
                  {/* Right side */}
                  <button className="btn-sm border-gray-200 hover:border-gray-300 shadow-sm flex items-center">
                    <svg
                      className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2"
                      viewBox="0 0 12 12"
                    >
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span>Connect</span>
                  </button>
                </div>
              </footer>
            </div>
          </div>
          {/* Card 5 */}
          <div className="col-span-full xl:col-span-6 2xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
            {/* Card content */}
            <div className="flex flex-col h-full p-5">
              <div className="grow">
                <header className="flex items-center mb-4">
                  <div className="w-10 h-10 rounded-full shrink-0 bg-gradient-to-tr from-yellow-500 to-yellow-300 mr-3">
                    <svg
                      className="w-10 h-10 fill-current text-white"
                      viewBox="0 0 40 40"
                    >
                      <path d="M26.946 18.005a.583.583 0 00-.53-.34h-6.252l.985-3.942a.583.583 0 00-1.008-.52l-7 8.167a.583.583 0 00.442.962h6.252l-.984 3.943a.583.583 0 001.008.52l7-8.167a.583.583 0 00.087-.623z" />
                    </svg>
                  </div>
                  <h3 className="text-lg text-slate-800 font-semibold">
                    Incident Response Crew
                  </h3>
                </header>
                <div className="text-sm">
                  Lorem ipsum dolor sit amet eiusmod sed do eiusmod tempor.
                </div>
              </div>
              {/* Card footer */}
              <footer className="mt-4">
                <div className="flex flex-wrap justify-between items-center">
                  {/* Left side */}
                  <div className="flex space-x-3">
                    <div className="flex items-center text-slate-400">
                      <svg
                        className="w-4 h-4 shrink-0 fill-current mr-1.5"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14.14 9.585a2.5 2.5 0 00-3.522 3.194c-.845.63-1.87.97-2.924.971a4.979 4.979 0 01-1.113-.135 4.436 4.436 0 01-1.343 1.682 6.91 6.91 0 006.9-1.165 2.5 2.5 0 002-4.547h.002zM10.125 2.188a2.5 2.5 0 10-.4 2.014 5.027 5.027 0 012.723 3.078c.148-.018.297-.028.446-.03a4.5 4.5 0 011.7.334 7.023 7.023 0 00-4.469-5.396zM4.663 10.5a2.49 2.49 0 00-1.932-1.234 4.624 4.624 0 01-.037-.516 4.97 4.97 0 011.348-3.391 4.456 4.456 0 01-.788-2.016A6.989 6.989 0 00.694 8.75c.004.391.04.781.11 1.166a2.5 2.5 0 103.86.584z" />
                      </svg>
                      <div className="text-sm text-slate-500">4K+</div>
                    </div>
                    <div className="flex items-center text-yellow-500">
                      <svg
                        className="w-4 h-4 shrink-0 fill-current mr-1.5"
                        viewBox="0 0 16 16"
                      >
                        <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                      </svg>
                      <div className="text-sm text-yellow-600">4.7</div>
                    </div>
                  </div>
                  {/* Right side */}
                  <button className="btn-sm border-gray-200 hover:border-gray-300 shadow-sm flex items-center">
                    <svg
                      className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2"
                      viewBox="0 0 12 12"
                    >
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span>Connect</span>
                  </button>
                </div>
              </footer>
            </div>
          </div>
          {/* Card 6 */}
          <div className="col-span-full xl:col-span-6 2xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
            {/* Card content */}
            <div className="flex flex-col h-full p-5">
              <div className="grow">
                <header className="flex items-center mb-4">
                  <div className="w-10 h-10 rounded-full shrink-0 bg-gradient-to-tr from-gray-500 to-gray-300 mr-3">
                    <svg
                      className="w-10 h-10 fill-current text-white"
                      viewBox="0 0 40 40"
                    >
                      <path d="M26.946 18.005a.583.583 0 00-.53-.34h-6.252l.985-3.942a.583.583 0 00-1.008-.52l-7 8.167a.583.583 0 00.442.962h6.252l-.984 3.943a.583.583 0 001.008.52l7-8.167a.583.583 0 00.087-.623z" />
                    </svg>
                  </div>
                  <h3 className="text-lg text-slate-800 font-semibold">
                    Phisher
                  </h3>
                </header>
                <div className="text-sm">
                  Lorem ipsum dolor sit amet eiusmod sed do eiusmod tempor.
                </div>
              </div>
              {/* Card footer */}
              <footer className="mt-4">
                <div className="flex flex-wrap justify-between items-center">
                  {/* Left side */}
                  <div className="flex space-x-3">
                    <div className="flex items-center text-slate-400">
                      <svg
                        className="w-4 h-4 shrink-0 fill-current mr-1.5"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14.14 9.585a2.5 2.5 0 00-3.522 3.194c-.845.63-1.87.97-2.924.971a4.979 4.979 0 01-1.113-.135 4.436 4.436 0 01-1.343 1.682 6.91 6.91 0 006.9-1.165 2.5 2.5 0 002-4.547h.002zM10.125 2.188a2.5 2.5 0 10-.4 2.014 5.027 5.027 0 012.723 3.078c.148-.018.297-.028.446-.03a4.5 4.5 0 011.7.334 7.023 7.023 0 00-4.469-5.396zM4.663 10.5a2.49 2.49 0 00-1.932-1.234 4.624 4.624 0 01-.037-.516 4.97 4.97 0 011.348-3.391 4.456 4.456 0 01-.788-2.016A6.989 6.989 0 00.694 8.75c.004.391.04.781.11 1.166a2.5 2.5 0 103.86.584z" />
                      </svg>
                      <div className="text-sm text-slate-500">4K+</div>
                    </div>
                    <div className="flex items-center text-yellow-500">
                      <svg
                        className="w-4 h-4 shrink-0 fill-current mr-1.5"
                        viewBox="0 0 16 16"
                      >
                        <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                      </svg>
                      <div className="text-sm text-yellow-600">4.7</div>
                    </div>
                  </div>
                  {/* Right side */}
                  <button className="btn-sm border-gray-200 hover:border-gray-300 shadow-sm flex items-center">
                    <svg
                      className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2"
                      viewBox="0 0 12 12"
                    >
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span>Connect</span>
                  </button>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </section>

      {/* Trending Categories cards */}
      <section>
        <h3 className="text-xl leading-snug text-slate-800 font-bold mt-6 mb-5">
          Trending
        </h3>
        <div className="grid grid-cols-12 gap-6">
          {/* Card 1 */}
          <div className="col-span-full xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
            {/* Card content */}
            <div className="flex flex-col h-full p-5">
              <div className="grow">
                <header>
                  <h3 className="text-lg text-slate-800 font-semibold mb-1">
                    Crowdmap
                  </h3>
                </header>
              </div>
              {/* Card footer */}
              <footer className="mt-2">
                <div className="flex flex-wrap justify-between items-center">
                  {/* Left side */}
                  <div className="text-sm text-slate-500 italic">400+ Apps</div>
                  {/* Right side */}
                  <a
                    className="text-sm font-medium text-primary-500 hover:text-primary-600"
                    href="#0"
                  >
                    Explore -&gt;
                  </a>
                </div>
              </footer>
            </div>
          </div>
          {/* Card 2 */}
          <div className="col-span-full xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
            {/* Card content */}
            <div className="flex flex-col h-full p-5">
              <div className="grow">
                <header>
                  <h3 className="text-lg text-slate-800 font-semibold mb-1">
                    Brady Insurance
                  </h3>
                </header>
              </div>
              {/* Card footer */}
              <footer className="mt-2">
                <div className="flex flex-wrap justify-between items-center">
                  {/* Left side */}
                  <div className="text-sm text-slate-500 italic">320+ Apps</div>
                  {/* Right side */}
                  <a
                    className="text-sm font-medium text-primary-500 hover:text-primary-600"
                    href="#0"
                  >
                    Explore -&gt;
                  </a>
                </div>
              </footer>
            </div>
          </div>
          {/* Card 3 */}
          <div className="col-span-full xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
            {/* Card content */}
            <div className="flex flex-col h-full p-5">
              <div className="grow">
                <header>
                  <h3 className="text-lg text-slate-800 font-semibold mb-1">
                    Data Security Misunderstandings
                  </h3>
                </header>
              </div>
              {/* Card footer */}
              <footer className="mt-2">
                <div className="flex flex-wrap justify-between items-center">
                  {/* Left side */}
                  <div className="text-sm text-slate-500 italic">270+ Apps</div>
                  {/* Right side */}
                  <a
                    className="text-sm font-medium text-primary-500 hover:text-primary-600"
                    href="#0"
                  >
                    Explore -&gt;
                  </a>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AppsPanel;

/*
  To show a feature based on criteria, wrap the feature's button in this component.
    The component will check the feature's flag and return the button if the flag is true.

    Example:
        <Feature type="product" productId={productID} featureName="Settings">
            <button>Feature</button>
        </Feature>

    The type can be "flag", "test", or "subscription", and others will be added in the future.
    The "flag" type will check the feature's flag and return the button if the flag is true.
    The "test" type will check if the user is a  user and return the button if true.
    The "subscription" type will check if the user has a subscription to the product and return the button if true.

  */

/* Should we auto-show all features in Beta? */

import React from "react";
import { useState, useEffect, useContext } from "react";
import { features } from "../modules/_features";
import { _auth } from "../modules/_auth";
import { _stripe } from "../modules/_stripe";
import { utils } from "../modules/_utils";
// import { Context } from "../layouts/store";
import { GlobalStateContext } from "../store/GlobalState";

export function FeatureFlag(props) {
  const publicPortal = props.publicPortal ? props.publicPortal : false;
  const type = props.type ? props.type : "flag";
  const role = props.role
    ? props.role
    : _auth.getUserProfile()?.role || "vendor";
  // const productId = _auth.getUserProfile().stripeProducts;
  const { featureName } = props;
  // const [tier, setTier] = useState(null);
  // const tier = _auth.getUserProfile().tier; //needs to change to cache
  const { children } = props;
  const [isEnabled, setIsEnabled] = useState(false);
  const [cache, setCache] = useState();
  const [flags, setFlags] = useState([]);
  // const state = useContext(Context);
  const state = useContext(GlobalStateContext);
  // const flags = state.state.flags;

  console.log("state", state);

  useEffect(() => {
    setFlags(state?.state?.flags ? state.state.flags : null);
    console.log("state.flags", state.state.flags);
  }, [state]);

  useEffect(() => {
    (async () => {
      console.log("Checking isEnabled", featureName);
      const isEnabled = await features.isEntitled(featureName, flags);
      if (isEnabled) {
        console.log("isEnabled returned true for", featureName, isEnabled);
        setIsEnabled(isEnabled);
      } else {
        console.log("isEnabled returned false for", featureName, isEnabled);
        setIsEnabled(false);
      }
    })();
  }, []);

  const isAllowed = () => {
    console.log(
      "checking isAllowed() featureName",
      featureName,
      "flags",
      flags,
      "role",
      role
    );
    const feature = flags
      ? flags.find((f) => f.FeatureName === featureName)
      : null;
    console.log(`Feature found: ${JSON.stringify(feature)}`);
    if (feature && feature !== undefined && feature !== null) {
      let isAuth = feature?.auth.includes(role);
      console.log(` ${featureName} isAllowed ${isAuth} for ${role}`);
      if (isAuth == true) {
        return true;
      } else {
        return false;
      }
    } else {
      console.log(` ${featureName} isAllowed FALSE for ${role}`);
      return false;
    }
  };

  if (type == "nav") {
    return <>{children}</>;
  }
  if (type === "flag") {
    console.log("flags checking isEntitled", featureName, isEnabled);
    if (isEnabled && isEnabled == true) {
      console.log("Flags: for ", featureName, "are enabled");
      return <>{children} </>;
    } else {
      console.log("Flags: for ", featureName, "are disabled");
      return null;
    }
  }

  if (type === "auth") {
    console.log("Checking", featureName, "for", role, "for auth");
    if (isAllowed(featureName, role) === false) {
      console.log("Flags: User not allowed for ", featureName, role);
      return null;
    } else {
      console.log("Flags: User allowed for ", featureName, role);
      return <>{children} </>;
    }

    // return isAllowed(featureName, role) === false ? null : <>{children} </>;
  }

  // if (type === "subscription") {
  //   if (isEntitled) {
  //     return <>{children} </>;
  //   } else {
  //     return null;
  //   }
  // }

  if (type === "full") {
    //check if the feature is inabled, entitled, and authorized
  }

  return null;
}

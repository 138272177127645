export function ProgressBarSmall({ progress, ...props }) {
  const height = props.height;
  const width = progress * 100;
  let color = "green";
  if (progress < 1) {
    color = "red";
    console.info("Setting color", color);
  }
  console.info("COLOR", color);
  return (
    <div class=" mt-5 w-full bg-gray-200 h-2  align-middle rounded-full">
      <div
        class={`bg-${color}-500 h-2 rounded-full`}
        style={{ width: progress * 100 + "%" }}
      ></div>
    </div>
  );
}

import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Projects } from "../../modules/_projects";

import RightActionArea from "../../components/ActionAreaRight";
import Button from "../../elements/Button";
import ModalBasic from "../../components/ModalBasic";
import NewProject from "./NewProject";
import ProjectDetails from "./ProjectDetails";
import { utils } from "../../modules/_utils";

import { TrashIcon } from "@heroicons/react/20/solid";
import DialogBox from "../../components/Dialog";
import ListTable from "./components/ListTable";
import PageHeader from "../../components/PageHeader";
import Tabs from "../component/Tabs";
import NewSolicitation from "./NewSolicitation";

export default function ProjectList(props) {
  const history = useHistory();
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false);
  const [newProjectStep, setNewProjectStep] = useState(1);
  const [newSolicitationModalOpen, setNewSolicitationModalOpen] =
    useState(false);
  const [newSolicitationData, setProjectForNewSolicitation] = useState(null);

  const [selectedProject, setSelectedProject] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [deletionEvent, setDeletionEvent] = useState(null);
  const [success, setSuccess] = useState(false);
  const [selectedProjectModalOpen, setSelectedProjectModalOpen] =
    useState(false);
  const [limit, setLimit] = useState(0);
  const [initialData, setInitialData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedTab, setSelectedTab] = React.useState("All");
  const fromModal = props.fromModal ? props.fromModal : false;

  useEffect(() => {
    (async () => {
      let list = await Projects.list("tenant", "project-list");

      if (list) {
        console.log("Got Project List", list);
        setData(list);
        setFilteredData(list);
        setLimit(list.length > 30 ? 30 : list.length);
      } else {
        console.log("Got Project list but it is empty");
        setData([]);
      }
    })();
  }, [props.data]);

  useEffect(() => {
    console.log("Selected Tab", selectedTab);
    if (selectedTab == "All") {
      setFilteredData(data);
    } else {
      let filtered = data.filter((item) => item.status == selectedTab);
      setFilteredData(filtered);
    }
  }, [selectedTab]);

  useEffect(() => {
    let newData = data && data.length > 0 ? data.slice(0, limit) : [];
    setInitialData(newData);
  }, [data]);

  // useEffect(() => {
  //   setNewProjectStep(1);
  //   alert("New Project Modal Open", newProjectStep);
  // }, [newProjectModalOpen]);

  const handleOpenProjectClick = (project) => {
    setSelectedProject(project);
    setSelectedProjectModalOpen(true);
  };

  const navigateToProject = (project) => {
    // alert("Navigate to project", JSON.stringify(project));
    const state = { project: project };
    history.push({
      pathname: "/projects/project-details/" + project._id,
      state: state,
    });
  };

  const handleOpenNewProjectModalClick = () => {
    setNewProjectStep(1);
    setNewProjectModalOpen(true);
  };

  const handleAfterCreateProject = (project) => {
    console.log("Project Created", project);
    //update data to include new project
    let oldData = data;
    let newData = [...oldData, project];
    setData(newData);
    setNewProjectStep(1);
    // setProjectForNewSolicitation(project);
  };
  const handleCreateNewSolicitation = (data) => {
    console.log("handleCreateNewSolicitation", data);
    setProjectForNewSolicitation(data);
    setNewSolicitationModalOpen(true);
  };

  // const handleAfterDeleteProject = (id) => {
  //   console.log("Project Created", project);
  //   //update data to include new project
  //   let oldData = data;
  //   let newData = [...oldData, project];
  //   setData(newData);
  // };

  const handleCloseProjectClick = () => {
    setSelectedProject({});
    setSelectedProjectModalOpen(false);
  };

  // const showDeleteConfirm = (project) => {
  //   setShowDeleteConfirm(true);
  //   setSelectedProject(project);
  // };

  const handleCancelDeleteProjectClick = () => {
    // e.preventDefault();
    setSuccess(true);
    setDeletionEvent(null);
    setIdToDelete(null);
    setShowDeleteConfirm(false);
  };

  const handleDeleteProjectClick = (e, id) => {
    console.log("handleDeleteProjectClick", id);
    setShowDeleteConfirm(true);
    setDeletionEvent(e);
    setIdToDelete(id);
  };

  const showSolicitationDetails = (project, item) => {
    let projectId = project._id;
    let solicitationId = item._id;
    console.log("Opening solicitation details", item);
    history.push({
      // pathname: "projects/project-details/solicitation-details",
      pathname:
        "/projects/project-details/" +
        projectId +
        "/solicitation-details/" +
        solicitationId,
      state: { project: project, solicitation: item },
    });
  };

  const deleteProject = async (e) => {
    console.log("deleteProject", idToDelete);
    // setDeleteConfirmed(true);
    deletionEvent.preventDefault();
    console.log("Delete Project", idToDelete);
    const deleted = await Projects.delete(idToDelete);

    if (deleted) {
      //remove from data
      let oldData = data;
      let newData = oldData.filter((item) => item._id !== idToDelete);
      setData(newData);
      setSuccess(true);
      setDeletionEvent(null);
      setIdToDelete(null);
      setShowDeleteConfirm(false);
    }
  };

  // const showSolicitationDetails = (project, item) => {
  //   console.log("Opening solicitation details", item);
  //   history.push({
  //     pathname: "projects/project-details/solicitation-details",
  //     state: { project: project, solicitation: item },
  //   });
  // };

  return (
    // Page Container
    <div id="page_container" className="w-full max-w-14xl mx-auto h-full">
      {/* Header area */}
      {/* {!fromModal && (
        <div
          id="header_row"
          className="sm:flex sm:justify-between sm:items-center"
        >
          <div className="mb-0 sm:mb-0 inline-flex ">
            <PageHeader>Projects</PageHeader>
          </div>

          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            <Button
              color="secondary"
              handleClick={() => handleOpenNewProjectModalClick()}
              // shadow
            >
              <svg
                className="flex w-3 h-3 fill-current opacity-90 shrink-0"
                viewBox="0 0 16 16"
              >
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
              </svg>
              <span className="inline-flex hidden xs:block ml-2">
                New Project
              </span>
            </Button>
          </div>
        </div>
      )} */}
      <div
        id="main_card"
        className={`${
          !fromModal ? "h-[calc(100vh-120px)] shadow-xl" : "h-full"
        } mt-4 bg-white rounded-sm border border-gray-200  w-full`}
      >
        {!fromModal && (
          <div id="card_header" className="px-6  ">
            <div className=" w-full flex items-center justify-between  ">
              <div>
                <header className=" py-4">
                  <h2 className="text-xl font-semibold text-gray-800">
                    Projects{" "}
                    <span className="text-md text-gray-400 font-medium">
                      {data && data.length}
                    </span>
                  </h2>
                </header>
              </div>
              <div></div>
            </div>
            <div className="border-b border-gray-200 flex items-center justify-between pb-4">
              <Tabs
                tabs={[
                  {
                    name: "All",
                    href: "#",
                    current: true,
                  },
                  {
                    name: "Open",
                    href: "#",
                    current: true,
                  },
                  {
                    name: "Closed",
                    href: "#",
                    current: true,
                  },
                ]}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
              />
              <div>
                {" "}
                <Button
                  color="secondary"
                  handleClick={() => handleOpenNewProjectModalClick()}
                  // shadow
                >
                  <svg
                    className="flex w-3 h-3 fill-current opacity-90 shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="inline-flex hidden xs:block ml-2">
                    New Project
                  </span>
                </Button>{" "}
              </div>
            </div>
          </div>
        )}

        <ListTable
          fromModal={fromModal}
          selectedRow={null}
          showDelete={!fromModal}
          setSelectedRow={null}
          dedupe={false}
          columns={[]}
          profile={null}
          competencies={[]}
          updatedId={null}
          updateMyData={null}
          setUpdatedId={null}
          skipReset={null}
          updateData={null}
          initialData={data} //this could also be unclaimedImpediments
          data={filteredData} //this could also be unclaimedImpediments
          page_size={10}
          size_limit={10}
          fetch_limit={10}
          loading={false}
          fromDashboard={false}
          showFooter={false}
          toggleValue={false}
          hasSubComponent={false}
          onToggleChange={(value) => {
            console.log("Toggle", value);
          }}
          navigateToProject={(item) => {
            fromModal
              ? props.handleOpenProjectClick(item)
              : navigateToProject(item);
          }}
          handleDeleteProjectClick={(e, item) =>
            handleDeleteProjectClick(e, item)
          }
        />
      </div>

      {/* {showDeleteConfirm && ( */}
      <DialogBox
        handleConfirmClick={deleteProject}
        handleCancelClick={handleCancelDeleteProjectClick}
        loading={false}
        success={success}
        open={showDeleteConfirm}
        title="Confirm Project Deletion"
        message={"Are you sure you want to delete this project?"}
        OKbuttonText={"Delete"}
        CancelButton={"Cancel"}
      />

      <ModalBasic
        id="assessment-modal"
        modalOpen={newProjectModalOpen}
        setModalOpen={setNewProjectModalOpen}
        title="Create a new project"
        showClose={false}
        showFooter={false}
        closeButtonText="Close"
        // size="lg"
        scrollable={true}
      >
        <div className="flex flex-col h-full items-center px-10 pt-3 pb-0">
          <NewProject
            setModalOpen={setNewProjectModalOpen}
            modalOpen={newProjectModalOpen}
            updateData={handleAfterCreateProject}
            step={newProjectStep}
            fromModal={"projects"}
            handleCreateNewSolicitation={handleCreateNewSolicitation}
          />
        </div>
      </ModalBasic>
      <ModalBasic
        id="new-solicitation-model"
        modalOpen={newSolicitationModalOpen}
        setModalOpen={setNewSolicitationModalOpen}
        title="Create New Bid Request"
        showClose={false}
        showFooter={false}
        closeButtonText="Close"
        // size="lg"
        scrollable={true}
      >
        <div className="flex flex-col h-full items-center px-10 pt-3 pb-0">
          <NewSolicitation
            projectId={newSolicitationData?._id}
            project={newSolicitationData}
            setModalOpen={setNewSolicitationModalOpen}
            modalOpen={newSolicitationModalOpen}
            handleCloseButtonClick={(project, solicitation) =>
              showSolicitationDetails(project, solicitation)
            }
            updateData={null}
            stepOptions={4}
            step={0}
          />
        </div>
      </ModalBasic>
      <ModalBasic
        id="project-details-modal"
        modalOpen={selectedProjectModalOpen}
        setModalOpen={setSelectedProjectModalOpen}
        title="Create a new project"
        showClose={true}
        showFooter={false}
        closeButtonText="Close"
        size="lg"
        scrollable={true}
      >
        {/* <div className="flex items-center p-10"> */}
        <ProjectDetails project={selectedProject} />
        {/* </div> */}
      </ModalBasic>
    </div>
  );
}

import React from "react";
import { useRef } from "react";
//import usehistory from "react-router-dom";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import Skeleton from "../../components/Skeleton";
import { NavLink } from "react-router-dom";
import RightActionArea from "../../components/ActionAreaRight";
import { tailwindConfig, hexToRGB } from "../../utils/Utils";
import { utils } from "../../modules/_utils";
import Tip from "../../components/Tip";
function AnalyticsCard05(props) {
  const title = props.title;
  const rowspan = props.rowSpan;
  const colSpan = props.colSpan;
  const ref = useRef(null);
  const data = props.data;
  const upper = props.uppercase;
  const loading = props.loading;
  const fullData = props.fullData;
  const projects = props.projects;
  const solicitations = props.solicitations;
  const history = useHistory();
  console.info("AnalyticsCard05 DATA", title, data);
  console.log("AnalyticsCard05 fullData", fullData);

  const getWidth2 = (val) => {
    const highVal = data[0][1];
    const lowVal = data[data.length - 1][1];
    let maxW = 0;
    if (ref.current) {
      maxW = ref.current.offsetWidth;
    } else {
      maxW = 800;
    }
    let width = ((val + 5 - lowVal) / (highVal - lowVal + 5)) * maxW;
    // let width = (maxW * val) / highVal;
    width = (width / maxW) * 100 + "%";

    return width;
  };
  const handleListItemClick = (index) => {
    if (props.projects) {
      handleNavigateToProject(index);
    } else if (props.solicitations) {
      handleNavigateToSolicitation(index);
    }
  };
  const handleNavigateToProject = (index) => {
    let project = props.projects ? props.projects[index] : null;
    history.push({
      pathname: "/projects/project-details/" + project._id,
      state: { project: project },
    });
  };
  const handleNavigateToSolicitation = (index) => {
    let project = props.solicitations
      ? props.solicitations[index].projectId
      : null;
    let solicitation = props.solicitations ? props.solicitations[index] : null;
    history.push({
      // pathname: "projects/project-details/solicitation-details",
      pathname:
        "/projects/project-details/" +
        project._id +
        "/solicitation-details/" +
        solicitation._id,
      state: { project: project, solicitation: solicitation },
    });
  };
  // const getWidth = (val, title) => {
  //   let highVal = data[0][1];

  //   const lowVal = data[data.length - 1][1];
  //   console.info("width val title", val, title);
  //   if (title === "High burden competencies") {
  //     return (val / 100) * 100 + "%";
  //   } else if (title === "Top Teams") {
  //     return (val / 10) * 100 + "%";
  //   }
  //   // console.info("width - ref", ref.current.offsetWidth);

  //   // console.info("width ref.current", ref.current);
  //   const maxW = ref.current ? ref.current.offsetWidth : 1000;
  //   // console.info("width ALL VALS ==>", maxW, val, highVal, lowVal);
  //   // let width = Math.log(maxW) * (Math.log(val) / Math.log(highVal));
  //   // highVal = highVal > 1 ? 1 : highVal;
  //   let width = ((val + 5 - lowVal) / (highVal - lowVal + 5)) * maxW;

  //   // let width = (maxW * val) / 100;
  //   console.info("width", width);
  //   width = (width / maxW) * 100 + "%";

  //   console.info("GET WIDTH", width);
  //   return width;
  // };

  const highVal = data[0][1];
  console.log("highVal", highVal);

  const getWidthDate = (val) => {
    const today = new Date();
    const endDate = new Date(val);
    if (today > endDate) {
      return "100%";
    }
    const diffTime = Math.abs(endDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log("width diffDays", title, diffDays);

    const maxDays = 30;
    const remainingDays = maxDays - diffDays;
    const percent = (remainingDays / maxDays) * 100;
    console.log("width percent", percent);

    const maxW = ref.current ? ref.current.offsetWidth : 100;
    console.info("width maxW", maxW);
    // let width = Math.log(maxW) * (Math.log(val) / Math.log(highVal));
    let width = percent + "%";
    // console.info("width", width);
    // width = (width / maxW) * 100 + "%";
    return width;
  };

  const getWidth = (val, title) => {
    console.info("width passed", val, title);

    if (highVal > 100) {
      //reduce to 100 by percent
      var percDiff = Math.abs((highVal - 100) / ((highVal + 100) / 2));

      let originalVal = val;
      val = originalVal - originalVal * percDiff;
      console.log(
        "highval > 100",
        "oringal val",
        originalVal,
        "percDiff",
        percDiff,
        "new val",
        val
      );
    }

    const maxW = ref.current ? ref.current.offsetWidth : 100;
    console.info("width maxW", maxW);
    // let width = Math.log(maxW) * (Math.log(val) / Math.log(highVal));
    let width = (maxW * val) / 100;
    console.info("width", width);
    width = (width / maxW) * 100 + "%";

    console.info("GET WIDTH", width);
    return width;
  };

  const getColor = (val, title) => {
    const today = new Date();
    const endDate = new Date(val);
    if (today > endDate) {
      return "bg-red-200";
    } else {
      return "bg-secondary-200";
    }
  };

  return (
    <div
      className={`row-span-${rowspan} max-h-96 col-span-full sm:col-span-6 md:col-span-${colSpan}  lg:col-span-${colSpan} xl:col-span-${colSpan}  `}
    >
      <div
        className={`flex h-full flex-col grow-0 bg-white shadow-lg rounded-sm border border-gray-200`}
      >
        <header className="flex grid grid-flow-col px-5 py-4 border-b border-gray-100">
          <h2 className="font-semibold text-slate-800">{props.title}</h2>
          <RightActionArea>
            <div className=" text-sm font-semibold text-accent-600">
              {/* <NavLink className="" to="/assessments">
              See All -&gt;
            </NavLink>{" "} */}
            </div>
          </RightActionArea>
        </header>
        {loading ? (
          <Skeleton type="spinner" color="gray" fill="purple" />
        ) : (
          <div className="grow p-3 h-full overflow-hidden">
            <div className="flex flex-col h-full overflow-hidden">
              <div className="grow h-full overflow-hidden">
                <ul className="flex justify-between text-xs uppercase text-slate-400 font-semibold px-2 space-x-2">
                  <li>{props.tableTitle ? props.tableTitle : "Name"}</li>
                  <li>{props.tableValue ? props.tableValue : "Total"}</li>
                </ul>

                <ul className="h-full overflow-y-auto scrollbar-default space-y-1 text-sm text-slate-800 mt-3 mb-4">
                  {/* Item */}
                  {data.length > 0 && data !== "loading" ? (
                    data.map((item, index) => {
                      const key = item[0];
                      const val = item[1];
                      // IF VAL IS NOT EMPTY RETURN
                      if (val === null || val === undefined) {
                        return null;
                      } else {
                        return (
                          <li
                            id="progress"
                            ref={ref}
                            className="relative px-2 py-1 cursor-pointer  "
                            key={index}
                            onClick={() => handleListItemClick(index)}
                          >
                            <div
                              className={`absolute inset-0 ${getColor(
                                val,
                                props.title
                              )} opacity-30  rounded-md transition ease-in-out duration-150`}
                              aria-hidden="true"
                              style={{
                                width: getWidthDate(val, props.title),
                              }}
                            ></div>
                            <div className="relative flex justify-between space-x-2">
                              <div
                                className={`${
                                  upper == true ? "uppercase" : null
                                } `}
                              >
                                {key}{" "}
                                {getColor(val, props.title) == "bg-red-200" ? (
                                  <span className="ml-2 text-xs text-red-500">
                                    Past Due
                                  </span>
                                ) : null}
                              </div>
                              <div className="font-medium">
                                {utils.formatDate(val, "short")}
                              </div>
                              {/* <div className="font-medium">{val2}</div> */}
                            </div>
                          </li>
                        );
                      }
                    })
                  ) : (
                    <Tip>
                      Not seeing what you're looking for? Be sure to use the
                      Deadline field when creating your projects and bid
                      requests.
                    </Tip>
                  )}
                </ul>
              </div>

              <div className="text-center pt-4 pb-1 border-t border-gray-100">
                {/* <Link
              className="text-sm font-medium text-primary-500 hover:text-primary-600"
              to="#0"
            >
              See All -&gt;
            </Link> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AnalyticsCard05;

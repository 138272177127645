import React from "react";
import DashboardCard10 from "../../../partials/dashboard/DashboardCard10";
import { useState } from "react";
import { useEffect } from "react";
import { _dashboard } from "../../../modules/_dashboard";
import { assessments } from "../../../modules/assessments";
import DashboardCard01 from "../../../partials/dashboard/DashboardCard01";
import RiskAssessmentHistoryCard from "../../../partials/dashboard/RiskAssessmentHistoryCard";
import Tabs from "../../component/Tabs";

function ProfileBody({ profileSidebarOpen, setProfileSidebarOpen, ...props }) {
  const data = props.data;

  // const profile = history.location.state.profile;

  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [showHome, setShowHome] = useState(false);
  const [assessmentHistory, setAssessmentHistory] = useState([]);
  // const [rotCardData, setRotCardData] = useState([]);
  // const [topAssessmentTakers, setTopAssessmentTakers] = useState([]);
  const [topAssessments, setTopAssessments] = useState([]);
  const [todaysHistory, setTodaysHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [gettingAssessment, setGettingAssessment] = useState(true);
  const [employeeData, setEmployeeData] = useState(props.employeeData);
  const [avgRisk, setAvgRisk] = useState();
  const [riskCounts, setRiskCounts] = useState();
  const asmt = props.assessmentHistory;
  useEffect(() => {
    // (async () => {
    setLoading(true);
    let response = props.assessmentHistory;

    setAssessmentHistory(response);

    // setRotCardData(_dashboard.convertDates(response));

    // setTopAssessmentTakers(assessments.getTotals(response, "taken_by", true));
    // setTopAssessments(
    //   assessments.getTotals(response, "assessment_type", true)
    // );
    // setTodaysHistory(_dashboard.todaysHistory(response));
    setRiskCounts(_dashboard.getCountOfEach(response));

    setAvgRisk(_dashboard.calculateAverageRisk(response));
    setLoading(false);
    // })();
  }, [asmt]);

  const todaysHistoryCount = todaysHistory.length;
  const unfinishedToday = _dashboard.getUnfinishedToday(todaysHistory);
  const unFinishedAssessments =
    _dashboard.getAllUnfinishedAssessments(assessmentHistory);

  const charts = [
    {
      title: "Assessments",
      component: (
        <DashboardCard01
          loading={loading}
          data={loading == true ? "loading" : assessmentHistory.length}
          title="Assessments taken"
          colSpan={3}
          rowSpan={1}
          unFinishedAssessments={
            unFinishedAssessments.length !== 0
              ? unFinishedAssessments.length
              : null
          }
        />
      ),
    },
    {
      title: "Today's Assessments",

      component: (
        <DashboardCard01
          loading={loading}
          data={loading == true ? "loading" : todaysHistory.length}
          title={"Taken today"}
          colSpan={3}
          rowSpan={1}
          unFinishedAssessments={
            unfinishedToday.length != 0 ? unfinishedToday.length : null
          }
        />
      ),
    },
    {
      title: "Average Risk Score",

      component: (
        <DashboardCard01
          loading={loading}
          data={loading == true ? "loading" : avgRisk}
          title="Average Risk Score"
          colSpan={3}
          rowSpan={1}
          scores={riskCounts}
        />
      ),
    },
    {
      title: "Top Assessment",

      component: (
        <DashboardCard01
          loading={loading}
          data={
            loading == true
              ? "loading"
              : topAssessments.length > 0
              ? topAssessments.slice(0, 1)[0][0].toUpperCase()
              : []
          }
          title="Top Assessment"
          colSpan={3}
          rowSpan={1}
          total={topAssessments.length > 0 ? topAssessments[0][1] : "loading"}
        />
      ),
    },
  ];

  const tabs = [
    { name: "Basic Info", href: "#", current: true },
    { name: "History", href: "#", current: false },
    { name: "Reputation", href: "#", current: false },
  ];

  return (
    <>
      {/* Profile background */}
      <div className="relative h-5  py-3 ">
        {/* Close button */}
        <button
          className="md:hidden absolute top-4 left-4 sm:left-6 text-white opacity-80 hover:opacity-100"
          onClick={() => setProfileSidebarOpen(!profileSidebarOpen)}
          aria-controls="profile-sidebar"
          aria-expanded={profileSidebarOpen}
        >
          <span className="sr-only">Close sidebar</span>
          <svg
            className="w-6 h-6 fill-current"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
          </svg>
        </button>
      </div>

      {/* Content */}
      <div className="relative px-4 sm:px-6 pb-8 ">
        {/* Pre-header */}

        {/* Header */}
        <header className="text-center sm:text-left mb-6">
          {/* Name */}
          <div className="inline-flex items-start mb-2">
            <h1 className="text-2xl text-slate-800 font-bold">{data.Name}</h1>
          </div>
          {/* Bio */}
          <div className="text-sm mb-3">{data.Description}</div>
          {/* Meta */}
          <div className="flex flex-wrap justify-center sm:justify-start space-x-4">
            <div className="flex items-center">
              <svg
                className="w-4 h-4 fill-current shrink-0 text-slate-400"
                viewBox="0 0 16 16"
              >
                <path d="M8 8.992a2 2 0 1 1-.002-3.998A2 2 0 0 1 8 8.992Zm-.7 6.694c-.1-.1-4.2-3.696-4.2-3.796C1.7 10.69 1 8.892 1 6.994 1 3.097 4.1 0 8 0s7 3.097 7 6.994c0 1.898-.7 3.697-2.1 4.996-.1.1-4.1 3.696-4.2 3.796-.4.3-1 .3-1.4-.1Zm-2.7-4.995L8 13.688l3.4-2.997c1-1 1.6-2.198 1.6-3.597 0-2.798-2.2-4.996-5-4.996S3 4.196 3 6.994c0 1.399.6 2.698 1.6 3.697 0-.1 0-.1 0 0Z" />
              </svg>
              <span className="text-sm font-medium whitespace-nowrap text-slate-500 ml-2">
                {data.Location ? data.Location : "Miami, FL"}
              </span>
            </div>
            <div className="flex items-center">
              <svg
                className="w-4 h-4 fill-current shrink-0 text-slate-400"
                viewBox="0 0 16 16"
              >
                <path d="M11 0c1.3 0 2.6.5 3.5 1.5 1 .9 1.5 2.2 1.5 3.5 0 1.3-.5 2.6-1.4 3.5l-1.2 1.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l1.1-1.2c.6-.5.9-1.3.9-2.1s-.3-1.6-.9-2.2C12 1.7 10 1.7 8.9 2.8L7.7 4c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l1.2-1.1C8.4.5 9.7 0 11 0ZM8.3 12c.4-.4 1-.5 1.4-.1.4.4.4 1 0 1.4l-1.2 1.2C7.6 15.5 6.3 16 5 16c-1.3 0-2.6-.5-3.5-1.5C.5 13.6 0 12.3 0 11c0-1.3.5-2.6 1.5-3.5l1.1-1.2c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L2.9 8.9c-.6.5-.9 1.3-.9 2.1s.3 1.6.9 2.2c1.1 1.1 3.1 1.1 4.2 0L8.3 12Zm1.1-6.8c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4.2 4.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l4.2-4.2Z" />
              </svg>
              <a
                className="text-sm font-medium whitespace-nowrap text-primary-500 hover:text-primary-600 ml-2"
                href="#0"
              >
                {data.website ? data.website : "https://www.mysite.com"}
              </a>
            </div>
          </div>
          <div className="relative my-6">
            <Tabs tabs={tabs} />
          </div>
        </header>

        {/* Content */}
        <div className="flex flex-col xl:flex-row xl:space-x-16">
          {/* Main content */}
          <div className="space-y-5 mb-8 xl:mb-0 w-full">
            {/* About Me */}
            <div>
              {/* <h2 className="text-slate-800 font-semibold mb-2">Overview</h2> */}
              <div className="grid grid-cols-12 gap-6 space-y-2">
                <p className="prose">{data.Description}</p>
              </div>
            </div>

            {/* Work History */}
            <div></div>
          </div>

          {/* Sidebar */}
        </div>
      </div>
    </>
  );
}

export default ProfileBody;

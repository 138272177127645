import { Fragment, useState } from "react";
import { useEffect } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
// import { XMarkIcon } from "@heroicons/react/20/solid";

export default function ToastNotification({ socket }, ...props) {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({
    type: "success",
    text: "Success",
  });
  const [showNotification, setShowNotification] = useState(true);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (socket !== undefined) {
      console.log("Socket", socket);
      socket.on("import-success", function () {
        console.log("Import complete, do something");
        setShow(true);
        setNotification({
          title: "Import Successful",
          type: "successs",
          text: "Import was successful. you can go back to the Assessment to see the new questions.",
        });
        console.log("Import complete, did something");
      });
      socket.on("import-error", function () {
        console.log("Import complete, do something");
        setShow(true);
        setNotification({
          title: "Import Failed",
          type: "failure",
          text: "The import failed after uploading.",
        });
        console.log("Import complete, failed");
      });
    }
  }, [socket]);

  const typeColor = (type) => {
    switch (type) {
      case "warning":
        return "text-yellow-500";
      case "error":
        return "text-red-500";
      case "unsure":
        return "text-red-500";
      case "success":
        return "text-green-500";
      default:
        return "text-primary-500";
    }
  };

  const typeIcon = () => {
    switch (type) {
      case "error":
        return (
          <XMarkIcon
            className={`"h-6 w-6" ${typeColor(type)}"`}
            aria-hidden="true"
          />
        );
      case "success":
        return (
          <CheckCircleIcon
            className={`"h-6 w-6" text-primary-500}"`}
            aria-hidden="true"
          />
        );

      default:
        return (
          <CheckCircleIcon
            className={`"h-6 w-6 text-primary-500"`}
            aria-hidden="true"
          />
        );
    }
  };

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed w-full inset-12 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div
          className="px-14
        flex w-full flex-col items-center space-y-4 sm:items-end"
        >
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">{typeIcon("success")}</div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-slate-900">
                      {notification.title}
                    </p>
                    <p className="mt-1 text-sm text-slate-500">
                      {notification.text}
                    </p>
                  </div>
                  <div className=" ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-slate-400 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}

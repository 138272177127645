import React, { useEffect, useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import FilterButton from "../components/DropdownFilter";
import Datepicker from "../components/Datepicker";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import DashboardCard01 from "../partials/dashboard/DashboardCard01";
import DashboardCard02 from "../partials/dashboard/DashboardCard02";
import DashboardCard03 from "../partials/dashboard/DashboardCard03";
import DashboardCard04 from "../partials/dashboard/DashboardCard04";
import DashboardCard05 from "../partials/dashboard/DashboardCard05";
import DashboardCard06 from "../partials/dashboard/DashboardCard06";
import DashboardCard07 from "../partials/dashboard/DashboardCard07";
import DashboardCard08 from "../partials/dashboard/DashboardCard08";
import DashboardCard09 from "../partials/dashboard/DashboardCard09";
import DashboardCard10 from "../partials/dashboard/DashboardCard10";
import DashboardCard11 from "../partials/dashboard/DashboardCard11";
import RiskOverTimeCard from "../partials/dashboard/RiskOverTimeCard";
import AnalyticsCard05 from "../partials/dashboard/AnalyticsCard05";
import RiskAssessmentHistoryCard from "../partials/dashboard/RiskAssessmentHistoryCard";
import { _assessment } from "../modules/_assessment";
import { assessments } from "../modules/assessments";

import { ChartBarSquareIcon } from "@heroicons/react/20/solid";
import { utils } from "../modules/_utils";
import { _auth } from "../modules/_auth";
import { audit } from "../modules/_audit";
import { useStore } from "../store/usestore";
import Home from "./Home";
import Skeleton from "../components/Skeleton";
import { _dashboard } from "../modules/_dashboard";
import ModalBasic from "../components/ModalBasic";

import AssessmentSelect from "./AssessmentSelect";
import { ContactModal } from "../components/ContactModal";
import ContactConfirm from "../components/ContactConfirm";
import Steps from "../components/Steps";
import { _impediments } from "../modules/impediments";
import { _competencies } from "../modules/competencies";
import DashboardMaturityCard from "../partials/dashboard/DashboardMaturityCard";
import Button from "../elements/Button";
import Card from "./component/Card";
import Stats from "../components/Stats";
import AnalyticsCard01 from "../partials/analytics/AnalyticsCard01";
import Leaderboard from "../partials/dashboard/Leaderboard";
import Banner from "../components/Banner";
import NotificationBanner from "../components/NotificationBanner";
import NewProject from "./projects/NewProject";
import { Projects } from "../modules/_projects";
import { Solicitations } from "../modules/_solicitations";
import IntroPage from "./home/Home";
import SolicitationList from "./projects/SolicitationList";
import { isNonNullExpression } from "typescript";
import BidList from "./projects/BidList";
import { Bids } from "../modules/_bids";
import RightActionArea from "../components/ActionAreaRight";
import PageHeader from "../components/PageHeader";
import NewSolicitation from "./projects/NewSolicitation";
import { set } from "date-fns";
import RadioOptionButtonsLarge from "../components/RadioOptionButtonsLarge";
import NewSolicitationShortcut from "./projects/components/NewSolicitationShortcut";
import BidCompare from "./projects/components/BidCompare";
import VendorDashboardComponent from "../components/VendorDashboardComponent";
import SolicitationsPage from "./projects/SolicitationsPage";
import Tip from "../components/Tip";
import MenuTag from "../components/MenuTag";

//test change

function Dashboard() {
  const location = useLocation();
  const history = useHistory();

  // const manageLoading = utils.manageLoading();
  // const isLoading = utils.isLoading();

  const { userAssessmentHistory, isSuccess, getUserAssessmentHistory } =
    useStore();
  // const profile = history.location.state.profile;
  const [showHome, setShowHome] = useState(true);
  const { pathname } = location;

  const user = _auth.getUserProfile();

  // OlD Dashboard

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [assessmentHistory, setAssessmentHistory] = useState([]);
  const [rotCardData, setRotCardData] = useState([]);
  const [topAssessmentTakers, setTopAssessmentTakers] = useState([]);
  const [topAssessments, setTopAssessments] = useState([]);
  const [todaysHistory, setTodaysHistory] = useState([]);

  const [gettingAssessment, setGettingAssessment] = useState(true);
  const [auth, setAuth] = useState(false);
  const [avgRisk, setAvgRisk] = useState();
  const [riskCounts, setRiskCounts] = useState();
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [viewAllSolicitationsModalOpen, setViewAllSolicitationsModalOpen] =
    useState(false);
  const [
    newSolicitationShortcutModalOpen,
    setNewSolicitationShortcutModalOpen,
  ] = useState(false);
  const [newSolicitationModalOpen, setNewSolicitationModalOpen] =
    useState(false);
  const [newSolicitationData, setNewSolicitationData] = useState(null);

  const [competencies, setCompetencies] = useState([]);
  const [impediments, setImpediments] = useState([]);
  const [score, setScore] = useState(0);
  const [effort, setEffort] = useState(0);
  const [maturityScore, setMaturityScore] = useState(0);
  const [maturityLevel, setMaturityLevel] = useState(0);
  const [topImpedimentsByScore, setTopImpedimentsByScore] = useState([]);
  const [topCompetenciesByScore, setTopCompetenciesByScore] = useState([]);
  const [topImpedimentsByEffort, setTopImpedimentsByEffort] = useState([]);
  const [topCompetenciesByEffort, setTopCompetenciesByEffort] = useState([]);
  const [breakEven, setBreakEven] = useState(0);
  const [burdenSummary, setBurdenSummary] = useState({});
  const [allCompetenciesByScore, setAllCompetenciesByScore] = useState([]);
  const [burndown, setBurndown] = useState([]);
  const [resolvedAndRemaining, setResolvedAndRemaining] = useState([]);

  const [sweetSpotCompetencies, setSweetSpotCompetencies] = useState([]);
  const [competencyLeaderBoard, setCompetencyLeaderBoard] = useState([]);
  const [tenantType, setTenantType] = useState("");
  const [showBanner, setShowBanner] = useState(
    localStorage.getItem("Show Dashboard Banner") !== "false" ? true : false
  );

  // New Dashboard
  const [projects, setProjects] = useState(null);
  const [solicitations, setSolicitations] = useState(null);
  const [activeSoliciations, setActiveSolicitations] = useState([]);
  const [projectsDueSoon, setProjectsDueSoon] = useState([]);
  const [projectsPastDue, setProjectsPastDue] = useState([]);
  const [projectsDueAndPastDue, setProjectsDueAndPastDue] = useState([]);
  const [topFiveDeadlines, setTopFiveDeadlines] = useState([]);
  const [loading, setLoading] = useState([]);
  const [upcomingExpirations, setUpcomingExpirations] = useState([]);
  const [topFiveExpirations, setTopFiveExpirations] = useState([]);
  const [allBids, setAllBids] = useState([]);
  const [showBidCompareModal, setShowBidCompareModal] = useState(false);
  const [solicitationToShow, setSolicitationToShow] = useState(null);
  const [updated, setUpdated] = useState(false);
  const data = {};

  const manageLoading = (item, bool) => {
    console.log("Manage loading", item, bool, "current loading state", loading);
    //if item is already in loading array and bool is true, do nothing
    if (loading.includes(item) && bool == true) {
      console.log(
        "Manage loading - already in loading array and bool is true",
        loading
      );
      setLoading(item);
    } else if (loading.includes(item) && bool == false) {
      //if item is already in loading array and bool is false, remove it
      let newLoading = loading.filter((i) => i !== item);
      console.log(
        "Manage loading - already in loading array and bool is false",
        loading,
        newLoading
      );
      setLoading(newLoading);
    } else if (!loading.includes(item) && bool == true) {
      //if item is not in loading array and bool is true, add it
      let newLoading = [...loading, item];
      console.log(
        "Manage loading - not in loading array and bool is true, adding",
        loading,
        newLoading
      );

      setLoading(newLoading);
    } else {
      console.log(
        "Manage loading - not in loading array and bool is false",
        loading
      );
      let newLoading = loading.filter((i) => i !== item);
      //if item is not in loading array and bool is false, do nothing
      setLoading(newLoading);
    }
  };

  const isLoading = (item) => {
    if (loading.includes(item)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    console.log("Profile changed, setting tenantType", user.tenantInfo);
    setTenantType(user.tenantInfo.type);
  }, [user]);

  useEffect(() => {
    (async () => {
      let auth = await _auth.getAuth();
      if (auth) {
        <Redirect to="/" />;
        setAuth(true);
      } else {
        setAuth(false);
      }
    })();
    const user = _auth.getUserProfile();
    console.log("user", user);
    if (user) {
      setShowHome(user.showHome);
      console.log("showHome", showHome);
    }
    console.info("DASHBOARD USER -->", user);
  }, []);

  //get all dashboard data

  //get data for the dashboard
  const calcBurdenSummary = (data) => {
    let summary = {};
    let effortDays = data.effort;
    let effortHours = effortDays * 8;
    let effortWeeks = effortDays / 5;
    let effortHoursPerWeek = effortHours / effortWeeks;
    let remainingWeeks = 52 - effortWeeks;
    let score = data.score;

    // console.log("Effort Breakdown", {
    //   effortDays,
    //   effortHours,
    //   effortWeeks,
    //   effortHoursPerWeek,
    // });

    let scoreDaysPerYear = (score * 52) / 8;
    let scoreDaysPerWeek = score / 52;
    let scoreHoursPerDay = score / 5;
    let scoreHoursPerYear = score * 52;

    let personHoursPerWeek = 40;
    let personHoursPerYear = personHoursPerWeek * 52;

    // console.log("Score Breakdown", {
    //   score,
    //   scoreDaysPerYear,
    //   scoreDaysPerWeek,
    //   scoreHoursPerDay,
    //   scoreHoursPerYear,
    // });

    let effortSummary =
      data.Name +
      " The yearly current cost is " +
      scoreHoursPerYear +
      " wasted hours per year, which is " +
      //total wasted days per year
      scoreDaysPerYear +
      " days per year." +
      " If you work 40 hours a week for " +
      effortWeeks +
      " weeks, you will free up " +
      score +
      " hours per week of burden, which is " +
      scoreHoursPerYear +
      " hours per year. That equates to " +
      scoreDaysPerWeek +
      " days per week.";

    console.log("Effort Summary: ", effortSummary);

    let scoreSummary =
      data.name +
      ": Score Breakdown Summary:  " +
      "After clearing the burden, " + // the first X weeks of "no burden" will recoup your effort.  After that, you will have X weeks remaining in the year
      "you will have " +
      52 -
      effortWeeks +
      " weeks remaining in year that started when you begain eliminating the burden. " +
      "The remaining weeks will not have the burden, saving you " +
      score +
      " hours per week for the remaining " +
      remainingWeeks +
      " weeks. That equates to " +
      remainingWeeks / 5 / 8 +
      " days per week.";
    console.log("Burden Summary: ", scoreSummary);

    summary.Name = data.Name;
    summary.Effort = data.effort;
    summary.Score = data.score;

    let hoursWastedDuringElimination = score * effortWeeks;
    let hoursRequiredToBreakEven =
      effortHoursPerWeek * effortWeeks + hoursWastedDuringElimination;

    let breakEvenPointInWeeks = hoursRequiredToBreakEven / 8 / 5;

    let burdenSummaryObject = {
      "Hours per week wasted": score,
      "Effort in days": effortDays,
      // "Hours per week to eliminate waste": effortHoursPerWeek,
      "Weeks of effort to eliminate waste": effortWeeks,
      "Total hours spent to eliminate waste": effortHours,
      "Total wasted hours during elimination": hoursWastedDuringElimination,
      "Hours required to break even": hoursRequiredToBreakEven,
      "Break even point in weeks": breakEvenPointInWeeks,
      "First year savings (days)":
        //savings is score * remaining weeks after break even
        (score * (remainingWeeks - breakEvenPointInWeeks)) / 8,
      "Future year savings (days)": (score * 52) / 8,
    };

    // let burdenSummaryObject = {
    //   "Hours per week wasted": score,
    //   "Hours per week to eliminate waste": effortHoursPerWeek,
    //   "Number of weeks to eliminate waste": effortWeeks,
    //   "Total wasted hours during elimination": score * effortWeeks,
    //   "Total hours spent to eliminate waste": effortHours,
    //   "Break even point": (score * effortWeeks) / score,
    //   "First year savings":
    //     //savings is score * remaining weeks after break even
    //     (score * (remainingWeeks - 1.6)) / 8,
    // };
    const str =
      "{\n" +
      Object.getOwnPropertyNames(burdenSummaryObject)
        .map((key) => `  ${key}: ${burdenSummaryObject[key]}`)
        .join("\n") +
      "\n}";
    // console.log(str);
    console.log("Burden Summary Object", str);

    summary.calculations = burdenSummaryObject;

    // summary.EffortBreakdown = {
    //   effort: effortDays,
    //   effortDaysPerYear: effortDays,
    //   remainingWeeks: 52 - effortWeeks,
    // };
    // summary.ScoreBreakdown = {
    //   score: score,
    //   yearlyHourWaste: scoreHoursPerYear,
    //   yearlyDayWaste: scoreDaysPerYear,
    //   remainingWeeksSavings: remainingWeeks / 5 / 8,
    // };

    console.log("Summary", summary);

    return summary;
  };
  const get2DArray = (list, d1, d2, d3) => {
    console.log("get2DArray", list, d1, d2, d3);
    let array = [];
    list.forEach((item) => {
      array.push([item[d1], item[d2]]);
    });
    console.log("get2DArray", array);
    return array;
  };

  const getBidListComponent = () => {
    return (
      <BidList
        onItemClick={(project, item, solicitation) => {
          handleNavigateToSolicitation(item);
        }}
        project={allBids}
        from="dashboard"
        items={
          allBids.length > 0
            ? allBids
            : // .sort((a, b) => {
              //   return (
              //     new Date(b.createdAt) -
              //     new Date(a.createdAt)
              //   );
              // })
              // .slice(0, 10)
              allBids
        }
      />
    );
  };

  const getMaturityLevel = (score) => {
    if (score <= 2) {
      return "Beginner";
    } else if (score <= 3) {
      return "Intermediate";
    } else if (score <= 4) {
      return "Advanced";
    } else if (score <= 5) {
      return "Expert";
    }
    return "No level";
  };
  const calcScoreByArea = (area) => {
    let sum = 0;
    let count = 0;
    competencies.forEach((comp) => {
      if (comp.Area === area) {
        if (comp.isCorrect === true || comp.isCorrect === false) {
          sum += comp.competency_score;
          count++;
        }
      }
    });
    //return sum and count
    return [sum, count];
  };

  useEffect(() => {
    (async () => {
      manageLoading("Get Projects", true);
      console.log("USEFFECT GET Projects", user.tenantInfo);
      let scope = user.tenantInfo.type == "admin" ? "all" : "tenant";
      let projects = await Projects.list(scope, "dashboard");
      // let impedimentList =
      //   user.tenantInfo.type == "admin"
      //     ? await _impediments.getImpediments(true)
      //     : await _impediments.getImpediments();
      if (projects) {
        setProjects(projects);
        manageLoading("Get Projects", false);
      }
    })();
  }, []);

  useEffect(() => {
    console.log("DASHBOARD ==> USE EFFECT, GET SOLICITATIONS", user.tenantInfo);
    (async () => {
      let solicitations = await Solicitations.list(
        user.tenantType === "admin" ? "all" : "tenant"
      );
      if (solicitations) {
        setSolicitations(solicitations);
        //set active solicitations by solicitation.status === "Active" || "Open"
        let active = solicitations.filter(
          (solicitation) =>
            solicitation.status === "Active" || solicitation.status === "Open"
        );
        setActiveSolicitations(active);
      }
    })();
  }, []);

  useEffect(() => {
    //get list of projects with deadlines within 30 days
    let dueSoon = [];
    let overDue = [];
    projects &&
      projects.forEach((project) => {
        let deadline = new Date(project.deadline);
        let today = new Date();
        let diff = deadline - today;
        let days = diff / (1000 * 60 * 60 * 24);
        if (days < 0) {
          overDue.push(project);
        } else if (days <= 10) {
          dueSoon.push(project);
        }
      });
    //sort by deadline
    dueSoon.sort((a, b) => {
      let dateA = new Date(a.deadline);
      let dateB = new Date(b.deadline);
      return dateA - dateB;
    });
    //set state
    console.log("DUE SOON", dueSoon);
    console.log("OVER DUE", overDue);
    setProjectsDueSoon(dueSoon);
    setProjectsPastDue(overDue);
    //combine dueSoon and pastDue into one array
    let combined = [...dueSoon, ...overDue];
    //sort by deadline
    combined.sort((a, b) => {
      let dateA = new Date(a.deadline);
      let dateB = new Date(b.deadline);
      return dateA - dateB;
    });
    setProjectsDueAndPastDue(combined);
  }, [projects]);

  useEffect(() => {
    //get list of projects with deadlines within 30 days
    let dueSoon = [];
    let overDue = [];
    solicitations &&
      solicitations.forEach((solicitation) => {
        let deadline = new Date(solicitation.deadline);
        let today = new Date();
        let diff = deadline - today;
        let days = diff / (1000 * 60 * 60 * 24);
        if (days < 0) {
          overDue.push(solicitation);
        } else if (days <= 10) {
          dueSoon.push(solicitation);
        }
      });
    //sort by deadline
    dueSoon.sort((a, b) => {
      let dateA = new Date(a.deadline);
      let dateB = new Date(b.deadline);
      return dateA - dateB;
    });
    //set state
    console.log("DUE SOON", dueSoon);
    console.log("OVER DUE", overDue);
    //combine dueSoon and pastDue into one array
    let combined = [...dueSoon, ...overDue];
    //sort by deadline
    combined.sort((a, b) => {
      let dateA = new Date(a.deadline);
      let dateB = new Date(b.deadline);
      return dateB - dateA;
    });

    setUpcomingExpirations(combined);
    // setPastExpirations(overDue);
  }, [solicitations]);

  useEffect(() => {
    (async () => {
      manageLoading("Get Bids", true);

      let bids = await Bids.list(
        user.tenantType === "admin" ? "all" : "tenant"
      );
      if (bids) {
        console.log("USEFFECT GET BIDS", bids);
        setAllBids(bids);
        manageLoading("Get Bids", false);
      }
    })();
  }, []);

  const handleNavigateToSolicitation = (item) => {
    let project = item.projectId;
    let solicitation = item.solicitationId;
    history.push({
      // pathname: "projects/project-details/solicitation-details",
      pathname:
        "/projects/project-details/" +
        project._id +
        "/solicitation-details/" +
        solicitation._id,
      state: { project: project, solicitation: solicitation },
    });
  };

  const handleOpenAssessmentModal = (e) => {
    console.log("onclick handleOpenAssessmentModal", e);
    e.preventDefault();

    setAssessmentModalOpen(!assessmentModalOpen);
  };

  const handleOpenAssessmentModalFromHome = (e) => {
    console.log("onclick handleOpenAssessmentModalFromHome", e);
    e.preventDefault();

    setAssessmentModalOpen(true);
  };
  const handleOpenContactModal = (e) => {
    console.log("onclick handleOpenContactModal", e);
    // e.preventDefault();

    setShowContactModal(e);
  };

  const handleCreateNewSolicitation = (data) => {
    console.log("handleCreateNewSolicitation", data);
    setNewSolicitationData(data);
    setNewSolicitationModalOpen(true);
  };

  const unFinishedAssessments =
    _dashboard.getAllUnfinishedAssessments(assessmentHistory);

  const competencyCharts = [
    {
      title: "Active Projects",
      icon: <ChartBarSquareIcon />,
      component: (
        <DashboardCard01
          loading={isLoading("Get Projects")}
          data={
            isLoading("Get Projects") == true
              ? "loading"
              : projects
              ? projects.length
              : 0
          }
          title={"Active Projects"}
          colSpan={3}
          rowSpan={1}
          unFinishedAssessments={
            //use this to display active projects with active bid requests
            unFinishedAssessments.length !== 0
              ? unFinishedAssessments.length
              : null
          }
        />
      ),
    },

    {
      title: "Active Bid Requests",
      icon: <ChartBarSquareIcon />,
      component: (
        //total score of all unresolved impediments for the team
        <DashboardCard01
          loading={isLoading("Get Soliciations")}
          data={
            isLoading("Get Solicitations") == true
              ? "loading"
              : activeSoliciations
              ? activeSoliciations.length
              : 0
          }
          title="Active Bid Requests"
          subTitle=""
          colSpan={3}
          rowSpan={1}
          effort={null}
          breakEven={null}
          burdenSummary={null}
          showLink={true}
          linkText={"See all"}
          handleLinkClick={() => {
            setViewAllSolicitationsModalOpen(true);
          }}
        />
      ),
    },
    {
      title: "Projects Due Soon",
      icon: <ChartBarSquareIcon />,
      component: (
        <DashboardCard01
          loading={isLoading("Get Projects")}
          data={
            isLoading("Get Projects") == true
              ? "loading"
              : projectsDueSoon
              ? projectsDueSoon.length
              : 0
          }
          title={"Projects Due Soon"}
          colSpan={3}
          rowSpan={1}
          unFinishedAssessments={
            projectsPastDue ? projectsPastDue.length : null
            // unfinishedToday.length != 0 ? unfinishedToday.length : null
          }
        />
      ),
    },
    {
      title: "Upcoming Expirations",
      icon: <ChartBarSquareIcon />,
      component: (
        <DashboardCard01
          data={
            isLoading("GetSolicitations") == true
              ? ""
              : upcomingExpirations
              ? upcomingExpirations.length
              : 0
          }
          title="Expiring Requests"
          colSpan={3}
          rowSpan={1}
          total={0} //topAssessments.length > 0 ? topAssessments[0][1] : "loading"}
        />
        // <DashboardMaturityCard
        //   loading={isLoading("Get Solicitations")}
        //   data={
        //     isLoading("GetSolicitations") == true
        //       ? ""
        //       : upcomingExpirations
        //       ? upcomingExpirations.length
        //       : 0
        //   }
        //   title="Expiring Requests"
        //   colSpan={3}
        //   rowSpan={1}
        //   total={0} //topAssessments.length > 0 ? topAssessments[0][1] : "loading"}
        // />
      ),
    },
  ];

  const handleHideBanner = () => {
    setShowBanner(false);
    let newVal = localStorage.setItem("View all impediments") == "false";
  };

  return (
    <div className="h-full">
      {(showHome == true || showHome == undefined) &&
      !pathname.includes("/dashboard") ? (
        // <div className="flex flex-col items-center justify-center m-auto">
        <IntroPage
          updated={updated}
          handleBidRequestClick={(bids, bid, solicitation) => {
            console.log("handleBidRequestClick", bids, bid, solicitation);
            setSolicitationToShow(solicitation);
            setShowBidCompareModal(true);
          }}
          solicitations={solicitations}
          handleOpenAssessmentModalFromHome={handleOpenAssessmentModalFromHome}
          setNewSolicitationShortcutModalOpen={
            setNewSolicitationShortcutModalOpen
          }
          handleOpenContactModal={handleOpenContactModal}
        />
      ) : (
        <div
          className={`flex h-full overflow-hidden bg-gray-100 divide-x divide-gray-200 mr:0 ${
            user.tenantType !== "vendor" && "lg:mr-[450px]"
          }`}
        >
          <div className="h-full overflow-hidden w-full max-w-9xl mx-auto px-4 ">
            <div className="sm:flex sm:justify-between sm:items-center ">
              {/* Welcome banner */}
              <div className="mb-0 sm:mb-0 inline-flex ">
                <PageHeader>
                  {tenantType == "!admin" && "Company"} Dashboard
                </PageHeader>
              </div>

              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Filter button */}
                {/* <FilterButton align="right" /> */}
                {/* Datepicker built with flatpickr */}
                {/* <Datepicker align="right" /> */}

                {/* <Link to="../assessmentselect"> */}
                {/* {tenantType !== "admin" && (
                <Button
                  color="secondary"
                  handleClick={handleOpenAssessmentModal}
                  shadow
                >
                  <svg
                    className="w-4 h-4 fill-current opacity-50 shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Take Assessment</span>
                </Button>
              )} */}
                {/* </Link> */}
                {/* Add view button */}

                {/* <button className="btn bg-primary-500 hover:bg-primary-600 text-white">
            <svg
              className="w-4 h-4 fill-current opacity-50 shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Add view</span>
          </button> */}
              </div>
            </div>
            <div className="col-span-full">
              {" "}
              <Tip color="emerald">
                Welcome to Breachlink! This dashboard is currently in beta. We
                are looking forward to hearing from you so we can give you the
                data you need. Please don't hesitate to reach out to us.{" "}
              </Tip>{" "}
            </div>
            <div className="sm:flex sm:justify-between sm:items-center ">
              {/* Left: Avatars */}
              {/* The below is only needed to keep the assmt button on right when Avatars are not present */}
              <div className="flex flex-wrap justify-center sm:justify-start mb-8 sm:mb-0 -space-x-3 -ml-px">
                {" "}
              </div>
              {/* {/* <DashboardAvatars />{" "}  */}
              {/* // Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Filter button */}
                {/* <FilterButton align="right" /> */}
                {/* Datepicker built with flatpickr */}
                {/* <Datepicker align="right" /> */}

                {/*Old Assessment Button */}
                {/* Add view button */}

                {/* <button className="btn bg-primary-500 hover:bg-primary-600 text-white">
            <svg
              className="w-4 h-4 fill-current opacity-50 shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Add view</span>
          </button> */}
              </div>
            </div>

            <div className="h-full overflow-hidden  gap-6 sm:auto-cols-max">
              {tenantType == "vendor" ? (
                <VendorDashboardComponent data={[]} get2DArray={get2DArray} />
              ) : (
                //
                <div
                  id="scrollable_container"
                  className="flex min-h-full max-h-full overflow-y-scroll scrollbar-default pb-48 pr-2"
                >
                  <div
                    id="grid"
                    className="flex h-full w-full col-span-full grid grid-cols-12 grow-0 gap-6 sm:auto-cols-max"
                  >
                    {competencyCharts.map((chart, index) => {
                      {
                        return chart.component;
                      }
                    })}
                    {user.tenantInfo.type == "admin" ||
                    user.tenantInfo.type !== "admin" ? (
                      <>
                        <AnalyticsCard05
                          title={"Upcoming Project Deadlines"}
                          loading={false}
                          data={
                            projectsDueAndPastDue.length > 0
                              ? get2DArray(
                                  projectsDueAndPastDue,
                                  "name",
                                  "deadline"
                                )
                              : "loading"
                          }
                          projects={projectsDueAndPastDue}
                          tableTitle={"Project"}
                          tableValue={"Due Date"}
                          titleField={"projectName"}
                          valueField={"deadline"}
                          colSpan={6}
                          rowSpan={4}
                          uppercase={false}
                        />

                        <AnalyticsCard05
                          title={"Expiring Bid Requests"}
                          loading={false}
                          data={
                            upcomingExpirations.length > 0
                              ? get2DArray(
                                  upcomingExpirations,
                                  "name",
                                  "deadline"
                                )
                              : "loading"
                          }
                          solicitations={upcomingExpirations}
                          tableTitle={"Bid Request"}
                          tableValue={"Due Date"}
                          colSpan={6}
                          rowSpan={4}
                          uppercase={false}
                        />
                        <div className=" lg:hidden col-span-full row-span-5 xl:col-span-4  grow-0 row-span-6 bg-white shadow-lg">
                          <header className="flex grid grid-flow-col px-5 py-4 border-b border-gray-100">
                            <h2 className="font-semibold text-slate-800">
                              Recent Active Bids
                            </h2>
                            <RightActionArea>
                              <div className=" text-sm font-semibold text-accent-600"></div>
                            </RightActionArea>
                          </header>
                          {/* Proposals */}
                          <div className=" flow-root">
                            {allBids && allBids.length > 0 ? (
                              getBidListComponent()
                            ) : (
                              <div className="py-2 px-6">
                                You currently have no active bids to review.
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <AnalyticsCard05
                          title={"High burden competencies"}
                          loading={false}
                          data={
                            topCompetenciesByScore.length > 0
                              ? topCompetenciesByScore.length > 5
                                ? topCompetenciesByScore.slice(0, 5)
                                : topCompetenciesByScore
                              : "loading"
                          }
                          colSpan={4}
                          rowSpan={1}
                          uppercase={false}
                        />
                        <AnalyticsCard05
                          title={"High effort competencies"}
                          loading={false}
                          data={
                            topCompetenciesByEffort.length > 0
                              ? topCompetenciesByEffort
                              : "loading"
                          }
                          //how to show this with large description
                          colSpan={4}
                          rowSpan={2}
                          uppercase={false}
                        />
                        <AnalyticsCard05
                          title={"High burden impediments"}
                          loading={false}
                          data={
                            topImpedimentsByScore.length > 0
                              ? topImpedimentsByScore.length > 5
                                ? topImpedimentsByScore.slice(0, 5)
                                : topImpedimentsByScore
                              : "loading"
                          }
                          colSpan={4}
                          rowSpan={2}
                          uppercase={false}
                        />
                        <AnalyticsCard05
                          title={"High effort impediments"}
                          loading={false}
                          data={
                            topImpedimentsByEffort.length > 0
                              ? topImpedimentsByEffort
                              : "loading"
                          }
                          //how to show this with large description
                          colSpan={4}
                          rowSpan={2}
                          uppercase={false}
                        />
                        <DashboardCard06
                          loading={false}
                          data={competencies.length > 0 ? competencies : []}
                          title={"Impediments by Competency"}
                          sorted={true}
                          colSpan={4}
                          rowSpan={2}
                          grouping="Name"
                        />
                        <DashboardCard06
                          loading={false}
                          data={competencies.length > 0 ? competencies : []}
                          title={"Competencies by impediment count"}
                          sorted={true}
                          colSpan={4}
                          rowSpan={2}
                          grouping="Area"
                        />
                        <DashboardCard04
                          data={allCompetenciesByScore}
                          title={"Competency Scores"}
                          span={12}
                          aspect={true}
                        />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {user.tenantType !== "vendor" && (
            <div
              id="bid-bar-outer"
              className=" h-full max-h-full overflow-y-hidden hidden absolute lg:flex w-[450px] bg-white  top-[47px] -bottom-0 -right-1"
            >
              <div
                id="bid-bar-inner"
                className="flex flex-col w-full h-full bg-white"
              >
                <div>
                  <header className="flex grid grid-flow-col px-5 py-4 border-b border-gray-100">
                    <h2 className="font-semibold text-slate-800">
                      Recent Active Bids
                    </h2>
                    <RightActionArea>
                      <div className=" text-sm font-semibold text-accent-600"></div>
                    </RightActionArea>
                  </header>
                </div>
                <div
                  id="bid-list-grandparent"
                  className="h-full overflow-y-scroll scrollbar-default  
              bg-white "
                >
                  <div className="h-full flow-root">
                    {allBids && allBids.length > 0 ? (
                      getBidListComponent()
                    ) : (
                      <div className="py-2 px-6">
                        You currently have no active bids to review.
                      </div>
                    )}
                  </div>
                </div>
                <div className="h-12">Test</div>
              </div>
            </div>
          )}
        </div>
      )}
      <ModalBasic
        id="view-all-solicitations"
        modalOpen={viewAllSolicitationsModalOpen}
        setModalOpen={setViewAllSolicitationsModalOpen}
        title={
          <span className="flex items-center">
            Bid Requests List <MenuTag status="Beta" />
          </span>
        }
        showClose={true}
        showFooter={false}
        showHeader={false}
        closeButtonText="Close"
        scrollable
        size="lg"
        // scrollable={true}
      >
        {" "}
        <div className="flex flex-col w-full h-full items-center px-10 pt-3 pb-0">
          <SolicitationsPage fromDashboard={true} />
        </div>
      </ModalBasic>
      <ModalBasic
        id="new-solicitation-shortcut"
        modalOpen={newSolicitationShortcutModalOpen}
        setModalOpen={setNewSolicitationShortcutModalOpen}
        title="Create a new bid request"
        showClose={false}
        showFooter={false}
        closeButtonText="Close"
        // size="lg"
        // scrollable={true}
      >
        {" "}
        <div className="flex flex-col h-full items-center px-10 pt-3 pb-0">
          <NewSolicitationShortcut
            setModalOpen={setNewSolicitationShortcutModalOpen}
            handleCreateNewProjectClick={() => {
              setNewSolicitationShortcutModalOpen(false);
              setAssessmentModalOpen(true);
            }}
            handleCreateNewSolicitation={(data) => {
              setNewSolicitationShortcutModalOpen(false);
              handleCreateNewSolicitation(data);
            }}
          />
        </div>
      </ModalBasic>
      <ModalBasic
        id="new-project-modal"
        modalOpen={assessmentModalOpen}
        setModalOpen={setAssessmentModalOpen}
        title="Create a project"
        showClose={false}
        showFooter={false}
        closeButtonText="Close"
        // size="lg"
        // scrollable={true}
      >
        {" "}
        <div className="flex flex-col h-full items-center px-10 pt-3 pb-0">
          <NewProject
            setModalOpen={setAssessmentModalOpen}
            handleCreateNewSolicitation={handleCreateNewSolicitation}
            modalOpen={assessmentModalOpen}
            updateData={() => console.log("Project saved")}
            step={1}
            from="dashboard"
          />
        </div>
      </ModalBasic>

      {/* <ContactModal
        id="contact-modal"
        showModal={showContactModal}
        setShowContactModal={() => setShowContactModal(false)}
      /> */}
      <ModalBasic
        id="support_modal"
        modalOpen={showContactModal}
        setModalOpen={() => handleOpenContactModal(false)}
        title={"Contact Us"}
        showClose={false}
      >
        <ContactConfirm
          setModalOpen={() => handleOpenContactModal(false)}
          company={"Breachlink"}
          email={"support@breachlink.com"}
          vendor_email={"vendor_email"}
          type="support"
        />
      </ModalBasic>

      <ModalBasic
        id="new-solicitation-model"
        modalOpen={newSolicitationModalOpen}
        setModalOpen={setNewSolicitationModalOpen}
        title="Create New Bid Request"
        showClose={false}
        showFooter={false}
        closeButtonText="Close"
        // size="lg"
        scrollable={true}
      >
        <div className="flex flex-col h-full items-center px-10 pt-3 pb-0">
          <NewSolicitation
            projectId={newSolicitationData?._id}
            project={newSolicitationData}
            setModalOpen={setNewSolicitationModalOpen}
            modalOpen={newSolicitationModalOpen}
            updateData={null}
            stepOptions={4}
            step={0}
          />
        </div>
      </ModalBasic>
      <ModalBasic
        modalOpen={showBidCompareModal}
        setModalOpen={setShowBidCompareModal}
        title="Compare Bids"
        showClose={true}
        showFooter={false}
        closeButtonText="Close"
        size="lg"
        scrollable={true}
      >
        <BidCompare
          modalOpen={showBidCompareModal}
          data={solicitationToShow?.responses}
          selectWinner={() => {
            setUpdated(!updated);
          }}
          //update solicitations array by solicitationId = _id
          // (result) => {
          //   console.log("selectWinner", result);
          //   let updatedSolicitations = solicitations.map((solicitation) => {
          //     if (solicitation._id == result.solicitationId) {
          //       //replce solicitation with updated solicitation
          //       solicitation = result;
          //     }
          //     return solicitation;
          //   });
          //   setSolicitations(updatedSolicitations);
          // }
          // }
          notifyWinner={() => {
            setUpdated(!updated);
          }}
          //update solicitations array by solicitationId = _id
          // (result) => {
          //   console.log("selectWinner", result);
          //   let updatedSolicitations = solicitations.map((solicitation) => {
          //     if (solicitation._id == result.solicitationId) {
          //       //replce solicitation with updated solicitation
          //       solicitation = result;
          //     }
          //     return solicitation;
          //   });
          //   setSolicitations(updatedSolicitations);
          // }
          // }
          loading={false}
          errorMessage={null}
          solicitation={solicitationToShow}
          setCompareModalOpen={setShowBidCompareModal}
        />
        {/* <BidCompare
          data={solicitation.responses}
          selectWinner={selectWinner}
          notifyWinner={notifyWinner}
          loading={selectWinnerLoading}
          errorMessage={errMessage}
          solicitation={solicitation}
          setCompareModalOpen={setCompareModalOpen}
        /> */}
      </ModalBasic>
    </div>
  );
}

export default Dashboard;

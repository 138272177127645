import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const Uploads = {
  profile() {
    if (
      _auth.getUserProfile() === null ||
      _auth.getUserProfile() === undefined
    ) {
      return JSON.parse(localStorage.getItem("guestProfile"));
    } else {
      return _auth.getUserProfile();
    }
  },

  //   async uploadNewSolicitationDocs(file) {
  //     console.log("Call API and pass data", file);
  //     let data = {};
  //     let profile = this.profile();
  //     let token = localStorage.getItem("jwtToken");
  //     if (!token) {
  //       token = profile.token;
  //     }
  //     setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

  //     data.tenantId = profile.tenantId;
  //     data.companyId = profile.companyId;

  //     let response = await postRequest("uploads/new-solicitation", data);
  //     if (response) {
  //       //should return a docId
  //       console.log("uploads/new-solicitation", response);
  //       return response.data.data;
  //     } else {
  //       return response;
  //     }
  //   },
  async uploadNewSolicitationDocs(file, type) {
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    // try {
    console.log("uploadNewSolicitationDocs before api", file);
    const data = new FormData();

    data.append("file", file);
    data.append("companyId", profile.companyId);
    //   data.append("company", profile.company);
    data.append("tenantId", profile.tenantId);
    data.append("type", type);
    let response = await postRequest("upload/bid-request-docs", data);
    if (response) {
      console.log("uploadNewSolicitationDocs", response.data.data);
      return response.data.data;
    } else {
      console.log("uploadNewSolicitationDocs failed", response);
      return false;
    }
    // } catch (e) {
    //   console.log(e);
    // }
  },
  async getFileById(id) {
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest(`upload/get-file-by-id/${id}`);
    if (response) {
      console.log("getFileById", response.data.data);
      return response.data.data;
    } else {
      console.log("getFileById failed", response);
      return false;
    }
  },
  async uploadLogoFile(file, type) {
    let profile = this.profile();
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    // try {
    console.log("upload file before api", file);
    const data = new FormData();

    data.append("file", file);
    data.append("companyId", profile.companyId);
    //   data.append("company", profile.company);
    data.append("tenantId", profile.tenantId);
    data.append("type", type);
    let response = await postRequest("upload/upload-logo", data);
    if (response) {
      console.log("uploadLogo", response.data.data);
      return response.data.data;
    } else {
      console.log("uploadLogo failed", response);
      return false;
    }
    // } catch (e) {
    //   console.log(e);
    // }
  },
  async uploadBidResponseDocument(file, type) {
    let profile = this.profile();
    // let token = localStorage.getItem("jwtToken");
    // setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    // try {
    console.log("uploadNewSolicitationDocs before api", file);
    const data = new FormData();
    data.append("file", file);
    data.append("type", type);
    try {
      let response = await postRequest("upload/bidresponse/doc", data);
      console.log("uploadBidResponseDocument response", response);

      if (response && response.data) {
        console.log("uploadNewSolicitationDocs", response.data.data);
        return response.data.data;
      } else {
        console.log("uploadNewSolicitationDocs failed", response);
        return false;
      }
    } catch (e) {
      console.log("uploadNewSolicitationDocs failed", e);
      return false;
    }
  },
  upload(file, type) {
    let res;
    if (type == "contract") {
      //remove this
      res = this.uploadNewSolicitationDocs(file, type);
    }
    if (type === "solicitation") {
      res = this.uploadNewSolicitationDocs(file, type);
    }
    if (type == "logo") {
      res = this.uploadLogoFile(file, type);
    }
    if (type == "response") {
      console.log(file, "file-");
      res = this.uploadBidResponseDocument(file, type);
    }
    if (type == "bidResponseDoc") {
      //remove this
      console.log(file, "file-");
      res = this.uploadBidResponseDocument(file, type);
    }
    return res;
  },
};

import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";
import { utils } from "./_utils";

const { postRequest, getRequest } = require("./ApiRequest");

export const _stripe = {
  stripePortalSession: async () => {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    const profile = _auth.getUserProfile();

    let stripeCustomerId = await utils.getCacheData("id", "Stripe");
    let body = {
      customerId: stripeCustomerId,
      tenantId: profile.tenantId,
      companyId: profile.tenantCompanyId,
    };
    console.log("getCacheDataResp stripeCustomerId", stripeCustomerId);
    let url = await postRequest("/stripe/create-customer-portal-session", body);
    console.log("session", url.data.data.url);
    return url.data.data.url;
  },

  async getStripeData() {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    console.log("Getting Stripe Data for id");
    const stripeId = await this.getStripeCustomerId();
    // const stripeSubscriptionForTenant = await this.getSubscription();
    const stripeProducts = await this.getProducts(stripeId);
    console.log("stripeProducts", stripeProducts[0]);
    const stripeTier = this.getTier(stripeProducts[0]);
    const userLimit = 100;
    const employeeLimit = 200;

    let data = {
      id: stripeId,
      products: stripeProducts,
      tier: stripeTier,
      userLimit: userLimit,
      employeeLimit: employeeLimit,
    };
    console.log("Caching stripeData", data);
    utils.cacheData("Stripe", data);

    console.log("stripeData", data);

    return data;
  },

  async getStripeCustomerId() {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    const profile = _auth.getUserProfile();

    let body = {
      tenantId: profile.tenantId,
      companyId: profile.tenantCompanyId,
      email: profile.email,
      name: profile.name,
      company: profile.tenantCompany,
      profile: profile,
    };

    //check tenants table for stripe customer id by tenantId
    let response = await postRequest("/stripe/get-customer-id", body);
    console.log("getStripeCustomerId", response.data.data.customerId);
    return response.data.data.customerId;
  },

  async getProducts(id) {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let customerId;
    if (id) {
      customerId = id;
    } else {
      customerId = await this.getStripeCustomerId();
    }
    console.log("getStripeProducts", customerId);
    let response = await postRequest("/stripe/get-products", {
      customerId: customerId,
    });
    if (response) {
      console.log("response getStripeProducts", response.data.data);
      return response.data.data;
    } else {
      console.log("getStripeProducts No product found");
      return [];
    }
  },

  async getStripeCustomer() {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    const id = "cus_MMpg2jjQKlbCOB";
    let response = await postRequest("/stripe/get-customer", { id });
    if (response) {
      console.log("Stripe Customer", response.data.data);
      return response.data.data;
    } else {
      console.log("No customer found");
      return [];
    }
  },

  getTier(productId) {
    let tiers = this.getTiers();
    if (tiers) {
      console.log("StripeProducts tiers", productId, tiers);
      let tier = tiers.find((t) => t.productId === productId);
      if (tier) {
        return tier.tier;
      } else return "Free";
    } else {
      return "Free";
    }
  },

  getTiers() {
    //move to database
    let tiers = [
      {
        tier: "Free",
        productId: "prod_MnjZVY3AgKLiEr",
      },
      {
        tier: "Grow",
        productId: "prod_MnjMHGT994ZrmL",
      },
      {
        tier: "Scale",
        productId: "prod_MnjMgQ8kVqKJqt",
      },
    ];
    return tiers;
  },

  async getSubscription() {
    const id = "cus_MMpg2jjQKlbCOB";
    let response = await postRequest("get-subscription", { id });
    if (response) {
      console.log("Stripe Subscription", response.data.data);
      return response.data.data;
    } else {
      console.log("No subscription found");
      return [];
    }
  },

  getEntitledFeatures(tier) {
    //filter out features that are not entitled
    let tmp = [];
    let features = this.features();
    let entitledFeatures = features.filter((feature) => {
      if (feature.tiers.includes(tier)) {
        tmp.push(feature);
        utils.cacheData("entitledFeatures", tmp);
        return true;
      } else {
        return false;
      }
    });
    return tmp;
  },
};

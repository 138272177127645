/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useMemo, useState } from "react";
//import useLocation
import { useLocation, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { usePersistStore, useStore } from "../../store/usestore";
import Button from "../../elements/Button";
import { utils } from "../../modules/_utils";
import { Solicitations } from "../../modules/_solicitations";
import BidList from "./BidList";
import RightActionArea from "../../components/ActionAreaRight";
import { Icon } from "@iconify/react";
import Tabs from "../component/Tabs";

import BidCompare from "./components/BidCompare";
import SolicitationSummary from "./components/SolicitationSummary";
import Tip from "../../components/Tip";
import ModalBasic from "../../components/ModalBasic";
import Datepicker from "flowbite-datepicker/Datepicker";
//import constants
import * as constants from "../../constants/constants";
import PdfViewer from "../../components/PdfViewer";
import Skeleton from "../../components/Skeleton";
import { Files } from "../../modules/_files";

import Badge from "../../components/Badge";
import TwoColumnExample from "../../layouts/test";
import Steps from "../../components/Steps";
import Comments from "../../components/Comments";
import { _comments } from "../../modules/_comments";
import Drawer from "../../components/Drawer";
import CommentButton from "../../components/CommentButton";
import DropdownMenu from "../../components/DropdownMenu";
import { set } from "date-fns";
import { _auth } from "../../modules/_auth";
import { FeatureFlag } from "../../components/FeatureFlag";
import InviteComponent from "../../components/InviteComponent";
import { PlusIcon } from "@heroicons/react/24/outline";
import { _assessment } from "../../modules/_assessment";
import DialogBox from "../../components/Dialog";
import { WORKFLOW_STATES, WORKFLOW_STATUS } from "../../constants/constants";
import DropdownSwitch from "../../components/DropdownSwitch";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SolicitationDetails(props) {
  const location = useLocation();
  const [loading, setLoading] = useState();
  const [err, setErr] = useState(null);
  const [errMessage, setErrMessage] = useState(null);
  const [saved, setSaved] = useState(false);
  const [solicitation, setSolicitation] = useState({});
  const [project, setProject] = useState({});
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [compareModalOpen, setCompareModalOpen] = useState(false);
  const [saveAsTemplateModalOpen, setSaveAsTemplateModalOpen] = useState(false);
  const { projectId, solicitationId } = useParams();

  const [id, setId] = useState(
    // location.state.solicitation && location.state.solicitation._id
    // ? location.state.solicitation._id
    // : null
    solicitationId
  );
  const [templateDescription, setTemplateDescription] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [selectWinnerLoading, setSelectWinnerLoading] = useState(false);
  const [pdfModalOpen, setPdfModalOpen] = useState(false);

  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [commentLoading, setCommentLoading] = useState(false);
  const [commentDrawerOpen, setCommentDrawerOpen] = useState(false);
  const [invitePeopleModal, setInvitePeopleModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showCloseBidRequestConfirm, setShowCloseBidRequestConfirm] =
    useState(false);

  const [success, setSuccess] = useState(false);
  const [biddingClosedIndex, setBiddingClosedIndex] = useState(0);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const [workflowStatus, setWorkflowStatus] = useState("");
  const [viewInvites, setViewInvites] = useState(false);
  const inviteId = location.state.inviteId;
  // const projectId = location.state.projectId;
  // debugger
  const history = useHistory();
  const tabOrder = constants.SOLICITATION_TAB_ORDER;
  const profile = _auth.getUserProfile();

  // const { sendAssessment } = useStore();
  useEffect(() => {
    (async () => {
      if (inviteId) {
        //get solicitation from invite table and set state
        let solId = await Solicitations.getByInviteId({
          inviteId: location.state.inviteId,
        });
        if (solId) {
          console.log(
            "Got solicitationId from InviteId, settings state",
            solId
          );
          setId(solId);
        }
      } else {
        setId(
          location.state.solicitation._id
            ? location.state.solicitation._id
            : location.state.solicitation.id
        );
      }
    })();
  }, []);

  useEffect(() => {
    setProject(
      location.state && location.state.project ? location.state.project : {}
    );
  }, []);

  useEffect(() => {
    setSolicitation(
      location.state && location.state.solicitation
        ? location.state.project
        : {}
    );
  }, []);

  useEffect(() => {
    (async () => {
      console.log("Getting solicitation by id after create", { id: id });

      let item = await Solicitations.getById({ id: id, projectId: projectId });
      if (item.services && item.services.length > 0) {
        let tabs = [];
        let orderedTabs = [];
        orderedTabs.push({
          name: "Overview",
          id: "overview",
          active: false,
        });
        item.services.forEach((service) => {
          tabs.push({
            name: service.service,
            id: service.id,
            active: false,
          });
        });
        //put these in the right order based on tabOrder
        utils.orderTabs(tabs, tabOrder, orderedTabs);
        // tabOrder.forEach((tab) => {
        //   tabs.forEach((t) => {
        //     if (t.name == tab) {
        //       orderedTabs.push(t);
        //     }
        //   });
        // });

        orderedTabs.push({ name: "Summary", id: "summary", active: false });
        console.log("Setting tabs", tabs);
        console.log("Setting orderedTabs", orderedTabs);
        setTabs(orderedTabs);
        setSelectedTab(orderedTabs[0].name);
        setSelectedService(orderedTabs[0].id);
      }
      if (item) {
        console.log("Got solicitation", item);
        setSolicitation(item);
      } else {
        setSolicitation([]);
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      const cmnts = await _comments.getCommentsById({
        commentType: "solicitation",
        solicitationId: solicitation._id,
      });
      //add cmnts to comments state array
      if (cmnts) {
        console.log(cmnts, "show cmts");
        setComments(cmnts);
      } else {
        setComments([]);
      }
    })();
  }, [solicitation]);

  useEffect(() => {
    setWorkflowStatus(
      Solicitations.getCurrentWorkflow(solicitation.workflow_status)?.name
    );
  }, [solicitation.workflow_status]);

  // useEffect(() => {
  //   const currentStepIndex = WORKFLOW_STATES.indexOf(solicitation.workflow);
  //   const biddingClosedIndex = WORKFLOW_STATES.indexOf("Bidding Closed");
  //   console.log(
  //     "useEffect compare currentStepIndex",
  //     currentStepIndex,
  //     "to biddingClosedIndex",
  //     biddingClosedIndex
  //   );
  //   setBiddingClosedIndex(currentStepIndex);
  //   setCurrentStepIndex(biddingClosedIndex);
  // }, [solicitation]);

  useEffect(() => {
    if (!solicitation) return;
    if (
      solicitation.workflow_status &&
      solicitation.workflow_status.length > 0
    ) {
      setSteps(solicitation.workflow_status);
    } else {
      let steps = buildSteps();
      console.log("useEffect steps", steps);
      setSteps(steps);
    }
  }, [solicitation]);

  const handlePostComment = async () => {
    setCommentLoading(true);
    let data = {
      comment: commentText,
      commentType: "solicitation",
      solicitationId: solicitation._id,
      responseId: null,
      projectId: project._id,
      authorType: "requestor",
      company: profile.tenantCompany,
    };
    setTimeout(() => {
      (async () => {
        if (!commentText) return;
        if (commentText == "") return;

        const comment = await _comments.addComment(data);
        setComments([...comments, comment]);
        setCommentText("");
        // props.updateCommentCount("add");
        setCommentLoading(false);
      })();
    }, 500);
  };

  const handleLike = async (e, id) => {
    console.log(id, "comment-id");
  };

  // const handlePostComment = async () => {
  //   let data = {
  //     comment: commentText,
  //     commentType: "solicitation",
  //     solicitationId: solicitation._id,
  //     responseId: null,
  //     projectId: project._id,
  //   };

  //   const result = await _comments.addComment(data);
  //   if (result) {
  //     console.log("Comment posted", result);
  //     //add comment to comments state array
  //     let newComments = [...comments];
  //     newComments.push(result);
  //     setComments(newComments);
  //     setCommentText("");
  //   }
  // };

  const handleSetPdfModalOpen = (e, attachment) => {
    e.preventDefault();
    console.log("handleSetPdfModalOpen", attachment);
    setPdfModalOpen(true);
  };

  const handleCloseBidding = async (e) => {
    let newWorkflowState =
      Solicitations.getCurrentWorkflow(solicitation.workflow).name ==
      "Bidding Closed"
        ? "Accepting Bids"
        : "Bidding Closed";
    let result = await Solicitations.openAndCloseBidding({
      _id: solicitation._id,
      newWorkflow: newWorkflowState,
      oldWorkflow: solicitation.workflow,
    });
    if (result) {
      //update state with new status
      let updatedSolicitation = { ...solicitation };
      // updatedSolicitation.workflow = newWorkflowState;
      updatedSolicitation.workflow_status = result.workflow_status;
      setSolicitation(updatedSolicitation);
    }
  };

  const handleChangeSolicitationStatus = async (e, action) => {
    e.preventDefault();
    let newStatus;
    let newWorkflow;
    if (action === "close") {
      newStatus = solicitation.status == "Closed" ? "Active" : "Closed";
      newWorkflow =
        solicitation.status == "Closed"
          ? solicitation.oldWorkflow
            ? solicitation.oldWorkflow
            : "Active"
          : "Closed";
    } else if (action === "archive") {
      newStatus = "Archived";
      newWorkflow = "Archived";
    } else if (action === "advance_workflow") {
    } else {
      newStatus = null;
      newWorkflow = action;
    }

    let result = await Solicitations.updateWorkflowStatus({
      _id: solicitation._id,
      status: newStatus,
      newWorkflow: newWorkflow,
      oldWorkflow: Solicitations.getCurrentWorkflow(
        solicitation.workflow_status
      )?.name,
      wfStatus: solicitation.workflow_status,
    });

    if (result) {
      //update state with new status
      let updatedSolicitation = { ...solicitation };
      if (newStatus !== null) {
        updatedSolicitation.status = newStatus;
      }
      updatedSolicitation.oldWorkflow = result.oldWorkflow;
      updatedSolicitation.workflow_status = result.workflow_status;
      setSolicitation(updatedSolicitation);
    }
  };
  const handleConfirmCloseBidRequest = async (e) => {
    setShowCloseBidRequestConfirm(true);
  };
  const handleCloseSolicitation = async (e) => {
    //notes:
    // 1. If the worfklow_status "winner_notified" is incomplete, show dialog to confirm closing the solicitation
    // 2. If the workflow_status "winner_notified" is complete, close the solicitation
    // 3. When calling api, pass flag to indicate if winner has been notified
    // 4. Use the flag to determine if emails should be sent to vendors
    e.preventDefault();
    let newStatus = solicitation.status == "Closed" ? "Active" : "Closed";
    let newWorkflow =
      solicitation.status == "Closed"
        ? solicitation.oldWorkflow
          ? solicitation.oldWorkflow
          : "Active"
        : "Closed";

    let result = await Solicitations.updateWorkflowStatus({
      _id: solicitation._id,
      status: newStatus,
      newWorkflow: newWorkflow,
      oldWorkflow: Solicitations.getCurrentWorkflow(
        solicitation.workflow_status
      )?.name,
      wfStatus: solicitation.workflow_status,
    });

    if (result) {
      //update state with new status
      let updatedSolicitation = { ...solicitation };
      updatedSolicitation.status = newStatus;
      updatedSolicitation.oldWorkflow = result.oldWorkflow;
      updatedSolicitation.workflow_status = result.workflow_status;
      setSolicitation(updatedSolicitation);
    }
  };
  const handleArchiveSolicitation = async (e) => {
    e.preventDefault();
    let newStatus = "Archived";

    let result = await Solicitations.update({
      _id: solicitation._id,
      status: newStatus,
    });

    if (result) {
      //update state with new status
      let updatedSolicitation = { ...solicitation };
      updatedSolicitation.status = newStatus;
      setSolicitation(updatedSolicitation);
    }
  };

  //moved to BidComparePage.js
  const selectWinner = async (e, bid) => {
    e.preventDefault();
    console.log("Selecting winner", e, bid);
    // setSelectWinnerLoading(true);
    setErrMessage(null);
    setSolicitation(bid);
    setCompareModalOpen(true);

    // let result = await Solicitations.selectWinner({
    //   solicitationId: solicitation._id,
    //   bid: bid,
    //   bidId: bid._id,
    //   solicitationId: bid.solicitationId,
    // });
    // console.log("selectWinner result", result);
    // if (result) {
    //   setSolicitation(result);
    //   setCompareModalOpen(true);
    //   setSelectWinnerLoading(false);
    // } else {
    //   console.log("Error selecting winner");
    //   setSelectWinnerLoading(false);
    //   errMessage("Error selecting winner");
    // }
  };

  const notifyWinner = async (e, bid, message) => {
    e.preventDefault();
    console.log("Notifying winner", bid, message);
    setSelectWinnerLoading(true);
    setErrMessage(null);
    setSolicitation(bid);
    // if (message == "" || message == null) {
    //   setTimeout(() => {
    //     setErrMessage("Please enter a message to the vendor");
    //     setSelectWinnerLoading(false);
    //   }, 500);
    //   return false;
    // } else {
    //   let result = await Solicitations.notifyWinner({
    //     solicitationId: solicitation._id,
    //     bid: bid,
    //     bidId: bid._id,
    //     solicitationId: bid.solicitationId,
    //     vendorEmail: bid.submittedByEmail,
    //     message: message,
    //   });
    //   console.log("selectWinner result", result);
    //   if (result) {
    //     console.log("Winner selected and confirmed", result);
    //     setSolicitation(result);
    //     setCompareModalOpen(false);
    //     setSelectWinnerLoading(false);
    //   } else {
    //     console.log("Error selecting winner");
    //     setSelectWinnerLoading(false);
    //     errMessage("Error selecting winner");
    //   }
    // }
  };
  // end moved to BidComparePage.js
  const finalizeWinner = async (e, bid, message) => {
    e.preventDefault();
    console.log("Selecting winner", bid, message);
    setSelectWinnerLoading(true);
    setErrMessage(null);
    if (message == "" || message == null) {
      setTimeout(() => {
        setErrMessage("Please enter a message to the vendor");
        setSelectWinnerLoading(false);
      }, 500);
      return false;
    } else {
      let result = await Solicitations.selectWinner({
        solicitationId: solicitation._id,
        bid: bid,
        bidId: bid._id,
        solicitationId: bid.solicitationId,
        vendorEmail: bid.submittedByEmail,
        message: message,
      });
      console.log("selectWinner result", result);
      if (result == true) {
        console.log("Winner selected and confirmed", result);

        setCompareModalOpen(false);
        setSelectWinnerLoading(false);
      } else {
        console.log("Error selecting winner");
        setSelectWinnerLoading(false);
        errMessage("Error selecting winner");
      }
    }
  };

  const manageLoading = (item, bool) => {
    utils.manageLoading(item, bool, loading, setLoading);
  };
  const isLoading = (item) => {
    if (loading.includes(item)) {
      console.log(item, "is loading");
      return true;
    } else {
      console.log(item, "is not loading");
      return false;
    }
  };
  const onFieldChange = (e, field) => {
    setErr(false);
    setErrMessage("");
    console.log("onFieldChange", "{field:", field, ",value:", e, "}");
    if (field == "Description") {
      setTemplateDescription(e.target.value);
    } else if (field == "Name") {
      setTemplateName(e.target.value);
    } else {
      console.log("Field ", field, "not found");
    }
  };

  const handleSaveAsTemplateClick = async (e) => {
    e.preventDefault();

    setSaveAsTemplateModalOpen(true);
    console.log("Save as template clicked");
  };

  const handleShowCompareModalClick = (e) => {
    e && e.preventDefault();
    setCompareModalOpen(!compareModalOpen);
  };

  const saveTemplate = async () => {
    utils.manageLoading("Save Template", true, loading, setLoading);
    setErr(false);
    setErrMessage("");
    if (!templateName || templateName == "") {
      setErr(true);
      setErrMessage("Please enter a name for the template");
      return;
    }
    if (!templateDescription || templateDescription == "") {
      setErr(true);
      setErrMessage("Please enter a description for the template");
      return;
    }
    let data = {
      fromSolicitationId: solicitation._id,
      name: templateName, // solicitation.name,
      description: templateDescription, // "Created by hardcoded description",
      template: solicitation,
    };
    let saved = await Solicitations.saveAsTemplate(data);
    if (saved) {
      console.log("Saved as template", saved);
    }
    setTimeout(() => {
      setSaveAsTemplateModalOpen(false);
    }, 1000);
    utils.manageLoading("Save Template", false);
  };

  const cancelWindowClick = () => {
    setSaveAsTemplateModalOpen(false);

    setTemplateDescription("");
    setTemplateName("");
    setErr(false);
    setErrMessage("");
  };

  const navigateBack = (e) => {
    e.preventDefault();
    history.goBack();
  };
  const navigateToBidComparePage = (e) => {
    // debugger;
    history.push({
      pathname:
        "/projects/project-details/" +
        projectId +
        "/solicitation-details/" +
        solicitationId +
        "/bid-compare",
      state: {
        solicitation: solicitation,

        // selectWinner: selectWinner,
        // notifyWinner: notifyWinner,
        selectWinnerLoading: loading,
        errMessage: errMessage,
        solicitation: { solicitation },
      },
    });
  };

  const cleanUpKeys = (key) => {
    let newKey = key.replace(/([A-Z])/g, " $1");

    newKey = newKey.replace(/_/g, " ");

    let finalKey = newKey.charAt(0).toUpperCase() + newKey.slice(1);
    console.log("FinalKey", finalKey);
    return finalKey;
  };

  const mainContent = useMemo(() => {
    return (
      <div>
        <div className="flex flex-row lg:flex-row  justify-between ">
          {/* left side */}
          <div className="w-1/4  ">
            {/* header */}
            <div className="flex flex-col lg:flex-row justify-between items-center">
              {/* Welcome banner */}
              <div className="mb-0 lg:mb-0 inline-flex ">
                <h2
                  id="applicant-information-title"
                  className="text-lg font-medium leading-6 text-slate-900"
                >
                  Bid Request Information
                </h2>
                {/* <div className="ml-3 flex items-center justify-between">
                    <p className="text-sm font-medium text-slate-700">
                      Expires
                      <a href="#" className="text-slate-700"></a>{" "}
                      <time
                        dateTime="2020-08-25"
                        className="text-secondary-500"
                      >
                        {solicitation &&
                          solicitation.deadline &&
                          utils.translateDaysRemaining(
                            utils.daysRemaining(solicitation.deadline)
                          )}
                      </time>
                    </p>
                  </div> */}
              </div>
            </div>
          </div>
          {/* right side */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {/* <span className="inline-flex items-center px-1 py-2 border border-transparent text-xs font-bold  text-slate-400 uppercase  ">
                Closes on
              </span>
              <span className="inline-flex items-center py-2 border border-transparent text-xs font-bold  text-secondary-500 uppercase  ">
                {utils.formatDate(solicitation.deadline, "long")}
              </span> */}
          </div>
        </div>
        <div className="flex flex-row lg:flex-row justify-between ">
          <div className="  ">
            {tabs && tabs.length > 0 && (
              <div className="col-span-full space-y-2">
                <div className="my-3">
                  <Tabs
                    tabs={tabs && tabs.length > 0 ? tabs : []}
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    cleanUpKeys={cleanUpKeys}
                  />
                </div>
              </div>
            )}
          </div>
          {/* right side */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {/* RIGHT SIDE ACTIONS */}
          </div>
          {/* <div className="w-3/4 mt-3 ">
            {" "}
            <div className="flex h-full flex-col lg:flex-row justify-end ">
              <div className="flex my-auto  inline-flex">
                <h2 className="text-2xl font-medium text-slate-900">
                  Your quote total is: $0.00
                </h2>
              </div>
            </div>
          </div> */}
        </div>
        <div className="my-3">
          <hr className="border-gray-200" />
        </div>
        <div
          // style={{ maxHeight: "63vh", minHeight: "63vh" }}
          className=" min-h-full max-h-full col-span-8  overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full "
        >
          <SolicitationSummary
            solicitation={solicitation}
            tabs={tabs}
            project={project}
            selectedTab={selectedTab}
            cleanUpKeys={cleanUpKeys}
            handleSetPdfModalOpen={handleSetPdfModalOpen}
          />
        </div>
      </div>
    );
  }, [solicitation]);

  const sideContent = useMemo(() => {
    return (
      <div
        className="col-span-4 overflow-y-hidden overflow-x-hidden scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
        scrollbar-thumb-rounded-full scrollbar-track-rounded-full "
      >
        <div className="space-y-2 mb-4">
          <div className="py-2 flex items-center justify-between col-span-full">
            <div className=" ">
              <h2 className="text-lg font-semibold leading-6 font-medium text-gray-900">
                Bids
              </h2>
            </div>
            <div>
              <Button
                rounded
                size={"sm"}
                color={"emerald"}
                onClick={null}
                className="mr-2"
                variant="secondary"
                handleClick={(e) => navigateToBidComparePage(e)}
                disabled={
                  solicitation.responses && solicitation.responses.length > 0
                    ? false
                    : true
                }
              >
                <Icon
                  //arrow icons left and right
                  icon="mdi:arrow-left-right-bold"
                  className="mr-2"
                />{" "}
                Compare bids{" "}
              </Button>
            </div>
          </div>
          <div className="block">
            {solicitation.responses &&
              solicitation.invitations &&
              solicitation.invitations.length -
                solicitation.responses.length !==
                0 && (
                <div className="flex items-center space-x-3">
                  <Badge color="secondary">
                    Submitted{": "}
                    {solicitation.responses && solicitation.responses.length > 0
                      ? solicitation.responses.filter(
                          (response) =>
                            response.status !== "draft" &&
                            response.status !== "withdrawn" &&
                            response.status !== "invited"
                        ).length
                      : 0}
                  </Badge>

                  <Badge color="secondary">
                    Remaining{": "}
                    {solicitation.responses &&
                      solicitation.invitations &&
                      solicitation.invitations.length -
                        solicitation.responses.length}
                  </Badge>
                </div>
              )}
          </div>
          <div className="col-span-full mt-0">
            {solicitation.responses &&
            solicitation.invitations &&
            solicitation.invitations.length - solicitation.responses.length ==
              0 ? (
              <Tip color="green">All vendors have responded.</Tip>
            ) : solicitation?.responses?.length < 1 ? (
              <Tip>Submitted bids will appear here.</Tip>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="border-t border-gray-200"> </div>
        <div
          // style={{ maxHeight: "40vh", minHeight: "40vh" }}
          className="mt-6  flow-root  
        overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
        scrollbar-thumb-rounded-full scrollbar-track-rounded-full pb-12"
        >
          <BidList
            onItemClick={null}
            project={solicitation}
            solicitation={solicitation}
            items={solicitation.responses ? solicitation.responses : []}
          />
        </div>
        <div className="mt-6 w-2/3 rounded-md shadow sm:mt-3 sm:ml-3 sm:flex-shrink-0">
          {/* <Button
          loader={loading}
          disabled={loading || saved || response.status == "complete"}
          handleClick={handleSaveServiceClick}
          type="submit"
          color="secondary"
          fullWidth
          className="w-full"
          // disabled={err}
          // className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <Icon icon="mdi:content-save" className="mr-2" />
          Save{" "}
          {cleanUpKeys(
            selectedTab && selectedTab !== undefined
              ? selectedTab
              : "Service"
          )}
        </Button> */}
        </div>
      </div>
    );
  }, [solicitation]);

  const getCurrentStep = () => {
    const workflow = solicitation.workflow;
    const wfStatus = solicitation.status;

    const stepOptions = [
      "Created",
      "Awaiting Bids",
      "Responses Closed",
      "Winner Selected",
      "Winner Notified",
      "Request Closed",
      "Request Archived",
    ];

    const steps = stepOptions.map((stepOption) => {
      let status = "incomplete";

      if (stepOption === "Created") {
        status = "complete";
      } else if (stepOption === "Awaiting Bids") {
        if (workflow === "Open") {
          const deadline = new Date(solicitation.deadline);
          const now = new Date();
          if (deadline > now) {
            status = "current";
          } else {
            status = "complete";
          }
        } else {
          status = "complete";
        }
      } else if (stepOption === "Responses Closed") {
        if (workflow === "Open") {
          const deadline = new Date(solicitation.deadline);
          const now = new Date();
          if (deadline < now) {
            status = "current";
          } else {
            status = "complete";
          }
        } else {
          status = "complete";
        }
      } else if (stepOption === "Winner Selected") {
        if (workflow === "winner identified" && wfStatus !== "Closed") {
          status = "current";
        } else if (
          ["Open", "winner identified"].includes(workflow) &&
          wfStatus !== "Closed"
        ) {
          status = "incomplete";
        } else {
          status = "complete";
        }
      } else if (stepOption === "Winner Notified") {
        if (workflow === "winner notified" && wfStatus !== "Closed") {
          status = "current";
        } else if (
          ["Open", "winner identified", "winner notified"].includes(workflow) &&
          wfStatus !== "Closed"
        ) {
          status = "incomplete";
        } else {
          status = "complete";
        }
      } else if (stepOption === "Request Closed") {
        if (wfStatus === "Closed") {
          status = "current";
        } else if (
          ["Open", "winner identified", "winner notified"].includes(workflow)
        ) {
          status = "incomplete";
        } else {
          status = "complete";
        }
      } else if (stepOption === "Request Archived") {
        if (wfStatus === "Archived") {
          status = "current";
        } else {
          status = "incomplete";
        }
      }

      return { name: stepOption, status };
    });

    return steps;
  };

  const buildSteps = () => {
    const workflow = solicitation.workflow;
    const wfStatus = solicitation.status;
    const allBidsReceived =
      solicitation.responses?.length === solicitation.invitations?.length &&
      solicitation.responses?.length > 0;
    let deadline = new Date(solicitation.deadline);
    let now = new Date();
    let expired = false;
    expired = deadline < now;

    const stepOptions = [
      "Created",
      "Accepting Bids",
      "Bidding Closed",
      "Winner Selected",
      "Winner Notified",
      "Closed",
      "Archived",
    ];

    const steps = stepOptions.map((stepOption) => {
      let status = "incomplete";
      //When closing, the wfStatus is Closed, the current workflow should be complete
      if (stepOption === "Created") {
        status = "complete";
      } else if (stepOption === "Accepting Bids") {
        // change the backend so that when the last response is submitted, the workflow is changed to "Bidding Closed"
        if (workflow === "Accepting Bids" || workflow === "Open") {
          if (allBidsReceived == true) {
            //expired == true ||
            status = "current"; //changed from complete 1/7/2024
            // will need to check expired && allLateBids == true to determine state.
            // best to do this on backend with a job
          } else if (["Open", "Active"].includes(wfStatus)) {
            status = "current";
          } else if (["Closed", "Archived"].includes(wfStatus)) {
            status = "skipped";
          } else {
            status = "incomplete";
          }
        } else {
          if (
            [
              "Bidding Closed",
              "Winner Selected",
              "Winner Notified",
              "Closed",
              "Archived",
            ].includes(workflow)
          ) {
            status = "complete";
          } else {
            status = "complete";
          }
        }
      } else if (stepOption === "Bidding Closed") {
        if (workflow === "Bidding Closed") {
          if (["Open", "Active"].includes(wfStatus)) {
            status = "current"; //changed from current 1/7/2024
            // status = "complete"; //
          } else if (["Closed", "Archived"].includes(wfStatus)) {
            status = "skipped";
          } else {
            status = "incomplete";
          }
        } else {
          if (workflow === "Accepting Bids") {
            if (["Closed", "Archived"].includes(wfStatus)) {
              status = "skipped";
            }
            //&& expired == true
            // status = "current";
            console.log("Bidding Closed", workflow, wfStatus);
          } else if (
            [
              "Bidding Closed",
              "Winner Selected",
              "Winner Notified",
              "Closed",
              "Archived",
            ].includes(workflow)
          ) {
            status = "complete";
          } else {
            status = "incomplete";
          }
        }
      } else if (stepOption === "Winner Selected") {
        if (workflow === "Winner Selected") {
          if (["Open", "Active"].includes(wfStatus)) {
            status = "complete";
          } else if (["Closed", "Archived"].includes(wfStatus)) {
            status = "complete"; //change this to market complete instead of skipped when Status is Closed
          } else {
          }
        } else {
          if (["Winner Notified", "Closed", "Archived"].includes(workflow)) {
            status = "complete";
          } else if (["Closed", "Archived"].includes(wfStatus)) {
            status = "skipped";
          } else {
            status = "incomplete";
          }
        }
      } else if (stepOption === "Winner Notified") {
        if (workflow === "Winner Notified" || workflow === "winner notified") {
          if (["Open", "Active"].includes(wfStatus)) {
            status = "complete";
          } else if (["Closed", "Archived"].includes(wfStatus)) {
            status = "complete"; //change this to mark it complete instead of skipped when Status is Closed
          } else {
            if (["Closed", "Archived"].includes(workflow)) {
              status = "complete";
            } else {
              status = "skipped";
            }
          }
        } else {
          if (["Closed", "Archived"].includes(workflow)) {
            status = "complete";
          } else if (["Closed", "Archived"].includes(wfStatus)) {
            status = "skipped";
          } else if (["Winner Selected"].includes(workflow)) {
            status = "current";
          } else {
            status = "incomplete";
          }
        }
      } else if (stepOption === "Closed") {
        if (["Closed"].includes(wfStatus)) {
          status = "current";
        } else {
          if (["Archived"].includes(wfStatus)) {
            status = "complete";
          }
        }
      } else if (stepOption === "Archived") {
        if (["Archived"].includes(wfStatus)) {
          status = "complete";
        } else {
          status = "incomplete";
        }
      } else {
      }

      return { name: stepOption, status };
    });

    return steps;
  };

  const onReinvite = async (InviteList) => {
    const sent = await _assessment.sendAssessment(InviteList, solicitation._id);
    if (sent) {
      console.log("Sent invitations", sent);
      //update solictation.inviations with the new sent invitations
      let updatedSolicitation = { ...solicitation };
      updatedSolicitation.invitations = sent.invitations;
      setSolicitation(updatedSolicitation);
      //close the modal
      // setInvitePeopleModal(false);
    }
  };

  const handleDeleteSolicitationClick = (e) => {
    e.preventDefault();
    console.log("handleDeleteProjectClick", solicitation._id);
    setShowDeleteConfirm(true);
    // setDeletionEvent(e);
    // setIdToDelete(id);
  };

  const bidCloseRequest = async (e) => {
    const solicitations = await Solicitations.closeBidRequestById({
      _id: id,
      projectId: projectId,
    });
    if (solicitations) {
      setShowCloseBidRequestConfirm(false);
      handleCloseSolicitation(e);
    } else {
      //show error meassage
      setShowCloseBidRequestConfirm(false);
    }
  };

  const deleteSolicitation = async (e) => {
    // e.preventDefault();
    console.log("deleteProject", solicitation._id);
    // setLoading(true);
    const deleted = await Solicitations.delete(solicitation._id);
    // const deleted = true;
    if (deleted) {
      //navigate back

      setTimeout(() => {
        console.log("deleteSoliciation success");
        setSuccess(true);

        history.goBack();
      }, 1000);
    } else {
      console.log("deleteSoliciation error");
      console.log("Set Dialog Box success to false");

      // setLoading(false);
      setErrMessage("Error deleting solicitation");
    }
  };
  const handleViewInvites = (e) => {
    e.preventDefault();
    setViewInvites(true);
  };
  return (
    <>
      <div
        className="overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
        scrollbar-thumb-rounded-full scrollbar-track-rounded-full rounded bg-white border border-gray-200 shadow-sm relative flex min-h-full max-h-full flex-col"
      >
        <div className="mx-auto w-full max-w-14xl flex-grow lg:flex ">
          <div id="mainContent" className="bg-white lg:min-w-0 lg:flex-1">
            <div>
              <div
                id="first_row"
                className="px-2 py-2 flex flex-row lg:flex-row  justify-between "
              >
                {/* left side */}
                <div className="w-2/3  ">
                  {/* header */}
                  <div className="flex items-center ">
                    {/* Welcome banner */}
                    <Button
                      iconButton
                      handleClick={(e) => navigateBack(e)}
                      color="text"
                    >
                      <Icon
                        className="h-6 w-6"
                        //back button

                        icon="ic:baseline-arrow-back"
                      />
                    </Button>{" "}
                    <h2
                      id="applicant-information-title"
                      className="text-lg font-medium leading-6 text-slate-900"
                    >
                      Bid Request Details
                      {/* {solicitation && solicitation.name} */}
                    </h2>
                  </div>
                  <div className="flex items-center">
                    {" "}
                    {solicitation.deadline && (
                      <p className="text-sm font-medium text-slate-700">
                        Deadline:
                        <a href="#" className="text-slate-700"></a>{" "}
                        <time
                          dateTime="2020-08-25"
                          className="text-secondary-500"
                        >
                          {solicitation &&
                            solicitation.deadline &&
                            utils.translateDaysRemaining(
                              utils.daysRemaining(solicitation.deadline)
                            )}
                        </time>
                      </p>
                    )}
                  </div>
                </div>
                {/* right side */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 pr-2">
                  <div className="flex flex-col lg:flex-row justify-end items-center">
                    {/* Welcome banner */}
                    <div className="mb-0 lg:mb-0 inline-flex  ">
                      {/* <div className="flex items-center"> */}
                      {solicitation.status == "Archived" &&
                        profile.role === "Super Admin" && (
                          <div className="flex mx-3">
                            <Button
                              loader={false}
                              size="xs"
                              // handleClick={() => manageLoading("Archive", true)}
                              handleClick={(e) => {
                                handleDeleteSolicitationClick(e);
                              }}
                              color="red"
                            >
                              <Icon
                                className="mr-2 h-5 w-5"
                                //delete button
                                icon="ic:baseline-delete"
                              />
                              Delete{" "}
                            </Button>
                          </div>
                        )}
                      {(solicitation.status === "Open" ||
                        solicitation.status === "Active" ||
                        solicitation.status === "Inactive") &&
                        workflowStatus == "Bidding Closed" && (
                          // &&
                          //
                          <Button
                            size="xs"
                            handleClick={(e) => {
                              handleChangeSolicitationStatus(
                                e,
                                "Reviewing Bids"
                              );
                              // handleSaveAsTemplateClick(e);
                            }}
                            color="text"
                          >
                            <div className="flex">
                              <Icon
                                className="mr-2 h-5 w-5"
                                icon="ci:folder-close"
                              />
                              Reviewing Bids
                            </div>
                          </Button>
                        )}
                      {(solicitation.status === "Open" ||
                        solicitation.status === "Active" ||
                        solicitation.status === "Inactive") &&
                        ![
                          "Created",
                          "Accepting Bids",
                          "Winner Selected",
                          "Winner Notified",
                        ].includes(workflowStatus) && (
                          //
                          <Button
                            size="xs"
                            handleClick={(e) => {
                              handleChangeSolicitationStatus(
                                e,
                                "Accepting Bids"
                              );
                              // handleSaveAsTemplateClick(e);
                            }}
                            color="text"
                          >
                            <div className="flex">
                              <Icon
                                className="mr-2 h-5 w-5"
                                icon="lets-icons:folders-group"
                              />{" "}
                              Accept Bids
                            </div>
                          </Button>
                        )}
                      {(solicitation.status === "Open" ||
                        solicitation.status === "Active" ||
                        solicitation.status === "Inactive") &&
                        workflowStatus === "Accepting Bids" && (
                          //
                          <Button
                            size="xs"
                            handleClick={(e) => {
                              handleChangeSolicitationStatus(
                                e,
                                "Bidding Closed"
                              );
                              // handleSaveAsTemplateClick(e);
                            }}
                            color="text"
                          >
                            <div className="flex">
                              <Icon
                                className="mr-2 h-5 w-5"
                                icon="ci:folder-close"
                              />
                              Stop Accepting Bids
                            </div>
                          </Button>
                        )}

                      {/* <Button
                          size="xs"
                          handleClick={(e) => {
                            setInvitePeopleModal(!invitePeopleModal);
                          }}
                          color="text"
                        >
                          <PlusIcon
                            className="mr-2  h-5 w-5 "
                            aria-hidden="true"
                          />
                          Invite Other People{" "}
                        </Button> */}

                      {/* <CommentButton
                          size="xs"
                          from="requestor"
                          commentType="solicitation"
                          solicitation={solicitation}
                          handlePost={handlePostComment}
                          comments={comments}
                          commentText={commentText}
                          handleLike={(e, id) => handleLike(e, id)}
                          setCommentText={setCommentText}
                          loading={commentLoading}
                          color="text"
                          profile={profile}
                        /> */}
                      {/* </FeatureFlag> */}

                      {/* <DropdownMenu
                          id="transition_workflow"
                          buttonText="Change Status"
                          buttonColor="text"
                          menuItems={[
                            {
                              name: "Accept Bids",

                              icon: "mdi:archive",
                              action: (e) => {
                                solicitation.status !== "Closed"  && solicitation.workflow !== "Winner Selected" && solicitation.workflow !== "Winner Notified" ?   handleConfirmCloseBidRequest(e) :
                                handleCloseSolicitation(e) ;
                                handleChangeSolicitationStatus(e, "close");
                              },
                              show: solicitation.status !== "Archived",
                              status: "",
                              group: "Status",
                            },
                          ]}
                        /> */}
                      <DropdownMenu
                        id="menu"
                        buttonText="Manage Request"
                        menuItems={[
                          {
                            name: "View Invites",
                            icon: "mdi:person",
                            action: (e) => {
                              handleViewInvites(e);
                            },
                            show: true,
                            group: "Sharing ",
                          },
                          {
                            name: "Invite others",
                            icon: "mdi:person-add",

                            action: (e) => {
                              setInvitePeopleModal(!invitePeopleModal);
                            },
                            show:
                              Solicitations.getCurrentWorkflow(
                                solicitation.workflow_status
                              )?.name === "Accepting Bids",
                            group: "Sharing ",
                          },
                          {
                            name:
                              solicitation.status !== "Closed"
                                ? "Close request"
                                : "Re-open request",
                            icon: "mdi:archive",
                            action: (e) => {
                              handleCloseSolicitation(e);
                            },
                            show: solicitation.status !== "Archived",
                            status: "",
                            group: "Status ",
                          },

                          {
                            name: "Archive request",
                            icon: "mdi:trash-can",
                            action: (e) => {
                              handleChangeSolicitationStatus(e, "archive");
                            },
                            show:
                              solicitation.status == "Closed" &&
                              solicitation.status !== "Avtive",
                            group: "Status ",
                          },
                          //

                          {
                            name: "Save as Template",
                            icon: "mdi:content-save",
                            action: (e) => {
                              handleSaveAsTemplateClick(e);
                            },
                            disabled: false,
                            status: "",
                            show: true,
                            group: "Templates ",
                          },
                        ]}
                      />
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="stepper_row"
                className="hidden md:flex pb-2 bg-gray-50  items-center border-t border-b border-gray-200  w-full"
              >
                <div className="px-6 block w-full">
                  <Steps steps={steps} />
                </div>
              </div>
              <div
                id="tab_row"
                className="px-2 flex flex-row lg:flex-row justify-between items-center "
              >
                <div className="  ">
                  {tabs && tabs.length > 0 && (
                    <div className="col-span-full space-y-2">
                      <div className="my-3">
                        <Tabs
                          tabs={tabs && tabs.length > 0 ? tabs : []}
                          setSelectedTab={setSelectedTab}
                          selectedTab={selectedTab}
                          cleanUpKeys={cleanUpKeys}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* right side */}
                <div className="">
                  {/* RIGHT SIDE ACTIONS */}
                  <CommentButton
                    size="xs"
                    from="requestor"
                    commentType="solicitation"
                    solicitation={solicitation}
                    handlePost={handlePostComment}
                    comments={comments}
                    commentText={commentText}
                    handleLike={(e, id) => handleLike(e, id)}
                    setCommentText={setCommentText}
                    loading={commentLoading}
                    color="text"
                    profile={profile}
                  />
                </div>
              </div>

              <div className="my-1">
                <hr className="border-gray-200" />
              </div>
              <div
                style={{ maxHeight: "60vh", minHeight: "60vh" }}
                className=" col-span-8  overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full "
              >
                {/* <div className="border-t border-gray-200 px-6 w-full"></div> */}

                <div>
                  <SolicitationSummary
                    solicitation={solicitation}
                    tabs={tabs}
                    project={project}
                    selectedTab={selectedTab}
                    cleanUpKeys={cleanUpKeys}
                    handleSetPdfModalOpen={handleSetPdfModalOpen}
                  />
                </div>
                <div id="bottomContent" className="  mx-6 mt-12 ">
                  {/* <div className="col-span-full">
                    <h3
                      id="notes-title"
                      className="border-b border-gray-200 pb-2 text-md uppercase font-bold text-secondary-600"
                    >
                      COMMENTS
                    </h3>
                    <div className=" justify-end "></div>
                  </div> */}
                </div>
                {/* <div id="bottomContent" className="  mx-6 mt-12 ">
                  <div className="col-span-full">
                    <h3
                      id="notes-title"
                      className="border-b border-gray-200 pb-2 text-md uppercase font-bold text-secondary-600"
                    >
                      COMMENTS
                    </h3>
                    <div className=" justify-end "></div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div
            id="sideContent_wrapper"
            className="w-full lg:w-1/3 bg-white pr-4 sm:pr-6 lg:flex-shrink-0 lg:border-l lg:border-gray-200 lg:pr-8 xl:pr-0"
          >
            {/* <div id="tabs" className="pl-4">
              TABS
            </div> */}
            <div id="sideContent" className="pl-4 lg:w-400">
              {sideContent}
            </div>
          </div>
        </div>
      </div>
      {/* <Drawer
        title="Discussion"
        show={commentDrawerOpen}
        setShow={setCommentDrawerOpen}
      >
        {" "}
        <div
          className="h-full    col-span-full px-0 py-3 sm:px-0 
                  overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
                  scrollbar-thumb-rounded-full scrollbar-track-rounded-full
                  "
        >
          <Comments
            solicitation={solicitation}
            handlePost={handlePostComment}
            comments={comments}
            commentText={commentText}
            setCommentText={setCommentText}
          />
        </div>
      </Drawer> */}
      {/* <Drawer
        title="Discussion"
        show={commentDrawerOpen}
        setShow={setCommentDrawerOpen}
      >
        {" "}
        <div
          className="h-full    col-span-full px-0 py-3 sm:px-0 
                  overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
                  scrollbar-thumb-rounded-full scrollbar-track-rounded-full
                  "
        >
          <Comments
            solicitation={solicitation}
            handlePost={handlePostComment}
            comments={comments}
            commentText={commentText}
            setCommentText={setCommentText}
          />
        </div>
      </Drawer> */}
      <ModalBasic
        id="invites_modal"
        modalOpen={invitePeopleModal}
        setModalOpen={(e) => {
          setInvitePeopleModal(!invitePeopleModal);
        }}
        title={"Invite Other People"}
        scrollable={true}
        // size={"md"}
        showClose={true}
        saveButtonText={"Save"}
        saveButton={false}
        saveButtonClick={() => {}}
        handleCancelClick={(e) => {
          setInvitePeopleModal(!invitePeopleModal);
        }}
        showFooter={false}
      >
        <div className="container p-5">
          <InviteComponent
            type="reinvite"
            onSave={onReinvite}
            solicitation={solicitation}
            readOnly={invitePeopleModal ? false : false}
          />
        </div>
      </ModalBasic>
      <ModalBasic
        id="view_invites"
        modalOpen={viewInvites}
        setModalOpen={(e) => {
          setViewInvites(!viewInvites);
        }}
        title={"Already Invited"}
        scrollable={true}
        // size={"md"}
        showClose={true}
        saveButtonText={"Done"}
        saveButton={false}
        saveButtonClick={() => {}}
        handleCancelClick={(e) => {
          setViewInvites(!viewInvites);
        }}
        showFooter={false}
      >
        <div className="container p-5">
          <InviteComponent
            type="view_invite"
            // onSave={onReinvite}
            solicitation={solicitation}
            readOnly={viewInvites ? true : false}
          />
        </div>
      </ModalBasic>
      <ModalBasic
        id="compare_modal"
        modalOpen={compareModalOpen}
        setModalOpen={(e) => handleShowCompareModalClick(e)}
        title={"Compare Bids"}
        size={"lg"}
        showClose={true}
        saveButtonText={"Save"}
        saveButton={false}
        saveButtonClick={saveTemplate}
        handleCancelClick={(e) => handleShowCompareModalClick(e)}
        showFooter={false}
      >
        {/* <BidCompare
          modalOpen={compareModalOpen}
          data={
            solicitation.responses
              ? solicitation.responses.filter(
                  (response) => response.status !== "declined"
                )
              : []
          }
          selectWinner={selectWinner}
          notifyWinner={notifyWinner}
          loading={selectWinnerLoading}
          errorMessage={errMessage}
          solicitation={solicitation}
          setCompareModalOpen={setCompareModalOpen}
        /> */}
      </ModalBasic>
      <ModalBasic
        id="save_template"
        modalOpen={saveAsTemplateModalOpen}
        setModalOpen={cancelWindowClick}
        title={"Save Template"}
        showClose={true}
        size={"lg"}
        scrollable
        saveButtonText={"Save"}
        saveButton={true}
        saveButtonClick={saveTemplate}
        handleCancelClick={cancelWindowClick}
        showFooter={true}
      >
        {/* form to save name and description for template */}
        {/* Needs to be a component */}
        <div className="p-6">
          <h2 className="text-xl font-medium text-gray-900">Save Template</h2>
          <Tip>Save this solicitation as a template for future use.</Tip>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="name"
                  value={templateName}
                  onChange={(e) => onFieldChange(e, "Name")}
                  className="max-w-lg block w-full shadow-sm focus:ring-secondary-500 focus:border-secondary-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <textarea
                  id="description"
                  name="description"
                  rows={3}
                  value={templateDescription}
                  onChange={(e) => onFieldChange(e, "Description")}
                  className="max-w-lg block w-full shadow-sm focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm border-gray-300 rounded-md"
                  defaultValue={""}
                />
                <p className="mt-2 text-sm text-gray-500">
                  Brief description for your template.
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
              {err && (
                <div className="text-red-500 text-sm font-medium">
                  {errMessage}
                </div>
              )}
            </div>
            {/* Buttons Save and Cancel */}
          </div>
        </div>
      </ModalBasic>
      <DialogBox
        handleConfirmClick={deleteSolicitation}
        handleCancelClick={() => setShowDeleteConfirm(false)}
        open={showDeleteConfirm}
        // loading={true}
        success={success}
        successMessage={"Solicitation deleted"}
        title="Confirm Deletion"
        message={"Are you sure you want to delete this bid request?"}
        OKbuttonText={"Delete"}
        CancelButton={"Cancel"}
      />
      <DialogBox
        handleConfirmClick={bidCloseRequest}
        handleCancelClick={() => setShowCloseBidRequestConfirm(false)}
        open={showCloseBidRequestConfirm}
        loading={true}
        //success={success}
        successMessage={"close bid"}
        title="Confirme close a bid"
        message={
          "Are you sure you want to close the bid without choosing a winner? Upon closing this bid request, all vendors will receive an email notification."
        }
        OKbuttonText={"Confirm"}
        CancelButton={"Cancel"}
      />
    </>
  );
}

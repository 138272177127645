import React from "react";
import { useState, useEffect, useHistory } from "react";
import { useLocation } from "react-router-dom";
import Button from "../../../elements/Button";
export default function VendorSignUp() {
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const location = useLocation();
  return (
    <div>
      <div>
        <h1>Vendor Sign Up</h1>
      </div>
      <div>{JSON.stringify(location.state)}</div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import "./charts/ChartjsConfig";
import "./css/style.scss";
//Import Layout
import Layout from "./layouts/layout";
import LoginLayout from "./layouts/loginLayout";
import ActionPlan from "./pages/ActionPlan";
import Analytics from "./pages/Analytics";
import Assessment from "./pages/Assessment";
import AssessmentHub from "./pages/AssessmentHub";
import AssessmentSelect from "./pages/AssessmentSelect";
import Calendar from "./pages/Calendar";
import Campaigns from "./pages/Campaigns";
import AccordionPage from "./pages/component/AccordionPage";
import AlertPage from "./pages/component/AlertPage";
import AvatarPage from "./pages/component/AvatarPage";
import BadgePage from "./pages/component/BadgePage";
import BreadcrumbPage from "./pages/component/BreadcrumbPage";
import ButtonPage from "./pages/component/ButtonPage";
import DropdownPage from "./pages/component/DropdownPage";
import FormPage from "./pages/component/FormPage";
import IconsPage from "./pages/component/IconsPage";
import ModalPage from "./pages/component/ModalPage";
import PaginationPage from "./pages/component/PaginationPage";
import TabsPage from "./pages/component/TabsPage";
import TooltipPage from "./pages/component/TooltipPage";
import ContactPage from "./pages/Contact";
// Import pages
import Dashboard from "./pages/Dashboard";
import Cart from "./pages/ecommerce/Cart";
import Cart2 from "./pages/ecommerce/Cart2";
import Customers from "./pages/ecommerce/Customers";
import Invoices from "./pages/ecommerce/Invoices";
import Orders from "./pages/ecommerce/Orders";
import Pay from "./pages/ecommerce/Pay";
import Product from "./pages/ecommerce/Product";
import Shop from "./pages/ecommerce/Shop";
import Shop2 from "./pages/ecommerce/Shop2";
import Employees from "./pages/Employees";
// import EmployeeProfile from "./pages/employees/EmployeeProfile";
import ForgotPassword from "./pages/ForgotPassword";
import AccessDenied from "./pages/AccessDenied";
import Home from "./pages/Home";
import Inbox from "./pages/Inbox";
import LearningHub from "./pages/LearningHub";
import Marketplace from "./pages/Marketplace";
import Messages from "./pages/Messages";
import Onboard from "./pages/Onboard";
import Onboarding01 from "./pages/Onboarding01";
import Onboarding02 from "./pages/Onboarding02";
import Onboarding03 from "./pages/Onboarding03";
import Onboarding04 from "./pages/Onboarding04";
import Preassessment01 from "./pages/Preassessment-01";
import Preassessment02 from "./pages/Preassessment-02";
import Preassessment03 from "./pages/Preassessment-03";
import ResetPassword from "./pages/ResetPassword";
import ResetPasswordEmail from "./pages/ResetPasswordEmail";
import Account from "./pages/settings/Account";
import Apps from "./pages/settings/Apps";
import Billing from "./pages/settings/Billing";
import Feedback from "./pages/settings/Feedback";
import Notifications from "./pages/settings/Notifications";
import Plans from "./pages/settings/Plans";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import SelectTenant from "./pages/SignInSelectTenant";
import Tasks from "./pages/Tasks";
import Profile from "./pages/team/Profile";
import TeamTabs from "./pages/team/TeamTabs";
import TeamTiles from "./pages/team/TeamTiles";
import UserImport from "./pages/UserImport";
import Changelog from "./pages/utility/Changelog";
import EmptyState from "./pages/utility/EmptyState";
import Faqs from "./pages/utility/Faqs";
import PageNotFound from "./pages/utility/PageNotFound";
import Roadmap from "./pages/utility/Roadmap";
import SignupConfirm from "./pages/Signup Confirm";
import MFA from "./pages/MFA";
import PrivateRoute from "./routes/PrivateRoute";
import VerifyGuest from "./pages/VerifyGuest";
import ThankYou from "./pages/ThankYou";
import AssessmentLayout from "./layouts/assessmentLayout";
import EmployeeProfile from "./pages/EmployeeProfile";
import ToastNotification from "./components/ToastNotification";
import { sockets } from "./modules/_notifications";
import Header from "./partials/Header";
//JDO
import Impediments from "./pages/Impediments";
import Competencies from "./pages/Competencies";
import ActionPlansPage from "./pages/ActionPlansPage";
import ImpedimentDetails from "./pages/impedimentDetails";
import Directory from "./pages/directory/Directory.js";
import LayoutTwoColumnMainLeft from "./layouts/LayoutTwoColumnMainLeft";
import TemplateBuilder from "./pages/TemplateBuilder";
import TemplateList from "./pages/TemplateList";
import TemplateViewer from "./pages/TemplateViewer";
import ProjectList from "./pages/projects/Projects";
import ProjectDetails from "./pages/projects/ProjectDetails";
import SolicitationDetails from "./pages/projects/SolicitationDetails";
import AuditPage from "./pages/AuditPage";
import VerifyVendor from "./pages/VerifyVendor";
import VendorResponsePage from "./pages/projects/public/VendorResponsePage";
import VendorReponseLayoutScrollable from "./layouts/vendor-response-layout-scrollable";
import VendorWelcomePage from "./pages/projects/public/VendorResponseWelcome";
import MarketplaceNew from "./pages/marketplace/Marketplace";
import AcceptInvite from "./pages/AcceptInvite";
import VendorSignUp from "./pages/projects/public/VendorSignUp";
import VenderHome from "./pages/vendor/VendorHome";
import VendorRedirect from "./pages/projects/public/VendorRedirect";
import { _auth } from "./modules/_auth";
import VendorProjects from "./pages/vendor/VendorProjects";
import VendorProjectDetails from "./pages/vendor/VendorProjectDetails";
import ResetPasswordLink from "./pages/ResetPasswordLink";
import BidComparePage from "./pages/projects/BidComparePage";
import MyPlan from "./pages/MyPlan/index.js";
import SolicitationsPage from "./pages/projects/SolicitationsPage.js";
import LayoutWithCard from "./layouts/layout-with-card.js";
import DashboardLayout from "./layouts/layout-dashboard.js";
import VendorResponsePageV2 from "./pages/projects/public/VendorResponsePageV2.js";

// const socket = sockets.connect();

function Routes() {
  const location = useLocation();
  const [tenantType, setTenantType] = useState();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  useEffect(() => {
    let profile = _auth.getUserProfile();
    if (profile && profile.tenantType) {
      setTenantType(profile.tenantType);
    } else {
      setTenantType("consumer");
    }
  }, [location.pathname]); // triggered on route change

  return (
    <>
      {" "}
      {/* <Header /> */}
      <Switch>
        {/* Public Login and Sign Up Routes */}
        <Route exact path="/projects/project-details/vendor-bid-info-v2">
          <VendorResponsePageV2 />
        </Route>
        <Route exact path="/projects/project-details/vendor-bid-info">
          <VendorResponsePage />
        </Route>
        <Route
          exact
          path="/verify-guest/:token/:email/:tenantId/:type/:userId/:inviteId/:solicitationId"
        >
          <LoginLayout>
            <VerifyVendor />
          </LoginLayout>
        </Route>
        <Route exact path="/accept-invite/:token/:id/:tenantId">
          <LoginLayout>
            <AcceptInvite />
          </LoginLayout>
        </Route>
        <Route exact path="/projects/project-details/bid-response">
          <VendorReponseLayoutScrollable>
            <VendorWelcomePage />
          </VendorReponseLayoutScrollable>
        </Route>
        <Route exact path="/vendorsignup">
          <LoginLayout>
            <VendorSignUp />
          </LoginLayout>
        </Route>
        <Route exact path="/vendor-options">
          <LoginLayout>
            <VendorRedirect />
          </LoginLayout>
        </Route>
        {/* /projects/project-details/solicitation-details */}
        <PrivateRoute exact path="/vendor/projects">
          <Layout>
            <VendorProjects />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/vendor/project-details">
          <Layout>
            <VendorProjectDetails />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/provider-directory">
          <Layout>
            <MarketplaceNew />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/projects">
          <Layout>
            <ProjectList />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/projects/project-details/:projectId">
          <Layout>
            <ProjectDetails />
          </Layout>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/projects/project-details/:projectId/solicitation-details/:solicitationId"
        >
          <Layout>
            <SolicitationDetails />
          </Layout>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/projects/project-details/:projectId/solicitation-details/:solicitationId/bid-compare"
        >
          <Layout>
            <BidComparePage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/audit">
          <Layout>
            <AuditPage />
          </Layout>
        </PrivateRoute>
        <Route exact path="/">
          <LoginLayout>
            <Signin />
          </LoginLayout>
        </Route>
        <Route exact path="/signin">
          <LoginLayout>
            <Signin />
          </LoginLayout>
        </Route>
        <Route exact path="/signup">
          <LoginLayout>
            <Signup />
          </LoginLayout>
        </Route>
        <Route exact path="/signupconfirmation">
          <LoginLayout>
            <SignupConfirm />
          </LoginLayout>
        </Route>
        <Route exact path="/forgot-password">
          <LoginLayout>
            <ResetPassword />
          </LoginLayout>
        </Route>
        <Route exact path="/reset-password">
          <LoginLayout>
            <ForgotPassword />
          </LoginLayout>
        </Route>
        <Route exact path="/reset-password-email/:token/:id">
          <LoginLayout>
            <ResetPasswordEmail />
          </LoginLayout>
        </Route>
        <Route exact path="/verify-link/:token">
          <LoginLayout>
            <ResetPasswordLink />
          </LoginLayout>
        </Route>
        <Route exact path="/mfa">
          <LoginLayout>
            <MFA />
          </LoginLayout>
        </Route>
        <Route exact path="/access-denied">
          <LoginLayout>
            <AccessDenied />
          </LoginLayout>
        </Route>
        <PrivateRoute exact path="/select-tenant">
          <LoginLayout>
            <SelectTenant />
          </LoginLayout>
        </PrivateRoute>
        <Route exact path="/accept-invite/:token/:id/:tenantId">
          <LoginLayout>
            <AcceptInvite />
          </LoginLayout>
        </Route>
        {/* Public Vendor Routes */}
        <Route exact path="/vendorsignup">
          <LoginLayout>
            <VendorSignUp />
          </LoginLayout>
        </Route>
        <PrivateRoute exact path="/vendor/projects">
          <Layout>
            <VendorProjects />
          </Layout>
        </PrivateRoute>
        <Route
          exact
          path="/verify-guest/:token/:email/:tenantId/:type/:userId/:inviteId/:solicitationId/:vendorCompanyId"
        >
          <LoginLayout>
            <VerifyVendor />
          </LoginLayout>
        </Route>
        <Route
          exact
          path="/verify-guest/:token/:email/:tenantId/:type/:userId/:inviteId/:solicitationId/:vendorCompanyId/:version"
        >
          <LoginLayout>
            <VerifyVendor />
          </LoginLayout>
        </Route>
        <Route
          exact
          path="/verify-guest/:token/:email/:tenantId/:type/:userId/:inviteId/:solicitationId"
        >
          <LoginLayout>
            <VerifyVendor />
          </LoginLayout>
        </Route>
        <Route
          exact
          path="/verify-guest/:token/:email/:tenantId/:type/:userId/:inviteId"
        >
          <LoginLayout>
            <VerifyVendor />
          </LoginLayout>
        </Route>
        <Route exact path="/vendor-options">
          <LoginLayout>
            <VendorRedirect />
          </LoginLayout>
        </Route>
        {/* Private Vendor Routes */}
        {/* Private Routes */}
        <PrivateRoute exact path="/home">
          <Layout>
            {tenantType == "vendor" ? <VenderHome /> : <Dashboard />}
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/dashboard">
          <DashboardLayout>
            <Dashboard />
          </DashboardLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/projects">
          <Layout>
            <ProjectList />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/bid-requests">
          <LayoutWithCard>
            <SolicitationsPage />
          </LayoutWithCard>
        </PrivateRoute>
        <PrivateRoute exact path="/board">
          <Layout>
            <MyPlan />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/provider-directory">
          <Layout>
            <MarketplaceNew />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/projects/project-details/:projectId">
          <Layout>
            <ProjectDetails />
          </Layout>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/projects/project-details/:projectId/solicitation-details/:solicitationId"
        >
          <Layout>
            <SolicitationDetails />
          </Layout>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/projects/project-details/:projectId/solicitation-details/:solicitationId/bid-compare"
        >
          <Layout>
            <BidComparePage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/audit">
          <Layout>
            <AuditPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/settings/account">
          <LoginLayout>
            <Account />
          </LoginLayout>
        </PrivateRoute>
        <Route exact path="/reset-password">
          <ForgotPassword />
        </Route>
        <PrivateRoute exact path="/onboarding">
          <Onboard />
        </PrivateRoute>
        {/* NOT YET USED BUT KEEP */}
        <PrivateRoute exact path="/template-builder">
          <Layout>
            <TemplateBuilder />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/template-list">
          <Layout>
            <TemplateList />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/template-viewer">
          <Layout>
            <TemplateViewer />
          </Layout>
        </PrivateRoute>
        {/* DELETE */}
        <PrivateRoute exact path="/User">
          <Layout>
            <UserImport />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/AddUser">
          <Layout>
            <FormPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/contact">
          <Layout>
            <ContactPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/competencies">
          <Layout>
            <Competencies />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/impediments">
          <Layout>
            <Impediments />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/impediments/details">
          <Layout>
            <ImpedimentDetails />
          </Layout>
        </PrivateRoute>
        <Route exact path="/assessmenthub">
          <Layout>
            <AssessmentHub />
          </Layout>
        </Route>
        <PrivateRoute exact path="/learn/regulations">
          <Layout>
            <Profile />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/learn">
          <Layout>
            <Campaigns />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/learninghub">
          <Layout>
            <LearningHub />
          </Layout>
        </PrivateRoute>
        <Route exact path="/assessmentselect">
          <LoginLayout>
            <AssessmentSelect />
          </LoginLayout>
        </Route>
        <Route exact path="/assessment">
          <AssessmentLayout>
            <Assessment />
          </AssessmentLayout>
        </Route>{" "}
        <Route exact path="/thankyou">
          <LoginLayout>
            <ThankYou />
          </LoginLayout>
        </Route>
        <Route exact path="/actionplan">
          <Layout>
            <ActionPlan />
          </Layout>
        </Route>
        <Route exact path="/analytics">
          <Layout>
            <Analytics />
          </Layout>
        </Route>
        <Route exact path="/employees">
          <Layout>
            <Employees />
          </Layout>
        </Route>
        <Route exact path="/employees/profile">
          <Layout>
            <EmployeeProfile />
          </Layout>
        </Route>
        <Route exact path="/team/profile">
          <Layout>
            <Profile />
          </Layout>
        </Route>
        <Route exact path="/campaigns">
          <Layout>
            <Campaigns />
          </Layout>
        </Route>
        <PrivateRoute exact path="/marketplace">
          <Layout>
            {" "}
            <MarketplaceNew />
          </Layout>
        </PrivateRoute>
        {/* Keep for Reference */}
        <PrivateRoute exact path="/settings/notifications">
          <Notifications />
        </PrivateRoute>
        <PrivateRoute exact path="/settings/apps">
          <Apps />
        </PrivateRoute>
        <PrivateRoute exact path="/settings/plans">
          <Plans />
        </PrivateRoute>
        <PrivateRoute exact path="/settings/billing">
          <Billing />
        </PrivateRoute>
        {/* NOT CLASSIFIED  */}
        <Route exact path="/utility/404">
          <PageNotFound />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
      {/* <ToastNotification show={true} socket={socket} /> */}
      {/* </Layout> */}
      {/* </Route> */}
      {/* </Switch> */}
    </>
  );
}

export default Routes;

import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Tooltip from "./Tooltip";
import {
  BadgeCheckIcon,
  CollectionIcon,
  DocumentChartBarIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  MinusCircleIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { _user } from "../modules/_user";
import Skeleton from "./Skeleton";

function TableItem(props) {
  const history = useHistory();
  const fromDashboard = props.fromDashboard;
  const counts = props.counts;
  const showCheckbox = props.showCheckbox;
  const data = props.data;
  const [loading, setLoading] = React.useState(false);
  // const showHideModal = props.showHideModal;
  const setModalItem = () => {
    console.info("ATTENTION - C-TABLE-ITEM - SHOW MODAL");
    props.setModalItem(props.thisAssessment);
  };
  console.info("C-Table", props);

  const onDisableUser = async () => {
    let response = await _user.disableUser(data.id);
    // //debugger
    if (response) {
      props.setUserData(response);
      return;
    }
  };

  const onEnableUser = async () => {
    let response = await _user.enableUser(data.id);
    // //debugger
    if (response) {
      props.setUserData(response);
      return;
    }
  };

  const onDeleteUser = async () => {
    console.log("Delete user");
    let response = await _user.deleteUserFromTenant(data);
    // //debugger
    if (response) {
      props.setUserData(response);
      return;
    }
  };
  const handleUpdateUserStatus = async (status) => {
    setLoading(true);

    let response = await _user.updateUserStatus(data, status);

    if (response) {
      props.setUserData(response);
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  const onResendInvite = async () => {
    setLoading(true);
    const invite = await _user.reinviteUser(data.id);
    if (invite) {
      setLoading(false);
      return;
    }
    // setTimeout(() => {}, 2000);
    // setLoading(false);
  };

  // const goToActionPlan = props.goToActionPlan;
  const typeColor = (type, status) => {
    if (type == "risk") {
      switch (status) {
        case "Normal":
          return "bg-green-100 text-green-600";
        case "Medium":
          return "bg-yellow-100 text-yellow-600";
        case "High":
          return "bg-red-100 text-red-600";
        default:
          return "";
      }
    }
    if (type == "status") {
      switch (status) {
        case "Enabled":
          return "text-green-600 bg-green-100";
        case "Disabled":
          return "text-slate-500 bg-gray-100";
        case "Invited":
          return "text-primary-500 bg-primary-100";
        default:
          return "text-slate-500 bg-gray-100";
      }
    }
  };
  return (
    <tr>
      {showCheckbox && showCheckbox == true && (
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">Select</span>
              <input
                id={props.id}
                className="form-checkbox"
                type="checkbox"
                onChange={props.handleClick}
                checked={props.isChecked}
              />
            </label>
          </div>
        </td>
      )}
      {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="flex items-center relative">
          <button>
            <svg className={`w-4 h-4 shrink-0 fill-current ${props.fav ? 'text-yellow-500' : 'text-slate-300'}`} viewBox="0 0 16 16">
              <path d="M8 0L6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934h-6L8 0z" />
            </svg>
          </button>
        </div>
      </td> */}
      {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{data.id}</div>
      </td> */}
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        {/* <div className="flex items-center"> */}
        {/* <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
            <img
              className="rounded-full"
              src={props.image}
              width="40"
              height="40"
              alt={props.name}
            />
          </div> */}
        <div className="text-left font-medium text-light-blue-500">
          <div className="text-left inline-flex">
            {" "}
            {data.name}{" "}
            {props.contributors === true && props.type != "pre" && (
              <Tooltip
                className={"whitespace-normal"}
                size="xl"
                bg="dark"
                position={"right"}
                title={"What is this?"}
                description={
                  "It looks like multiple people contributed to this assessment. That's awesome because cybersecurity is a team sport! You can see who answered each question in your action plan."
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="inline ml-2 h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                </svg>
              </Tooltip>
            )}
          </div>
        </div>
        {/* </div> */}
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        {/* <div className="text-left">{props.type}</div> */}
        <div className="text-left lowercase">{data.email}</div>
      </td>
      {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-center">{props.orders}</div>
      </td> */}
      {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div
          className={`text-left  rounded-full ${typeColor(
            "status",
            "Finished"
          )}`}
        >
          {data.role}
        </div>
      </td> */}

      <td className="text-left px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  ">
        <div className={`text-left  `}>
          <div
            className={`inline-flex items-center px-2 py-0.5 rounded text-xs uppercase font-medium bg-accent-700 text-accent-100 `}
          >
            {/* {props.risk === "null" || props.risk === null ? (
              ""
            ) : props.risk == "High" ? (
              <ExclamationIcon className="h-4 w-4 mr-2" />
            ) : (
              <ExclamationCircleIcon className="h-4 w-4 mr-2" />
            )} */}
            {data.tenantRole && data.tenantRole !== ""
              ? data.tenantRole
              : data.role}
            {console.log(data, "datatata")}
          </div>
        </div>
      </td>
      <td className="text-left px-2 first:pl-5 last:pr-10 py-3 whitespace-nowrap ">
        <div
          className={`inline-flex items-center px-2 py-0.5 rounded text-xs uppercase font-medium ${typeColor(
            "status",
            data.status
          )}`}
        >
          {data.status == "Invited" ? "Pending Invite" : data.status}{" "}
          {/*options - Invited, Active, Disabled */}
        </div>
      </td>

      <td className="text-left px-2 first:pl-5 last:pr-10 py-3 whitespace-nowrap">
        {data.status !== "Invited" ? (
          <div className="p-auto justify-center m-auto">
            {data.status !== "Disabled" ? (
              <Tooltip
                className={"whitespace-normal  inline-flex"}
                size="sm"
                bg="dark"
                position={"top"}
                description={"Disable this user"}
              >
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDisableUser();
                    // handleUpdateUserStatus("Disabled");
                  }}
                  className="inline-flex items-center p-2 ml-1 border border-transparent rounded-full 
                    shadow-sm text-zinc-600  bg-white hover:bg-gray-100 focus:outline-none 
                    "
                >
                  <MinusCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </Tooltip>
            ) : (
              <Tooltip
                className={"whitespace-normal  inline-flex"}
                size="sm"
                bg="dark"
                position={"top"}
                description={"Enable this user"}
              >
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onEnableUser();
                    // handleUpdateUserStatus("Enabled");
                  }}
                  className="inline-flex items-center p-2 ml-1 border border-transparent rounded-full shadow-sm text-zinc-600  bg-white hover:bg-gray-100 focus:outline-none "
                >
                  <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </Tooltip>
            )}
            {data.status !== "Enabled" && (
              <Tooltip
                className={"whitespace-normal  inline-flex"}
                size="sm"
                bg="dark"
                position={"top"}
                description={"Delete this user"}
              >
                <button
                  type="button"
                  onClick={(e) => {
                    // e.stopPropagation();
                    onDeleteUser();
                    // handleUpdateUserStatus("Deleted");
                  }}
                  className="inline-flex items-center p-2  border border-transparent rounded-full shadow-sm text-zinc-600  bg-white hover:bg-gray-100 focus:outline-none "
                >
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </Tooltip>
            )}
          </div>
        ) : (
          <div>
            {/* {console.info("LAST INVITE SENT", data)} */}
            {
              _user.canBeReinvited(data.lastInviteSent) ? (
                <button
                  type="button"
                  onClick={() => {
                    onResendInvite();
                  }}
                  className="ml-2 inline-flex items-center px-3 py-1 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none "
                >
                  {loading ? (
                    <Skeleton forButton={true} type={"spinner"} />
                  ) : (
                    <PencilSquareIcon
                      className="h-4 w-4 mr-2"
                      aria-hidden="true"
                    />
                  )}
                  Re-invite
                </button>
              ) : null
              // <div
              //   className=" inline-flex items-center  justify-center items-center inline-flex
              // "
              // >
              //   <span
              //     className={` px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-400`}
              //   >
              //     Invite Pending
              //   </span>
              // </div>
            }
            <Tooltip
              className={"whitespace-normal  inline-flex"}
              size="sm"
              bg="dark"
              position={"top"}
              description={"Delete this user"}
            >
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteUser();
                  // handleUpdateUserStatus("Deleted");
                }}
                className=" ml-2 inline-flex items-center p-2  border border-transparent rounded-full shadow-sm text-zinc-600  bg-white hover:bg-gray-100 focus:outline-none "
              >
                <TrashIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </Tooltip>
          </div>
          //Note from Oran:  We need to add a "Pending" status for users that are invited but not yet active.  After the link expires,
          //we can show the button to re-invite the user.  That way the Admin knows whether or not the invite has expired.
        )}
      </td>
    </tr>
  );
}

export default TableItem;

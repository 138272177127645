import React from "react";
import Badge from "./Badge";

const MenuTag = (props) => {
  let color;

  // Set color based on status
  switch (props.status) {
    case "New!":
      color = "green";
      break;
    case "Coming Soon!":
      color = "gray";
      break;
    case "Try it!":
      color = "secondary";
      break;
    case "Beta":
      color = "secondary";
      break;
    case "Preview!":
      color = "purple";
      break;
    default:
      color = "gray";
  }

  return (
    <span className={`ml-2 ${props.uppercase && "capitalize"}`}>
      <Badge color={color}>{props.status}</Badge>
    </span>
  );
};

export default MenuTag;

import ComingSoon from "../images/coming.png";
/* This example requires Tailwind CSS v2.0+ */
export default function EmptyState() {
  return (
    <div
      //   type="button"
      className="relative block w-fullrounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="mx-auto h-12 w-12 text-slate-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

      <span className="mt-2 block text-xl text-slate-800 font-regular">
        {/* <img src={ComingSoon} alt="" width="240" style={{}} /> */}
        Coming Soon
      </span>
    </div>
  );
}

import React from "react";

export default function ToggleButtons({
  size,
  value,
  text1,
  text2,
  onToggleChange,
  ...props
}) {
  const [checked, setChecked] = React.useState(value);
  const handleClick = (button) => {
    console.log("handleClick selection", button);
    setChecked(button);
    onToggleChange(button);
  };
  const getButtonSize = () => {
    switch (size) {
      case "sm":
        return "h-8";
      case "md":
        return "h-10";
      case "lg":
        return "h-12";
      default:
        return "h-8";
    }
  };
  return (
    <div className="mt-2 w-full col-span-2 text-sm flex items-start justify-left   ">
      <span className=" isolate inline-flex ">
        <button
          onClick={(e) => handleClick(text1)}
          type="button"
          value="table"
          className={`${getButtonSize()} relative inline-flex items-center 
                    
                    shadow-slate-400
                    rounded-l border-r-none border-l border-t border-b  ${
                      checked !== text2
                        ? "bg-secondary-600 border-secondary-600 text-white shadow-sm shadow-slate-400 "
                        : "bg-white text-slate-500 border-none shadow-sm shadow-slate-400 "
                    } 
                    
                    px-4 py-2 text-sm font-medium  `}
        >
          {text1}
        </button>

        <button
          onClick={(e) => handleClick(text2)}
          type="button"
          value="grid"
          className={`${getButtonSize()} relative inline-flex items-center 
                    shadow-sm
                  
                  rounded-r border-r border-t border-b  
                  ${
                    checked == text2
                      ? "bg-secondary-600 border-secondary-600 text-white shadow-sm shadow-slate-400"
                      : "bg-white text-slate-500 shadow-sm shadow-slate-400 border-none "
                  } px-4 py-2 text-sm font-medium  `}
        >
          {text2}
        </button>
      </span>
    </div>
  );
}

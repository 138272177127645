import React from "react";
import { Transition } from "@headlessui/react";

export default function EaseIn({ children, show }) {
  return (
    // <Transition
    //   //   id={id}
    //   //   className="fixed inset-0 z-50 overflow-hidden flex items-center my-4 justify-center transform px-4 sm:px-6"
    //   role="dialog"
    //   aria-modal="true"
    //   show={show}
    //   enter="transition ease-in-out duration-200"
    //   enterStart="opacity-0 translate-y-4"
    //   enterEnd="opacity-100 translate-y-0"
    //   leave="transition ease-in-out duration-200"
    //   leaveStart="opacity-100 translate-y-0"
    //   leaveEnd="opacity-0 translate-y-4"
    // >
    <Transition
      show={show}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {children}
    </Transition>
  );
}

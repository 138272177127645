import React from "react";
import { useHistory } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { ReactFormBuilder, ReactFormGenerator } from "react-form-builder2";

import "../css/font-awesome.min.css";
import "react-form-builder2/dist/app.css";

import store from "../store/formBuilderStore";
import { templates } from "../modules/_templates";
import Table from "../components/ReactTable";

import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import Button from "../elements/Button";

export default function TemplateList(props) {
  const history = useHistory();
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      let list = await templates.getAllTemplates();

      if (list) {
        console.log("Got List", list);
        setData(list);
      } else {
        setData([]);
      }
    })();
  }, [props.data]);

  store.subscribe((state) => setData(state.data));

  const handleCreateTemplateClick = () => {
    history.push({
      pathname: "/template-builder",
    });
  };
  const handleViewAnswers = () => {
    history.push({
      pathname: "/template-viewer",
    });
  };

  const columns = React.useMemo(
    () => [
      {
        ID: "Name",
        Header: "Name",
        accessor: "name",

        // Filter: SelectColumnFilter, // new
        filter: "includes", // new
        type: "text",
        // minWidth: "150px",
        // maxWidth: "150px",

        // Cell: (props) => RenderTeamName(props),
      },
      //   {
      //     ID: "Status",
      //     Header: (props) =>
      //       RenderHeader(
      //         props,
      //         "Status",
      //         null,
      //         "Status is the current state of the impediment."
      //       ),
      //     accessor: "Resolved",

      //     Cell: (props) => RenderTagCell(props),
      //     minWidth: "30px",
      //     maxWidth: "30px",
      //   },
    ],
    [data]
  );

  return (
    // <div
    //   id="creator-content"
    //   className="pt-3 absolute bottom-0 top-10  h-ful w-full bg-gray-50
    //     col-span-10 2xl:col-span-10 lg:col-span-9
    //     overflow-y-hidden
    //   "
    // >
    //   <Table data={data} columns={columns} />
    // </div>

    <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto space-y-6">
      <div className="sm:flex sm:justify-between sm:items-center ">
        <h1 className="text-xl md:text-2xl text-slate-800 font-bold">
          Templates <span className="text-slate-400 font-medium"></span>
        </h1>

        {/* Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          <Button
            color="secondary"
            shadow={true}
            handleClick={() => handleCreateTemplateClick()}
            // className="btn bg-primary-500 hover:bg-primary-600 text-white"
          >
            <DocumentPlusIcon className="h-5 w-5" />
            <span className="hidden xs:block ml-2">New Template</span>
          </Button>
          <Button
            color="secondary"
            shadow={true}
            handleClick={() => handleViewAnswers()}
            // className="btn bg-primary-500 hover:bg-primary-600 text-white"
          >
            <DocumentPlusIcon className="h-5 w-5" />
            <span className="hidden xs:block ml-2">View Answers</span>
          </Button>
        </div>
      </div>
      <div
        style={{ height: "calc(100vh - 200px)" }}
        className="bg-white shadow-lg rounded-sm border border-gray-200 relative  pt-4"
      >
        <Table data={data} columns={columns} />
      </div>
    </div>
  );
}

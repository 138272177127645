import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function RadioGroupCards(props) {
  const [selectedMailingLists, setSelectedMailingLists] = useState(
    props.defaultValue
  );

  // const items = props.options;
  const type = props.type ? props.type : "single";
  const stacked = props.stacked ? props.stacked : false;
  const [items, setItems] = useState(props.options);

  useEffect(() => {
    setSelectedMailingLists(props.defaultValue);
  }, [props.defaultValue]);

  useEffect(() => {
    setItems(props.options);
  }, [props.options]);

  const handleSelect = (e) => {
    if (type === "single") {
      handleSelectSingle(e);
    } else {
      handleSelectMultiple(e);
    }

    // console.log("handleSelect");
    // setSelectedMailingLists(e);
    // props.onChange(e);
  };
  const handleSelectSingle = (e) => {
    console.log("handleSelectMultiple");
    setSelectedMailingLists(e);
    props.onChange(e);
  };
  const handleSelectMultiple = (e) => {
    console.log("handleSelectMultiple");

    let newSelectedMailingLists = [...selectedMailingLists];
    if (newSelectedMailingLists.includes(e)) {
      newSelectedMailingLists = newSelectedMailingLists.filter(
        (item) => item !== e
      );
    } else {
      newSelectedMailingLists.push(e);
    }
    setSelectedMailingLists(newSelectedMailingLists);
    props.onChange(e);
  };
  function isSelected(value) {
    let selected;
    if (type === "single") {
      selected = selectedMailingLists === value ? true : false;
    } else {
      selected =
        selectedMailingLists && selectedMailingLists.length > 0
          ? selectedMailingLists.find((el) => el === value)
            ? true
            : false
          : false;
    }

    // console.log("isSelected", selected, value, selectedMailingLists);
    return selected;
  }
  const handleOptionClick = (value) => {
    if (type === 'single' && selectedMailingLists === value) {
        setSelectedMailingLists(null);  // Deselect the option
        props.onChange(null);
    } else {
        handleSelect(value); // Use existing logic for selection
    }
};
  return (
    <RadioGroup
      defaultValue={props.defaultValue}
      value={selectedMailingLists}
      onChange={(e) => handleSelect(e)}
      disabled={props.disabled}
    >
      <div
        className={`${
          props.className
            ? props.className
            : "mt-4 grid  gap-y-6 grid-cols-3  sm:gap-x-4"
        }`}
      >
        {items.map((item) => {
          const enabled = item.enabled === false ? false : true;
          const checked = isSelected(item.value);
          return (
            <RadioGroup.Option
              key={item.id}
              value={item.value}
              disabled={!enabled}
              onClick={props.deselect ?() => handleOptionClick(item.value) : {}}
              className={({ active }) =>
                classNames(
                  checked
                    ? "border-secondary-500 ring-1 ring-secondary-500"
                    : "border-gray-300",
                  active
                    ? "border-secondary-500 ring-2 ring-secondary-500"
                    : "",
                  `relative flex  cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none`,
                  "hover:border-gray-300 hover:shadow-md hover:bg-gray-50",
                  "col-span-3 md:col-span-1 lg:col-span-1 xl:col-span-1 2xl:col-span-1"
                )
              }
            >
              {({ active }) => (
                <>
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="block text-sm font-semibold  text-slate-800"
                      >
                        {item.title}
                        <span className="font-semibold text-slate-800">
                          {item.status != "published" && (
                            <span className="normal-case text-xs italic text-primary-500 align-top ml-1">
                              {item.status}
                              {enabled == false && " (coming soon)"}
                            </span>
                          )}
                        </span>
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className="mt-1 flex items-center text-xs text-slate-500"
                      >
                        {item.description}
                      </RadioGroup.Description>
                    </span>
                  </span>
                  <CheckCircleIcon
                    className={classNames(
                      !checked ? "invisible" : "",
                      "h-5 w-5 text-secondary-600"
                    )}
                    aria-hidden="true"
                  />
                  <span
                    className={classNames(
                      active ? "border" : "border",
                      checked
                        ? "border-secondary-500 ring-1 ring-secondary-500"
                        : "border-transparent",
                      "pointer-events-none absolute -inset-px rounded-lg"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          );
        })}
      </div>
    </RadioGroup>
  );
}

// store.js

import React, { createContext, useEffect, useMemo, useState } from "react";
import { utils } from "../modules/_utils";
import { _navigation } from "../modules/_navigation";

export const GlobalStateContext = createContext(null);

export const GlobalStateStore = ({ children }, props) => {
  const [state, setState] = useState({});
  const [cache, setCache] = useState();
  const [flags, setFlags] = useState();

  useEffect(() => {
    (async () => {
      let cache = await utils.getCacheData("flags", "flags");

      if (cache) {
        console.log("USEEFFECT in STORE --> GETTING FLAGS", cache);
        setFlags(cache);
        setCache(cache);
      }
      setState({ ...state, flags: cache.data });
    })();
  }, []);

  const setProfile = (values) => {
    setState({ ...state, profile: values });
  };
  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  return (
    <GlobalStateContext.Provider
      value={{ state: state, setProfile, updateState }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

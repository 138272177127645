import React, { useState, useEffect, useRef } from "react";
import {
  KeyIcon,
  UserCircleIcon,
  CreditCardIcon,
  UserGroupIcon,
  PlusIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/24/outline";
import Uploader from "./Uppy";
// import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { CalendarIcon, MegaphoneIcon } from "@heroicons/react/24/outline";
import { qList } from "../modules/_assessment_questions";
import Badge from "./Badge";
import Button from "../elements/Button";
import { _assessment } from "../modules/_assessment";
import MultiSelectListBox from "./MultiSelectListBox";
import RadioGroupCards from "./RadioGroupCards";
import DialogBox from "./Dialog";
import { _impediments } from "../modules/impediments";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let options = [
  {
    id: 1,
    value: "Infrastructure",
    name: "Infrastructure",
    area: "Technical",

    description: "",
    Examples: [],
  },
  {
    id: 1,
    value: "Architecture",
    name: "Architecture",
    area: "Technical",

    description: "",
    Examples: [],
  },
  {
    id: 1,
    value: "Other",
    name: "Other",
    area: "Technical",

    description: "",
    Examples: [],
  },
  {
    id: 2,
    value: "Insufficient Tooling",
    name: "Insufficient Tooling",
    area: "Operational",
    description: "",
    Examples: [],
  },
  {
    id: 3,
    value: "Process",
    name: "Process",
    area: "Procedural",
    description: "",
    Examples: [],
  },
  {
    id: 4,
    value: "Org Chart",
    name: "Org Chart",
    area: "Organizational",
    description: "",
    Examples: [],
  },
];

export default function AssessmentTemplateQuestionCard(props) {
  // const [question, setQuestion] = useState(props.question);
  // const [questions, setQuestions] = useState(props.question);
  const [updated, setUpdated] = useState({});
  const [modified, setModified] = useState(false);
  // const [question, setQuestion] = useState({});
  const [loading, setLoading] = useState(false);
  const [discardLoading, setDiscardLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [success, setSuccess] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [questionIdToDelete, setQuestionIdToDelete] = useState(null);
  const [deletionEvent, setDeletionEvent] = useState(null);
  // const [question, setQuestion] = useState({});
  const question = props.question;
  const index = props.index;
  const create = props.create ? props.create : false;
  const createQuestion = props.createQuestion ? props.createQuestion : false;

  // useEffect(() => {
  //   setQuestion(props.question);
  // }, []);

  useEffect(() => {
    setUpdated(props.question);
    // setQuestion(props.question);
  }, [props.question]);

  useEffect(() => {
    if (JSON.stringify(question) != JSON.stringify(updated)) {
      setModified(true);
    } else {
      setModified(false);
    }
  }, [updated]);

  useEffect(() => {
    console.log("updated changed", updated);
  }, [updated]);

  useEffect(() => {
    if (deleteConfirmed == true) {
    }
  }, [deleteConfirmed]);

  const handleDeleteQuestionClick = (e, id) => {
    setConfirmBox(true);
    setDeletionEvent(e);
    setQuestionIdToDelete(id);
  };

  const deleteQuestion = async () => {
    // setDeleteConfirmed(true);
    deletionEvent.preventDefault();
    const deleted =
      props.type == "impediment"
        ? await _impediments.deleteImpediment(questionIdToDelete)
        : await _assessment.deleteQuestion(questionIdToDelete);

    if (deleted) {
      console.log("Question deleted");
      props.removeQuestion(index);
      setDeleteConfirmed(false);
      setSuccess(true);
      setDeletionEvent(null);
      setQuestionIdToDelete(null);
      setConfirmBox(false);
    }
  };

  const handleSelect = (value, name) => {
    // console.log("handleSelect handleSelect", value, name);
    let options = [];
    if (name == "type") {
      if (value == "Multiple Choice") {
        console.log("handleSelect MC", value, name);
        options = [null, "", "", ""];
      }
    }
    if (name == "best_answer") {
      if (type == "True/False") {
        options = [true, false];
      }

      if (type == "Yes/No") {
        options = ["Yes", "No", "Unsure"];
      }
    }
    console.log("Updating questions", options);
    props.updateQuestion(props.index, {
      answer_options: options,
    });

    setUpdated({
      ...updated,
      [name]: value,
      answer_options: options,
    });
  };
  const handleSelectImpedimentType = (value, name) => {
    console.log("handleSelectImpedimentType", value, name);
    setUpdated({
      ...updated,
      [name]: value,
    });
  };

  const handleImpedimentSelect = async (id, impediment, value, objValue) => {
    let subcategory = objValue.name;

    setUpdated({
      ...updated,
      Subcategory: subcategory,
    });
  };

  const handleFieldChange = (e, q) => {
    // console.log("handleFieldChange", e.target, index);
    console.log("handleFieldChange", e.target.value, index);
    let { name, value, type, f } = e.target;

    // save updated question in updated state
    setUpdated({
      ...updated,
      [name]: value,
    });
  };

  const handleUpdateQuestionButtonClick = async () => {
    setLoading(true);
    console.log("handleUpdateQuestionButtonClick", updated);
    let newData = await _assessment.updateTemplateQuestions(
      updated,
      question._id
    );
    if (newData) {
      console.log("Question updated", newData);
      // setQuestion(newData);
      setTimeout(() => {
        setModified(false);
        setLoading(false);
        props.replaceQuestion(newData);
      }, 1000);
    } else {
      console.log("Question did not update");
    }

    // setLoading(false);
  };

  const handleSaveQuestionButtonClick = async () => {
    setLoading(true);
    console.log("handleSaveQuestionButtonClick", updated, props.type);
    let newData =
      props.type == "impediment"
        ? await _impediments.createImpediment(updated)
        : await _assessment.addNewQuestion(updated);
    if (newData) {
      console.log("Question updated", newData);
      // setQuestion(newData);
      setTimeout(() => {
        setModified(false);
        setLoading(false);
        if (props.type == "impediment") {
          props.updateCompetencies(newData);
        } else {
          props.replaceQuestion(newData);
        }
      }, 2000);
    } else {
      console.log("Question did not update");
    }

    // setLoading(false);
  };

  const handleDiscardQuestionButtonClick = async () => {
    setDiscardLoading(true);
    setTimeout(() => {
      setModified(false);
      setDiscardLoading(false);
      if (props.type == "impediment") {
        console.log("handleDiscardQuestionButtonClick", index);
        props.removeImpediment(index);
      } else {
        props.removeQuestion(index);
      }
    }, 300);
  };
  const prevBestAnswer = useRef();
  useEffect(() => {
    //assign the ref's current value to the count Hook
    prevBestAnswer.current = updated.best_answer;
  }, [updated]);

  const handleOptionsFieldChange = (e, q, oldAnswer, index) => {
    let { name, value, type, f } = e.target;
    let best_answer;
    console.log(
      "handleOptionsFieldChange old answer",
      "field:",
      name,
      "Prev best:",
      prevBestAnswer.current,
      "New Value:",
      value,
      "answer opts",
      updated.answer_options
    );

    if (name == "best_answer") {
      console.info(
        "Prev best:",
        prevBestAnswer.current,
        "Answer opt",
        updated.answer_options[0],
        "value",
        value
      );
      index = updated.answer_options.findIndex(
        (a) => a == prevBestAnswer.current
      );
      console.log(
        "compareAnswerOptions index:",
        index,
        "best_answer:",
        "Answer opt",
        updated.answer_options[index],
        "best_answer:",
        best_answer
      );
      best_answer = value;
      console.log(
        "compareAnswerOptions new index:",
        index,
        "best_answer:",
        best_answer
      );
    }

    if (
      name == "answer_options" &&
      updated.best_answer == prevBestAnswer.current
    ) {
      best_answer = value;
    }

    let answer_options = updated.answer_options.map((a, i) => {
      if (i == index) {
        console.log("found answer_options index", i, "value", value);
        return value;
      } else {
        return a;
      }
    });

    console.log("answer_options-1", answer_options);

    // saveUpdated answer_options index of oldAnswer
    setUpdated({
      ...updated,
      //update answer_options
      answer_options: answer_options,

      best_answer: best_answer,
    });
  };

  const type =
    updated && updated.type
      ? updated.type
      : question.type
      ? question.type
      : null;

  return (
    <>
      {/* <form action={null}> */}
      {props.type !== "impediment" ? (
        <div className="shadow space-y-6 mb-6 sm:overflow-hidden sm:rounded-md">
          <div className=" bg-white py-6 px-4 sm:p-6">
            <div className="grid grid-cols-3 border-b mb-6 py-1 space-x-6">
              <h3 className="text-lg font-medium leading-6 text-slate-900 ">
                {props.type !== "impediment" ? "Question" : "Impediment"}{" "}
                {index + 1}
              </h3>
              {/* <div className="inline col-start-4 justify-items-end">
                <Badge> {createQuestion && "Create Question"}</Badge>
              </div>
              <div className="inline col-start-5 justify-items-end">
                <Badge> {create && "Create"}</Badge>
              </div> */}

              <div className="inline-flex col-start-4 justify-items-end">
                {updated.type !== null && (
                  <Badge color={"accent"}> {updated.type}</Badge>
                )}
              </div>
              {question._id && (
                <div className="inline-flex  col-start-6 justify-items-end">
                  <Button
                    iconButton
                    color={"white"}
                    handleClick={(e) =>
                      handleDeleteQuestionClick(e, question._id)
                    }
                  >
                    <TrashIcon
                      className={"h-5 w-5 text-slate-500 hover:text-red-500"}
                      aria-hidden="true"
                    />
                  </Button>
                </div>
              )}
            </div>

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-6">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-slate-700"
                >
                  Question
                </label>
                <textarea
                  type="text"
                  name={`question`}
                  id={`${question._id}`}
                  onChange={(e) => handleFieldChange(e, question)}
                  defaultValue={question.question}
                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                />
              </div>
              {createQuestion && (
                <div className="col-span-6 sm:col-span-6">
                  <label
                    htmlFor="type"
                    className="block text-sm font-medium text-slate-700"
                  >
                    Question Type
                  </label>
                  <div className=" text-sm">
                    <RadioGroupCards
                      name="type"
                      defaultValue={question.type}
                      value={updated.type}
                      onChange={(e) => handleSelect(e, "type")}
                      options={[
                        {
                          id: 1,
                          title: "Multiple Choice",
                          description:
                            "This question will have multiple options to choose from.",
                          value: "Multiple Choice",
                        },
                        {
                          id: 2,
                          title: "Yes/No",
                          description:
                            "This question will have the options of Yes or No.",
                          value: "Yes/No",
                        },
                        {
                          id: 3,
                          title: "True/False",
                          description:
                            "This question will have the options of True or False.",
                          value: "True/False",
                        },
                      ]}
                    />
                  </div>
                  {/* <MultiSelectListBox
                    options={["Multiple Choice", "Yes/No", "True/False"]}
                    fieldType="single"
                    label="Question Type"
                    name="type"
                    handleSelect={(e, value) => handleSelect(e, value)}
                    // handleDeselect={(e) => handleDeselect(e)}
                    defaultValue={"Multiple Choice"}
                    value={type}
                  /> */}
                </div>
              )}

              <div className="col-span-6 sm:col-span-6">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-slate-700"
                >
                  Best Answer
                </label>
                {type == "Yes/No" && (
                  // <select
                  //   id="type"
                  //   name="best_answer"
                  //   autoComplete="type"
                  //   onChange={(e) => handleFieldChange(e)}
                  //   defaultValue={question.best_answer}
                  //   className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                  // >
                  //   <option value="0">Select Best Answer</option>
                  //   <option value="Yes">Yes</option>
                  //   <option value="No">No</option>
                  //   <option value="Unsure">Unsure</option>
                  // </select>
                  // <div className=" text-sm">
                  <RadioGroupCards
                    name="best_answer"
                    defaultValue={question.best_answer}
                    value={updated.best_answer}
                    onChange={(e) => handleSelect(e, "best_answer")}
                    options={[
                      {
                        id: 1,
                        title: "Yes",
                        description: "",
                        value: "Yes",
                      },
                      {
                        id: 2,
                        title: "No",
                        description: "",
                        value: "No",
                      },
                      {
                        id: 3,
                        title: "Unsure",
                        description: "",
                        value: "Unsure",
                      },
                    ]}
                  />
                )}
                {type == "True/False" && (
                  // <select
                  //   id="type"
                  //   name="best_answer"
                  //   autoComplete="type"
                  //   onChange={(e) => handleFieldChange(e)}
                  //   defaultValue={question.best_answer}
                  //   className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                  // >
                  //   <option value="0">Select Best Answer</option>
                  //   <option value="true">True</option>
                  //   <option value="false">False</option>
                  // </select>
                  <RadioGroupCards
                    name="best_answer"
                    defaultValue={question.best_answer}
                    value={updated.best_answer}
                    onChange={(e) => handleSelect(e, "best_answer")}
                    options={[
                      {
                        id: 1,
                        title: "True",
                        description: "",
                        value: true,
                      },
                      {
                        id: 2,
                        title: "False",
                        description: "",
                        value: false,
                      },
                    ]}
                  />
                )}
                {type == "Multiple Choice" && (
                  <textarea
                    type="text"
                    name="best_answer"
                    // id={`${q_iduestions.}`}
                    onChange={(e) =>
                      handleOptionsFieldChange(
                        e,
                        question,
                        question.best_answer,
                        index
                      )
                    }
                    defaultValue={question.best_answer}
                    // disabled={create == true ? false : true}
                    // autoComplete="family-name"
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                  />
                )}
              </div>
              {question.answer_options &&
                question.answer_options.map((answer, index) => {
                  // figure out how to show only 3 options + best answer
                  if (
                    ((question.best_answer == "" ||
                      question.best_answer == null) &&
                      answer != question.best_answer &&
                      type != "Yes/No" &&
                      type != "True/False") ||
                    (answer !== "" &&
                      answer != question.best_answer &&
                      type != "Yes/No" &&
                      type != "True/False")
                  ) {
                    return (
                      <div className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-slate-700"
                        >
                          Answer Option {index}
                        </label>

                        <textarea
                          type="text"
                          rows={5}
                          // name={`${questions._id}`}
                          // id={`${questions._id}`}
                          onChange={(e) =>
                            handleOptionsFieldChange(e, question, answer, index)
                          }
                          defaultValue={answer}
                          // disabled={create == true ? false : true}
                          // autoComplete="family-name"
                          className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                        />
                      </div>
                    );
                  }
                })}
              <div className="col-span-6 sm:col-span-6">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-slate-700"
                >
                  Action Plan/Explanation
                </label>
                <textarea
                  rows={5}
                  type="text"
                  name="action_plan"
                  // id={`${questions._id}`}
                  onChange={(e) => handleFieldChange(e, question)}
                  defaultValue={question.action_plan}
                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                />
                <div className="flex space-x-6 justify-end pt-10">
                  {!question._id && (
                    <Button
                      loader={discardLoading}
                      handleClick={() => handleDiscardQuestionButtonClick()}
                      color={"white"}
                      audit={false}
                    >
                      Discard
                    </Button>
                  )}
                  <Button
                    loader={loading}
                    disabled={!modified}
                    handleClick={
                      !question._id
                        ? () => handleSaveQuestionButtonClick()
                        : () => handleUpdateQuestionButtonClick()
                    }
                    color={"lime"}
                    audit={false}
                  >
                    {!question._id ? (loading ? "Saving" : "Save") : "Update"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {confirmBox && (
            <DialogBox
              handleConfirmClick={deleteQuestion}
              loading={loading}
              success={success}
            />
          )}
        </div>
      ) : (
        <div className="shadow space-y-6 mb-6 sm:overflow-hidden sm:rounded-md">
          <div className=" bg-white py-6 px-4 sm:p-6">
            <div className="grid grid-cols-3 border-b mb-6 py-1 space-x-6">
              <h3 className="text-lg font-medium leading-6 text-slate-900 ">
                Impediment {index + 1}
              </h3>

              <div className="inline-flex col-start-4 justify-items-end">
                {updated.type !== null && <Badge> {updated.type}</Badge>}
              </div>
              {question._id && (
                <div className="inline-flex  col-start-6 justify-items-end">
                  <Button
                    iconButton
                    color={"white"}
                    handleClick={(e) =>
                      handleDeleteQuestionClick(e, question._id)
                    }
                  >
                    <TrashIcon
                      className={"h-5 w-5 text-slate-500 hover:text-red-500"}
                      aria-hidden="true"
                    />
                  </Button>
                </div>
              )}
            </div>

            <div className="grid grid-cols-6 gap-6">
              {/* Description */}
              <div className="col-span-full ">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-slate-700"
                >
                  Description
                </label>
                <textarea
                  type="text"
                  name={`Description`}
                  id={`${question._id}`}
                  onChange={(e) => handleFieldChange(e, question)}
                  defaultValue={question.description}
                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                />
              </div>
              {/* Score and Burden */}
              <div className=" items-center col-span-6 gap-6 sm:grid sm:grid-cols-6  sm:space-y-0 space-y-6">
                <div className="col-span-3 sm:col-span-full   ">
                  {/* <div className="flex col-span-1"> */}
                  <label
                    htmlFor="name"
                    className="mr-6 block text-sm font-medium text-slate-700"
                  >
                    How many hours do you spend per week doing manual week
                    because of this impediment?
                  </label>
                  <input
                    type="text"
                    name="score"
                    // id={`${questions._id}`}
                    onChange={(e) => handleFieldChange(e, question)}
                    defaultValue={question.score}
                    className="mt-1 block w-48 rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                  />
                  {/* </div> */}
                  {/* <div className="flex col-span-1"> */}
                </div>
                <div className="col-span-3 sm:col-span-full  ">
                  <label
                    htmlFor="name"
                    className="mr-6 block text-sm font-medium text-slate-700"
                  >
                    What is the effort required to resolve this?
                  </label>
                  <input
                    type="text"
                    name="effort"
                    // id={`${questions._id}`}
                    onChange={(e) => handleFieldChange(e, question)}
                    defaultValue={question.effort}
                    className="mt-1 block w-48  rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                  />
                </div>
              </div>
              {createQuestion && (
                <div className="col-span-12 sm:col-span-6">
                  <label
                    htmlFor="Category"
                    className="block text-sm font-medium text-slate-700"
                  >
                    Impediment Type
                  </label>
                  <div className=" text-sm">
                    <RadioGroupCards
                      name="Category"
                      className={"mt-4 grid  gap-y-6 grid-cols-4  sm:gap-x-4"}
                      defaultValue={question.Category}
                      value={updated.Category}
                      onChange={(e) =>
                        handleSelectImpedimentType(e, "Category")
                      }
                      options={[
                        {
                          id: 1,
                          title: "Technical",
                          description:
                            "This imepediment is due to architecture, infrastructure, or other technical issues.",
                          value: "Technical",
                        },
                        {
                          id: 2,
                          title: "Operational",
                          description:
                            "This impediment is due to  tooling, frameworks, provisioning pipelines, environment stability, or other operational components.",
                          value: "Operational",
                        },
                        {
                          id: 3,
                          title: "Procedural",
                          description:
                            "This impediment is due to processes, procedures, policies, or other procedural issues that require attention.",
                          value: "Procedural",
                        },
                        {
                          id: 3,
                          title: "Organizational",
                          description:
                            "This impediment is related to culture, organization structure, team structure or other organization-related issues.",
                          value: "Organizational",
                        },
                      ]}
                    />
                  </div>
                </div>
              )}
              <div className="col-span-6 sm:col-span-2">
                <div className="mt-4">
                  <label
                    htmlFor="type"
                    className="block text-sm font-medium text-slate-700"
                  >
                    {type} Subcategory
                  </label>
                  <div className=" text-sm">
                    <MultiSelectListBox
                      options={options.filter((option) => {
                        return option.area === updated.Category;
                      })}
                      fieldType="single"
                      label="Question Type"
                      name="Subcategory"
                      handleSelect={handleImpedimentSelect}
                      // handleDeselect={(e) => handleDeselect(e)}
                      defaultValue={updated.Subcategory}
                      value={
                        updated.Subcategory
                          ? updated.Subcategory
                          : "Select an option"
                      }
                    />
                  </div>
                </div>
              </div>

              {/* <div className="col-span-6 sm:col-span-6">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-slate-700"
                >
                  Best Answer
                </label>
                {type == "Yes/No" && (
                  // <select
                  //   id="type"
                  //   name="best_answer"
                  //   autoComplete="type"
                  //   onChange={(e) => handleFieldChange(e)}
                  //   defaultValue={question.best_answer}
                  //   className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                  // >
                  //   <option value="0">Select Best Answer</option>
                  //   <option value="Yes">Yes</option>
                  //   <option value="No">No</option>
                  //   <option value="Unsure">Unsure</option>
                  // </select>
                  // <div className=" text-sm">
                  <RadioGroupCards
                    name="best_answer"
                    defaultValue={question.best_answer}
                    value={updated.best_answer}
                    onChange={(e) => handleSelect(e, "best_answer")}
                    options={[
                      {
                        id: 1,
                        title: "Yes",
                        description: "",
                        value: "Yes",
                      },
                      {
                        id: 2,
                        title: "No",
                        description: "",
                        value: "No",
                      },
                      {
                        id: 3,
                        title: "Unsure",
                        description: "",
                        value: "Unsure",
                      },
                    ]}
                  />
                )}
                {type == "True/False" && (
                  // <select
                  //   id="type"
                  //   name="best_answer"
                  //   autoComplete="type"
                  //   onChange={(e) => handleFieldChange(e)}
                  //   defaultValue={question.best_answer}
                  //   className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                  // >
                  //   <option value="0">Select Best Answer</option>
                  //   <option value="true">True</option>
                  //   <option value="false">False</option>
                  // </select>
                  <RadioGroupCards
                    name="best_answer"
                    defaultValue={question.best_answer}
                    value={updated.best_answer}
                    onChange={(e) => handleSelect(e, "best_answer")}
                    options={[
                      {
                        id: 1,
                        title: "True",
                        description: "",
                        value: true,
                      },
                      {
                        id: 2,
                        title: "False",
                        description: "",
                        value: false,
                      },
                    ]}
                  />
                )}
                {type == "Multiple Choice" && (
                  <textarea
                    type="text"
                    name="best_answer"
                    // id={`${q_iduestions.}`}
                    onChange={(e) =>
                      handleOptionsFieldChange(
                        e,
                        question,
                        question.best_answer,
                        index
                      )
                    }
                    defaultValue={question.best_answer}
                    // disabled={create == true ? false : true}
                    // autoComplete="family-name"
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                  />
                )}
              </div> */}
              {/* {question.answer_options &&
                question.answer_options.map((answer, index) => {
                  // figure out how to show only 3 options + best answer
                  if (
                    ((question.best_answer == "" ||
                      question.best_answer == null) &&
                      answer != question.best_answer &&
                      type != "Yes/No" &&
                      type != "True/False") ||
                    (answer !== "" &&
                      answer != question.best_answer &&
                      type != "Yes/No" &&
                      type != "True/False")
                  ) {
                    return (
                      <div className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-slate-700"
                        >
                          Answer Option {index}
                        </label>

                        <textarea
                          type="text"
                          rows={5}
                          // name={`${questions._id}`}
                          // id={`${questions._id}`}
                          onChange={(e) =>
                            handleOptionsFieldChange(e, question, answer, index)
                          }
                          defaultValue={answer}
                          // disabled={create == true ? false : true}
                          // autoComplete="family-name"
                          className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-secondary-500 sm:text-sm"
                        />
                      </div>
                    );
                  }
                })} */}

              <div className="col-span-6 sm:col-span-6 pb-12">
                {" "}
                <div className="flex space-x-6 justify-end pt-10">
                  {!question._id && (
                    <Button
                      loader={discardLoading}
                      handleClick={() => handleDiscardQuestionButtonClick()}
                      color={"white"}
                      audit={false}
                    >
                      Discard
                    </Button>
                  )}
                  <Button
                    loader={loading}
                    disabled={!modified}
                    handleClick={
                      !question._id
                        ? () => handleSaveQuestionButtonClick()
                        : () => handleUpdateQuestionButtonClick()
                    }
                    color={"secondary"}
                    audit={false}
                  >
                    {!question._id ? (loading ? "Saving" : "Save") : "Update"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {confirmBox && (
            <DialogBox
              handleConfirmClick={deleteQuestion}
              loading={loading}
              success={success}
            />
          )}
        </div>
      )}

      {/* </form> */}
    </>
    //   </div>
    // </div>
  );
}

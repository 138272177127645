import React from "react";
import { useEffect } from "react";
import BarChart from "../../charts/BarChart05";
import Skeleton from "../../components/Skeleton";

// Import utilities
import { tailwindConfig } from "../../utils/Utils";

function DashboardCard04(props) {
  // const [labels, setLabels] = React.useState([]);
  // const [chartD, setChartD] = React.useState([]);
  const [chartData, setChartData] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const data = props.data;
  const initialData = loading ? loading : data;
  const title = props.title;
  const span = props.span;
  const aspect = props.aspect;
  console.info("PROPS DATA DBC 4", data);
  const labels = [];
  const chartD = [];
  //split data into labels and chart data

  data.map((item) => {
    console.info("pushing item", item);
    labels.push(item[0]);
    chartD.push(item[1]);
  });
  let newItems = {
    labels: labels,

    datasets: [
      {
        label: "Competency",
        data: chartD, // [800, 1600, 900, 1300, 1950, 1700],
        backgroundColor: tailwindConfig().theme.colors.accent[600],
        hoverBackgroundColor: tailwindConfig().theme.colors.accent[800],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
        skipNull: true,
      },
    ],
  };

  useEffect(() => {
    setLoading(false);
  }, [data]);

  return (
    <div
      className={`flex flex-col col-span-${span} sm: col-span-${span} bg-white shadow-lg rounded-sm border border-gray-200`}
    >
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-slate-800">{props.title}</h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      {loading == true ? (
        <Skeleton type="spinner" />
      ) : (
        <BarChart
          data={newItems}
          width={595}
          height={248}
          aspect={props.aspect}
        />
      )}
    </div>
  );
}

export default DashboardCard04;

import React, { useState } from "react";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import { Store } from "./store";
import Logo from "../images/logo.png";

const VendorReponseLayoutScrollable = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    // <Store>
    <div className="flex h-screen overflow-hidden ">
      {/* Sidebar */}
      {/* <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

      {/* Content area */}
      <div className="relative flex flex-col flex-1 ">
        {/*  Site header */}
        {/* <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}
        <header className="sticky top-0 z-20 bg-white border-b border-gray-200  ">
          <div className="px-4 py-2 sm:px-6 lg:px-8">
            <div className="flex justify-between h-12 -mb-px">
              <div className="flex">
                <div className="flex flex-row justify-center content-center">
                  <img width="" className="mr-3 " src={Logo} />
                  <span className="inline-flex text-3xl m-auto font-medium text-slate-700">
                    Breachlink{" "}
                  </span>
                </div>

                <div className="hidden sm:hidden md:hidden lg:flex xl:inline 2xl:inline 3xl:inline 4xl:inline "></div>
              </div>

              <div className="flex items-center space-x-3"></div>
            </div>
          </div>
        </header>

        <main>{children}</main>
      </div>
    </div>
  );
};

export default VendorReponseLayoutScrollable;

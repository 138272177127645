import React, { useState } from "react";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SettingsSidebar from "../../partials/settings/SettingsSidebar";
import BillingPanel from "../../partials/settings/BillingPanel";
import Empty from "../../components/Empty";
import { _stripe } from "../../modules/_stripe";
import Button from "../../elements/Button";

function Billing() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <main>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        {/* Page header */}
        <div className="mb-8">
          {/* Title */}
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            Billing
          </h1>
        </div>

        {/* Content */}
        {/* <form> */}
        <form
          target="_blank"
          action={
            "https://billing.stripe.com/p/session/test_YWNjdF8xTGU1dmNEVUZ5cXNaNWR0LF9Nb3B2Y0JoQlMzSXNNSm8xSllweTdxU2VQa0RRY1I50100Rj1HS1JL"
          }
        >
          <Button>Manage billing</Button>
        </form>
        {/* </form> */}

        {/* <SettingsSidebar /> */}
        {/* <Empty
          title={"Manage your subscription"}
          description={"Test "}
          buttonText="Manage"
          buttonLink="/"
        /> */}
        {/* <BillingPanel /> */}
      </div>
    </main>
  );
}

export default Billing;

import React, { useState, useLocation, useEffect } from "react";
import { withRouter } from "react-router-dom";
import ModalBasic from "../../components/ModalBasic";
import ProfileBg from "../../images/profile-bg.jpg";
import UserAvatar from "../../images/user-128-01.jpg";
import Icon02 from "../../images/icon-02.svg";
import Icon03 from "../../images/icon-03.svg";
import UserImage01 from "../../images/avatar-01.jpg";
import UserImage02 from "../../images/avatar-02.jpg";
import UserImage03 from "../../images/avatar-03.jpg";
import UserImage04 from "../../images/avatar-04.jpg";
import UserImage05 from "../../images/avatar-05.jpg";
import UserImage06 from "../../images/avatar-06.jpg";
import Onboarding01 from "../../pages/Onboarding01";
import Onboarding02 from "../../pages/Onboarding02";
import Onboarding03 from "../../pages/Onboarding03";
import Onboarding04 from "../../pages/Onboarding04";
import Tabs from "../../pages/component/Tabs";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import EmptyState from "../../components/EmptyState";

function ProfileBody(props) {
  const profileSidebarOpen = props.profileSidebarOpen;
  const setProfileSidebarOpen = props.setProfileSidebarOpen;
  const data = props.data;
  const title = data.title;

  const [selectedTab, setSelectedTab] = useState("Overview");
  const tabs = [
    { name: "Overview", href: "#", current: true },
    { name: "Details", href: "#", current: false },
    { name: "Resources", href: "#", current: false },
    { name: "Calculator", href: "#", current: false },
  ];
  const [scrollbarModalOpen, setScrollbarModalOpen] = useState(false);

  console.info("Data object", data);
  // const title = props.title;
  return (
    <div
      className={`grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out ${
        profileSidebarOpen ? "translate-x-1/3" : "translate-x-0"
      }`}
    >
      {/* Profile background */}
      {/* <div className="relative h-56 bg-gray-200"> */}
      {/* <img className="object-cover h-full w-full" src={ProfileBg} width="979" height="220" alt="Profile background" /> */}
      {/* Close button */}
      {/* <button
          className="md:hidden absolute top-4 left-4 sm:left-6 text-white opacity-80 hover:opacity-100"
          onClick={() => setProfileSidebarOpen(!profileSidebarOpen)}
          aria-controls="profile-sidebar" 
          aria-expanded={profileSidebarOpen}
        >
          <span className="sr-only">Close sidebar</span>
          <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
          </svg>
        </button>
      </div> */}

      {/* Content */}
      <div className="relative px-4 sm:px-6 pb-8 mt-5">
        {/* Pre-header */}

        {/* Header */}
        <header className="text-center sm:text-left mb-6">
          {/* Actions */}
          <div className="float-right">
            {/* <button className="p-1.5 shrink-0 rounded border border-gray-200 hover:border-gray-300 shadow-sm">
                <svg className="w-4 h-1 fill-current text-slate-400" viewBox="0 0 16 4">
                  <circle cx="8" cy="2" r="2" />
                  <circle cx="2" cy="2" r="2" />
                  <circle cx="14" cy="2" r="2" />
                </svg>
              </button>
              <button className="p-1.5 shrink-0 rounded border border-gray-200 hover:border-gray-300 shadow-sm">
                <svg className="w-4 h-4 fill-current text-primary-500" viewBox="0 0 16 16">
                  <path d="M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7Zm4 10.8v2.3L8.9 12H8c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8Z" />
                </svg>
              </button> */}
            {/* <Link to={"/assessmentselect"}>
              <button
                className="btn-sm bg-lime-500 hover:bg-lime-600 text-white"
                aria-controls="scrollbar-modal"
              >
                <span className="ml-2">Button Text &gt;</span>
              </button>
            </Link> */}
          </div>
          {/* Name */}
          <div className="items-start mb-2 space-y-25">
            <div className="mb-25 space-y-25">
              <h1 className="text-2xl text-slate-800 font-bold">
                {data.data.title}{" "}
              </h1>
            </div>
            <div className="mt-25 space-y-25">
              <h2 className="text-xl text-slate-800 font-regular">
                {data.data.title}{" "}
              </h2>
            </div>
          </div>
          {/* <svg className="w-4 h-4 fill-current shrink-0 text-yellow-500 ml-2" viewBox="0 0 16 16">
              <path d="M13 6a.75.75 0 0 1-.75-.75 1.5 1.5 0 0 0-1.5-1.5.75.75 0 1 1 0-1.5 1.5 1.5 0 0 0 1.5-1.5.75.75 0 1 1 1.5 0 1.5 1.5 0 0 0 1.5 1.5.75.75 0 1 1 0 1.5 1.5 1.5 0 0 0-1.5 1.5A.75.75 0 0 1 13 6ZM6 16a1 1 0 0 1-1-1 4 4 0 0 0-4-4 1 1 0 0 1 0-2 4 4 0 0 0 4-4 1 1 0 1 1 2 0 4 4 0 0 0 4 4 1 1 0 0 1 0 2 4 4 0 0 0-4 4 1 1 0 0 1-1 1Z" />
            </svg> */}
          {/* </div> */}
          {/* Bio */}
          <div className="text-sm mb-3">
            <p>{data.data.content}</p>
          </div>
          {/* Meta */}
          {/* <div className="flex flex-wrap justify-center sm:justify-start space-x-4">
            <div className="flex items-center">
              <svg className="w-4 h-4 fill-current shrink-0 text-slate-400" viewBox="0 0 16 16">
                <path d="M8 8.992a2 2 0 1 1-.002-3.998A2 2 0 0 1 8 8.992Zm-.7 6.694c-.1-.1-4.2-3.696-4.2-3.796C1.7 10.69 1 8.892 1 6.994 1 3.097 4.1 0 8 0s7 3.097 7 6.994c0 1.898-.7 3.697-2.1 4.996-.1.1-4.1 3.696-4.2 3.796-.4.3-1 .3-1.4-.1Zm-2.7-4.995L8 13.688l3.4-2.997c1-1 1.6-2.198 1.6-3.597 0-2.798-2.2-4.996-5-4.996S3 4.196 3 6.994c0 1.399.6 2.698 1.6 3.697 0-.1 0-.1 0 0Z" />
              </svg>
              <span className="text-sm font-medium whitespace-nowrap text-slate-500 ml-2">Milan, IT</span>
            </div>
            <div className="flex items-center">
              <svg className="w-4 h-4 fill-current shrink-0 text-slate-400" viewBox="0 0 16 16">
                <path d="M11 0c1.3 0 2.6.5 3.5 1.5 1 .9 1.5 2.2 1.5 3.5 0 1.3-.5 2.6-1.4 3.5l-1.2 1.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l1.1-1.2c.6-.5.9-1.3.9-2.1s-.3-1.6-.9-2.2C12 1.7 10 1.7 8.9 2.8L7.7 4c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l1.2-1.1C8.4.5 9.7 0 11 0ZM8.3 12c.4-.4 1-.5 1.4-.1.4.4.4 1 0 1.4l-1.2 1.2C7.6 15.5 6.3 16 5 16c-1.3 0-2.6-.5-3.5-1.5C.5 13.6 0 12.3 0 11c0-1.3.5-2.6 1.5-3.5l1.1-1.2c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L2.9 8.9c-.6.5-.9 1.3-.9 2.1s.3 1.6.9 2.2c1.1 1.1 3.1 1.1 4.2 0L8.3 12Zm1.1-6.8c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4.2 4.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l4.2-4.2Z" />
              </svg>
              <a className="text-sm font-medium whitespace-nowrap text-primary-500 hover:text-primary-600 ml-2" href="#0">carolinmcneail.com</a>
            </div>
          </div> */}
        </header>

        {/* Tabs */}
        <div className="relative mb-6">
          <div
            className="absolute bottom-0 w-full h-px bg-gray-200"
            aria-hidden="true"
          ></div>
        </div>

        {/* Profile content */}
        <div>
          {/* Main content */}
          <div className="space-y-5 mb-8 xl:mb-0">
            <div
              style={{ height: 525 }}
              className="bg-white p-4 border border-gray-200 rounded-sm shadow-sm w-full"
            >
              {/* <Tabs
                tabs={tabs}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
              /> */}

              <div className="mt-10">
                {selectedTab === "Overview" && (
                  <div>
                    <ReactPlayer
                      url={data.data.videoLink}
                      width="100%"
                      // height="360px"
                      controls={true}
                    />
                  </div>
                )}
                {selectedTab === "Details" && (
                  <div>
                    <EmptyState />
                  </div>
                )}
                {selectedTab === "Resources" && (
                  <div>
                    <EmptyState />{" "}
                  </div>
                )}
                {selectedTab === "Calculator" && (
                  <div>
                    <EmptyState />{" "}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Sidebar */}
          {/* <aside className="xl:min-w-56 xl:w-56 space-y-3">
            <div className="text-sm">
              <h3 className="font-medium text-slate-800">Title</h3>
              <div>Senior Product Designer</div>
            </div>
            <div className="text-sm">
              <h3 className="font-medium text-slate-800">Location</h3>
              <div>Milan, IT - Remote</div>
            </div>
            <div className="text-sm">
              <h3 className="font-medium text-slate-800">Email</h3>
              <div>carolinmcneail@acme.com</div>
            </div>
            <div className="text-sm">
              <h3 className="font-medium text-slate-800">Birthdate</h3>
              <div>4 April, 1987</div>
            </div>
            <div className="text-sm">
              <h3 className="font-medium text-slate-800">Joined Acme</h3>
              <div>7 April, 2017</div>
            </div>
          </aside> */}
        </div>
      </div>
      {/* Modal w/ Scroll Bar */}
      <div className="m-1.5">
        {/* Start */}

        <ModalBasic
          id="scrollbar-modal"
          modalOpen={scrollbarModalOpen}
          setModalOpen={setScrollbarModalOpen}
          title="Privacy Assessment"
        >
          {/* Modal content */}
          <div className="px-5 py-4">
            <div className="text-sm">
              <div className="font-medium text-slate-800 mb-2">
                Let’s Talk Paragraph
              </div>
              <div className="space-y-2">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum.
                </p>
                <p>
                  Ullamcorper malesuada proin libero nunc consequat interdum
                  varius sit. Nec sagittis aliquam malesuada bibendum arcu vitae
                  elementum curabitur vitae. Mattis enim ut tellus elementum el
                  fringilla est ullamcorper eget nulla. Enim eu turpis egestas
                  pretium aenean pharetra magna. Aliquam id diam maecenas
                  ultricies mi eget us mauris vitae ultricies leo integer t
                  malesuada fames ac turpis egestas maecenas pharetra volutpat
                  lacus laoreet non.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum.
                </p>
                <p>
                  Ullamcorper malesuada proin libero nunc consequat interdum
                  varius sit. Nec sagittis aliquam malesuada bibendum arcu vitae
                  elementum curabitur vitae. Mattis enim ut tellus elementum el
                  fringilla est ullamcorper eget nulla. Enim eu turpis egestas
                  pretium aenean pharetra magna. Aliquam id diam maecenas
                  ultricies mi eget us mauris vitae ultricies leo integer t
                  malesuada fames ac turpis egestas maecenas pharetra volutpat
                  lacus laoreet non.
                </p>
              </div>
            </div>
          </div>
          {/* Modal footer */}
          <div className="sticky bottom-0 px-5 py-4 bg-white border-t border-gray-200">
            <div className="flex flex-wrap justify-end space-x-2">
              <button
                className="btn-sm border-gray-200 hover:border-gray-300 text-slate-600"
                onClick={(e) => {
                  e.stopPropagation();
                  setScrollbarModalOpen(false);
                }}
              >
                Close
              </button>
              <button className="btn-sm bg-primary-500 hover:bg-primary-600 text-white">
                I Understand
              </button>
            </div>
          </div>
        </ModalBasic>
        {/* End */}
      </div>
    </div>
  );
}

export default ProfileBody;

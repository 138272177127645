//create blank js page called Directory.js

// Path: src\pages\directory\directory.js
import React, { useState, useEffect, useLocation } from "react";
import { useHistory } from "react-router-dom";
import { _auth } from "../../modules/_auth";
import { _navigation } from "../../modules/_navigation";
import { Store } from "../../layouts/store";
import LayoutTwoColumnMainLeft from "../../layouts/LayoutTwoColumnMainLeft";

import { _marketplace } from "../../modules/_marketplace";
// import Button from "./Button";
import MarketplaceListingGrid from "./ListingGrid";
import MarketplaceSideBar from "./SideBar";
import PageHeader from "../../components/PageHeader";
import Filters from "./Filters";
import { filters as _filters } from "../../modules/_filters";
import { set } from "date-fns";
import Tabs from "../component/Tabs";
import ModalBasic from "../../components/ModalBasic";

export default function Directory(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [profileSidebarOpen, setProfileSidebarOpen] = useState(false);
  const [data, setData] = useState({});
  const [assessmentHistory, setAssessmentHistory] = useState([]);
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [filters, setFilters] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filteredData, setFilteredData] = useState({});
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [tabsContainer, setTabsContainer] = useState([]);
  const [selectedTab, setSelectedTab] = useState("View All");
  // const [FILTERS, setFILTERS] = useState(DEFAULT_FILTERS);
  const profile = _auth.getUserProfile();
  useEffect(() => {
    (async () => {
      let mkt;
      if (profile.role == "Super Admin") {
        mkt = await _marketplace.getMarketPlaceVendors("all");
      } else {
        mkt = await _marketplace.getMarketPlaceVendors("published");
      }

      if (mkt) {
        console.log("Got marketplace", mkt);
        // console.log("getHistory", getHistory);
        setData(mkt);
        setList(mkt);
        const defaultFilters = _marketplace.getDefaultFilters();
        setDefaultFilters(defaultFilters);
      }
    })();
  }, []);

  useEffect(() => {
    const newFilters = _filters.buildFilterObject(data, filterOptions);
    // const newTabs = _filters.buildTabs(data, newFilters);
    // console.log("newTabs", newTabs);
    setFilterOptions(newFilters);
    // setTabs(newTabs);
  }, [defaultFilters]);

  useEffect(() => {
    const newTabs = _filters.buildTabs(data, filterOptions);
    console.log("newTabs", newTabs);
    setTabsContainer(newTabs);
  }, [data, filterOptions]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    const newFilters = _filters.buildFilterObject(filteredData, defaultFilters);
    setFilterOptions(newFilters);
    // if (data && data.length > 0) {
    //   // {
    //   //   id: "category",
    //   //   name: "Category",
    //   //   options: [
    //   //     // { value: "tees", label: "Tees" },
    //   //     // { value: "crewnecks", label: "Crewnecks" },
    //   //     // { value: "hats", label: "Hats" },
    //   //   ],
    //   // },
    //   // {
    //   //   id: "services",
    //   //   name: "Services",
    //   //   options: [
    //   // { value: "clothing-company", label: "Clothing Company" },
    //   // { value: "fashion-inc", label: "Fashion Inc." },
    //   // { value: "shoes-n-more", label: "Shoes 'n More" },
    //   // ],
    //   // },
    //   let filters = [];

    //   FILTERS.forEach((filter) => {
    //     var filterObj = {};
    //     let filterId = filter.id;
    //     let filterName = filter.name;
    //     let filterOptions = [];
    //     filterObj.id = filterId;
    //     filterObj.name = filterName;
    //     data.forEach((item) => {
    //       if (item[filterId]) {
    //         if (Array.isArray(item[filterId])) {
    //           //check if filterOptions has an object with the same value
    //           item[filterId].forEach((option) => {
    //             if (!filterOptions.find((o) => o.value == option)) {
    //               filterOptions.push({ value: option, label: option });
    //             }
    //           });

    //           // item[filterId].forEach((option) => {
    //           //   if (!filterOptions.includes(option)) {
    //           //     filterOptions.push({ value: option, label: option });
    //           //   }
    //           // });
    //         } else {
    //           if (!filterOptions.find((o) => o.value == item[filterId])) {
    //             filterOptions.push({
    //               value: item[filterId],
    //               label: item[filterId],
    //             });
    //           }
    //         }
    //       }
    //     });
    //     filterObj.options = filterOptions;
    //     filters.push(filterObj);
    //   });
    //   console.log("filters", filters);
    //   setFILTERS(filters);
    //   setFilterOptions(filters);
    // }
  }, [filteredData]);

  useEffect(() => {
    if (filters.length > 0) {
      let filtered = _filters.filter(data, filters);
      setFilteredData(filtered);
    } else {
      console.log("No filters");
      setFilteredData(data);
    }
  }, [filters]);

  useEffect(() => {
    console.log("Selected Tab", selectedTab);
    if (selectedTab == "View All") {
      setFilteredData(data);
    } else {
      let filtered = data.filter((item) => item.services.includes(selectedTab));
      setFilteredData(filtered);
    }
  }, [selectedTab]);

  const handleChangeFilter = (filter, value) => {
    //if filter and value exist in filters, remove it.
    //if filter exists but value does not, add it
    //if filter does not exist, add it and add value
    //if filter exists but value does not, add it

    let newFilters = [...filters];
    let filterIndex = newFilters.findIndex((f) => f.filter == filter);
    if (filterIndex > -1) {
      let valueIndex = newFilters[filterIndex].value.findIndex(
        (v) => v == value
      );
      if (valueIndex > -1) {
        newFilters[filterIndex].value.splice(valueIndex, 1);
      } else {
        newFilters[filterIndex].value.push(value);
      }
    } else {
      newFilters.push({ filter: filter, value: [value] });
    }

    //if filter exists with no values, remove it
    newFilters = newFilters.filter((f) => f.value.length > 0);

    setFilters(newFilters);
  };
  const handleUpdateMarketplace = async (id, field, value) => {
    console.log("handleUpdateMarketplace", id, field);
    let data = {
      id: id,
      updates: {
        [field]: value ? value : !selectedItem[field],
      },
    };
    let response = await _marketplace.updateMarketplaceVendor(data);

    if (response) {
      console.log("marketplace updated", response);
      let updatedData = [...filteredData];
      let index = updatedData.findIndex((item) => item._id == id);
      updatedData[index][field] = value ? value : !selectedItem[field];
      setFilteredData(updatedData);
      setSelectedItem(updatedData[index]);
    }
  };

  return (
    // <LayoutTwoColumnMainLeft sideContent={sideContent}>
    //   <ProfileBody
    //     className=""
    //     profileSidebarOpen={profileSidebarOpen}
    //     setProfileSidebarOpen={setProfileSidebarOpen}
    //     data={data}
    //     title={data.Name}
    //     manager={data.manager}
    //     assessmentHistory={assessmentHistory}
    //   />
    // </LayoutTwoColumnMainLeft>

    <div
      id="page_container"
      className=" w-full max-w-14xl max-h-[calc(100vh-100px)]"
    >
      {" "}
      {/* Page header */}
      <div className="pb-4">
        <div className="pb-2 md:flex md:items-center md:justify-between md:space-x-5  ">
          <div className="flex items-center">
            <div>
              <PageHeader>Marketplace</PageHeader>
            </div>
          </div>
          {/* <Filters
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            handleChangeFilter={handleChangeFilter}
          /> */}
        </div>
        <div className="flex items-center  w-full">
          {tabsContainer &&
            tabsContainer.length > 0 &&
            tabsContainer.map((tabs, index) => {
              return (
                <div className="flex text-sm items-center" key={index}>
                  {/* <span className="mr-3 capitalize font-medium">
                    {tabs.field}
                  </span> */}
                  <Tabs
                    key={index}
                    tabs={tabs.tabs}
                    //sort tabs.tabs alphabetically by name
                    // .sort((a, b) => (a.name > b.name ? 1 : -1))}
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div>
        {data && data.length > 0 && (
          <MarketplaceListingGrid
            profile={profile}
            data={filteredData}
            setSelectedItem={(item) => setSelectedItem(item)}
            show={show}
            setShow={(show) => setShow(show)}
            updateMarketplace={handleUpdateMarketplace}
          />
        )}
        <MarketplaceSideBar
          profile={profile}
          data={filteredData}
          show={show}
          selectedItem={selectedItem}
          setShow={() => setShow(!show)}
        />
      </div>
    </div>
  );
}

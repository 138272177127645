import { Draggable } from "@hello-pangea/dnd";
import Badge from "../../../components/Badge";

export default function BoardCard({
  index,
  title,
  subTitle,
  description,
  status,
  burden,
  effort,
  total,
  id,
  key,
  selected,
  handleCardClick,
  data,
  cardKey,
  isDragDisabled,
  createDate,
  color,
}) {
  return (
    <Draggable
      key={id}
      draggableId={id}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => (
        <li
          key={key}
          className={`${
            selected ? "bg-gray-500" : "bg-white"
          } relative list-none`}
          onClick={(e) => handleCardClick(e, index, data)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            id="card-shell"
            className="w-full  flex cursor-pointer bg-white text-sm rounded-sm shadow "
          >
            <div
              id="card-color"
              className={`bg-${color}-500 bg-opacity-80 flex w-2 flex-shrink-0 items-center 
                justify-center rounded-l-sm text-sm font-medium text-white`}
            ></div>
            <div
              id="card-content"
              className="flex flex-1 flex-col h-full py-2 px-1  items-center justify-between truncate rounded-r bg-white"
            >
              <div
                id="row-one-header"
                className="flex flex-row items-top w-full px-1 justify-between"
              >
                <div
                  id="row-one-left"
                  className="flex items-top uppercase text-xs "
                >
                  {cardKey}
                </div>
                <div id="row-two-right" className="flex items-center  text-xs ">
                  <Badge
                    shape="rounded"
                    color="secondary"
                    case="uppercase"
                    // className="-pb-2"
                  >
                    {status}
                  </Badge>
                </div>
              </div>
              <div
                id="row-two-body"
                className="flex flex-col w-full px-1  justify-between"
              >
                <div className="flex justify-between max-w-full font-semibold ">
                  {title && title !== "" && title}
                </div>
                <div className="flex  justify-between text-xs">
                  {subTitle || description}
                </div>
              </div>

              <div
                id="row-three-footer"
                className="flex flex-row text-xs w-full pt-1 px-1 items-center justify-between"
              >
                <div className="flex justify-between">
                  Created{" "}
                  {new Date(createDate).toLocaleDateString("en-US", {
                    timeZone: "America/New_York",
                    dateStyle: "short",
                  })}
                </div>
                <div className="flex justify-between">{total()}</div>
              </div>
            </div>
          </div>
        </li>
      )}
    </Draggable>
  );
}

import PreferenceList from "./PreferenceList";
import PreferenceListItem from "./PreferencesListItem";

export default function PreferencesSection({ title, options, bottomDivider }) {
  return (
    <section className="w-full">
      {/* <h3 className="text-xl leading-snug text-slate-800 font-bold mb-1">
        {title}
      </h3> */}
      <div className="col-span-full">
        <h3
          id="notes-title"
          className="border-b border-gray-200 pb-2 text-md uppercase font-bold text-secondary-600"
        >
          {title}
        </h3>
        <div className=" justify-end "></div>
      </div>
      <PreferenceList options={options} />
      {bottomDivider && (
        <div className="flex justify-between items-center py-3 border-b border-gray-200"></div>
      )}
    </section>
  );
}

import React, { useState, useEffect, useMemo, useContext } from "react";
import Sidebar from "../partials/Sidebar";
import NarrowSidebar from "../components/NarrowSidebar";
import Header from "../partials/Header";
import { useHistory } from "react-router-dom";
import { _auth } from "../modules/_auth";
import ToastNotification from "../components/ToastNotification";
import { _navigation } from "../modules/_navigation";
import { Store } from "./store";
import useGlobalState from "../hooks/useGlobalState";

const LayoutWithCard = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [noChange, setNoChange] = useState(false);
  const { setProfile } = useGlobalState();
  let history = useHistory();
  const profile = _auth.getUserProfile();

  useEffect(() => {
    setProfile({ profile });
  }, []);
  // const role = useMemo(() => profile.role, [role]);
  useEffect(() => {
    _auth.getAuth(history);
  }, []);

  // const navigation = useMemo(
  //   () => _navigation.getNavLinksByRole(profile.role),
  //   [profile]
  // );

  const navigation = useMemo(
    () =>
      _navigation.getNavLinksByTenantType(
        profile && profile.tenantType ? profile.tenantType : "admin",
        profile.role
      ),
    [profile]
  );

  // const homelinks = useMemo(
  //   () =>
  //     _navigation.getHomeLinksByTenantType(
  //       profile && profile.tenantType ? profile.tenantType : "admin"
  //     ),
  //   [profile]
  // );

  const showHome = useMemo(() => profile.showHome, [profile.showHome]);

  const setSidebarExpanded = (e) => {
    e.preventDefault();
    setSidebarOpen(true);
  };
  const setSidebarClosed = (e) => {
    e.preventDefault();
    setSidebarOpen(false);
  };

  // useEffect(() => {
  //   _auth.getAuth(history);
  //   let profile = _auth.getUserProfile();
  //   setProfile(profile);
  //   // setShowHome(profile.showHome ? profile.showHome : true);
  //   setShowHome(profile.showHome);
  //   console.log("profile in layout showHome", profile);
  //   // const navigation = _navigation.getNavLinksByRole(profile.role);
  //   // setNavigation(navigation);
  //   console.info(" showHome", profile.showHome);
  // }, []);

  // useEffect(() => {
  //   const navigation = _navigation.getNavLinksByRole(profile.role);
  //   setNavigation(navigation);
  //   console.info("navigation in layout", JSON.stringify(navigation));
  // }, [profile]);

  return (
    <div id="screen_content" className="h-screen flex ">
      {/* <Sidebar
        showHome={showHome}
        profile={profile}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        setSidebarClosed={setSidebarClosed}
        navigation={navigation}
      />
      <NarrowSidebar
        showHome={showHome}
        profile={profile}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        navigationItems={navigation}
      /> */}
      {/* Content area */}
      <div
        id="right-content"
        className="h-full w-full flex flex-col justify-between overflow-hidden"
      >
        <div className="h-12 ">
          {" "}
          <Header
            showHome={showHome}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarExpanded}
            setSidebarClosed={setSidebarClosed}
            navigation={navigation}
          />
        </div>

        {/* <main className={"px-4 sm:px-6 lg:px-8 py-8 "}> */}
        {/* flex flex-col h-full max-h-full min-h-full p-4 pb-4 w-full */}
        <main
          className={
            "h-full flex flex-0 bg-gray-100 overflow-hidden p-4 pb-4 w-full"
          }
        >
          {/* flex h-full w-full shadow-sm rounded bg-white p-2 */}
          <div
            id="page-card"
            className="flex flex-col h-full w-full shadow-sm rounded bg-white p-2 overflow-x-auto oveflow-y-hidden"
          >
            <ToastNotification show={true} />
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default LayoutWithCard;

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { templates } from "../../modules/_templates";
import AssessmentSelect from "../AssessmentSelect";
import { Projects } from "../../modules/_projects";
import YesNoQuestions from "../../components/YesNoQuestions";
import RadioGroupCards from "../../components/RadioGroupCards";
import MultiSelectListBox from "../../components/MultiSelectListBox";
import { Jurisdictions } from "../../modules/_jurisdiction_list";
import ComboboxSelector from "../../components/ComboBoxSelector";
import { PlusIcon } from "@heroicons/react/20/solid";
import { FolderPlusIcon } from "@heroicons/react/24/outline";
import JurisdictionInput from "./components/JurisdictionInput";
import DatePicker from "react-datepicker";
import Tip from "../../components/Tip";
import QuestionToolTip from "../../components/QuestionToolTip";
import { _auth } from "../../modules/_auth";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function NewProject(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(props.step);
  const [newProject, setNewProject] = useState({});
  const [selectedJurisdictions, setSelectedJurisdictions] = useState([]);
  const [jurisdictionOptions, setJurisdictionOptions] = useState([]);
  const [err, setErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [projectState, setProjectState] = useState(null);
  const profile = _auth.getUserProfile();
  useMemo(() => {
    const jurisdictions = Jurisdictions.getJurisdictions();
    const priority = [
      { id: 124, alpha2: "ca", alpha3: "can", name: "Canada" },
      {
        id: 826,
        alpha2: "gb",
        alpha3: "gbr",
        name: "United Kingdom",
      },
      { id: 840, alpha2: "us", alpha3: "usa", name: "United States" },
    ];
    //combine the two arrays
    const combined = [...priority, ...jurisdictions];

    setJurisdictionOptions(combined);
  }, []);
  useEffect(() => {
    console.log("newProject changed", newProject);
  }, [newProject]);

  useEffect(() => {
    //update newProject insurer and newProject insurer contact
    if (newProject.insurer) {
      const newState = { ...newProject };
      newState.insurer_contact = "";
      newState.insurer = "";
      setNewProject(newState);
    }
  }, [newProject.isInsured]);

  useState(() => {
    setStep(props.step);
    // alert("New Project Modal Open", step);
  }, [props.modalOpen]);

  // const handleSelect = async (value, impediment, v, obj) => {
  //   // setLoading(true);
  //   console.log("handleSelect Render", value, impediment, v, obj);
  //   const updated = await _impediments.updateImpedimentCompetencyId(
  //     impediment,
  //     value,
  //     impedimentId
  //   );

  //   if (updated) {
  //     console.log("Update Competency UPDATED", updated);
  //     const fieldsToUpdate = [
  //       // { field: "Competency", value: updated.Competency },
  //       {
  //         colName: "Competency._id",
  //         value: updated.Competency._id,
  //         rowIndex: props.index,
  //       },
  //       {
  //         colName: "Competency.Name",
  //         value: updated.Competency.Name,
  //         rowIndex: props.index,
  //       },
  //       {
  //         colName: "Competency.Area",
  //         value: updated.Competency.Area,
  //         rowIndex: props.index,
  //       },
  //       // { colName: "Assigned_to", value: updated.Competency.Name },
  //     ];
  //     setEdit(false);
  //     updateData(updated, impedimentId, props, fieldsToUpdate);
  //     setSelectedArea(obj.area);
  //     setSelectedCompetency(obj.name);
  //   }
  // };
  function isSelected(value) {
    // console.log("isSelected", value, selectedPerson);
    const selected = selectedJurisdictions
      ? selectedJurisdictions.find((el) => el === value)
        ? true
        : false
      : false;

    console.log("isSelected", selected, value, selectedJurisdictions);
    return selected;
  }
  const handleSelectedJurisdictions = (selected) => {
    //updated state array selectedJurisdictions with new items from selected
    if (isSelected(selected)) {
      const selectedJurisdictionsUpdated = selectedJurisdictions.filter(
        (el) => el !== selected
      );
      setSelectedJurisdictions(selectedJurisdictionsUpdated);
    } else {
      setSelectedJurisdictions((prev) => [...prev, selected]);
      //remove jurisdiction from newProject
      const updated = { ...newProject };
      console.log("handleSelectedJurisdictions", updated.jurisdictions);
      delete updated.jurisdictions[selected];
      setNewProject(updated);
    }

    console.log("handleSelectedJurisdictions", newProject);
  };
  // const handleSelectedJurisdictions = (selected) => {
  //   //updated state array selectedJurisdictions with new items from selected
  //   if (isSelected(selected)) {
  //     const selectedJurisdictionsUpdated = selectedJurisdictions.filter(
  //       (el) => el !== selected
  //     );
  //     setSelectedJurisdictions(selectedJurisdictionsUpdated);
  //   } else {
  //     setSelectedJurisdictions((prev) => [...prev, selected]);
  //     //remove jurisdiction from newProject
  //     const updated = { ...newProject };
  //     console.log("handleSelectedJurisdictions", updated.jurisdictions);
  //     delete updated.jurisdictions[selected];
  //     setNewProject(updated);
  //   }

  //   console.log("handleSelectedJurisdictions", newProject);
  // };
  const handleClearAllSelections = () => {
    setSelectedJurisdictions([]);
    setNewProject({ ...newProject, jurisdictions: [] });
  };
  function handleDeselectedJurisdictions(value) {
    console.log("handleDeselectedJurisdictions", value);
    //remove jurisdiction from newProject
    // const updated = { ...newProject };
    // console.log("handleDeselectedJurisdictions", updated.jurisdictions);
    // delete updated.jurisdictions[value];
    // setNewProject(updated);
  }

  const onFieldChange = (field, e, key) => {
    console.log("onFieldChange", field, e, key);
    setErr(false);
    setErrMessage("");
    const value = e.target && e.target.value !== undefined ? e.target.value : e;
    const newState = { ...newProject };
    //update the newState.jurisdictions array with the new key/value pair
    if (field && field == "jurisdictions") {
      if (!newState[field]) {
        newState[field] = [];
      }
      let jdArray = newState[field];
      const jd = { key: key, value: value };
      // if jdArray does not contain {key: key}, add it
      if (!jdArray.find((el) => el.key == key)) {
        jdArray.push(jd);
      } else {
        //if jdArray contains {key: key}, update the value
        const index = jdArray.findIndex((el) => el.key == key);
        jdArray[index].value = value;
      }

      console.log("newproject jd", jdArray);

      //update the newState with the new array
      newState[field] = jdArray;
      //add up the total of all the jurisdictions
      let total = 0;
      jdArray.forEach((el) => {
        total += parseInt(el.value);
      });
      newState["total_affected"] = total;
      setNewProject(newState);
    } else if (field && field == "deadline") {
      console.log("onFieldChange field == deadline");
      newState[field] = e;
      setNewProject(newState);
    } else {
      newState[field] = value;
      setNewProject(newState);
    }
    console.log("newproject", newProject);
  };

  // newState["blurbs"][name] = value;
  // if (field && field == "jurisdictions") {
  //   if (!newState[field]) {
  //     newState[field] = [];
  //   }
  //   let jdArray = newState[field];
  //   const jd = { [key]: value };
  //   // if jdArray does not contain the jurisdiction, add it
  //   if (!jdArray.find((el) => el[key])) {
  //     jdArray.push(jd);
  //   } else {
  //     //if jdArray contains the jurisdiction, update the value
  //     const index = jdArray.findIndex((el) => el[key]);
  //     jdArray[index] = jd;
  //   }
  //   console.log("newproject jd", jdArray);

  //   let update = { ...newProject, jurisdictions: jdArray };
  //   setNewProject(update);

  //   console.log("onChange NewProject.js", field, value, newState);
  // } else {
  //   newState[field] = value;
  //   setNewProject(newState);
  //   console.log("onChange NewProject.js", field, value, newState);
  // }

  // const onFieldChange = (key, e, jurisdiction) => {
  //   setErr(false);
  //   setErrMessage("");
  //   const value = e.target.value;

  //   const newState = { ...newProject };
  //   // newState["blurbs"][name] = value;
  //   if (key && key == "jurisdictions") {
  //     if (!newState[key]) {
  //       newState[key] = [];
  //     }
  //     let jdArray = newState[key];
  //     const jd = { [jurisdiction]: value };
  //     // if jdArray does not contain the jurisdiction, add it
  //     if (!jdArray.find((el) => el[jurisdiction])) {
  //       jdArray.push(jd);
  //     } else {
  //       //if jdArray contains the jurisdiction, update the value
  //       const index = jdArray.findIndex((el) => el[jurisdiction]);
  //       jdArray[index] = jd;
  //     }
  //     console.log("newproject jd", jdArray);

  //     let update = { ...newProject, jurisdictions: jdArray };
  //     setNewProject(update);

  //     // if (!newState[key]) {
  //     // } else {
  //     //   newState[key] = { ...newState[key], jd };
  //     // }

  //     // if (newState[key]) {
  //     //   if (newState[key][jurisdiction]) {
  //     //     newState[key][jurisdiction] = value;
  //     //   } else {
  //     //     newState[key] = { ...newState[key], ...jd };
  //     //   }
  //     // } else {
  //     //   newState[key] = jd;
  //     // }

  //     // setNewProject(newState);
  //     console.log("onChange NewProject.js", key, value, newState);
  //   } else {
  //     newState[key] = value;
  //     setNewProject(newState);
  //     console.log("onChange NewProject.js", key, value, newState);
  //   }
  // };
  const handleSelect = (field, value) => {
    console.log("handleSelect NewProject.js", field, value);
    //update state with new key/value pair
    const newState = { ...newProject };
    // newState["blurbs"][name] = value;
    newState[field] = value;
    setNewProject(newState);
  };

  const cleanUpState = () => {
    setNewProject({});
    setStep(1);
    setErr(false);
    setErrMessage("");
    setSelectedJurisdictions([]);
    setProjectState(null);
  };
  const isValid = (field) => {
    console.log("Check isValid", newProject, field);

    if (newProject && Object.keys(newProject).length === 0) {
      setErr(true);
      setErrMessage("You need to give your project a name before saving.");
      setLoading(false);
      return false;
    } else {
      console.log("isValid the newProject is not empty");
      return true;
    }
  };
  const onSave = async () => {
    setLoading(true);
    console.log("onSave");
    const valid = isValid("project");
    if (!valid) {
      setLoading(false);
    } else {
      console.log("isValid true", valid);
      const saved = await Projects.create(newProject);
      if (saved) {
        console.log("Saved successfully", saved);
        props.updateData(saved);
        setProjectState(saved);
        // cleanUpState();

        // props.setModalOpen(false);
      }
    }

    //   //close the modal and show success notification
  };

  const handleExecuteNextSteps = (e) => {
    if (e == "close") {
      props.setModalOpen(false);
      cleanUpState();
    } else if (e == "project") {
      // navigate to project details and pass state
      history.push({
        pathname: "/projects/project-details/" + projectState._id,
        state: { project: projectState },
      });
    } else if (e == "create_bid_request") {
      cleanUpState();
      props.setModalOpen(false);
      props.handleCreateNewSolicitation(projectState);
    } else {
      alert(3);
      // return props.setModalOpen(false)
    }
  };

  return (
    <div className="flex flex-col h-full w-full ">
      <form className="mb-5 space-y-8 divide-y divide-gray-200">
        <div className="">
          {step == 0 && (
            <div>
              <AssessmentSelect type="project" />
            </div>
          )}
          {step == 1 && (
            <div>
              <div>
                <h3 className="text-base font-semibold leading-6 text-slate-900">
                  Project Basics
                </h3>
                <p className="mt-2 mb-6 text-sm text-slate-500">
                  Use this form to create a new project. Write a few sentences
                  to describe the nature of the project. These are your notes.
                  They will not be shared with vendors.
                </p>
                <Tip text="Project details are never shared with vendors.">
                  The only required field here is the project name. However, we
                  recommend you fill out as much information as possible to help
                  you manage your project.
                </Tip>
                {/* <p className="mt-1 mb-6 text-sm text-slate-900"> */}
                {/* <span className="font-semibold">
                    Note: Project details are never shared with vendors.
                  </span> */}
                {/* </p> */}
                {/* <Tip text="Project details are never shared with vendors.">
                  Once a project is created, you will be able to create as many
                  bid requests as you need for the various stages of your
                  project.{" "}
                </Tip> */}

                {/* <p className="p-2 bg-indigo-50 border-2 border-dashed border-indigo-500 mt-3 mb-2 text-xs text-slate-600">
                  Tip: Use the Identifier field to reference the project in your
                  matter management system for future integration.
                </p> */}
              </div>

              <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-6">
                <div className="sm:col-span-1">
                  <label
                    htmlFor="project_id"
                    className="block text-sm font-medium text-slate-700"
                  >
                    File Number
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="project_id"
                      id="project_id"
                      autoComplete="none"
                      value={newProject.identifier}
                      onChange={(e) => onFieldChange("identifier", e)}
                      className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="sm:col-span-4">
                  <label
                    htmlFor="project_name"
                    className="block text-sm font-medium text-slate-700"
                  >
                    Project name
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="project_name"
                      id="project_name"
                      autoComplete="none"
                      value={newProject.name}
                      onChange={(e) => onFieldChange("name", e)}
                      className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <label
                    htmlFor="deadline"
                    className="block text-sm font-medium text-slate-700"
                  >
                    Deadline
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <DatePicker
                      // selected={null}
                      showMonthDropdown
                      minDate={new Date()}
                      onSelect={(e) => onFieldChange("deadline", e)}
                      selected={newProject.deadline}
                      value={newProject.deadline}
                      onChange={(e) => onFieldChange("deadline", e)}
                      // calendarClassName="calendar"

                      className="block w-full min-w-full flex-1  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    {/* <input
                      type="text"
                      name="deadline"
                      id="deadline"
                      autoComplete="none"
                      value={newProject.deadline}
                      onChange={(e) => onFieldChange("deadline", e)}
                      className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    /> */}
                  </div>
                </div>
                {profile && profile.role === "Super Admin" && (
                  <div className="flex items-center space-x-3 sm:col-span-6 border-t border-b border-gray-200 bg-gray-50 py-6">
                    <label
                      htmlFor="isForTesting"
                      className="flex items-center cursor-pointer font-medium"
                    >
                      This project is for testing/demo purposes only.
                    </label>
                    <Switch
                      id={"isForTesting"}
                      checked={newProject.isForTesting}
                      onChange={(e) => onFieldChange("isForTesting", e)}
                      className={classNames(
                        newProject.isForTesting
                          ? "bg-indigo-600"
                          : "bg-gray-200",
                        "flex items-center w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          newProject.isForTesting
                            ? "translate-x-3.5"
                            : "translate-x-0",
                          "flex h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  </div>
                )}
                {/* <div className="sm:col-span-6">
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"> */}
                <div className="sm:col-span-3">
                  <label
                    htmlFor="client_name"
                    className="block text-sm font-medium text-slate-700"
                  >
                    Client
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="client_name"
                      id="client_name"
                      autoComplete="none"
                      value={newProject.client}
                      onChange={(e) => onFieldChange("client", e)}
                      className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="client_email"
                    className="block text-sm font-medium text-slate-700"
                  >
                    Client Email
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="client_email"
                      id="client_email"
                      autoComplete="none"
                      value={newProject.clientContact}
                      onChange={(e) => onFieldChange("clientContact", e)}
                      className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                {/* <div className="sm:col-span-6">
                      <label
                        htmlFor="is_insured"
                        className="block text-sm font-medium text-slate-700"
                      >
                        Is this project insured?
                      </label>
                      <span className="text-sm text-slate-500">
                        If vendors have special arrangements with insurers, they
                        may be able to offer you a discount.
                      </span>
                      <div className="mt-1 flex rounded-md ">
                        <RadioGroupCards
                          name="isInsured"
                          defaultValue={newProject.isInsured}
                          value={newProject.isInsured}
                          type="single"
                          className="grid gap-y-6 grid-cols-2  sm:gap-x-4"
                          onChange={(e) => handleSelect("isInsured", e)}
                          options={[
                            {
                              id: 1,
                              title: "Yes",
                              description:
                                "Choose this option if the project is insured.",
                              value: true,
                            },
                            {
                              id: 2,
                              title: "No",
                              description:
                                "Choose this if the project is not insured.",
                              value: false,
                            },
                          ]}
                        />
                      </div>
                    </div> */}
                {/* {newProject.isInsured && newProject.isInsured == true && (
                      <> */}
                <div className="sm:col-span-3">
                  <label
                    htmlFor="insurer"
                    className="block text-sm font-medium text-slate-700"
                  >
                    Insurer
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="insurer"
                      id="insurer"
                      autoComplete="none"
                      value={newProject.insurer}
                      onChange={(e) => onFieldChange("insurer", e)}
                      className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="insurer_contact"
                    className="block text-sm font-medium text-slate-700"
                  >
                    Insurer Email
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="insurer_contact"
                      id="insurer_contact"
                      autoComplete="none"
                      value={newProject.insurer_contact}
                      onChange={(e) => onFieldChange("insurer_contact", e)}
                      className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                {/* </> */}
                {/* )} */}
                <div className="sm:col-span-6">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-slate-700"
                  >
                    Details
                  </label>
                  <p className="mt-2 text-sm text-slate-500">
                    Write a few sentences to describe the nature of the project.
                    These are your notes. They will not be shared with vendors.
                  </p>
                  <div className="mt-1">
                    <textarea
                      id="description"
                      name="description"
                      placeholder=""
                      rows={3}
                      value={newProject.description}
                      onChange={(e) => onFieldChange("description", e)}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
            </div>
            //   </div>
            // </div>
          )}
          {step == -2 && (
            <div className="">
              <div>
                <h3 className="text-base font-semibold leading-6 text-slate-900">
                  Project Details
                </h3>
                <p className="mt-1 text-sm text-slate-900">
                  Use this page to help you keep track of your projects and your
                  clients. By associating this project to a client, you will be
                  able to save a full client history for future reference and
                  reporting.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="client_name"
                    className="block text-sm font-medium text-slate-700"
                  >
                    Client
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="client_name"
                      id="client_name"
                      autoComplete="none"
                      value={newProject.client}
                      onChange={(e) => onFieldChange("client", e)}
                      className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="client_name"
                    className="block text-sm font-medium text-slate-700"
                  >
                    Client Contact
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="client_name"
                      id="client_name"
                      autoComplete="none"
                      value={newProject.clientContact}
                      onChange={(e) => onFieldChange("clientContact", e)}
                      className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="is_insured"
                    className="block text-sm font-medium text-slate-700"
                  >
                    Is this project insured?
                  </label>
                  <span className="text-sm text-slate-500">
                    If vendors have special arrangements with insurers, they may
                    be able to offer you a discount.
                  </span>
                  <div className="mt-1 flex rounded-md ">
                    <RadioGroupCards
                      name="isInsured"
                      defaultValue={newProject.isInsured}
                      value={newProject.isInsured}
                      type="single"
                      className="grid gap-y-6 grid-cols-2  sm:gap-x-4"
                      onChange={(e) => handleSelect("isInsured", e)}
                      options={[
                        {
                          id: 1,
                          title: "Yes",
                          description:
                            "Choose this option if the project is insured.",
                          value: true,
                        },
                        {
                          id: 2,
                          title: "No",
                          description:
                            "Choose this if the project is not insured.",
                          value: false,
                        },
                      ]}
                    />
                  </div>
                </div>
                {newProject.isInsured && newProject.isInsured == true && (
                  <>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="insurer"
                        className="block text-sm font-medium text-slate-700"
                      >
                        Insurer
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="insurer"
                          id="insurer"
                          autoComplete="none"
                          value={newProject.insurer}
                          onChange={(e) => onFieldChange("insurer", e)}
                          className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="insurer_contact"
                        className="block text-sm font-medium text-slate-700"
                      >
                        Insurer Contact
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="insurer_contact"
                          id="insurer_contact"
                          autoComplete="none"
                          value={newProject.insurer_contact}
                          onChange={(e) => onFieldChange("insurer_contact", e)}
                          className="block w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {step == 2 && (
            <div className="">
              <div>
                <h3 className="text-base font-semibold leading-6 text-slate-900">
                  Affected Individuals
                </h3>
                <p className="mt-2 mb-4 text-sm text-slate-500">
                  Use this page to fill in the affected individual counts for
                  each jurisdication.
                </p>
                <Tip>
                  This information will be needed for the vendors to give you
                  accurate bids. Give the best information you have.
                </Tip>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="client_name"
                    className="inline text-sm font-medium text-slate-700"
                  >
                    Jurisdictions
                  </label>{" "}
                  <QuestionToolTip
                    field="Jurisdictions"
                    text="Select each applicable jurisdication and enter the total number of affected individuals.  If you don't know, you can leave it blank or give it your best estimate."
                  />
                  {/* <span className="text-sm text-slate-500">
                    Select all the jurisdication where affected individuals live
                    and then enter the total in each jurisdiction.
                  </span> */}
                  <div className="mt-1  flex rounded-md shadow-sm">
                    <ComboboxSelector
                      name="jurisdictions"
                      options={jurisdictionOptions}
                      fieldType="multiSelect"
                      open={true}
                      handleSelect={handleSelectedJurisdictions}
                      handleDeselect={handleDeselectedJurisdictions}
                      handleClearAllSelections={handleClearAllSelections}
                      value={
                        newProject.jurisdictions ? newProject.jurisdictions : []
                      }
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="client_name"
                    className="inline text-sm font-medium text-slate-700"
                  >
                    Total affected individuals
                  </label>
                  <QuestionToolTip
                    field="Total affected individuals"
                    text="This is the total number of affected individuals across all jurisdictions. You can enter the total if you know it, or just leave it blank and we will calculate it based on the jurisdictions you selected."
                  />

                  {/* <span className="block text-sm text-slate-500">
                    If you don't know the total, we can calculate it for you
                    based on the jurisdictions you selected.
                  </span> */}
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      placeholder="Auto calculated but you can modify it if necessary"
                      disabled={selectedJurisdictions.length > 0 ? true : false}
                      name="client_name"
                      id="client_name"
                      autoComplete="none"
                      value={newProject.total_affected}
                      onChange={(e) => onFieldChange("total_affected", e)}
                      className="block h-10 w-full min-w-0 flex-1  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="sm:col-span-6 col-span-6">
                  <label
                    htmlFor="is_insured"
                    className="inline text-sm font-medium text-slate-700"
                  >
                    {selectedJurisdictions &&
                      selectedJurisdictions.length > 0 &&
                      "Let's break down your affected entities by population."}
                  </label>
                </div>
                <div className="sm:col-span-6">
                  <JurisdictionInput
                    create={true}
                    newProject={newProject}
                    field={"jurisdictions"}
                    onFieldChange={onFieldChange}
                    setTotal={null}
                    selectedJurisdictions={selectedJurisdictions}
                    setSelectedJurisdictions={setSelectedJurisdictions}
                  />
                </div>
              </div>
            </div>
          )}
          {step == -4 && (
            <div className="">
              <div>
                <h3 className="text-base font-semibold leading-6 text-slate-900">
                  Services
                </h3>

                <p className="mt-1 text-sm text-slate-500">
                  If you already know which services you will need, select them
                  here. Otherwise, leave this blank and come back when you know.
                </p>

                <p className="mt-3 mb-2 text-sm text-slate-500">
                  Tip: If you fill this in, we can use it to help you create
                  your bid requests.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label
                    htmlFor="is_insured"
                    className="block text-sm font-medium text-slate-700"
                  >
                    What kinds of bids do you need?
                  </label>
                  <div className="mt-1 flex rounded-md ">
                    <RadioGroupCards
                      name="services"
                      defaultValue={newProject.mailing}
                      value={newProject.mailing}
                      className="grid  gap-y-6 grid-cols-3  sm:gap-x-12"
                      onChange={(e) => handleSelect("mailing", e)}
                      options={[
                        {
                          id: 1,
                          title: "Mailings",
                          description: "",
                          value: true,
                        },
                        {
                          id: 2,
                          title: "Emailings",
                          description: "",
                          value: false,
                        },
                        {
                          id: 3,
                          title: "Call Center",
                          description: "",
                          value: false,
                        },
                        {
                          id: 4,
                          title: "Landing Page",
                          description: "",
                          value: false,
                        },
                        {
                          id: 5,
                          title: "Credit Monitoring",
                          description: "",
                          value: false,
                        },
                        // {
                        //   id: 6,
                        //   title: "Call Center",
                        //   description:
                        //     "Choose this if the project is not insured.",
                        //   value: false,
                        // },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {step == -5 && (
            <div className="pt-8">
              <div>
                <h3 className="text-base font-semibold leading-6 text-slate-900">
                  Notifications
                </h3>
                <p className="mt-1 text-sm text-slate-500">
                  We'll always let you know about important changes, but you
                  pick what else you want to hear about.
                </p>
              </div>
              <div className="mt-6">
                <fieldset>
                  <legend className="sr-only">By Email</legend>
                  <div
                    className="text-sm font-semibold text-slate-900"
                    aria-hidden="true"
                  >
                    By Email
                  </div>
                  <div className="mt-4 space-y-4">
                    <div className="relative flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="comments"
                          name="comments"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="comments"
                          className="font-medium text-slate-700"
                        >
                          Comments
                        </label>
                        <p className="text-slate-500">
                          Get notified when someones posts a comment on a
                          posting.
                        </p>
                      </div>
                    </div>
                    <div className="relative flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="candidates"
                          name="candidates"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="candidates"
                          className="font-medium text-slate-700"
                        >
                          Candidates
                        </label>
                        <p className="text-slate-500">
                          Get notified when a candidate applies for a job.
                        </p>
                      </div>
                    </div>
                    <div className="relative flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="offers"
                          name="offers"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="offers"
                          className="font-medium text-slate-700"
                        >
                          Offers
                        </label>
                        <p className="text-slate-500">
                          Get notified when a candidate accepts or rejects an
                          offer.
                        </p>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset className="mt-6">
                  <legend className="contents text-sm font-semibold text-slate-900">
                    Push Notifications
                  </legend>
                  <p className="text-sm text-slate-500">
                    These are delivered via SMS to your mobile phone.
                  </p>
                  <div className="mt-4 space-y-4">
                    <div className="flex items-center">
                      <input
                        id="push-everything"
                        name="push-notifications"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        htmlFor="push-everything"
                        className="ml-3 block text-sm font-medium text-slate-700"
                      >
                        Everything
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="push-email"
                        name="push-notifications"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        htmlFor="push-email"
                        className="ml-3 block text-sm font-medium text-slate-700"
                      >
                        Same as email
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="push-nothing"
                        name="push-notifications"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        htmlFor="push-nothing"
                        className="ml-3 block text-sm font-medium text-slate-700"
                      >
                        No push notifications
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          )}
          {step == 3 && (
            <div className="">
              <div>
                <h3 className="text-base font-semibold leading-6 text-slate-900">
                  That's everything we need
                </h3>

                <p className="mt-1 text-sm text-slate-900">
                  Now that we've collected all the information related to this
                  project, you can click the Create button below to save the
                  project.
                </p>

                <p className="mt-3 mb-2 text-sm text-slate-900">
                  Once you do that, you can choose to continue to the bid
                  request or you can choose to create the bid requestion later.
                </p>
                <Tip>
                  To create a bid request later, just go to the project and
                  click Create Bid Request.
                </Tip>
                {/* <p className="mt-3 mb-2 text-sm text-slate-900">
                  Remember, nothing will be shared externally without your
                  permission.
                </p> */}
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="col-span-6  flex items-center justify-center min-h-full h-full">
                  <div className="text-center mt-20">
                    {
                      //if projectState is null, show the save project button
                      projectState == null ? (
                        <div id="save_project">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              vectorEffect="non-scaling-stroke"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                            />
                          </svg>
                          <h3 className="mt-2 text-sm font-semibold text-gray-900">
                            Save Project
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Click below to save your project.
                          </p>
                          <div className="mt-6">
                            <button
                              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={(e) => {
                                e.preventDefault();
                                onSave();
                              }}
                            >
                              <FolderPlusIcon
                                className="-ml-0.5 mr-1.5 h-5 w-5"
                                aria-hidden="true"
                              />{" "}
                              Save Project
                            </button>
                            {err && (
                              <p className="mt-1 text-sm text-red-500">
                                {errMessage}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <RadioGroupCards
                          name="next_step_options"
                          defaultValue={null} //temp fix. need to map the values of newProject.services get newProject.services.service value
                          value={null}
                          type={"single"}
                          className="grid  gap-y-6 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3  sm:gap-x-3"
                          onChange={(e) => handleExecuteNextSteps(e)}
                          options={[
                            {
                              id: 1,
                              title: "Create a bid request",
                              description:
                                "Create a bid request to begin receiving bids related to this project.",
                              value: "create_bid_request",
                              higlighted: true,
                            },
                            {
                              id: 2,
                              title: "Go to my project",
                              description:
                                "Go to the main page for this project.",
                              value: "project",
                            },
                            {
                              id: 3,
                              title: "Close this window",
                              description:
                                "Close this window and return to your home page.",
                              value: "close",
                            },
                          ]}
                        />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
      <footer className="absolute pt-3 -ml-10 px-6 mt-auto w-full border-t bg-white  bottom-5 ">
        <div className="relative flex items-center justify-between ">
          <div className="flex justify-start ">
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-slate-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={(e) => {
                e.preventDefault();
                cleanUpState();
                props.setModalOpen(false);
              }}
            >
              Discard
            </button>
          </div>
          <div className="flex justify-end">
            {step !== 1 && (
              <button
                className="cursor-pointer ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-200 py-2 px-4 text-sm font-medium text-secondary-500 shadow-sm hover:text-secondary-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                disabled={step === 1}
                onClick={(e) => {
                  e.preventDefault();
                  setStep(step - 1);
                }}
              >
                Previous Step
              </button>
            )}

            {step !== 3 && (
              <button
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={(e) => {
                  e.preventDefault();
                  setStep(step + 1);
                }}
              >
                Next Step
              </button>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
}

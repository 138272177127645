import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  ArrowLongLeftIcon,
  CheckIcon,
  HandThumbUpIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
// import { _assessment } from "../../modules/_assessment";
import { Solicitations } from "../../../modules/_solicitations";
import { Responses } from "../../../modules/_responses";
import Button from "../../../elements/Button";
import ContentSplit from "../../../components/ContentSplit";
import ActionPlanExplanation from "../../../components/ActionPlanExplanation";
import RightActionArea from "../../../components/ActionAreaRight";
// import { _assessment } from "../modules/_assessment";
// import { _assessment } from "../modules/_assessment";

function VendorWelcomePage(props) {
  const printRef = useRef();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  // const counts = location.state.data;
  // const thisAssessment = location.state.thisAssessment;

  const [solicitation, setSolicitation] = useState({});

  const [project, setProject] = useState({});
  const [id, setId] = useState(
    location.state.solicitation && location.state.solicitation._id
      ? location.state.solicitation._id
      : null
  );
  const inviteId = location.state.inviteId;
  const name = location.state.name;
  const company = location.state.company;
  const [guestId, setGuestId] = useState(null);
  const [expiredOrInvalid, setExpiredOrInvalid] = useState(false);
  useEffect(() => {
    debugger;
    (async () => {
      if (location.state.inviteId) {
        let invite = await Responses.getFullInvite({ inviteId: inviteId });
        if (invite) {
          setGuestId(invite._id);
          console.log("Got guest id from invite id", invite);
        }
        //get soliticationId by inviteId
        let solicitationId = await Solicitations.getByInviteId({
          inviteId: location.state.inviteId,
        });
        if (solicitationId) {
          console.log(
            "Got solicitationId from InviteId, settings state",
            solicitationId
          );
          setId(solicitationId);
          console.log("DEBUG solicitationId", solicitationId);
          const solicitation = await Solicitations.getById({
            id: location.state.solicitation,
            fromVendor: true,
            from: "useEffect vendor welcome page",
          });
          if (solicitation && solicitation._id) {
            console.log("Got solicitation", solicitation);
            setSolicitation(solicitation);
            let item = solicitation;

            //get response by solicitationId
          } else {
            setSolicitation({});
            setExpiredOrInvalid(true);
          }
        }
      }
    })();
  }, []);
  const parseFirstName = (name) => {
    if (name) {
      let nameArray = name.split(" ");
      return nameArray[0];
    }
  };

  return (
    <div className="w-screen h-screen overflow-none bg-slate-100">
      <div className="bg-slate-100 flex flex-col h-screen max-w-5xl mx-auto mt-6 overflow-hidden">
        <div className="p-6 overflow-hidden bg-white shadow sm:rounded-md">
          {expiredOrInvalid ? (
            <div className="px-4 py-5 sm:px-6 col-span-2">
              <h2
                id="applicant-information-title"
                className="text-2xl leading-6 font-medium text-slate-900"
              >
                This link is no longer valid
              </h2>
              <p className="mt-5  text-slate-500">
                This link is no longer valid. Please contact the person who
                invited you to request a new link.
              </p>
            </div>
          ) : (
            history.push({
              pathname: "/projects/project-details/vendor-bid-info",
              state: {
                ...location.state,
                solicitation: solicitation,
                project: project,
              },
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default VendorWelcomePage;

import React from "react";
import { useEffect, useState } from "react";
import { ReactFormGenerator } from "react-form-builder2";
import { templates } from "../modules/_templates";
export default function TemplateViewer(props) {
  const [data, setData] = useState([]);
  React.useEffect(() => {
    (async () => {
      let form = await templates.getFormData("64151ed94e1ed4bf5bf0e1bd");

      if (form) {
        console.log("Got form", form);
        setData(form);
      } else {
        setData([]);
      }
    })();
  }, [props.form]);

  const submit = (data) => {
    console.log("Submitted", data);
  };

  return (
    <div
      id="creator-content"
      className="items-center pt-3 absolute bottom-0 top-10  h-ful w-full bg-gray-50 
      col-span-10 2xl:col-span-10 lg:col-span-9  
      overflow-y-hidden
    "
    >
      <div className="flex flex-col m-auto items-center">
        <ReactFormGenerator
          download_path=""
          back_action="/"
          back_name="Back"
          answer_data={{}}
          action_name="Save"
          form_action="/"
          form_method="POST"
          onSubmit={submit}
          //   variables={this.props.variables}
          data={data.form ? data.form : []}
        />
      </div>
    </div>

    //   <div>{JSON.stringify(data)}</div>;
  );
}

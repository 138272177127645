import HomeImage from "../images/homeimage.jpg";
import { Link } from "react-router-dom";
export default function HomeHeader(props) {
  const data = props.data;
  const title = data.title;
  const text = data.text;
  const to = data.to;
  const linkText = data.linkText;
  const image = data.imageURL;
  const showLinkLine = props.showLinkLine;

  return (
    <div className="relative bg-gray-200">
      {/* <div className="lg:absolute lg:inset-0"> */}
      {/* <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2 mx-auto items-center"></div> */}

      <div
        id="header"
        className="mb-10 relative  px-4 pt-10 pb-5 sm:px-6  lg:max-w-7xl  lg:grid lg:grid-cols-2"
      >
        {/* <div id="text" className="lg:col-start-2 lg:pl-8"> */}
        <div className="p-5 text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
          {/* <h2 className="leading-6 text-primary-600 font-semibold tracking-wide uppercase">
              Work with us
            </h2> */}
          <h2 className="pl-5 leading-6 text-slate-600 font-semibold tracking-wide">
            {title}
          </h2>
          <p className=" p-5 text-sm text-slate-500">{text}</p>
          {showLinkLine && (
            <p className=" pl-5 text-sm text-slate-500">
              <Link to={to} className="text-primary-500">
                Click here
              </Link>{" "}
              {linkText}
            </p>
          )}
          {/* Click here for a message from our founder. */}
        </div>
        {/* </div> */}
        <div id="guage" className="">
          {/* <HighestRisk /> */}
          <img className="mx-auto" src={HomeImage} alt="" />
        </div>
      </div>
    </div>
  );
}

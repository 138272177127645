import React, { useState } from "react";
import { useEffect, useMemo } from "react";
import { useRef } from "react";

import { useHistory } from "react-router-dom";
import MultiSelectListBox from "../components/MultiSelectListBox";
import Table, {
  SelectColumnFilter,
  GlobalFilter,
} from "../components/ReactTable";

import Button from "../elements/Button";
import { FeatureFlag } from "../components/FeatureFlag";
import {
  CheckIcon,
  DocumentPlusIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { _impediments } from "../modules/impediments";
import { _competencies } from "../modules/competencies";
import {
  LogoutIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import Tooltip from "../components/Tooltip";
import AssessmentTemplateQuestionEditor from "../components/AssessmentTemplatesQuestEditor";
import ModalBasic from "../components/ModalBasic";
import RightActionArea from "../components/ActionAreaRight";
import { ToggleButtons } from "../components/ReactTable";
import { _auth } from "../modules/_auth";
import { Icon } from "@iconify/react";

export function RenderActions(props, updateData, setUpdatedId) {
  const [loadingClaimButton, setLoadingClaimButton] = useState(false);
  const [loading, setLoading] = useState([]);
  const [selectedCopy, setSelectedCopy] = useState([]);
  const [isResolvedLoading, setIsResolvedLoading] = useState(false);
  const [isResolvedSuccess, setIsResolvedSuccess] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [isResolved, setIsResolved] = useState(
    props.row.original.Resolved !== true ? false : true
  );
  const [claimedByTeam, setClaimedByTeam] = useState();
  const [success, setSucces] = useState(false);
  const profile = _auth.getUserProfile();
  const history = useHistory();
  useEffect(() => {
    setIsClaimed(props.row.original.Claimed);
  }, [props.row.original.Claimed]);

  useEffect(() => {
    console.log("ClaimedByTeam changed", props.row.original);
    setClaimedByTeam(props.row.original.ClaimedByTeam);
  }, [props.row.original.ClaimedByTeam]);

  useEffect(() => {
    console.log(
      "isResolved changed, ",
      isResolved,
      "for ",
      props.row.original._id
    );
  }, [isResolved]);

  // useEffect(() => {
  //   console.log(
  //     "isResolved prop changed",
  //     props.row.original.Resolved,
  //     "for ",
  //     props.row.original._id
  //   );
  //   setIsResolved();
  // }, []);

  // console.log("RenderActions", props);
  let impedimentId = props.row.original._id;

  const handleClaimImpediment = async (e, impedimentId, claimed) => {
    // add team id to impediment
    // add claim by person id
    //set loading for 2 second
    e.preventDefault();
    //push impedimentId to loadingClaimButton array

    setLoading((loading) => [...loading, "Claim"]);
    // setLoadingClaimButton({ ...loadingClaimButton, [impedimentId]: true });
    claimed = !claimed;
    //get index of impedimentId in employees array
    // const index = employees.findIndex((emp) => emp._id === impedimentId);

    const updated = await _impediments.claimImpediment(impedimentId, claimed);

    if (updated) {
      console.log("Updated impediment claimed", updated);
      if (claimed) {
        let fieldsToUpdate = [
          { colName: "Claimed", value: claimed, rowIndex: props.index },
          {
            colName: "Claimed_by_team.tenantTitle",
            value: updated.Claimed_by_team
              ? updated.Claimed_by_team.tenantTitle
              : null,

            rowIndex: props.index,
          },
        ];
        setTimeout(function () {
          setLoading((loading) => loading.filter((item) => item !== "Claim"));
          setIsClaimed(claimed);
          setSucces(true);
        }, 750); //show checkmark for 1 second
        setTimeout(function () {
          setUpdatedId(impedimentId);
        }, 2000);
        setTimeout(function () {
          updateData(updated, impedimentId, props, fieldsToUpdate); //remove this if it gets confusing
        }, 3000);
        //cleanup
        setTimeout(function () {
          setSucces(false);
        }, 3000);
      } else {
        let fieldsToUpdate = [
          { colName: "Claimed", value: claimed, rowIndex: props.index },
          {
            colName: "Claimed_by_team.tenantTitle",
            value: null,
            rowIndex: props.index,
          },
        ];
        setTimeout(function () {
          setLoading((loading) => loading.filter((item) => item !== "Claim"));
          setIsClaimed(claimed);
          updateData(updated, impedimentId, props, fieldsToUpdate);
        }, 1500);
      }
    }

    // const updated = _impediments.claimImpediment(impedimentId);
  };
  const handleResolveImpediment = async (e, impedimentId, resolve) => {
    setIsResolvedLoading(true);
    // setLoading((loading) => [...loading, "Resolve"]);
    console.log("handleResolveImpediment", impedimentId);

    resolve = !resolve;
    console.log("isResolved before", isResolved, "and after", !isResolved);
    const resolved = await _impediments.resolveImpediment(
      impedimentId,
      null,
      "Resolved",
      resolve
    );
    let fieldsToUpdate = [
      { colName: "Resolved", value: resolve, rowIndex: props.index },
    ];
    if (resolved) {
      console.log("resolved", resolved);
      setTimeout(function () {
        setIsResolvedLoading(false);
        // setLoading((loading) => loading.filter((item) => item !== "Resolve"));
        setIsResolved(!isResolved);
        setIsResolvedSuccess(true);
      }, 700);

      setTimeout(function () {
        updateData(resolved, impedimentId, props, fieldsToUpdate); //remove this if it gets confusing
      }, 1500);
      setTimeout(function () {
        setIsResolvedSuccess(false);
      }, 2000);
    }
  };
  const handleViewDetails = async (e, impedimentId) => {
    e.preventDefault();
    console.log("handleViewDetails", props);
    let state = {
      data: props.row.original,
      list: props.data,
    };
    let path = "/impediments/details";
    return history.push({
      pathname: path,
      state: state,
    });
  };
  return (
    <div className="flex items-right space-x-3">
      <>
        <Button
          size="xs"
          // rounded
          iconButton
          // loader={loadingClaimButton[impedimentId]}
          loader={
            // if "Claim" is in loading array, show loader
            loading.includes("Claim") ? true : false
          }
          disabled={
            isClaimed == true &&
            props.row.original.Claimed_by_team !== null &&
            props.row.original.Claimed_by_team._id !== profile.tenantId &&
            profile.tenantInfo.type !== "admin"
          }
          // className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
          handleClick={(e) =>
            handleClaimImpediment(
              e,
              props.row.original._id,
              isClaimed == true ? true : false
            )
          }
        >
          {!loading.includes("Claim") &&
            (isClaimed == true ? (
              success == true ? (
                <CheckIcon className="w-5 h-5 text-green-500" />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // fill="fill-gray-800"
                  className="w-5 h-5 "
                  viewBox="0 0 20 20"
                >
                  {" "}
                  <path
                    fill-rule="evenodd"
                    d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z"
                    clip-rule="evenodd"
                  />{" "}
                </svg>
              )
            ) : (
              <svg
                // fill="fill-cyan-500"
                className="w-5 h-5 "
                viewBox="0 0 20 20"
              >
                <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
              </svg>
            ))}
        </Button>
        <Button
          size="xs"
          // rounded
          iconButton
          // loader={loadingClaimButton[impedimentId]}
          loader={
            // if "Claim" is in loading array, show loader
            isResolvedLoading ? true : false
          }
          // disabled={
          //   props.row.original.Claimed_by_team &&
          //   props.row.original.Claimed_by_team.name !== "JDO" &&
          //   props.row.original.Claimed_by_team !== ""
          // }
          // className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
          handleClick={(e) =>
            handleResolveImpediment(
              e,
              props.row.original._id,
              props.row.original.Resolved ? true : false
            )
          }
        >
          {isResolvedSuccess == true && !isResolvedLoading && (
            //show the green check box
            <CheckIcon className="w-5 h-5 text-green-500" />
          )}

          {!isResolvedLoading &&
            isResolved == true &&
            isResolvedSuccess !== true && (
              //show the Unresolve button
              <span className="text-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                  />
                </svg>
              </span>
            )}

          {isResolved !== true && !isResolvedLoading && !isResolvedSuccess && (
            //show the Resolve button
            <span className="text-green-500">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 "
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
                />
              </svg>
            </span>
          )}
        </Button>
        <Button
          size="xs"
          iconButton
          color={"red"}
          loader={false}
          handleClick={(e) => handleViewDetails(e, props.row.original._id)}
        >
          <Icon icon="material-symbols:open-in-new" className="h-5 w-5" />
          {/* <Icon icon="ic:round-add-task" /> */}
        </Button>

        {/* <Button
          size="xs"
          iconButton
          color={"green"}
          loader={loadingClaimButton[impedimentId]}
          handleClick={(e) =>
            handleClaimImpediment(
              e,
              props.row.original._id,
              isClaimed == true ? true : false
            )
          }
        >
          {" "}
          {!loadingClaimButton[impedimentId] && (
            <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5 ">
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          )}
        </Button> */}
      </>
    </div>
  );
}

export function RenderDynamicRDCell(props, updateData) {
  const getCategory = (area) => {
    if (area) {
      switch (area) {
        case "Culture":
          return "R&D";
        case "Plan":
          return "R&D";
        case "Build":
          return "R&D";
        case "Test":
          return "R&D";
        case "Deploy":
          return "Ops";
        case "Operate":
          return "Ops";
        case "Monitor":
          return "Ops";
        case "Respond":
          return "Ops";
        default:
          return "R&D";
      }
    } else {
      return null;
    }
  };
  const [value, setValue] = useState();
  // props.row.original.Competency
  //   ? getCategory(props.row.original.Competency.Area)
  //   : null
  useEffect(() => {
    if (props.row.original.Competency) {
      console.log("props.row.original.Competency", props.row.original);
      setValue(getCategory(props.row.original.Competency.Area));
    }
  }, [props.row.original.Competency]);

  return (
    <div className="text-green-700 flex items-center space-x-3">
      {value && value}
    </div>
  );
}

export function RenderTeamName(props) {
  const [teamName, setTeamName] = useState();
  // props.row.original.Team ? props.row.original.Team.Name : null
  const [claim, setClaim] = useState();
  // props.row.original.Claim ? props.row.original.Claim : null

  useEffect(() => {
    if (props.row.original.Claimed) {
      setTeamName(props.row.original.Claimed_by_team.tenantTitle);
      setClaim("Claimed");
    } else {
      setTeamName(props.row.original.Team);
      setClaim("Unclaimed");
    }
  }, [props.row.original.Claimed]);

  return (
    <>
      <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="text-slate-900 font-medium">{teamName}</div>
        <div className="flex font-normal text-gray-500">
          {" "}
          {claim == "Claimed" ? (
            <Icon
              icon="material-symbols:workspace-premium-outline-rounded"
              className="mr-1 my-auto"
            />
          ) : (
            <Icon
              icon="material-symbols:warning-outline-rounded"
              className="mr-1 my-auto"
            />
          )}
          {claim}
        </div>
      </div>
    </>
  );
}

export function RenderCompetencyList(props, competencies, updateData) {
  const buttonRef = useRef(null);
  console.log("RENDER competecencies", competencies);
  const [edit, setEdit] = useState(false);
  const [selectedCompetency, setSelectedCompetency] = useState(
    props.row.original.Competency ? props.row.original.Competency.Name : null
  );
  const [selectedArea, setSelectedArea] = useState(
    props.row.original.Competency ? props.row.original.Competency.Area : null
  );
  // console.log("RenderCompetencyList", props);
  // let edit = true;
  let impedimentId = props.row.original._id;
  // console.log("RenderCompetencyList", props.row.original._id);
  useEffect(() => {
    const clickHandler = ({ target }) => {
      console.log({
        clickhandler: "clickhandler",
        buttonRef: buttonRef,
        editButtonContainsTarget:
          buttonRef.current && buttonRef.current.contains(target),
        target: target,
        edit: edit,
      });

      // if (!editButton.current || editButton.current === null) return;
      // if (editButton.current.contains(target)) return;

      if (
        target.id !== "dropdown" &&
        target.id !== "editButton" &&
        target.id !== "buttonRef" &&
        target.id !== "headlessui-listbox-button-:r6v:" &&
        target.parentElement.id !== "dropdown" &&
        target.id !== "dropdown-button" &&
        target.parentElement.id !== "dropdown-button" &&
        target.parentElement.id !== "headlessui-listbox-button-:r6v:"
      ) {
        console.log("clickHandler", "passed checks", "edit", edit);
        setEdit(false);
      }
    };

    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  useEffect(() => {
    setEdit(false);
  }, [props.row.original.Competency]);

  useEffect(() => {
    console.log("clickHandler edit changed", edit);
  }, [edit]);
  //PROBLEM:  NEED UPDATEDATA like in the other components
  const handleSelect = async (value, impediment, v, obj) => {
    // setLoading(true);
    console.log("handleSelect Render", value, impediment, v, obj);
    const updated = await _impediments.updateImpedimentCompetencyId(
      impediment,
      value,
      impedimentId
    );

    if (updated) {
      console.log("Update Competency UPDATED", updated);
      const fieldsToUpdate = [
        // { field: "Competency", value: updated.Competency },
        {
          colName: "Competency._id",
          value: updated.Competency._id,
          rowIndex: props.index,
        },
        {
          colName: "Competency.Name",
          value: updated.Competency.Name,
          rowIndex: props.index,
        },
        {
          colName: "Competency.Area",
          value: updated.Competency.Area,
          rowIndex: props.index,
        },
        // { colName: "Assigned_to", value: updated.Competency.Name },
      ];
      setEdit(false);
      updateData(updated, impedimentId, props, fieldsToUpdate);
      setSelectedArea(obj.area);
      setSelectedCompetency(obj.name);
    }
  };
  return (
    <div className="flex items-center space-x-3">
      {edit !== true ? (
        <button
          id="editButton"
          ref={buttonRef}
          onClick={(e) => {
            e.preventDefault();
            setEdit(true);
          }}
          className={`${
            props.row.original.Competency
              ? "bg-accent-700 text-accent-50"
              : "bg-orange-100 text-secondary-700"
          }  px-3 py-1 text-xs rounded-md whitespace-nowrap`}
        >
          {selectedCompetency
            ? selectedArea + " - " + selectedCompetency
            : "Select a competency"}
          {/* {props.row.original.Competency
            ? props.row.original.Competency.Area 
            + " - " +
              props.row.original.Competency.Name
            : "Select a competency"} */}
        </button>
      ) : (
        <div>
          <MultiSelectListBox
            key={impedimentId}
            options={competencies}
            fieldType="single"
            label="Competencies"
            name="competencies"
            impedimentId={impedimentId}
            // handleSelect={null}
            handleDeselect={null}
            handleSelect={handleSelect}
            setEdit={setEdit}
            // handleDeselect={(e) => handleDeselect(e)}
            // defaultValue={
            //   props.row.original.Competency
            //     ? props.row.original.Competency.Name
            //     : "Select a competency"
            // }
            value={selectedCompetency ? selectedCompetency : "Select"}
          />
          {/* <div>
            {edit == true ? (
              <div className="inline">
                <Button color="link" handleClick={() => setEdit(false)}>
                  X
                </Button>
              </div>
            ) : (
              <div>not edit</div>
            )}
          </div> */}
        </div>
      )}
    </div>
  );
}

export function RenderImpedimentTypeList(props, profile) {
  let options = [
    {
      id: 1,
      value: "Infrastructure",
      name: "Infrastructure",
      area: "Technical",

      description: "",
      Examples: [],
    },
    {
      id: 1,
      value: "Architecture",
      name: "Architecture",
      area: "Technical",

      description: "",
      Examples: [],
    },
    {
      id: 1,
      value: "Other",
      name: "Other",
      area: "Technical",

      description: "",
      Examples: [],
    },
    {
      id: 2,
      value: "Insufficient Tooling",
      name: "Insufficient Tooling",
      area: "Operational",
      description: "",
      Examples: [],
    },
    {
      id: 3,
      value: "Process",
      name: "Process",
      area: "Procedural",
      description: "",
      Examples: [],
    },
    {
      id: 4,
      value: "Org Chart",
      name: "Org Chart",
      area: "Organizational",
      description: "",
      Examples: [],
    },
  ];
  console.log("RENDER impedimentTypes", options);

  const buttonRef = useRef(null);

  const [edit, setEdit] = useState(false);
  // cosnt[(selectedType, setSelectedType)] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(
    props.row.original.Category
  );
  const [selectedSubcategory, setSelectedSubcategory] = useState(
    props.row.original.Subcategory
  );
  // console.log("RenderCompetencyList", props);
  // let edit = true;
  let impedimentId = props.row.original._id;
  // console.log("RenderCompetencyList", props.row.original._id);
  useEffect(() => {
    const clickHandler = ({ target }) => {
      console.log({
        clickhandler: "clickhandler",
        buttonRef: buttonRef,
        editButtonContainsTarget:
          buttonRef.current && buttonRef.current.contains(target),
        target: target,
        edit: edit,
      });

      // if (!editButton.current || editButton.current === null) return;
      // if (editButton.current.contains(target)) return;

      if (
        target.id !== "dropdown" &&
        target.id !== "editButton" &&
        target.id !== "buttonRef" &&
        target.id !== "headlessui-listbox-button-:r6v:" &&
        target.parentElement.id !== "dropdown" &&
        target.id !== "dropdown-button" &&
        target.parentElement.id !== "dropdown-button" &&
        target.parentElement.id !== "headlessui-listbox-button-:r6v:"
      ) {
        console.log("clickHandler", "passed checks", "edit", edit);
        setEdit(false);
      }
    };

    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  useEffect(() => {
    setEdit(false);
  }, [props.row.original.Category]);
  // console.log("RenderCompetencyList", props.row.original._id);
  const handleSelect = async (id, impediment, value, objValue) => {
    let category = objValue.area;
    let subcategory = objValue.name;

    let data = {
      Subcategory: subcategory,
    };
    console.log("Updated category value", value, impediment);

    const updated = await _impediments.updateImpediment(
      impediment,
      null,
      "Category",
      category,
      data
    );
    if (updated) {
      console.log("UPDATED", updated);
      setSelectedCategory(category);
      setSelectedSubcategory(subcategory);
    }
  };
  return (
    <div className="flex items-center space-x-3">
      {!edit ? (
        // props.row.original.Category + " - " + props.row.original.Subcategory
        <button
          disabled={profile.tenantInfo.type !== "admin"}
          id="editButton"
          ref={buttonRef}
          onClick={(e) => {
            e.preventDefault();
            setEdit(true);
          }}
          className={`${
            props.row.original.Category && props.row.original.Subcategory
              ? "bg-accent-700 text-accent-50"
              : "bg-orange-100 text-secondary-700"
          }  px-3 py-1 text-xs rounded-md whitespace-nowrap`}
          // className="bg-accent-600 text-accent-100 px-3 py-1 text-xs rounded-md whitespace-nowrap "
        >
          {selectedCategory && selectedSubcategory
            ? selectedCategory + " - " + selectedSubcategory
            : "Select Impediment Type"}
        </button>
      ) : (
        <MultiSelectListBox
          key={impedimentId}
          options={options.sort((a, b) => a.area.localeCompare(b.area))}
          fieldType="single"
          label="Categories"
          name="categories"
          impedimentId={impedimentId}
          // handleSelect={null}
          handleDeselect={null}
          handleSelect={handleSelect}
          // handleDeselect={(e) => handleDeselect(e)}
          // defaultValue={
          //   props.row.original.Competency
          //     ? props.row.original.Competency.Name
          //     : "Select a competency"
          // }
          value={
            props.row.original.Subcategory
              ? props.row.original.Subcategory
              : "Select"
          }
        />
      )}
    </div>
  );
}

export function RenderNumberColumn(
  props,
  handleTextColumnValueChange2,
  handleOnBlur
) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);

  // useEffect(() => {
  //   console.log("ShowError", error);
  //   if (error) {
  //     setShowError(true);
  //   } else setShowError(false);
  // }, [error]);

  const handleNumberColumnonBlur = (e, id, columnId, row) => {
    let value = e.target.value;
    const valid = checkValid(value);
    if (valid) {
      handleOnBlur(e, id, columnId, row);
    } else {
      setError(true);
      setErrorMessage("Please enter a number greater than 0 or leave blank");
      return;
    }
  };

  const handleNumberColumnChange = (e, id, columnId, row) => {
    setError(false);
    setErrorMessage("");
    let value = e.target.value;
    const valid = checkValid(value);
    if (valid) {
      handleTextColumnValueChange2(e, id, columnId, row);
    } else {
      setError(true);
      setErrorMessage("Please enter a number greater than 0 or leave blank");
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, 500);
    }
  };

  const checkValid = (value) => {
    if (
      //value not a number
      isNaN(value) ||
      //value is a number but not an integer
      (value % 1 != 0 && value != "") ||
      //value is a number but is negative
      (value < 0 && value != "")
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="w-24 flex items-center">
      <input
        className={`w-full h-10 text-sm px-3  
        rounded focus:ring-2 
        ${
          error
            ? "focus:ring-red-500 focus:outline-none focus:border-red-500   transition duration-150 ease-in-out"
            : "focus:ring-secondary-500 focus:outline-none focus:border-secondary-500"
        } focus:shadow-outline`}
        value={props.row.original[props.column.id]}
        onChange={(e) =>
          handleNumberColumnChange(
            e,
            props.row.original._id,
            props.column.id,
            props.row.original
          )
        }
        onBlur={(e) =>
          handleNumberColumnonBlur(e, props.row.original._id, props.column.id)
        }
      />
      {/* <span className="-ml-10 text-gray-500 ">
        {props.column.id == "score" ? " hrs/wk " : "days"}
      </span> */}
    </div>
  );
}

export function RenderTextColumn(
  props,
  handleTextColumnValueChange2,
  handleOnBlur
) {
  return (
    <div className="w-24 flex items-center">
      <input
        className="w-full h-10 text-sm px-3  rounded focus:ring-2 focus:ring-secondary-500 focus:outline-none focus:border-secondary-500 focus:shadow-outline"
        value={props.row.original[props.column.id]}
        onChange={(e) =>
          handleTextColumnValueChange2(
            e,
            props.row.original._id,
            props.column.id,
            props.row.original
          )
        }
        onBlur={(e) => handleOnBlur(e, props.row.original._id, props.column.id)}
      />
      {/* <span>{props.row.original[props.column.id]}</span> */}
    </div>
  );
}

export function RenderHeader(props, name, sub, description) {
  return (
    <div className="flex text-center space-x-2 ">
      <div>
        {name}
        <div className="text-xs text-accent-700">{sub}</div>
      </div>
      <Tooltip
        className={"w-32 text-xs text-slate-500"}
        position={"bottom"}
        size="md"
        description={description}
        title={name}
      >
        <QuestionMarkCircleIcon className="h-4 w-4 align-top text-slate-400 mb-auto" />
      </Tooltip>
    </div>
  );
}

export function RenderSubComponent({ handleOnBlur, row }) {
  console.log("RenderSubComponent", row);
  const [updatedDescription, setUpdatedDescription] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    setDescription(row.row.original.Description);
  }, [row.row.original.Description]);
  useEffect(() => {
    setUpdatedDescription(row.row.original.Description);
  }, [row.row.original.Description]);

  return (
    <div>
      {/* <p className="font-semibold">Description: </p> */}
      <textarea
        rows={3}
        type="text"
        name="description"
        className="resize-none prose h-fit w-full text-sm px-3 border-none rounded focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:border-secondary-500 focus:shadow-outline "
        value={updatedDescription}
        defaultValue={description}
        onChange={(e) => setUpdatedDescription(e.target.value)}
        onBlur={(e) => handleOnBlur(e, row.row.original._id, "Description")}
      />
    </div>
  );
}

export function RenderTagCell(props) {
  console.log("RenderTagCell", props.row.original);
  const [value, setValue] = useState(
    props.row.original.Resolved == true ? true : false
  );
  useEffect(() => {
    setValue(props.row.original.Resolved == true ? true : false);
  }, [props.row.original.Resolved]);

  return value == true ? (
    <span className="flex bg-green-100 text-green-700 px-3 py-1   text-xs rounded-md ">
      <Icon icon="ic:round-check" className="mr-1 my-auto" /> Resolved
    </span>
  ) : (
    <span className="flex bg-red-100 text-red-700 px-3 py-1  text-xs rounded-md ">
      <Icon
        className="mr-1 my-auto"
        icon="material-symbols:info-outline-rounded"
      />
      Unresolved
    </span>
  );
}

export function RenderTagCell2(props) {
  console.log("RenderTagCell", props.row.original);
  const [value, setValue] = useState(props.row.original.R_and_D_Category);
  useEffect(() => {
    setValue(props.row.original.R_and_D_Category);
  }, [props.row.original.R_and_D_Category]);

  return (
    value !== "" &&
    value !== null &&
    value !== undefined && (
      <span className="bg-accent-300 text-accent-700 px-3 py-1 text-xs rounded-md">
        {value}
      </span>
    )
  );
}

function Impediments() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [UnresolvedImpediments, setUnResolvedImpediments] = useState([]);
  const [ResolvedImpediments, setResolvedImpediments] = useState([]);
  const [unClaimedImpediments, setUnClaimedImpediments] = useState([]);
  const [claimedImpediments, setClaimedImpediments] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [competencyList, setCompetencyList] = useState([]);
  const [competencies, setCompetencies] = useState([]);
  const [loadingClaimButton, setLoadingClaimButton] = useState({});
  const [changed, setChanged] = useState();
  const [edit, setEdit] = useState(true);
  const [editRow, setEditRow] = useState({});
  const [description, setDescription] = useState([]);
  const [updateField, setUpdateField] = useState([]);
  const [updatedId, setUpdatedId] = useState("");
  const [toggleValue, setToggleValue] = useState(
    localStorage.getItem("View all impediments") == "true" ? true : false
  );

  const [impedimentModalOpen, setImpedimentModalOpen] = useState(false);
  const history = useHistory();
  const profile = _auth.getUserProfile();
  // const [columns, setColumns] = useState([]);

  useEffect(() => {
    (async () => {
      let toggle =
        localStorage.getItem("View all impediments") == "true" ? true : false;
      let impedimentList = [];
      if (toggle === true) {
        impedimentList = await _impediments.getImpediments(toggle);
      } else {
        impedimentList = await _impediments.getImpediments();
      }
      if (impedimentList) {
        console.log("ViewDetails impList", impedimentList);
        setEmployees(impedimentList);
        setInitialValues(impedimentList);
        setList(impedimentList);
        setLoading(false);
      }
    })();
  }, [toggleValue]);

  useEffect(() => {
    console.log("ImpedimentsData", employees);
    if (toggleValue === false) {
      setUnClaimedImpediments(employees);
    } else {
      let filtered = employees.filter((employee) => {
        return employee.Claimed !== true;
      });
      setUnClaimedImpediments(filtered);
    }
  }, [employees, toggleValue]);

  useEffect(() => {
    (async () => {
      let compList = await _competencies.getCompetencyList();
      if (compList) {
        console.log("ViewDetails compList", compList);
        setCompetencies(compList);
      } else {
        console.log("ViewDetails compList nothing");
      }
    })();
  }, [list]);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  const skipResetRef = React.useRef(false);

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    skipResetRef.current = true;
    setEmployees((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    skipResetRef.current = false;
  }, [employees]);

  useEffect(() => {
    console.log("Updated Impediments", employees);
  }, [employees]);

  const updateData = (updated, impedimentId, rowProps, fieldsToUpdate) => {
    // console.log("Update Competency updateData", updated, impedimentId);
    console.log(" Update Competency updateData fieldsToUpdate", fieldsToUpdate);

    fieldsToUpdate.forEach((field) => {
      console.log(
        "Update Competency for id",
        impedimentId,
        "as follows",
        field
      );
      let rowIndex = field.rowIndex;
      let columnId = field.colName;
      let subfield;
      //if columnId has a period, split it up into an array
      if (columnId.includes(".")) {
        columnId = columnId.split(".");
        subfield = columnId[1];
        columnId = columnId[0];
      }
      console.log("ColumnID", columnId);

      let value = field.value;
      skipResetRef.current = true;
      setEmployees((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            console.log(
              "Update Competency updating...",
              row,
              "with",
              value,
              "at index",
              index,
              columnId
            );

            if (subfield) {
              console.log("Update Competency subfield", subfield);
              return {
                ...row,
                [columnId]: {
                  ...row[columnId],
                  [subfield]: value,
                },
              };
            } else {
              return {
                ...row,
                [columnId]: value,
              };
            }
          }

          return row;
        })
      );
    });

    // }
  };

  const updateData1 = (updated, impedimentId) => {
    console.log("updateData1", updated, impedimentId);
    //update employees state array with "updated" data
    let newEmployees = employees;
    let updatedEmployees = newEmployees.map((employee) => {
      if (employee._id === impedimentId) {
        employee.Claimed_by_team = updated.Claimed_by_team;
        employee.Claimed = updated.Claimed;
      }
    });

    console.log("updateData", updated);

    setEmployees(updatedEmployees);
  };

  const updateImpediments = async () => {
    let updatedImpediments = await _impediments.getImpediments();
    if (updatedImpediments) {
      setEmployees(updatedImpediments);
      setInitialValues(updatedImpediments);
      setList(updatedImpediments);
      setLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      // {
      //   Header: "Description",
      //   accessor: "Description",

      //   width: 50,
      //   maxWidth: 50,

      // },
      {
        ID: "Team",
        Header: "Team",
        accessor: "Team",
        // accessorFn: (row) => `${row.Team} ${row.Claimed_by_team.name}`,
        Filter: SelectColumnFilter, // new
        filter: "includes", // new
        type: "text",
        minWidth: "150px",
        maxWidth: "150px",

        Cell: (props) => RenderTeamName(props),
      },
      {
        ID: "Status",
        Header: (props) =>
          RenderHeader(
            props,
            "Status",
            null,
            "Status is the current state of the impediment."
          ),
        accessor: "Resolved",

        Cell: (props) => RenderTagCell(props),
        minWidth: "30px",
        maxWidth: "30px",
      },
      // {
      //   Header: "Owner",
      //   ID: "Claimed by",
      //   accessor: "Claimed_by_team.tenantTitle",
      //   Cell: (props) => {
      //     return (
      //       <>
      //         <div className="font-semibold text-secondary-500 whitespace-nowrap">
      //           {props.row.original.Claimed_by_team &&
      //             props.row.original.Claimed_by_team.tenantTitle}
      //         </div>
      //       </>
      //     );
      //   },
      //   filter: "includes",
      //   type: "text",
      //   minWidth: "150px",
      //   maxWidth: "150px",
      // },
      {
        ID: "Related Competency",
        Header: "Related Competency",
        accessor: "Competency.Name",
        Cell: (props) => RenderCompetencyList(props, competencies, updateData),
        Filter: SelectColumnFilter,
        filter: "equals", // new
        type: "custom",
      },
      {
        ID: "Root Issue",
        Header: (props) =>
          RenderHeader(
            props,
            "Root Issue",
            null,
            "The root issue is the underlying cause of the impediment. It is the problem that needs to be solved to remove the impediment."
          ),
        accessor: "Subcategory",
        // Filter: SelectColumnFilter, // new
        // filter: "includes", // new
        type: "text",
        // minWidth: "150px",
        // maxWidth: "150px",
        Cell: (props) => RenderImpedimentTypeList(props, profile),
      },

      {
        ID: "Category",
        Header: (props) =>
          RenderHeader(
            props,
            "Category",
            null,
            "This value is based on the DevOps model and is dynamically generated based on the selected competency."
          ),
        accessor: "R_and_D_Category",
        type: "text",
        minWidth: "150px",
        maxWidth: "150px",
        Cell: (props) => RenderDynamicRDCell(props, updateData),
      },

      {
        ID: "Burden",
        Header: (props) =>
          RenderHeader(
            props,
            "Burden",
            "hrs/wk",
            "Burden should be expressed in hours per week wasted due to the impediment."
          ),
        accessor: "score" || "0",
        Cell: (props) =>
          RenderNumberColumn(props, handleTextColumnValueChange2, handleOnBlur),
        minWidth: "30px",
        maxWidth: "30px",
      },

      {
        ID: "Effort",
        Header: (props) =>
          RenderHeader(
            props,
            "Effort",
            "days",
            "Effort is expressed in days of work required to resolve the impediment."
          ),
        accessor: "effort" || "0",

        Cell: (props) =>
          RenderNumberColumn(props, handleTextColumnValueChange2, handleOnBlur),
        minWidth: "30px",
        maxWidth: "30px",
      },

      {
        ID: "Actions",
        Header: (props) => {
          let rendered = (
            <div className=" space-y-3">
              <div className="flex justify-start">
                <div className="text-slate-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="w-4 h-4 "
                    viewBox="0 0 20 20"
                  >
                    <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                  </svg>
                </div>
                <div className="ml-2 text-slate-500">Claim</div>
              </div>
              <div className="flex justify-start">
                <div className="text-slate-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="flex w-5 h-5 "
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-2 text-slate-500">Release</div>
              </div>
              <div className="flex justify-start">
                <div className="text-slate-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 "
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div className="ml-2 text-slate-500">Resolve</div>
              </div>
              <div className="flex justify-start">
                <div className="text-slate-500">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    className="w-4 h-4"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div className="ml-2 text-slate-500">Details</div>
              </div>
            </div>
          );
          return RenderHeader(props, "Actions", null, rendered);
        },

        accessor: "Actions",
        Cell: (props) => (
          <div className="flex justify-start pr-6">
            {RenderActions(props, updateData, setUpdatedId)}
          </div>
        ),
        // Cell: (props) => RenderActions(props, updateData),
        type: "custom",
        minWidth: 10,
        maxWidth: 10,
        sticky: true,
        position: "left",
      },
    ],
    [competencies]
  );

  const onToggleChange = async (value) => {
    setToggleValue(value);
  };

  const handleTextColumnValueChange = (e, id, fieldname) => {
    console.log("handleTextColumnValueChange", e.target.value, id);
    let newImpediments = [...employees];
    let index = newImpediments.findIndex((impediment) => impediment._id === id);
    //check if score field exists in employees state

    if (
      newImpediments &&
      newImpediments[index] &&
      fieldname in newImpediments[index]
    ) {
      newImpediments[index][fieldname] = e.target.value;
      setEmployees(newImpediments);
    }
  };

  const handleTextColumnValueChange2 = (e, id, fieldname, row) => {
    //update description field in state
    //setDescription with id, value

    //push updatedRow to updateField state
    // setUpdateField({ ...updateField, [id]: updatedRow });

    if (fieldname !== "Description") {
      let updatedRow = row;
      updatedRow[fieldname] = e.target.value;
      // ({ ...updateField, [id]: { [fieldname]: e.targsetUpdateFieldet.value } });
      setUpdateField({ ...updateField, [id]: updatedRow });
    } else {
      setDescription({ ...description, [id]: e.target.value });
      console.log("updated descrption", description);
    }
  };

  useEffect(() => {
    console.log("FieldName", updateField);
  }, [updateField]);

  useEffect(() => {
    //loading changed
    console.log("loading changed", loading);
  }, [loading]);

  const handleOnBlur = async (e, id, fieldname) => {
    console.log("handleOnBlur BLUR", e);
    //get value from description state
    const value = e.target.value;
    //"description" ? description[id] : updateField[id][fieldname];
    console.log("handleOnBlur set", id, "fieldname", fieldname, "to", value);

    //update impediment in db
    const update = await _impediments.updateImpediment(
      id,
      null,
      fieldname,
      // e.target.value
      value
    );

    if (update) {
      console.log("handleOnBlur UPDATED", update);

      const newDescription = { ...description };
      delete newDescription[id];

      console.log("handleOnBlur NEWDESCRIPTION", newDescription);

      let newImpediments = [...employees];
      console.log("FINDING newImpediments", newImpediments);
      let index = newImpediments.findIndex(
        (impediment) => impediment._id === id
      );

      console.log("UPDATING RECORD", index, "FIELD", fieldname, "TO", value);
      console.log("UPDATING RECORD", newImpediments[index]);
      newImpediments[index][fieldname] = value;
      // newImpediments[index][fieldname] = value;
      // setEmployees(newImpediments);
    }
  };

  const RenderTeamColumn = (props) => {
    if (
      props.row.original.Claimed_by_team &&
      props.row.original.Claimed_by_team.name !== "JDO" &&
      props.row.original.Claimed_by_team !== ""
    ) {
      return (
        <div className="flex items-center space-x-3">
          <span>{props.row.original.Claimed_by_team.name}</span>
        </div>
      );
    } else {
      return (
        <div className="flex items-center space-x-3">
          <span>{props.row.original.Team}</span>
        </div>
      );
    }
  };

  //get employees from api

  const viewDetails = (row, list) => {
    // console.log("ViewDetails", list);
    let state = { data: row, list: list };
    let path = "/employees/profile";
    return history.push({
      pathname: path,
      state: state,
    });
  };

  const setChangedDateTime = () => {
    let date = new Date();
    let dateStr = date.toISOString();
    setChanged(dateStr);
    return dateStr;
  };

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const getData = () => {
    const data = employees;
    console.log("employeeData", data);
    return data;
  };

  return (
    <>
      <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto space-y-6">
        <div className="sm:flex sm:justify-between sm:items-center ">
          <h1 className="text-xl md:text-2xl text-slate-800 font-bold">
            Impediments <span className="text-slate-400 font-medium"></span>
          </h1>

          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            <ToggleButtons
              value={toggleValue}
              onToggleChange={(value) => onToggleChange(value)}
            />
            {/* <FeatureFlag featureName="Add Impediment" type="flag">
              <Button
                color="secondary"
                shadow={true}
                handleClick={() => setImpedimentModalOpen(true)}
                // className="btn bg-primary-500 hover:bg-primary-600 text-white"
              >
                <DocumentPlusIcon className="h-5 w-5" />
                <span className="hidden xs:block ml-2">New Impediment</span>
              </Button>
            </FeatureFlag> */}
          </div>
        </div>
        <div
          style={{ height: "calc(100vh - 200px)" }}
          className="bg-white shadow-lg rounded-sm border border-gray-200 relative  pt-4"
        >
          <Table
            columns={columns}
            updatedId={updatedId}
            updateMyData={updateMyData}
            skipReset={skipResetRef.current}
            updateData={updateData}
            data={employees} //this could also be unclaimedImpediments
            loading={loading}
            fromDashboard={true}
            showFooter={true}
            renderSubComponent={(row) => {
              return (
                <RenderSubComponent row={row} handleOnBlur={handleOnBlur} />
              );
            }}
            hasSubComponent={true}
            onToggleChange={(value) => {
              onToggleChange(value);
            }}
          />
        </div>
      </div>
      <ModalBasic
        id="add-impediment-modal"
        modalOpen={impedimentModalOpen}
        setModalOpen={setImpedimentModalOpen}
        title={"Impediments"}
        showClose={true}
        showFooter
        closeButtonText="Close"
        scrollable={true}
        size="lg"
      >
        <AssessmentTemplateQuestionEditor
          assessment_type={"impediment"}
          selectedCompetency={null}
          questions={[]}
          addQuestionRef={null} //{addQuestionRef}
          refreshQuestions={updateImpediments} //{refreshQuestions}
          setQuestions={updateImpediments} //{setQuestions}
          modalItem={null}
          title={"Impediment "}
          description={"Add a new impdiment below"}
          create={true}
        />
      </ModalBasic>
    </>
  );
}

export default Impediments;

import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import SearchForm from "../partials/actions/SearchForm";
import FilterButton from "../components/DropdownFilter";
import CampaignsCard from "../partials/campaigns/CampaignsCard";
import PaginationNumeric from "../components/PaginationNumeric";
import Tabs from "./component/Tabs";
import Image01 from "../images/user-28-01.jpg";
import Image02 from "../images/user-28-02.jpg";
import Image03 from "../images/user-28-03.jpg";
import Image04 from "../images/user-28-04.jpg";
import Image05 from "../images/user-28-05.jpg";
import Image06 from "../images/user-28-06.jpg";
import Image07 from "../images/user-28-07.jpg";
import Image08 from "../images/user-28-08.jpg";
import Image09 from "../images/user-28-09.jpg";
import Image10 from "../images/user-28-10.jpg";
import Image11 from "../images/user-28-11.jpg";
import Image12 from "../images/user-28-12.jpg";

import { _regulations } from "../modules/_regulations";
import HomeGrid from "../components/HomeGrid";
import HomeHeader from "../components/HomeHeader";
import Grid from "../components/Grid";
import GridCard from "../components/GridCard";

function LearningHub() {
  const cards = _regulations.getRegulations();

  const headerInfo = {
    title: "Learning Hub",
    text: "PCI? NIST? CCPA? These are complicated topics. Visit our Learning Center to get up to speed on the basics or just brush up on the latest changes. At DSU, we strive to make the complicated, simple!",
    to: "",
    linkText: "",
    imageUrl: "",
    status: "",
  };

  const tabs = [
    { name: "Regulations", href: "campaigns", current: true },
    { name: "Assessment history", href: "ecommerce/customers", current: false },
  ];

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full  ">
      <div className="max-w-5xl mx-auto  content-center  ">
        <HomeHeader data={headerInfo} />
        <ul
          role="list"
          className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
        >
          {cards.map((card, idx) => (
            <li
              key={idx}
              className="h-full col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
            >
              <GridCard
                type="learning"
                data={card}
                title={card.title}
                text={card.content}
                button={card.button}
                to={card.to}
                imageName={card.imageURL}
                status={card.status}
              />
            </li>
          ))}
        </ul>
        {/* <Grid
        data={items}
        title={card.title}
        text={card.text}
        button={card.button}
        to={card.to}
        imageURL={card.imageURL}
        status={card.status}
      /> */}
        {/* {items.map((item) => {
        return (
          <CampaignsCard
            key={item.id}
            id={item.id}
            link={"ecommerce/customers"}
            data={item}
          />
        );
      })} */}
      </div>
    </div>
  );
}

export default LearningHub;

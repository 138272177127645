import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import SearchForm from "../partials/actions/SearchForm";
import FilterButton from "../components/DropdownFilter";
import CampaignsCard from "../partials/campaigns/CampaignsCard";
import PaginationNumeric from "../components/PaginationNumeric";
import Tabs from "./component/Tabs";
import Image01 from "../images/user-28-01.jpg";
import Image02 from "../images/user-28-02.jpg";
import Image03 from "../images/user-28-03.jpg";
import Image04 from "../images/user-28-04.jpg";
import Image05 from "../images/user-28-05.jpg";
import Image06 from "../images/user-28-06.jpg";
import Image07 from "../images/user-28-07.jpg";
import Image08 from "../images/user-28-08.jpg";
import Image09 from "../images/user-28-09.jpg";
import Image10 from "../images/user-28-10.jpg";
import Image11 from "../images/user-28-11.jpg";
import Image12 from "../images/user-28-12.jpg";

import { _regulations } from "../modules/_regulations";
import HomeGrid from "../components/HomeGrid";
import HomeHeader from "../components/HomeHeader";
import Grid from "../components/Grid";
import GridCard from "../components/GridCard";

function AssessmentHub() {
  // const cards = _regulations.getRegulations();
  const cards = [
    {
      title: "Pre-Assessment",
      text: "To get started, take the pre-assessment to see where your company stands in general from a Cyber Security and Data Privacy stance.  Knowing where you stand will give you the foundation you need before tackling more in-depth assessments.      ",
      button: "Take the Pre-Assessment",
      to: "/assessment",
      imageUrl: "",
      status: "",
    },
    {
      title: "Assessment",
      text: "There are different laws and standards that companies are required to be compliant with.  They also are proof points to your business customers and consumers that you are keeping their information safe,",
      button: "Take an Assessment",
      to: "/assessmentselect",
      imageUrl: "",
      status: "",
    },
    {
      title: "Assessment History",
      text: "Track your assessments, and those of your team and third parties, and track them over time to ensure that your company is progressing towards being better prepared and fully compliant.      ",
      button: "View Assessment History",
      to: "/ecommerce/customers",
      imageUrl: "",
      status: "",
    },
  ];
  const headerInfo = {
    title: "Assessments and Action Plans",
    text: "Assessments will give you an idea on where your company stands.  For any areas that can be improved, you’ll be instructed on what they are, and how to fix them, in your customized action plan once you complete the assessment.",
    to: "",
    linkText: "",
    imageUrl: "",
    status: "",
  };

  const tabs = [
    { name: "Regulations", href: "campaigns", current: true },
    { name: "Assessment history", href: "ecommerce/customers", current: false },
  ];

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full  ">
      <div className="max-w-5xl mx-auto  content-center  ">
        <HomeHeader data={headerInfo} />
        <ul
          role="list"
          className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
        >
          {cards.map((card, idx) => (
            <li
              key={idx}
              className="h-full col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
            >
              <GridCard
                type={"assessment"}
                function={"nav"}
                data={card}
                title={card.title}
                text={card.text}
                button={card.button}
                to={card.to}
                imageURL={card.imageURL}
                status={card.status}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AssessmentHub;

import PreferenceListItem from "./PreferencesListItem";

export default function PreferenceList({ options }) {
  return (
    <ul>
      {options.map((option, index) => {
        return <PreferenceListItem key={index} item={option} />;
      })}
    </ul>
  );
}

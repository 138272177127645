import React from "react";
import { Link, NavLink } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function CenterCard({ headingText, headingSubtext, goBackHome, children }) {
  return (
    <main className="h-[calc(100vh-64px)] ">
      <div className="relative md:flex justify-center">
        <div className=" justify-center content-center">
          <div className="min-h-[calc(100vh-64px)] h-full flex flex-col content-center justify-center">
            <div
              className="flex flex-col px-4  
            w-full md:w-[75vw] lg:w-[50vw] xl:w-[40vw] 2xl:w-[33vw] 3xl:w-[33vw]
            py-2  justify-center m-auto"
            >
              {/* <div className="flex flex-col"> */}
              <div className="space-y-10">
                <div
                  className="mx-auto 
                
                  p-10 bg-white rounded shadow-lg overflow-auto   max-h-full scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full"
                >
                  <div className="w-full">
                    <div className="text-sm">
                      <div className="text-center">
                        {/* <svg
                            className="mx-auto h-12 w-12 "
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            viewBox="0 0 36 36"
                          >
                            <path
                              fill="#A0041E"
                              d="m1 17l8-7l16 1l1 16l-7 8s.001-5.999-6-12s-12-6-12-6z"
                            />
                            <path
                              fill="#FFAC33"
                              d="M.973 35s-.036-7.979 2.985-11S15 21.187 15 21.187S14.999 29 11.999 32c-3 3-11.026 3-11.026 3z"
                            />
                            <circle cx="8.999" cy="27" r="4" fill="#FFCC4D" />
                            <path
                              fill="#55ACEE"
                              d="M35.999 0s-10 0-22 10c-6 5-6 14-4 16s11 2 16-4c10-12 10-22 10-22z"
                            />
                            <path d="M26.999 5a3.996 3.996 0 0 0-3.641 2.36A3.969 3.969 0 0 1 24.999 7a4 4 0 0 1 4 4c0 .586-.133 1.139-.359 1.64A3.993 3.993 0 0 0 30.999 9a4 4 0 0 0-4-4z" />
                            <path
                              fill="#A0041E"
                              d="M8 28s0-4 1-5s13.001-10.999 14-10s-9.001 13-10.001 14S8 28 8 28z"
                            />
                          </svg> */}

                        <h2 className="mt-2 text-lg font-medium text-slate-900">
                          {headingText}
                        </h2>
                        <p className="mt-3 text-sm text-slate-500 mb-10 text-left">
                          {headingSubtext}
                        </p>
                      </div>
                      <div className=" max-h-[calc(100vh_-_30rem)]  overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
                        <div className="text-sm">
                          {/* Options */}
                          {children}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default CenterCard;

const LoginLayout = ({ children }) => {
  return (
    // <Store>
    <div className="flex h-screen overflow-auto md:overflow-hidden">
      {/* Content area */}
      <div className="relative flex flex-col flex-1 ">
        <main>{children}</main>
      </div>
    </div>
  );
};

export default LoginLayout;

import { Fragment, useEffect, useState } from "react";
import {
  Bars3Icon,
  CalendarIcon,
  CogIcon,
  HomeIcon,
  MagnifyingGlassCircleIcon,
  MapIcon,
  MegaphoneIcon,
  SquaresPlusIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronLeftIcon, EnvelopeIcon } from "@heroicons/react/20/solid";
import { StarIcon } from "@heroicons/react/20/solid";
import { _marketplace } from "../../modules/_marketplace";
import VendorProfile from "./VendorProfile";
import MessageVendor from "./MessageVendorPanel";
import { Transition } from "@headlessui/react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const tabs = [
  { name: "Profile", href: "#", current: true },
  { name: "Projects", href: "#", current: false },
  { name: "Reputation", href: "#", current: false },
];
const team = [
  {
    name: "Leslie Alexander",
    handle: "lesliealexander",
    role: "Co-Founder / CEO",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Michael Foster",
    handle: "michaelfoster",
    role: "Co-Founder / CTO",
    imageUrl:
      "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Dries Vincent",
    handle: "driesvincent",
    role: "Business Relations",
    imageUrl:
      "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Lindsay Walton",
    handle: "lindsaywalton",
    role: "Front-end Developer",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];
export default function MarketplaceSideBar(props, { children }) {
  const [selectedItem, setSelectedItem] = useState(props.selectedItem);
  const [show, setShow] = useState(props.show);
  const [sidebarPanelToShow, setSidebarPanelToShow] = useState("profile");

  useEffect(() => {
    console.log("show changed", props.show);
    setShow(props.show);
  }, [props.show]);

  useEffect(() => {
    console.log("props.selectedItem changed", props.selectedItem);
    setSelectedItem(props.selectedItem);
  }, [props.selectedItem]);

  return (
    <div>
      <Transition
        className="fixed inset-0 bg-gray-900 bg-opacity-30 z-40 transition-opacity"
        show={show}
        onClick={() => {
          setShow(false);
          props.setShow(false);
        }}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      <aside
        //translate x from 0 to 100% to show and hide
        className={`w-[100vw] md:w-[90vw] lg:w-[70vw] xl:w-[40vw] 
        pt-0 pb-2 z-50
transition-all duration-500 ease-in-out 
transform ${show && show == true ? "translate-x-0" : "translate-x-full"}
bg-white fixed bottom-0 right-0 top-0 

 overflow-x-hidden
overflow-y-inherit border-l border-gray-200 xl:block scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full`}
      >
        {sidebarPanelToShow == "profile" && (
          <VendorProfile
            setSidebarPanel={setSidebarPanelToShow}
            selectedItem={selectedItem}
            tabs={tabs}
            team={team}
            setShow={props.setShow}
          />
        )}
        {sidebarPanelToShow == "message" && (
          <MessageVendor
            selectedItem={selectedItem}
            handleClickBack={(name) => {
              setSidebarPanelToShow(name);
            }}
            setSidebarPanel={setSidebarPanelToShow}
          />
        )}
      </aside>
    </div>
  );
}

import React, { useEffect, useRef } from "react";
import Button from "../elements/Button";
import { Icon } from "@iconify/react";
import { HandThumbUpIcon } from "@heroicons/react/20/solid";
import CommentsBar from "./CommentsBar";
import { utils } from "../modules/_utils";
import { _comments } from "../modules/_comments";
import Badge from "./Badge";
import Chips from "./Chips";
import { FeatureFlag } from "./FeatureFlag";

// const comments = [
//   {
//     id: 1,
//     name: "Michael Gough",
//     date: "Feb. 8, 2022",
//     comment:
//       "Very straight-to-point article. Really worth time reading. Thank you! But tools are just the instruments for the UX designers. The knowledge of the design tools are as important as the creation of the design strategy.",
//     replies: [
//       {
//         id: 1,
//         name: "Jese Leos",
//         date: "Feb. 12, 2022",
//         comment: "Much appreciated! Glad you liked it ☺️",
//         replies: [],
//         thanks: {
//           count: 2,
//           users: [
//             { id: "1", name: "Oran Sears" },
//             { id: "2", name: "Bill Gates" },
//           ],
//         },
//       },
//       {
//         id: 2,
//         name: "Bonnie Green",
//         date: "Mar. 12, 2022",
//         comment:
//           "The article covers the essentials, challenges, myths and stages the UX designer should consider while creating the design strategy.",
//         replies: [],
//         thanks: { count: 2, users: ["Oran Leos", "Bonnie Green"] },
//       },
//       {
//         id: 3,
//         name: "Helene Engels",
//         date: "Jun. 23, 2022",
//         comment:
//           "Thanks for sharing this. I do came from the Backend development and explored some of the tools to design my Side Projects.",
//         replies: [],
//         thanks: { count: 2, users: ["Jese Leos", "Bonnie Green"] },
//       },
//     ],
//     thanks: { count: 2, users: ["Jese Leos", "Bonnie Green"] },
//   },
//   {
//     id: 2,
//     name: "Jese Leos",
//     date: "Feb. 12, 2022",
//     comment: "Much appreciated! Glad you liked it ☺️",
//     replies: [],
//     thanks: { count: 2, users: ["Jese Leos", "Bonnie Green"] },
//   },
//   {
//     id: 3,
//     name: "Bonnie Green",
//     date: "Mar. 12, 2022",
//     comment:
//       "The article covers the essentials, challenges, myths and stages the UX designer should consider while creating the design strategy.",
//     replies: [],
//     thanks: { count: 0, users: [] },
//   },
//   {
//     id: 4,
//     name: "Helene Engels",
//     date: "Jun. 23, 2022",
//     comment:
//       "Thanks for sharing this. I do came from the Backend development and explored some of the tools to design my Side Projects.",
//     replies: [],
//     thanks: { count: 0, users: [] },
//   },
// ];

export default function Comments(props) {
  const {
    profile,
    from,
    setCommentText,
    commentText,
    commentType,
    handlePost,
    handleReply,
    handleDelete,
    handleEdit,
    handleThankYou,
    // comments,
    handleLike,
    audit,
    action,
    auditDetails,
  } = props;
  const ref = useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [comments, setComments] = React.useState([]);
  const [sortDirection, setSortDirection] = React.useState("asc");
  //   const [commentText, setCommentText] = React.useState("");

  useEffect(() => {
    console.log("Comments changed", comments);
  }, [comments]);

  useEffect(() => {
    console.log("USEEFFECT props.comments changed");
    if (props.comments && props.comments.length > 0) {
      if (sortDirection == "asc") {
        //oldest to newest
        const sortedComments = props.comments.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        setComments(sortedComments);
      } else {
        //newest to oldest
        const sortedComments = props.comments.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setComments(sortedComments);
      }
    }
  }, [props.comments]);

  useEffect(() => {
    console.log("USEEFFECT sortDirection changed");
    if (props.comments && props.comments.length > 0) {
      const sortedComments = [...props.comments].sort((a, b) => {
        if (sortDirection === "asc") {
          // oldest to newest
          return new Date(a.createdAt) - new Date(b.createdAt);
        } else {
          // newest to oldest
          return new Date(b.createdAt) - new Date(a.createdAt);
        }
      });
      setComments(sortedComments);
    }
  }, [sortDirection, props.comments]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  const handleInput = (e) => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = `${e.target.scrollHeight - 16}px`;
    }
  };
  const getDisplayParams = (comment) => {
    if (
      comment?.email &&
      comment.email.toLowerCase() == profile?.email.toLowerCase()
    ) {
      return "Me";
    } else if (props.from == "requestor" && comment.authorType == "requestor") {
      return comment.name;
    } else if (props.from == "requestor" && comment.authorType == "vendor") {
      return comment.name + " @ " + comment.company;
    } else if (props.from == "vendor" && comment.authorType == "requestor") {
      return "Someone at " + comment.company;
    } else if (props.from == "vendor" && comment.authorType == "vendor") {
      return "Anonymous Vendor";
    } else {
      return "All else failed";
    }
  };

  // const onLike = async (e, id) => {
  //   if (profile) {
  //     let updatedComment = await _comments.likeOnComment({
  //       commentId: id,
  //       userId: profile.user_id,
  //     });
  //     if (updatedComment) {
  //       let newLikes = [...updatedComment.likes];
  //       if (alreadyLiked) {
  //         newLikes = newLikes.filter((like) => like.userId !== profile.user_id);
  //       } else {
  //         newLikes.push({ userId: profile.user_id });
  //       }
  //       setComments({ ...updatedComment, likes: newLikes });
  //     }
  //   }
  // };

  const onLike = async (e, id) => {
    console.log(id, "id-comment");
    // handleLike(e,id)

    if (profile) {
      console.log(profile, "profile-likes");
      console.log("comments-on-likes", comments);
      let updated = await _comments.likeOnComment({
        commentId: id,
        userId: profile.user_id,
      });
      if (updated) {
        console.log("updated like in db", updated);
      }
      let newComments = [...comments];
      let comment = newComments.find((comment) => comment._id == id);
      console.log(comment, "comment-likes");
      //if the like is already there, remove it
      //if the like is not there, add it
      let liked = comment?.likes?.filter(
        (like) => like.userId == profile.user_id
      );
      if (liked && liked.length > 0) {
        console.log("Already liked, removing", liked);
        //remove the like
        comment.likes = comment.likes.filter(
          (like) => like.userId != profile.user_id
        );
      } else {
        console.log("You have not liked this comment, adding", liked);
        //add the like
        //find the like in updated and add it to the comment
        let myLike = updated.likes.find(
          (like) => like.userId == profile.user_id
        );
        console.log("myLike", myLike);

        comment.likes = [...comment.likes, myLike];
      }

      setComments(newComments);
    }
  };

  const handleSort = () => {
    if (sortDirection === "asc") {
      console.log("changing sortDirection to", "desc");
      setSortDirection("desc");
    } else {
      console.log("changing sortDirection to", "asc");
      setSortDirection("asc");
    }
  };

  return (
    <section class="w-full    antialiased">
      <div class="max-w-2xl mx-auto px-4">
        <form class="pb-6  border-b border-gray-200">
          <div class="relative bg-white py-2 px-4 mb-4 ">
            <label for="comment" class="sr-only">
              Your comment
            </label>
            {/* display a comment icon in the textarea */}
            <div className="pb-2 items-start text-xl pointer-events-none absolute inset-y-4 left-3 flex pl-3">
              <Icon
                className="h-5 w-5 text-gray-400"
                icon="fluent:comment-20-filled"
              />
            </div>
            <textarea
              id="comment"
              ref={ref}
              rows="3"
              className="px-6 pl-8 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Write your comment here..."
              value={commentText}
              required
              //disable resizing
              style={{ resize: "none" }}
              onInput={(e) => handleInput(e)}
              onChange={(e) => setCommentText(e.target.value)}
            ></textarea>
          </div>
          <div class="flex items-center justify-end">
            <Button
              type="submit"
              color="secondary"
              handleClick={(e) => {
                e.preventDefault();
                handlePost();
              }}
              audit={audit}
              action={action ? action : ""}
              details={auditDetails}
              disabled={!commentText}
              loader={loading}
              //move button to end of line

              // class="inline-flex ml-auto items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
            >
              Post comment
            </Button>
          </div>
        </form>
        <div className="flex items-center justify-end mt-4">
          <Button size="md" color="link" handleClick={handleSort}>
            <span className="flex text-sm">
              {sortDirection === "asc" ? "Oldest first" : "Newest first"}
            </span>
            <Icon
              icon={
                //because the icons seem backwards, we use the opposite icon
                sortDirection === "asc"
                  ? "mdi:sort-calendar-descending"
                  : "mdi:sort-calendar-ascending"
              }
              className="h-5 w-5 flex  ml-2"
            />
          </Button>
        </div>
        <ul role="list" className="">
          {comments &&
            comments.length > 0 &&
            comments.map((comment, cIdx) => {
              const { _id, name, createdAt, replies, authortype, company } =
                comment;
              // display comment and replies
              return (
                <li key={_id} className="mb-6 border rounded  border-gray-200">
                  <article class=" px-6 pt-6 pb-2 text-base  ">
                    <footer class="flex justify-between items-center mb-2">
                      <div class="flex items-center">
                        <p class="inline-flex items-center mr-3 text-sm text-gray-900  font-semibold">
                          {/* <img
                          class="mr-2 w-6 h-6 rounded-full"
                          src="https://flowbite.com/docs/images/people/profile-picture-4.jpg"
                          alt="Helene Engels"
                        /> */}
                          {getDisplayParams(comment)}
                        </p>
                        <p class="text-sm text-gray-600 ">
                          <time pubdate datetime={createdAt} title={createdAt}>
                            {utils.formatDate(createdAt, "long-time")}
                          </time>
                        </p>
                      </div>
                      <div>
                        <Badge color="secondary" case="uppercase">
                          {comment?.authorType}
                        </Badge>
                      </div>
                      {/* REMOVE Dropdown button */}
                      {/* <button
                      id={`dropdownComment${cIdx}Button`}
                      data-dropdown-toggle={`dropdownComment${cIdx}Button`}
                      class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500  rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50  dark:focus:ring-gray-600"
                      type="button"
                    >
                      <svg
                        class="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 16 3"
                      >
                        <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                      </svg>
                    </button> */}

                      <div
                        id={`dropdown${cIdx}`}
                        class="hidden z-10 w-36  rounded divide-y divide-gray-100 shadow  dark:divide-gray-600"
                      >
                        <ul
                          class="py-1 text-sm text-gray-700 "
                          aria-labelledby="dropdownMenuIconHorizontalButton"
                        >
                          <li>
                            <a
                              href="#"
                              class="block py-2 px-4 hover:bg-gray-100 dark:hover:text-white"
                            >
                              Edit
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              class="block py-2 px-4 hover:bg-gray-100 dark:hover:text-white"
                            >
                              Remove
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              class="block py-2 px-4 hover:bg-gray-100 dark:hover:text-white"
                            >
                              Report
                            </a>
                          </li>
                        </ul>
                      </div>
                    </footer>
                    <p class="text-gray-500 ">{comment.comment}</p>
                    {/* <FeatureFlag
                      id="comments-bar"
                      featureName="Comments Bar"
                      type="flag"
                    > */}
                    <CommentsBar
                      profile={profile}
                      comment={comment}
                      replies={replies}
                      replyCount={replies && replies.length}
                      thanksCount={comment && comment.likes && comment.likes}
                      handleLike={(e) => {
                        console.log("like pressed");
                        onLike(e, comment._id);
                      }}
                    />
                    {/* </FeatureFlag> */}
                  </article>
                </li>
              );
            })}
        </ul>
      </div>
    </section>
  );
}

import React, { useState, useEffect } from "react";
// import USAMap from "react-usa-map";
import CampaignsCard from "../partials/campaigns/CampaignsCard";
import Tabs from "./component/Tabs";
import { _regulations } from "../modules/_regulations";
import "../css/maps.css";
import { _policies } from "../modules/_policy_tracker";
import ModalBasic from "../components/ModalBasic";
import AccordionBasic from "../components/AccordionBasic";
import StatePolicyViewer from "../components/StatePolicyView";
function Campaigns() {
  const [selectedTab, setSelectedTab] = useState("Regulations");
  const [policies, setPolicies] = useState([]);
  const [modalItem, setModalItems] = useState({});
  const [showModal, setShowModal] = useState(false);
  const items = _regulations.getRegulations();
  const tabs = [
    { name: "Regulations", href: "#", current: true },
    { name: "Policy Tracker", href: "#", current: false },
  ];

  useEffect(() => {
    (async () => {
      console.log("useEffect");

      const policies = await _policies.getAllPolicies();
      console.log("policies", policies);
      setPolicies(policies);
    })();
  }, []);

  const setModalItem = (item) => {
    setModalItems(item);
    showHideModal();
  };

  const showHideModal = () => {
    setShowModal(!showModal);
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);
  /* mandatory */
  const mapHandler = (event) => {
    //get state from polices

    const state = event.target.dataset.name.toLowerCase();
    console.log("statePolicies state", state);

    let statePolicies = policies.filter((policy) => {
      if (state) {
        return policy.stateCode.toLowerCase() === state;
      }
    });
    console.log("statePolicies", statePolicies);

    setModalItem(statePolicies);
    // alert(event.target.dataset.name);
  };

  /* optional customization of filling per state and calling custom callbacks per state */
  const statesFilling = () => {
    return {
      NJ: {
        fill: "#9aca3c",
        clickHandler: () => alert("Custom callback for the NJ state"),
      },
      NY: {
        fill: "#412267",
      },
      TX: {
        fill: "#CC0000",
      },
    };
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            Knowledge Center
          </h1>
        </div>

        {/* Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          {/* Search form */}
          {/* <SearchForm /> */}
          {/* Filter button */}
          {/* <FilterButton align="right" /> */}
          {/* Create campaign button */}
          {/* <button className="btn bg-primary-500 hover:bg-primary-600 text-white">
                  <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Create Campaign</span>
                </button> */}
        </div>
      </div>
      {/* General */}
      <div className="mb-6">
        {/* Filters */}
        {/* <div className="mb-4 border-b border-gray-200"> */}
        <div className="mb-5">
          <Tabs
            tabs={tabs}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
        </div>
      </div>

      {selectedTab === "Regulations" && (
        <div className="grid grid-cols-12 gap-6">
          {items.map((item) => {
            return (
              <CampaignsCard
                key={item.id}
                id={item.id}
                link={"ecommerce/customers"}
                data={item}
              />
            );
          })}
        </div>
      )}
      {selectedTab === "Policy Tracker" && (
        <div className="map grid h-screen place-items-center ">
          {" "}
          {/* <div className="map"> */}
          {/* <USAMap customize={statesFilling} onClick={mapHandler} /> */}
          {/* </div> */}
        </div>
      )}

      <ModalBasic
        id="state-policy-modal"
        modalOpen={showModal}
        setModalOpen={showHideModal}
        title={showModal == true && modalItem[0].state}
      >
        {showModal == true && <StatePolicyViewer modalItem={modalItem} />}
        {/* Modal footer */}
        <div className="sticky bottom-0 px-5 py-4 bg-white border-t border-gray-200">
          <div className="flex flex-wrap justify-end space-x-2">
            <button
              className="btn-sm bg-primary-500 hover:bg-primary-600 text-white"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                showHideModal();
              }}
            >
              Done
            </button>
            {/* <button className="btn-sm bg-primary-500 hover:bg-primary-600 text-white">
              I Understand
            </button> */}
          </div>
        </div>
      </ModalBasic>
    </div>
  );
}

export default Campaigns;

import React, { useState } from "react";
import PreferencesSection from "../../components/Preferences/PreferenceSection";
import Divider from "../../components/Divider";

function NotificationsPanel() {
  const [comments, setComments] = useState(false);
  const [messages, setMessages] = useState(false);
  const [mentions, setMentions] = useState(false);

  return (
    <div className="grow w-full h-full">
      {/* Panel body */}
      <div className="p-6 space-y-6 w-full">
        <h2 className="text-2xl text-slate-800 font-bold mb-5">
          Notification Preferences
        </h2>
        {/* Comments */}
        <PreferencesSection
          title="Comments"
          bottomDivider={false}
          options={[
            {
              id: "comments", //comment-notifications
              name: "Comments",
              description:
                "Email me when new comments are left on bid requests.",
              value: comments,
              setValue: setComments,
            },
            // {
            //   id: "messages",
            //   name: "Messages",
            //   description:
            //     "Email me when new messages are left on bid requests.",
            //   value: messages,
            //   setValue: setMessages,
            // },
            // {
            //   id: "mentions",
            //   name: "Mentions",
            //   description: "Email me when I am mentioned in a comment.",
            //   value: mentions,

            //   setValue: setMentions,
            // },
          ]}
        />
      </div>

      {/* Panel footer */}
      {/* <footer>
        <div className="flex flex-col px-6 py-5 border-t border-gray-200">
          <div className="flex self-end">
            <button className="btn border-gray-200 hover:border-gray-300 text-slate-600">
              Cancel
            </button>
            <button className="btn bg-primary-500 hover:bg-primary-600 text-white ml-3">
              Save Changes
            </button>
          </div>
        </div>
      </footer> */}
    </div>
  );
}

export default NotificationsPanel;

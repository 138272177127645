import { _assessment } from "../modules/_assessment";

import Hamock from "../images/hammock.png";
// import Empty from "../components/Empty";
import Empty from "../components/Empty";
export default function ThankYou(props) {
  // const riskLevel = _assessment.calculateRisk(
  //   counts,
  //   countOfEach,
  //   risk_threshold
  // );
  const riskExplanation = ""; //look up function and pass riskLevel
  return (
    <div className="relative bg-white">
      <Empty
        buttonColor={"green"}
        title={"You're all set!"}
        content={
          "Thanks for completing this assessment. We'll be in touch with you shortly."
        }
        buttonText={"Want to create your own assessments?"}
        buttonLink={"/signup"}
      />
    </div>
  );
}

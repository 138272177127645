/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from "react";
import Grid from "../components/Grid";
import HomeGrid from "../components/HomeGrid";
import HomeHeader from "../components/HomeHeader";
import { _auth } from "../modules/_auth";
import { _user_profile } from "../modules/_user_profile";
import { useHistory } from "react-router-dom";
import Skeleton from "../components/Skeleton";
import Button from "../elements/Button";
import { audit } from "../modules/_audit";
import StackedList from "../components/StackedList";
import ModalBasic from "../components/ModalBasic";
import NewSolicitation from "./projects/NewSolicitation";
import Drawer from "../components/Drawer";
import BidList from "./projects/BidList";
import { Bids } from "../modules/_bids";
import BidRequestListWithBids from "../components/BidRequestListWithBids";
import { Solicitations } from "../modules/_solicitations";

const cards = [
  {
    title: "Understand your risk",
    text: "How prepared are you for a data incident?  Are you compliant with the latest data privacy laws? Take one of our Assessments and receive your own Readiness Report with customized action plan to minimize your risk.",
    button: "Take an assessment",
    to: "/assessmenthub",
    action_icon: "takeassessment",
    status: "",
    show: true,
  },
  {
    title: "Cyber Security 101, 201,…",
    text: "PCI?  NIST?  CCPA?  These are complicated topics.  Visit our Learning Center to get up to speed on the basics or just brush up on the latest changes.  At DSU, we strive to make the complicated, simple!",
    button: "Jumpstart your Understanding",
    to: "/learninghub",
    action_icon: "Understanding",
    status: "",
    show: false,
  },
  {
    title: "Get Help!",
    text: "Whether you are looking to review your action plan, or connect with a solution provider to understand their offering, we have Specialists ready to assist.",
    button: "Book an Expert",
    to: "/contact",
    action_icon: "Expert",
    status: "",
    show: true,
  },
  {
    title: "Solutions",
    text: "Was your action plan not spotless?  Have some work to do and need to connect with a provider?  DSU has a fully vetted list of first-class partners ready to assist.",
    button: "Visit the Marketplace",
    to: "/marketplace",
    action_icon: "marketplace",
    show: false,
  },
  {
    title: "Community",
    text: "Connect with people and companies who have been where you are.  Have a question for the community?  Want to rave about a partner or solution?  Your experiences are valuable and we want to hear them.  Join the conversation!",
    button: "Next Stop: Community!",
    to: "/home",
    action_icon: "Community",
    status: "coming soon",
    show: false,
  },
  {
    title: "Premium Offerings",
    text: "Certified employee training is one of the most important things you can do to be prepared.  This, and other valuable offerings, are available to you as a DSU community member. ",
    button: "Explore Value Added Services",
    to: "/home",
    action_icon: "Premium Offerings",
    status: "coming soon",
    show: false,
  },
];

const headerInfo = {
  title: "Welcome, Joe!",
  text: "This is your DSU hub. From here you’ll be able to understand the world of cyber security and data privacy through our Learning Center, get tailored action plans with our Assessments, connect with the DSU Community, browse our solution partners in the Marketplace and more!",
  to: "/home",
  linkText: "for a message from our founder.",
  action_icon: "",
  status: "",
};

export default function Home(props) {
  const { setNewSolicitationShortcutModalOpen } = props;
  let profile = _auth.getUserProfile();
  const homeGrid = true;
  const [showHome, setShowHome] = useState(true);
  const [checked, setChecked] = useState(false);

  const [loading, setLoading] = useState(false);
  const [newSolicitationModalOpen, setNewSolicitationModalOpen] =
    useState(false);
  const [newSolicitationData, setNewSolicitationData] = useState(null);
  const [solicitations, setSolicitations] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);

  const history = useHistory();

  const isChecked = () => {
    return checked;
  };

  useEffect(() => {
    (async () => {
      const bidRequests = await Solicitations.getByTenantId();
      setSolicitations(bidRequests);
    })();
  }, [props.updated]);

  // useEffect(() => {
  //   setSolicitations(props.solicitations);
  // }, [props.solicitations]);

  // async function handleUndoClick(e) {
  //   setChecked(false);
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setLoading(true);

  //   let ans = await _user_profile.updateShowHome({ showHome: true });
  //   if (ans) {
  //     profile.showHome = true;
  //     _auth.setUserProfile(profile);
  //     profile = _auth.getUserProfile();
  //   }

  //   setLoading(false);

  //   // setShowHome(true);
  // }

  // };
  const handleBidRequestClick = (bids, bid, solicitation) => {
    history.push({
      pathname: "/projects/project-details/"+ bid.projectId +"/solicitation-details/"+solicitation._id,
      state: { project: bid, solicitation: solicitation },
    });
  };

  

  return (
    <>
      <div className="flex flex-col px-4   py-2 w-full justify-center  max-w-6xl m-auto">
        <div className="flex divide-x ">
          <StackedList
            headingText="Let's get you started."
            headingSubtext="Here are some things you can do to get started. If you need help, feel free to reach out to us!"
            // options={[
            //   {
            //     id: "1",
            //     status: "Active",
            //     title: "Invite your team",

            //     subTitle: "Invite your team to join your organization",
            //     rightText: "",
            //     handleClick: () => {
            //       history.push("/settings/account");
            //     },
            //   },
            //   {
            //     id: "2",
            //     status: "Active",
            //     title: "Create a project",
            //     subTitle: "Create a project to start your bid requests",
            //     rightText: "",
            //     handleClick: (e) => {
            //       console.log("onclick called", e);
            //       props.handleOpenAssessmentModalFromHome(e);
            //     },
            //   },
            //   {
            //     id: "3",
            //     status: "Active",
            //     title: "Get help",
            //     subTitle: "Get help from our team",
            //     rightText: "",
            //     handleClick: (e) => {
            //       props.handleOpenContactModal(e);
            //     },
            //   },
            // ]}
            handleOpenAssessmentModalFromHome={(e) => {
              console.log("onclick called", e);
              props.handleOpenAssessmentModalFromHome(e);
            }}
            setNewSolicitationShortcutModalOpen={(e) => {
              console.log("onclick called", e);
              setNewSolicitationShortcutModalOpen(true);
            }}
            options={
              props.options
                ? props.options
                : [
                    // {
                    //   id: "2",
                    //   status: "Active",
                    //   title: "See your existing projects",
                    //   subTitle: "Go to your existing projects.",
                    //   rightText: "",
                    //   highlight: false,
                    //   handleClick: () => {
                    //     history.push("/projects");
                    //   },
                    // },
                    {
                      id: "2",
                      status: "Active",
                      title: "See your active bids",
                      subTitle: "See your most recent active bids.",
                      rightText: "",
                      highlight: false,
                      handleClick: () => {
                        setShowDrawer(true);
                      },
                    },
                    {
                      id: "2a",
                      status: "Active",
                      title: "View your dashboard",
                      subTitle:
                        "Check out your dashboard to see your progress.",
                      rightText: "",
                      highlight: false,
                      handleClick: () => {
                        history.push("/dashboard");
                      },
                    },
                    {
                      id: "0",
                      status: "Active",
                      title: "Invite your team",

                      subTitle: "Invite your team to collaborate with you.",
                      rightText: "",
                      handleClick: () => {
                        history.push({
                          pathname: "/settings/account",
                          state: {
                            panel: "collaborators",
                            group: "team",
                            tabName: "Collaborators",
                          },
                        });
                      },
                    },
                    {
                      id: "3",
                      status: "Active",
                      title: "Get help",
                      subTitle: "Get help from our team.",
                      rightText: "",
                      handleClick: (e) => {
                        props.handleOpenContactModal(e);
                      },
                    },
                  ]
            }
          />
        </div>

        <Drawer show={showDrawer} setShow={setShowDrawer} title="Recent Bids">
          <div
            className="flex py-6  mx-auto my-auto h-[calc(100vh-4em)] overflow-y-auto
        
        scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full bg-white
        "
          >
            {/* {JSON.stringify(props.solicitations)} */}
            <BidRequestListWithBids
              solicitations={solicitations}
              handleClick={(bids, bid, solicitation) => {
                handleBidRequestClick(bids, bid, solicitation);
              }}
            />
          </div>
        </Drawer>
      </div>
    </>
  );
}

let baseUrl = "";
if (process.env.REACT_APP_NODE_ENV === "local") {
  baseUrl = "http://localhost:7001/api/";
} else if (process.env.REACT_APP_NODE_ENV === "production") {
  baseUrl = "https://prod-api.breachlink.com/api/";
} else if (process.env.REACT_APP_NODE_ENV === "beta") {
  baseUrl = "https://beta-api.breachlink.com/api/";
} else if (process.env.REACT_APP_NODE_ENV === "demo") {
  baseUrl = "https://demo-api.breachlink.com/api/";
} else if (process.env.REACT_APP_NODE_ENV === "test") {
  baseUrl = "https://test-api.breachlink.com/api/";
} else if (process.env.REACT_APP_NODE_ENV === "dev") {
  baseUrl = "https://dev-api.breachlink.com/api/";
} else {
  baseUrl = "http://localhost:7001/api/";
}
console.info("CURRENT ENVIRONMENT: ", process.env.REACT_APP_NODE_ENV);
console.info("CURRENT BASE URL: ", baseUrl);
export { baseUrl };

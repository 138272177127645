import React, { useEffect, useState } from "react";
import Input from "./Input";
import { calculator } from "../modules/_calculator";
import { utils } from "../modules/_utils";

function AssumptionsCalc({ bids }) {
  const [assumptionValues, setAssumptionValues] = useState({});
  const [total, setTotal] = useState(0);
  const assumptionList = [
    "data_volume",
    "number_of_documents",
    "project_management",
    "project_duration",
    "project_management",
    "hosting_volume",
    "hosting_duration",
    "special_pdf_total",
    "special_spreadsheets_total",
  ];

  useEffect(() => {
    console.log("assumptionValues", assumptionValues);
    //if assumptionValues is empty, then do not calculate
    if (Object.keys(assumptionValues).length === 0) {
      return;
    }
    let total = calculator.calculate(bids, assumptionValues);
    console.log("calc total", total);
    setTotal(total);
  }, [assumptionValues]);

  const handleOnFieldChange = (e) => {
    setAssumptionValues({
      ...assumptionValues,
      [e.target.name]: Number(e.target.value),
    });
  };

  const calculateTotal = (service, lineItem, assumption) => {};

  return (
    <div className="w-full h-full">
      <div className="h-full flex gap-x-6 divide-x divide-gray-200 justify-between w-full space-y-4">
        <div className="p-6 w-1/4">
          {assumptionList.map((assumption, index) => (
            <div className="block">
              <Input
                onChange={handleOnFieldChange}
                showLabel={true}
                key={index}
                name={assumption}
                placeholder=""
                Icon=""
                iconPosition=""
                label={assumption}
                id={assumption}
                indicator=""
                type=""
                helpText=""
                errorText=""
                disabled=""
                autofill=""
              />
            </div>
          ))}
        </div>
        <div className="flex w-3/4 space-y-8">
          <div className="py-4 px-2 gap-x-6 gap-y-6 flex flex-wrap">
            {total &&
              total.map((companyObj, index) => {
                const companyName = Object.keys(companyObj)[0];
                return (
                  <ul
                    key={index}
                    className="space-y-4 shadow border border-gray-200 rounded p-4"
                  >
                    <li className="text-xl font-semibold">{companyName}</li>
                    {companyObj[companyName].map((serviceObj, index) => {
                      const serviceName = Object.keys(serviceObj)[0];
                      return (
                        <div key={index}>
                          <li className="text-md font-medium">
                            {utils.cleanUpKeys(serviceName)}
                          </li>
                          {Object.keys(serviceObj[serviceName]).map(
                            (lineItem, index) => (
                              <li key={index}>
                                {lineItem} :
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                }).format(serviceObj[serviceName][lineItem])}
                              </li>
                            )
                          )}
                          <div className="text-xl border-t border-black">
                            Total:
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(
                              Object.values(serviceObj[serviceName]).reduce(
                                (total, value) => total + value,
                                0
                              )
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </ul>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssumptionsCalc;

import React, { useEffect, useState } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { convertToHTML } from "draft-convert";

const WYSIWYGViewer = ({ editorState: rawEditorState }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  useEffect(() => {
    if (rawEditorState instanceof EditorState) {
      setEditorState(rawEditorState);
    } else if (typeof rawEditorState === "object") {
      if (!rawEditorState.entityMap) {
        rawEditorState.entityMap = {};
      }
      console.log("rawEditorState", rawEditorState);
      const contentState = convertFromRaw(rawEditorState);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [rawEditorState]);
  const contentState = editorState ? editorState.getCurrentContent() : {};
  const blockToHTML = (block) => {
    if (block.type === "unstyled") {
      return <p style={{ marginBottom: "1em", marginTop: "1em" }} />;
    }
    if (block.type === "unordered-list-item") {
      return { start: "<li>", end: "</li>" };
    } else if (block.type === "ordered-list-item") {
      return {
        start: "<ol><li>",
        end: "</li></ol>",
      };
    }
  };
  const styleToHTML = (style) => {
    console.log("style", style);

    if (style.startsWith("color-")) {
      return <span style={{ color: style.replace("color-", "") }} />;
    } else if (style.startsWith("bgcolor-")) {
      return (
        <span style={{ backgroundColor: style.replace("bgcolor-", "") }} />
      );
    }
  };
  let html = convertToHTML({
    blockToHTML: blockToHTML,
    styleToHTML: styleToHTML,
  })(contentState);
  // Post-processing step to correctly wrap list items
  html = html.replace(/<\/ul>\s*<ul>/g, "").replace(/<\/ol>\s*<ol>/g, "");

  return (
    <div>
      <div className="b px-6 py-8" dangerouslySetInnerHTML={{ __html: html }} />
      {/* <div>{html}</div> */}
    </div>
  );
};

export default WYSIWYGViewer;

import React, { useState } from "react";

import Image from "../../images/user-avatar-80.png";

function AccountPanel() {
  const [sync, setSync] = useState(false);

  return (
    <div className="grow p-6 space-y-6 overflow-y-auto">
      <h2 className="text-2xl text-slate-800 font-bold mb-5">My Account</h2>
      {/* Picture */}
      <section>
        <div className="flex items-center">
          <div className="mr-4">
            <img
              className="w-20 h-20 rounded-full"
              src={Image}
              width="80"
              height="80"
              alt="User upload"
            />
          </div>
          <button className="btn-sm bg-primary-500 hover:bg-primary-600 text-white">
            Change
          </button>
        </div>
      </section>
      {/* Business Profile */}
      <section>
        <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">
          Business Profile
        </h2>
        <div className="text-sm">
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
          officia deserunt mollit.
        </div>
        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1" htmlFor="name">
              Business Name
            </label>
            <input id="name" className="form-input w-full" type="text" />
          </div>
          <div className="sm:w-1/3">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="business-id"
            >
              Business ID
            </label>
            <input id="business-id" className="form-input w-full" type="text" />
          </div>
          <div className="sm:w-1/3">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="location"
            >
              Location
            </label>
            <input id="location" className="form-input w-full" type="text" />
          </div>
        </div>
      </section>
      {/* Email */}
      <section>
        <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">
          Email
        </h2>
        <div className="text-sm">
          Excepteur sint occaecat cupidatat non proident sunt in culpa qui
          officia.
        </div>
        <div className="flex flex-wrap mt-5">
          <div className="mr-2">
            <label className="sr-only" htmlFor="email">
              Business email
            </label>
            <input id="email" className="form-input" type="email" />
          </div>
          <button className="btn border-gray-200 hover:border-gray-300 shadow-sm text-primary-500">
            Change
          </button>
        </div>
      </section>
      {/* Password */}
      <section>
        <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">
          Password
        </h2>
        <div className="text-sm">
          You can set a permanent password if you don't want to use temporary
          login codes.
        </div>
        <div className="mt-5">
          <button className="btn border-gray-200 shadow-sm text-primary-500">
            Set New Password
          </button>
        </div>
      </section>
      {/* Smart Sync */}
      <section>
        <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">
          Smart Sync update for Mac
        </h2>
        <div className="text-sm">
          With this update, online-only files will no longer appear to take up
          hard drive space.
        </div>
        <div className="flex items-center mt-5">
          <div className="form-switch">
            <input
              type="checkbox"
              id="toggle"
              className="sr-only"
              checked={sync}
              onChange={() => setSync(!sync)}
            />
            <label className="bg-gray-400" htmlFor="toggle">
              <span className="bg-white shadow-sm" aria-hidden="true"></span>
              <span className="sr-only">Enable smart sync</span>
            </label>
          </div>
          <div className="text-sm text-slate-400 italic ml-2">
            {sync ? "On" : "Off"}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AccountPanel;

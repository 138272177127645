import { useHistory } from "react-router-dom";
import HighestRisk from "../images/highest.jpg";
import { _assessment } from "../modules/_assessment";
// import ChartGauge from "./ChartGuage";
import DynamicImage from "./DynamicImage";
import GaugeChart from "react-gauge-chart";
import { ProgressBarSmall } from "./ProgressSmall";
export default function ContentSplit(props) {
  const goToActionPlan = props.goToActionPlan;
  const counts = props.counts;
  const thisAssessment = props.thisAssessment;
  const risk = props.risk;

  const history = useHistory();
  const goHome = () => {
    return history.push({
      pathname: "/home",
    });
  };
  const assessment_details = _assessment.getAssessmentTypeDetails(
    thisAssessment.assessment_type
  );

  console.info("Assessment Details", assessment_details);
  const blurbs =
    assessment_details.length > 0 ? assessment_details[0].blurbs : [];
  const blurb = blurbs.length > 0 ? _assessment.getBlurb(risk, blurbs) : [];

  console.info("thisAssessment", thisAssessment);
  // const countOfEach = {};
  // for (const num of counts) {
  //   countOfEach[num] = countOfEach[num] ? countOfEach[num] + 1 : 1;
  // }
  // console.info("CountofEach", countOfEach);
  const risk_threshold = _assessment.getRiskThresholds();
  const riskLevel = thisAssessment.risk_score;
  console.info("Risk Level", riskLevel);
  // const riskLevel = _assessment.calculateRisk(
  //   counts,
  //   countOfEach,
  //   risk_threshold
  // );
  const riskExplanation = ""; //look up function and pass riskLevel

  const res = thisAssessment.questions
    ? thisAssessment.questions.filter(
        (item) => item.isCorrect === true // "yes"
      ).length
    : null;

  const answered_questions =
    res != null
      ? thisAssessment.questions.length // thisAssessment.questions.filter((item) => "your_answer" in item).length
      : 0;
  console.info("Results - Yes answers", res);
  console.info("Answered Total", answered_questions);
  const progress = res != null ? res / answered_questions : null;
  console.info(
    "You answered",
    res,
    "out of",
    answered_questions,
    "questions correctly."
  );
  console.info(
    "PROGRESS",
    res,
    "answered out of",

    progress
  );
  return (
    <div className="relative bg-white " style={{}}>
      {/* <div className="lg:absolute lg:inset-0"> */}
      {/* <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2 mx-auto items-center"></div> */}
      <div className="pt-8 text-center">
        <div
          className=" gap-10 mt-2 mb-5 text-center text-3xl leading-8 font-extrabold  text-lime-500 sm:text-4xl"
          style={{ color: "rgb(132 204 22)" }}
        >
          {/*Change text color according to risk */}
          {riskLevel === "Normal" ? (
            "Perfect Score!   That's insane!"
          ) : (
            <span className="text-red-500">At Risk</span>
          )}
          <div
            id="progress"
            className="text-base text-slate-500 font-normal mt-5 mx-auto mb-10 w-96 text-center content-center m-auto"
          >
            <div className="mt-5 text-center">
              You answered {res} of {answered_questions} questions favorably.{" "}
              {progress === 0 ? (
                <div class=" mt-5 w-full bg-gray-200 h-2  align-middle rounded-full">
                  <div
                    class={`bg-red-500 h-2 rounded-full`}
                    style={{ width: "100%" }}
                  ></div>
                </div>
              ) : (
                <ProgressBarSmall progress={progress} />
              )}
            </div>
          </div>
        </div>
        <div className="w-3/4 m-auto mb-10">
          {progress > 0 &&
            progress < 1 &&
            "Unlike horseshoes, close may not be good enough when it comes to cyber security. It only takes one security vulnerability to create an opening for a hacker, so it is essential to fix all risk exposures to safeguard your organization."}
        </div>
      </div>

      <div
        id="header"
        className="relative  px-4  sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2"
      >
        <div id="guage" className="justify-center text-center content-center">
          {riskLevel !== "Normal" ? (
            <>
              {/* <GaugeChart
                id="gauge-chart2"
                nrOfLevels={20}
                percent={progress}
                colors={["#FF0000", "#00FF00"]}
              /> */}
              <div className="text-center  mb-5 p-5">
                <h3
                  className={`text-xl font-medium ${
                    progress < 1 ? "text-red-500" : null
                  }`}
                >
                  {" "}
                  Your Risk Meter{" "}
                </h3>
              </div>
              <GaugeChart
                id="gauge-chart3"
                nrOfLevels={20}
                percent={1 - progress}
                colors={["#00FF00", "#FF0000"]}
                textColor="#000000"
                hideText={true}
              />{" "}
              <div className="m-auto p-3 text-slate-500 text-sm mb-5 p-5 w-3/4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 inline mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
                </svg>
                {riskLevel !== "Normal"
                  ? "Pro-tip: Move the needle. Bring your risk meter down by following your action plan."
                  : "Pro-tip: Keep the needle low by routinely taking the assessment."}
              </div>
            </>
          ) : (
            <DynamicImage imageName={riskLevel.toLowerCase()} type={"meter"} />
          )}
        </div>
        <div id="text" className="lg:col-start-2 lg:pl-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0 p-5">
            <p className="leading-relaxed text-slate-500">
              {blurb}
              {/* {riskLevel === "Highest" &&
                "A grade of Highest Risk denotes a total lack of familiarity with PCI-DSS requirements, as well as the common network security  measures implemented to prevent data security breaches. Immediate action should be taken to increase your awareness of PCI-DSS requirements and safeguard your network from infiltration by hackers and other criminals. Your customers’ credit card data may be at risk. Below are suggested action items you can take to increase your knowledge and better protect your network from a data security breach."}
              {riskLevel === "High" &&
                "A grade of High Risk denotes a total lack of familiarity with PCI-DSS requirements, as well as the common network security  measures implemented to prevent data security breaches. Immediate action should be taken to increase your awareness of PCI-DSS requirements and safeguard your network from infiltration by hackers and other criminals. Your customers’ credit card data may be at risk. Below are suggested action items you can take to increase your knowledge and better protect your network from a data security breach."}
              {riskLevel === "Vulnerable" &&
                "A grade of Vulderable Risk denotes a total lack of familiarity with PCI-DSS requirements, as well as the common network security  measures implemented to prevent data security breaches. Immediate action should be taken to increase your awareness of PCI-DSS requirements and safeguard your network from infiltration by hackers and other criminals. Your customers’ credit card data may be at risk. Below are suggested action items you can take to increase your knowledge and better protect your network from a data security breach."}
              {riskLevel === "Moderate" &&
                "A grade of Moderate Risk denotes a total lack of familiarity with PCI-DSS requirements, as well as the common network security  measures implemented to prevent data security breaches. Immediate action should be taken to increase your awareness of PCI-DSS requirements and safeguard your network from infiltration by hackers and other criminals. Your customers’ credit card data may be at risk. Below are suggested action items you can take to increase your knowledge and better protect your network from a data security breach."}
              {riskLevel === "Low" &&
                "A grade of Low Risk denotes a total lack of familiarity with PCI-DSS requirements, as well as the common network security  measures implemented to prevent data security breaches. Immediate action should be taken to increase your awareness of PCI-DSS requirements and safeguard your network from infiltration by hackers and other criminals. Your customers’ credit card data may be at risk. Below are suggested action items you can take to increase your knowledge and better protect your network from a data security breach."}
              {riskLevel === "Normal" &&
                "This puts your significantly ahead of the curve. It does not mean, however, that your network is completely secure or fully protected. Nor does it mean that your network is fully PCI-DSS compliant. PCI-DSS requirements are extensive and constantly changing. There’s always more to learn and do. Below are suggested action items you can take to increase your knowledge and better protect your network from a data security breach."} */}
            </p>
            {/* <span className="mt-8 text-lg text-primary-500 float-left">
              See how scoring works
            </span> */}
            <div className="mt-10 flex items-center mr-10 ">
              {props.goToActionPlan && (
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                  {riskLevel !== "Normal" && (
                    <button
                      className="mt-8 btn bg-lime-500 hover:bg-lime-600 text-white ml-auto float-right"
                      onClick={goToActionPlan}
                    >
                      Go to full action plan
                    </button>
                  )}
                  <button
                    className="mt-8 btn bg-primary-500 hover:bg-primary-600 text-white ml-auto float-right"
                    onClick={goHome}
                  >
                    Take me home
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

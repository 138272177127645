import React, { useEffect } from "react";
import DoughnutChart from "../../charts/DoughnutChart";
import Skeleton from "../../components/Skeleton";
// Import utilities
import { tailwindConfig } from "../../utils/Utils";

function DashboardCard06(props) {
  const { data } = props;
  const title = props.title;
  const rowspan = props.rowSpan;
  const colSpan = props.colSpan;
  const grouping = props.grouping;
  const sorted = props.sorted;
  const loading = false;

  // const [data, setData] = React.useState([]);
  const [chartLabels, setChartLabels] = React.useState([]);
  const [chartValues, setChartValues] = React.useState([]);
  const [chartData, setChartData] = React.useState({});
  const labels = [];
  const chartD = [];
  // let chartData = {};
  // useEffect(() => {
  console.log("DB6 DATA", data);

  data.forEach((item) => {
    if (item.impediments && item.impediments.length > 0) {
      if (labels.includes(item[props.grouping])) {
        let index = labels.indexOf(item[props.grouping]);
        chartD[index] = chartD[index] + item.impediments.length;
      } else {
        labels.push(item[props.grouping]);
        chartD.push(item.impediments.length);
      }
    }
  });

  // if (data != "loading") {
  //   if (sorted == true) {
  //     console.info("DC6 SORTED", data);
  //     Object.entries(data)
  //       .slice(0, 5)
  //       .map((k, v) => {
  //         console.info("pushing item", "as label", k, "and total", v);
  //         labels.push(k[1][0]);
  //         chartD.push(k[1][1]);
  //         console.info("pushing LABBBBBBBELS DC6", labels);
  //         console.info("pushing LABBBBBBBELS DC6", chartD);
  //       });
  //   } else {
  //     Object.entries(data)
  //       .slice(0, 5)
  //       .map((k, v) => {
  //         console.info("pushing item", "as label", k, "and total", v);
  //         labels.push(k[0]);
  //         chartD.push(k[1]);
  //         console.info("pushing LABBBBBBBELS DC6", labels);
  //         console.info("pushing LABBBBBBBELS DC6", chartD);
  //       });
  //   }
  // }
  let mychartData = "";
  if (!loading) {
    // setChartLabels(labels);
    // setChartValues(chartD);
    mychartData = {
      labels: labels,
      datasets: [
        {
          label: "Top Countries",
          data: chartD, // [35, 30, 35],
          backgroundColor: [
            tailwindConfig().theme.colors.accent[500],
            tailwindConfig().theme.colors.primary[500],
            tailwindConfig().theme.colors.secondary[500],
            tailwindConfig().theme.colors.accent[700],
            tailwindConfig().theme.colors.primary[400],
            tailwindConfig().theme.colors.secondary[400],
            tailwindConfig().theme.colors.accent[300],
            tailwindConfig().theme.colors.primary[300],
            tailwindConfig().theme.colors.secondary[300],
          ],
          hoverBackgroundColor: [
            tailwindConfig().theme.colors.accent[600],
            tailwindConfig().theme.colors.primary[600],
            tailwindConfig().theme.colors.secondary[600],
            tailwindConfig().theme.colors.accent[900],
            tailwindConfig().theme.colors.primary[500],
            tailwindConfig().theme.colors.secondary[500],
            tailwindConfig().theme.colors.accent[600],
            tailwindConfig().theme.colors.primary[400],
            tailwindConfig().theme.colors.secondary[400],
          ],
          hoverBorderColor: tailwindConfig().theme.colors.white,
        },
      ],
    };
    // setChartData(mychartData);
    console.info("DashboardCard06 DC6", chartData);
  }

  // }, [data]);

  console.info("DC6 Final Data", mychartData);
  return (
    <div
      className={`flex  flex-col col-span-full sm:col-span-full md:col-span-${colSpan}  lg:col-span-${colSpan} xl:col-span-${colSpan}  grow-0 row-span-${rowspan} bg-white shadow-lg rounded-sm border border-gray-200`}
    >
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-slate-800">{title}</h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      {loading ? (
        <Skeleton type="spinner" color="gray" fill="purple" />
      ) : (
        <DoughnutChart data={mychartData} width={389} height={260} />
      )}
    </div>
  );
}

export default DashboardCard06;

import React, { useState, useEffect, useRef } from "react";
import { Preferences } from "../modules/_user_preferences";
import { _auth } from "../modules/_auth";
import { Label } from "./Typography/FieldLabel";

const DoNotShowAgain = ({ preferenceId, value, setProfile, profile }) => {
  const [isChecked, setIsChecked] = useState(false);
  const isFirstRender = useRef(true);
  useEffect(() => {
    (async () => {
      //update the specific preference in the profile then set it in local storage
      if (isFirstRender.current || isChecked !== value) {
        isFirstRender.current = false;
        return;
      }
      let prf = _auth.getUserProfile();
      let preferences = prf.preferences;
      let preference = Preferences.getPreference(preferences, preferenceId);
      preference.value = !isChecked;
      _auth.setUserProfile({
        ...prf,
        preferences: preferences,
      });

      const updated = await Preferences.setPreferences({
        id: preferenceId,
        value: !isChecked,
      });

      if (updated) {
        //set preference in profile.preferences in local storage
        console.log("Preference set");
        _auth.setUserProfile({
          ..._auth.getUserProfile(),
          preferences: updated,
        });
      }
      console.log("Preference set");
    })();

    // if (isChecked) {
    //   timeoutId = setTimeout(setPreference, 5000);
    // }

    // return () => {
    //   clearTimeout(timeoutId);
    // };
  }, [isChecked]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="p-2">
      <input
        id={"do_not_show_again_" + preferenceId}
        name={"do_not_show_again_" + preferenceId}
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        defaultChecked={value}
        defaultValue={value}
        value={value}
        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
      />
      <Label
        className="inline-block mr-2 ml-2"
        sm
        htmlFor={"do_not_show_again_" + preferenceId}
      >
        Do not show again
      </Label>
    </div>
  );
};

export default DoNotShowAgain;
